let storage = require('./storage.js');
let userSession = require('./session.js');
let utils = require('./utils.js');
let language = require('./locale/language.js');
let stropheConnection = require('./stropheConnection.js');
let timeFormat = require('./timeformat.js');
let userProfile = require('./userprofile.js')
const Push = require('push.js');

let oncallRoleTimeOut,oncallHandoverSearchTimeOut;
let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

let self = module.exports = {

    /** get Oncall Batch Count API**/
    getOncallBatchCount: function () {
        let oncallsUrl = window._env_.ApiURL + "/onCalls/requests/pendingCount";

        let data;
        
        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getOncallBatchCount()
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
                let oncallhandovercount = data.pendingCount;
                if(oncallhandovercount <= 99){
                    if(oncallhandovercount == 0){
                        $('.oncallhandovercount').hide();
                    }else {
                        $('.oncallhandovercount').show();

                        $('.oncallhandovercount').text(oncallhandovercount)
                    }

                }else {
                    $('.oncallhandovercount').show();
                    $('.oncallhandovercount').text('99+')

                }
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
            }
        });
    },

    /** get Total Oncall Data API**/
    getOncallData: function (conQueryParams) {
        let oncallsUrl = window._env_.ApiURL + "/onCalls/myOnCalls";

        let data;
        
        $.ajax({
            url: oncallsUrl + conQueryParams,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getOncallData(conQueryParams)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $(".sideOncallRoleNavLoader").hide()
            }
        });
        return data;
    },
    /** get Oncall Hand over Data API**/
    getOncallHandoverData: function(params){
        let oncallsUrl = window._env_.ApiURL + params;

        let data;
        
        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getOncallHandoverData(params)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
                return data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $(".sideOncallRoleNavLoader").hide()
            }
        });
        return data;
    },
    /** get Single Oncall Data API**/
    getSingleOncallList: function(param){
        let oncallsUrl = window._env_.ApiURL + param;

        let data;
        
        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getSingleOncallList(param)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
                return data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $('.sideOncallRoleNavLoader').hide()
            }
        });
        return data;
    },
    /** get Multi Requests Data API**/
    getMultiRequestsData: function(param){
        let oncallReqUrl = window._env_.ApiURL + param;

        let data;
        
        $.ajax({
            url: oncallReqUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getMultiRequestsData(param)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
                return data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $('.sideOncallRoleNavLoader').hide()
            }
        });
        return data;
    },
    /**  Oncall Handover & Takeover Unregister User Check Start **/
    OncallTakeoverUserRegisteredOrNot: function(toUserParam){
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        if(userInfo[toUserParam] && userInfo[toUserParam][16] == "1"){
            $('#onCallTakeoverUnregisterUserConfirm').css('display','flex')
            $('.oncallTakeOverUnregisterConfirmBody').children().remove()
            let userName = userInfo[toUserParam][1];
            let body = `<p><span>`+userName+`</span> is <span>Not</span> using <span>Ikonix Connect</span>. Please <span>directly</span> contact <span>`+userName+`</span> about the <span>Role<span> update</p>`;
            
            $('.oncallTakeOverUnregisterConfirmBody').append(body);
        }
        
    },

    OncallHandoverUserRegisteredOrNot: function(toUserParam){
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        if(userInfo[toUserParam][16] == "1"){
            $('#onCallHandoverUnregisterUserConfirm').css('display','flex')
            $('.oncallHandoverUnregisterConfirmBody').children().remove()
            let userName = userInfo[toUserParam][1];
            let body = `<p><span>`+userName+`</span> is <span>Not</span> using <span>Ikonix Connect</span>. Please <span>directly</span> contact <span>`+userName+`</span> about the <span>Role<span> update</p>`;
            
            $('.oncallHandoverUnregisterConfirmBody').append(body);

        }
        
    },
    /**  Oncall Handover & Takeover Unregister User Check End **/


    /** Handle Oncall Handover And Takeover API**/
    oncallHandoverAndTakeoverRequest: function(ApiParams,formData,methodType){
        
        let url = window._env_.ApiURL + ApiParams
        $.ajax({
            url: url,
            type: methodType,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(formData),
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                204: function (response) {
                  console.log("Username does not exist");
                },
                401: function (response) {
                  console.log("Username or password is wrong");
                },
                500: function (response) {
                  console.log("Internal Server Error");
                }
              },
            success: function (response) {
                if(response.status == 400){
                    module.exports.oncallToastAlert(response.message,1);
                    $('#onCallHandAndTakeoverConfirmPopup').css('display','none')
                    $('#onCallHandoverPageConfirmPopup').css('display','none')
                    $('.oncallConfirmBtn').removeAttr('disabled')
                    $("#onCallrolesSearchTxt").trigger('keyup')

                }else {
                    $('#onCallHandAndTakeoverConfirmPopup').css('display','none')
                    $('#onCallHandoverPageConfirmPopup').css('display','none')
                    $(".oncallHandoverPageBack").trigger('click')
                    $(".oncallMultiRequestPopupClose").trigger('click')
                    $('.oncallConfirmBtn').removeAttr('disabled')
    
                    module.exports.oncallToastAlert(response.message,1);
                }
                
            },
            error: function (jqXHR, exception) {
                $('#loader_upload').css("display",'none');
                utils.errorMsgHandling(jqXHR, exception)
            }

        });
    },
    /** Handle Oncall ExitRole API**/
    onCallExitRole: function(param){
        let oncallsUrl = window._env_.ApiURL + param;

        let data;
        
        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getSingleOncallList(param)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
                return data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $('.onCallHandoverExistblur').removeClass('overlay-con');

            }
        });
        return data;
    },
    getOnCallsParams: function(siteId,search,page){
        let params; 
        if(siteId == null || siteId == "" || siteId.length == 0) {
            params = '?search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination;
        } else {
            params = '?siteId=' + siteId + '&search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination;
        }

        return params;
    },
    getOnCallsEmptyCheck(pageNumber,elementId){
        if (pageNumber == 1) 
            $(elementId+ ' .oncall_block').empty();
    },
    getOnCallsActiveMembObj: function(oncallObj){

        let activeMemberName = oncallObj.activeMemberName,
            activeMemberId   = oncallObj.activeMemberId,
            userLoggedIn     = oncallObj.userLoggedIn,
            isActive         = oncallObj.isActive;
 

        let activeMemberColorName,activeMembObj;
        if(!isActive){
            activeMemberName = "Role not active";
            activeMemberColorName = "#696969";

        }else {
            if(activeMemberId == "") {
                activeMemberColorName = "#000";
                activeMemberName  = "Shared Device";
            } else if (activeMemberId != "" && activeMemberId === userLoggedIn) {
                activeMemberColorName = "#42AB14";
                activeMemberName = "You";
            } else {
                activeMemberColorName = "#000";
            }
            
        }
        activeMembObj = {
            activeMemberName        :   activeMemberName,
            activeMemberColorName   :   activeMemberColorName
        }

        return activeMembObj;
    },
    getOncallHandOverBtnObj: function(oncallObj){
        let loggedUserId         = oncallObj.userLoggedIn;
        let onCallRequest        = oncallObj.onCallRequest;
        let onCallRequestsCount  = oncallObj.onCallRequestsCount;
        let requestId;
        let requestFrom,requestFromName,toUser,toUserName,reqType;

        let OncallRoleBtnTxt,
            onCallRoleBtnClass='',
            isAccDecBtn = false,
            dataType = 'TAKEOVER';

            if(onCallRequestsCount == 0){

                OncallRoleBtnTxt = "Hand Over";
                onCallRoleBtnClass = "btn_black";
                dataType = 'HANDOVER';

            }else {
                if(onCallRequestsCount == 1){
                    if(onCallRequest == null || onCallRequest.fromUser == loggedUserId){
                        OncallRoleBtnTxt = "Cancel Hand Over";
                        onCallRoleBtnClass = "btn_red";
                        dataType = 'CANCELHANDOVER';
                        requestId = onCallRequest.id;
                        requestFrom =onCallRequest.fromUser;
                        requestFromName =onCallRequest.fromUserName;
                        toUser =onCallRequest.toUser;
                        toUserName =onCallRequest.toUserName;

                    }else {
                        isAccDecBtn = true;
                        requestId = onCallRequest.id;
                        requestFrom =onCallRequest.fromUser;
                        requestFromName =onCallRequest.fromUserName;
                        toUser =onCallRequest.toUser;
                        toUserName =onCallRequest.toUserName;
                        reqType = onCallRequest.type;
                    }
                    
                }else {
                    OncallRoleBtnTxt = "View Requests";
                    onCallRoleBtnClass = "btn_skyblue";
                    dataType = 'MULTIREQUEST';
                }
                

            }
            let obj = {
                OncallRoleBtnTxt,
                onCallRoleBtnClass,
                dataType,
                requestId,
                requestFrom,
                requestFromName,
                toUser,
                toUserName,
                isAccDecBtn,
                reqType,
            }

            return obj;

    },
    getOncallTakeOverBtnObj: function(oncallObj){
        let loggedUserId         = oncallObj.userLoggedIn;
        let onCallRequest        = oncallObj.onCallRequest;
        let onCallRequestsCount  = oncallObj.onCallRequestsCount;
        let requestId;
        let requestFrom,requestFromName,toUser,toUserName,reqType;

        let OncallRoleBtnTxt,
            onCallRoleBtnClass='',
            isAccDecBtn = false,
            dataType = 'TAKEOVER';

        if(onCallRequestsCount == 0){

            OncallRoleBtnTxt = "Take Over";
            onCallRoleBtnClass = "btn_black";


        }else {
            if(onCallRequestsCount == 1){

                if(onCallRequest == null || onCallRequest.fromUser == loggedUserId){
                    OncallRoleBtnTxt = "Cancel Take Over";
                    onCallRoleBtnClass = "btn_red";
                    dataType = 'CANCELTAKEOVER';
                    requestId = onCallRequest.id;
                    requestFrom =onCallRequest.fromUser;
                    requestFromName =onCallRequest.fromUserName;
                    toUser =onCallRequest.toUser;
                    toUserName =onCallRequest.toUserName;

                }else {
                    isAccDecBtn = true;
                    requestId = onCallRequest.id;
                    requestFrom =onCallRequest.fromUser;
                    requestFromName =onCallRequest.fromUserName;
                    toUser =onCallRequest.toUser;
                    toUserName =onCallRequest.toUserName;
                    reqType = onCallRequest.type;

                }
            }else {
                OncallRoleBtnTxt = "View Requests";
                onCallRoleBtnClass = "btn_skyblue";
                dataType = 'MULTIREQUEST';
            }
            
        }
        let obj = {
            OncallRoleBtnTxt,
            onCallRoleBtnClass,
            dataType,
            requestId,
            requestFrom,
            requestFromName,
            toUser,
            toUserName,
            isAccDecBtn,
            reqType,
        };

        return obj;


    },
    getHandOverAndTakeOverBtn: function(oncallObj){
        let activeMemberName     = oncallObj.activeMemberName;
        let activeMemberId       = oncallObj.activeMemberId;
        let oncallName           = oncallObj.onCallName;
        let loggedUserId         = oncallObj.userLoggedIn;
        let hasSharedUser        = oncallObj.hasSharedUser;
        let sharedUserId         = oncallObj.sharedUserId;
        let is_mandatory         = oncallObj.mandatory;
        let onCallId             = oncallObj.onCallId;
        let requestId;
        let requestFrom,requestFromName,toUser,toUserName,reqType;

        let OncallRoleBtnTxt,
            onCallRoleBtnClass='',
            isAccDecBtn = false,
            dataType = 'TAKEOVER',
            oncallHandOverTakeOverBtnObj;

        if(hasSharedUser && sharedUserId != ""){
            dataType = 'SHAREDDEVICE';
            OncallRoleBtnTxt = "Shared Device";
            onCallRoleBtnClass = "btn_blue sharedDeviceBtn";

        }else {
            if(activeMemberId == ""){
                OncallRoleBtnTxt = "Take Over";
                onCallRoleBtnClass = "btn_black";
                
            }else {

                if(activeMemberId == loggedUserId){
                    oncallHandOverTakeOverBtnObj = module.exports.getOncallHandOverBtnObj(oncallObj)
                    
                }else {
                    oncallHandOverTakeOverBtnObj = module.exports.getOncallTakeOverBtnObj(oncallObj)
    
                }

                OncallRoleBtnTxt    = oncallHandOverTakeOverBtnObj.OncallRoleBtnTxt;
                onCallRoleBtnClass  = oncallHandOverTakeOverBtnObj.onCallRoleBtnClass;
                dataType            = oncallHandOverTakeOverBtnObj.dataType;
                requestId           = oncallHandOverTakeOverBtnObj.requestId;
                requestFrom         = oncallHandOverTakeOverBtnObj.requestFrom;
                requestFromName     = oncallHandOverTakeOverBtnObj.requestFromName;
                toUser              = oncallHandOverTakeOverBtnObj.toUser;
                toUserName          = oncallHandOverTakeOverBtnObj.toUserName;
                isAccDecBtn         = oncallHandOverTakeOverBtnObj.isAccDecBtn;
                reqType             = oncallHandOverTakeOverBtnObj.reqType;
            }
        }
        let handOverTakeOverBtn;

        if(isAccDecBtn){
            handOverTakeOverBtn =  `<button class="btn btn_green onCallRoleBtn" data-type="ACCEPT" data-oncallName="`+oncallName+`"" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+requestFrom+`" data-requestfromName = "`+requestFromName+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`" data-reqType="`+reqType+`">Accept</button><button class="btn btn_orange onCallRoleBtn" data-type="DECLINE" data-oncallName="`+oncallName+`"" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+requestFrom+`" data-requestfromName = "`+requestFromName+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`" data-reqType="`+reqType+`">Decline</button>`

        }else {

            handOverTakeOverBtn =  `<button class="btn `+onCallRoleBtnClass+` onCallRoleBtn" data-type="`+dataType+`" data-oncallName="`+oncallName+`" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+requestFrom+`" data-requestfromName = "`+requestFromName+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`" data-mandatory="`+is_mandatory+`" data-reqType="`+reqType+`">`+OncallRoleBtnTxt+`</button>`
        }

        return handOverTakeOverBtn;
        
    },
    getOnCallElementAppend: function(oncallObj){
        let Icon          = oncallObj.Icon;
        let onCallName    = oncallObj.onCallName;
        let siteId        = oncallObj.siteId;
        let onCallId      = oncallObj.onCallId;
        let isActive      = oncallObj.isActive;
        let hasSharedUser = oncallObj.hasSharedUser;
        let onCallImage   = oncallObj.onCallImage;
        let activeMemberId = oncallObj.activeMemberId;
        let activeMemberColorName = "";
        
        let activeMemberObj = module.exports.getOnCallsActiveMembObj(oncallObj);

        let activeMemberName        =   activeMemberObj.activeMemberName;
        activeMemberColorName       =   activeMemberObj.activeMemberColorName;
        let siteName = utils.getSiteAbbreviation(siteId);

        let handOverTakeOverBtn = module.exports.getHandOverAndTakeOverBtn(oncallObj)

        let oncallListEl =`<li id="oncallList-`+onCallId+`" data-activeMemberId="`+activeMemberId+`" data-oncallName="`+onCallName+`" data-oncallId="`+onCallId+`" data-siteId="`+siteId+`" data-isActive="`+isActive+`" data-hasShared="`+hasSharedUser+`" class="oncall-roles-list">
                                <picture class="user_image" style="background-color: `+ utils.hexToRgbA(onCallImage) +`; border: 1px solid #ddd;">
                                    `+Icon+`
                                </picture>
                                <div class="user_info">
                                    <h4>`+onCallName+`</h4>
                                    <h5>`+siteName+`</h5>
                                    <h6 class="mine" style="color:`+activeMemberColorName+`">`+activeMemberName+`</h6>
                                </div>
                                <div class="btn_block">
                                    `+handOverTakeOverBtn+`
                                </div>
                            </li>`

        $('#oncallManageMentDiv .oncall_block').append(oncallListEl)
      
    },
    getOnCallCustomScrollBarset: function(page,elementId,elementName){

        if (page == null || page == 1 || page == "") {
            $(elementId).mCustomScrollbar("destroy");

            $(elementId).mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                      
                        let oncallRolesCount = $(''+elementId+' .oncall-roles-list').length;
                        let oncallRolesLength = oncallRolesCount / language.locali().default_size_pagination;
                        page = oncallRolesLength + 1;

                        let params = '?page=' + page + '&size=' + language.locali().default_size_pagination;

                        if (oncallRolesCount % language.locali().default_size_pagination == 0) {
                            module.exports.onCallTakeOverHandoverPageHandling(params,'oncallManageMentDiv');
                        }
                    }
                }
            });
        } else {
            $(elementId).mCustomScrollbar("update");
        }
    },
    onCallTakeOverHandoverPageHandling : function(params,elementName){

        let elementId = "#"+elementName;
       
      
        let onCallData        = module.exports.getOncallData(params);
        let userLoggedIn        = stropheConnection.logininfo.userJID;

      
      let searchParams = new URLSearchParams(params),
            pageNumber   = searchParams.get('page');
    
        if (typeof onCallData !== 'undefined' && onCallData.length > 0) {
            $(''+elementId+' #noOncallParticipant').remove();
            module.exports.getOnCallsEmptyCheck(pageNumber,elementId);
    
    
            for (let keys in onCallData) {
                let values = onCallData[keys];
                let onCallImage = values.onCallImage.trim();
                let onCallForegroundColor = values.onCallForegroundColor.trim();
                let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";
                let Icon;

                Icon = utils.getOncallIcon(values.onCallIcon, fillColor, onCallImage);
    
                let activeMemberId      = (values.activeMemberId) ? values.activeMemberId.trim() : "";
                let activeMemberName    = values.activeMemberName.trim();
                let isChatUser          = values.isChatUser;
                let onCallId            = values.onCallId.trim();
                let onCallName          = values.onCallName.trim();
                let siteId              = values.siteId;
                let isActive            = values.isActive;
                let hasSharedUser       = values.hasSharedUser;
                let onCallRequest       = values.onCallRequest;
                let onCallRequestsCount = values.onCallRequestsCount;
                let sharedUserId        = values.sharedUserId;
                let mandatory           = values.mandatory;
                let onCallDescription   = values.onCallDescription;
                
                
                let oncallElObj = {
                    Icon,
                    onCallName,
                    onCallId,
                    siteId,
                    activeMemberName,
                    activeMemberId,
                    isActive,
                    userLoggedIn,
                    isChatUser,
                    onCallRequest,
                    onCallRequestsCount,
                    hasSharedUser,
                    sharedUserId,
                    mandatory,
                    onCallImage,
                    onCallDescription
                }
    
                module.exports.getOnCallElementAppend(oncallElObj);
               
                
            }
            module.exports.getOnCallCustomScrollBarset(pageNumber,elementId,elementName);
    
    
        }else {
            if(pageNumber == 1){
                $(''+elementId+' #noOncallParticipant').remove();

                $(elementId).find(".mCSB_container").append("<div id='noOncallParticipant'>" + language.locali().nooncall_available + "</div>");

                $(elementId).mCustomScrollbar("update");

            }
            
        }
    
    
        return true;
    }, 

    getonCallRequestData: function(params){
        let oncallsUrl = window._env_.ApiURL + "/onCalls/requests";
        let data;
        
        $.ajax({
            url: oncallsUrl + params,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getonCallRequestData(conQueryParams)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $(".sideOncallRequestNavLoader").hide()

            }
        });
        return data;
    },
    getOnCallRequestsEmptyCheck: function(pageNumber,elementId){
        if (pageNumber == 1) {

            $(elementId+ ' .oncallrequest_block').empty();
        }
    },
    getOncallRequestStatus : function(status){
        let statusName;
        let requestLableClassName;
        if(status == 0){ //status '0' Means Pending State
            statusName = 'Request Pending';
            requestLableClassName = '';
        }else if(status == 1){ //status '1' Means Accept State
            statusName = 'Request Accepted'
            requestLableClassName = 'acceptedd';

        }else if(status == 2){ //status '2' Means Decline State
            statusName = 'Request Declined';
            requestLableClassName = 'declined';

        }else{ //status '3' Means Cancel State
            statusName = 'Request Cancelled';
            requestLableClassName = 'canceled';

        }
        let statusObj = {
            statusName,
            requestLableClassName
        }
        return statusObj;
    },
    getOncallRequestType: function(onCallRequestType){
        let requestType;
        if(onCallRequestType == 1){ //type '1' Means Handover Request
            requestType = "Hand Over";
        }else { //type '2' Means Take Over Request
            requestType = "Take Over";
        }
        return requestType;
    },
    getOncallRequestSendOrReceiveType: function(fromUser,fromUserName,toUserName,fromUserImage,toUserImage){
        let userJid = stropheConnection.logininfo.userJID;
        let content;
        let fromOrTo;
        let name,image;
        if(fromUser == userJid){
            content = 'You have sent a' ;
            fromOrTo = 'to';
            name = toUserName;
            image = toUserImage;

        }else {
            content = 'You received' ;
            fromOrTo = 'from';
            name = fromUserName;
            image = fromUserImage;

        }
        let obj = {
            content,
            fromOrTo,
            name,
            image
        }
        return obj;
    },
    profileImgError: function() {
        $('img').on("error", function () {
          this.onerror = "";
          this.src = "./src/images/avatar/chat/profile_img_one.svg";
        });
    },
    getOncallRequestImageElment: function(imageId,fromUserName,concatedLetter){
        let userToken           = storage.getuserToken(),
            userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let mediaUrl = window._env_.ApiURL + "/media/";
        let image,imageElment;
        
        if (imageId != null && imageId != "src/images/avatar/chat/profile_img_one.svg" &&imageId.trim() != "") {
                if (userInfo != null && typeof userInfo[fromUserName.toLowerCase()] != "undefined") {
                    let last2 = fromUserName.slice(-2);
                    if(last2 == '==')
                        imageId = userInfo[fromUserName.toLowerCase()][2];
                    
                    image = mediaUrl + imageId + "?mf=" + userToken;
                } else{
                    image = mediaUrl + imageId + "?mf=" + userToken;
                }

                imageElment = '<img id="onCallRequestImg-'+fromUserName+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'">';
            } else {
                imageElment = '<span><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></span>';
            }


            return imageElment;
    },
    getImageDisplayText: function(name){
        let concatedLetter;
        if(name != "") {
          let splitedArr = name.split(' ');
          let splitedNameArr = splitedArr.filter(function(v){return v!==''});
          let firstNameLetter,secondNameLetter;
          if(splitedNameArr.length == 1){
              firstNameLetter = splitedNameArr[0][0];
              secondNameLetter = splitedNameArr[0][1];
          }else {
              firstNameLetter = splitedNameArr[0][0];
              secondNameLetter = splitedNameArr[1][0];
      
          }
          concatedLetter = (firstNameLetter+secondNameLetter).toUpperCase()
        }
      
        return concatedLetter;
    },
    getOnCallRequestAppend : function(oncallRequestObj){
        let createdAt         =oncallRequestObj.createdAt;
        let fromUser          =oncallRequestObj.fromUser;
        let fromUserImage     =oncallRequestObj.fromUserImage;
        let fromUserName      =oncallRequestObj.fromUserName;
        let onCallName        =oncallRequestObj.onCallName;
        let onCallRequestType =oncallRequestObj.onCallRequestType;
        let status            =oncallRequestObj.status;
        let toUserName        =oncallRequestObj.toUserName;
        let toUserImage       =oncallRequestObj.toUserImage;

        let statusObj = module.exports.getOncallRequestStatus(status);


        let requestType = module.exports.getOncallRequestType(onCallRequestType)

        let sendOrReceiveStatus = module.exports.getOncallRequestSendOrReceiveType(fromUser,fromUserName,toUserName,fromUserImage,toUserImage);
        

        let concatedLetter = module.exports.getImageDisplayText(sendOrReceiveStatus.name);


        let imgEl = module.exports.getOncallRequestImageElment(sendOrReceiveStatus.image,sendOrReceiveStatus.name,concatedLetter);

        // Convert to JavaScript Date object
        let dateAndTime = new Date(createdAt);

        // Extract date and time components
        let date = dateAndTime.toISOString().split('T')[0];
        let time = dateAndTime.toLocaleString().split(',')[1]
        time = time.trim();

        // Concatenate date and time components with space
        let formattedDateTime = date + " " + time;

        let sentdate = dateAndTime.getDate() + '-' + monthNames[dateAndTime.getMonth()] + '-' + dateAndTime.getFullYear();
        let sentimeOnly = timeFormat.convertTimestamp(formattedDateTime, 'created_at');

        let oncallRequestList =`<li class="oncall-request-list">
                                <picture class="oncall_image">
                                    `+imgEl+`
                                </picture>
                                <div class="callrequest_info_block">
                                    <div class="callrequest_info">
                                        <p>`+sendOrReceiveStatus.content+` <span>`+requestType+`</span> request for <span>`+onCallName+`</span> `+sendOrReceiveStatus.fromOrTo+` <span>`+sendOrReceiveStatus.name+`</span></p>
                                    </div>
                                    <div class="time_block">
                                        <p>`+sentdate+`<span>at `+sentimeOnly+`</span></p>
                                    </div>
                                </div>
                                <div class="request_status_block">
                                    <span class="`+statusObj.requestLableClassName+`">`+statusObj.statusName+`</span>
                                </div>
                            </li>`

        $('#oncallRequestDiv .oncallrequest_block').append(oncallRequestList)
    },
    getOnCallRequestCustomScrollBarset : function(pageNumber,elementId,elementName){
        if (pageNumber == null || pageNumber == 1 || pageNumber == "") {
            $(elementId).mCustomScrollbar("destroy");

            $(elementId).mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                      
                        let oncallRolesCount = $(''+elementId+' .oncall-request-list').length;
                        let oncallRolesLength = oncallRolesCount / language.locali().default_size_pagination;
                        let page = oncallRolesLength + 1;

                        let params = '?page=' + page + '&requestType=all&size=' + language.locali().default_size_pagination;

                        if (oncallRolesCount % language.locali().default_size_pagination == 0) {

                            module.exports.onCallRequestPageHandling(params,'oncallRequestDiv');
                        }
                    }
                }
            });
        } else {
            $(elementId).mCustomScrollbar("update");
        }
    },
    onCallRequestPageHandling : function(params,elementName){
      let elementId = "#"+elementName;
      
      let onCallRequestData  = module.exports.getonCallRequestData(params);
      
      let searchParams = new URLSearchParams(params),
            pageNumber   = searchParams.get('page');
    
        if (typeof onCallRequestData !== 'undefined' && onCallRequestData.length > 0) {
            module.exports.getOnCallRequestsEmptyCheck(pageNumber,elementId);
    
    
            for (let keys in onCallRequestData) {
                let values = onCallRequestData[keys];
            
                let oncallRequestObj = {
                    createdAt           : values.createdAt,
                    fromIsChatUser      : values.fromIsChatUser,
                    fromUser            : values.fromUser,
                    fromUserImage       : values.fromUserImage,
                    fromUserName        : values.fromUserName,
                    onCallId            : values.onCallId,
                    onCallName          : values.onCallName,
                    onCallRequestType   : values.onCallRequestType,
                    status              : values.status,
                    toUser              : values.toUser,
                    toUserName          : values.toUserName,
                    toUserImage         : values.toUserImage
                }
    
                module.exports.getOnCallRequestAppend(oncallRequestObj);
               
            }
    
            module.exports.getOnCallRequestCustomScrollBarset(pageNumber,elementId,elementName);
    
        }else {
            $('#oncallRequestDiv .oncallrequest_block').children().remove();
            let noReqEl = `<div id="noOncallRequest">No Requests yet</div>`;
            $('#oncallRequestDiv .oncallrequest_block').append(noReqEl)
        }
    
        return true;
    },

    getOncallConfirmHeading : function(oncallBtnObj){
        let type             = oncallBtnObj.type;
        let reqType          = oncallBtnObj.reqType;

        let heading = ''
        if(type == "TAKEOVER"){
            heading = "Take Over Confirmation";
        }else if(type == "HANDOVER"){
            heading = "Hand Over confirmation";

        }else if(type == "SHAREDDEVICE") {
            heading = "Take Over Confirmation";

        }else if(type == "CANCELTAKEOVER") {
            heading = "Cancel Take Over";

        }else if(type == "CANCELHANDOVER") {
            heading = "Cancel Hand Over";

        }else if(type == "ACCEPT") {
            if(reqType == 1){
                heading = "Accept Hand Over";

            }else if(reqType == 2) {
                heading = "Accept Take Over";
            }

        }else if(type == "DECLINE") {
            if(reqType == 1){
                heading = "Decline Hand Over";

            }else if(reqType == 2) {
                heading = "Decline Take Over";
            }
        }else {
            heading = "Pending Request";
        }

        return heading;
    },
    getMulitRequestBodyView: function(oncallBtnObj) {
        console.log("BBBBBBBBB")
        let oncallName         = oncallBtnObj.oncallName,
            activeMemberName   = oncallBtnObj.activeMemberName,
            onCallId           = oncallBtnObj.onCallIdParam;

        $('.oncallMultiRequestPopup .oncallrequest_block').children().remove();
        $('.oncallMultiRequestPopup').css('display','flex');

        let param = "/onCalls/requests/"+onCallId;

        let requests = module.exports.getMultiRequestsData(param);

        if(typeof requests != 'undefined' && requests.length>0){
            for (let keys in requests) {
                let value          = requests[keys],
                    fromUser       = value.fromUser,
                    fromUserName   = value.fromUserName,
                    fromUserImage  = value.fromUserImage,
                    toUser         = value.toUser,
                    toUserImage    = value.toUserImage,
                    activeMemberId = toUser,
                    toUserName     = value.toUserName,
                    createdAt      = value.createdAt,
                    requestId      = value.id,
                    reqType        = value.type;

                    console.log("onCallName : ",oncallName);
                    console.log("onCallRequestType : ",reqType);
                let concatedLetter = module.exports.getImageDisplayText(fromUserName);

                let sendOrReceiveStatus = module.exports.getOncallRequestSendOrReceiveType(fromUser,fromUserName,toUserName,fromUserImage,toUserImage);

                let requestType = module.exports.getOncallRequestType(reqType)

                let imgEl = module.exports.getOncallRequestImageElment(sendOrReceiveStatus.image,sendOrReceiveStatus.name,concatedLetter);


                // Convert to JavaScript Date object
                let dateAndTime = new Date(createdAt);

                // Extract date and time components
                let date = dateAndTime.toISOString().split('T')[0];
                let time = dateAndTime.toLocaleString().split(',')[1]
                time = time.trim();

                // Concatenate date and time components with space
                let formattedDateTime = date + " " + time;

                let sentdate = dateAndTime.getDate() + '-' + monthNames[dateAndTime.getMonth()] + '-' + dateAndTime.getFullYear();
                let sentimeOnly = timeFormat.convertTimestamp(formattedDateTime, 'created_at');

                let accRejBtn;
                if(requestType == "Hand Over"){
                    accRejBtn =  `<button class="btn btn_red onCallRoleBtn" data-btntype="MultiRequestBtn" data-type="CANCELHANDOVER" data-oncallName="`+oncallName+`"" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+fromUser+`" data-requestfromName = "`+fromUserName+`" data-reqType= "`+reqType+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`">Cancel</button>`;
                }else {
                    accRejBtn =  `<button class="btn_green onCallRoleBtn" data-btntype="MultiRequestBtn" data-type="ACCEPT" data-oncallName="`+oncallName+`"" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+fromUser+`" data-requestfromName = "`+fromUserName+`" data-reqType= "`+reqType+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`">Accept</button><button class="btn btn_orange onCallRoleBtn" data-btntype="MultiRequestBtn" data-type="DECLINE" data-oncallName="`+oncallName+`"" data-activeMember="`+activeMemberName+`" data-activememberid="`+activeMemberId+`" data-oncallId="`+onCallId+`" data-requestid="`+requestId+`" data-requestfrom = "`+fromUser+`" data-requestfromName = "`+fromUserName+`" data-reqType= "`+reqType+`" data-toUser = "`+toUser+`" data-toUserName = "`+toUserName+`">Decline</button>`;
                }
                


                let reqLiEl = `<li class="oncall-roles-list">
                                    <picture class="oncall_image">
                                        `+imgEl+`
                                    </picture>
                                    <div class="callrequest_info">
                                        <p>`+sendOrReceiveStatus.content+`<span> `+requestType+`</span> request for<span>`+oncallName+`</span>`+sendOrReceiveStatus.fromOrTo+`<span>`+fromUserName+`</span></p>
                                        <p class="time_block">
                                            <p>`+sentdate+`<span>at `+sentimeOnly+`</span></p>
                                        </p>
                                    </div>
                                    <div class="btn_block viewrequestbtn">
                                       `+accRejBtn+`
                                    </div>
                                </li>`;
              $('.oncallMultiRequestPopup .oncallrequest_block').append(reqLiEl)
            }
        }
    },
    getMultiReqBtnConfirmBody: function(oncallBtnObj){
        let type               = oncallBtnObj.type;
        let body;

        switch (type) {
            case 'ACCEPT':
                body = `<p>Do you want to Accept this request?</p>`
                break;
            case 'DECLINE':
                body = `<p>Do you want to Decline this request?</p>`
                break;
            case 'CANCELHANDOVER':
                body = `<p>Do you want to Cancel this request?</p>`
                break;
            default:
                body = '';
        }
        return body;


    },
    getOncallConfirmBody : function(oncallBtnObj){
        let type               = oncallBtnObj.type,
            oncallName         = oncallBtnObj.oncallName,
            activeMemberName   = oncallBtnObj.activeMemberName,
            fromUserName       = oncallBtnObj.fromUserName,
            toUserName         = oncallBtnObj.toUserName,
            reqType            = oncallBtnObj.reqType;

        let body;

       switch (type) {
            case 'TAKEOVER':
                if(typeof(activeMemberName) == 'undefined' || activeMemberName == ''){
                    body = `<p>Please confirm you wish to <span>Take Over</span> the role <span>`+oncallName+`</span>`

                }else {

                    body = `<p>Please confirm you wish to <span>Take Over</span> the role <span>`+oncallName+`</span> from <span>`+activeMemberName+`</span></p>`
                }
                break;
            case 'HANDOVER':
                body = `<p>Please confirm you wish to <span>Hand Over</span> the role <span>`+oncallName+`</span> to <span>`+activeMemberName+`</span></p>`

                break;
            case 'SHAREDDEVICE':
                body = `<p>Please confirm you wish to <span>Take Over</span> the role <span>`+oncallName+`</span> from <span>`+activeMemberName+`</span></p>`

                break;
            case 'CANCELTAKEOVER':
                body = `<p>Please confirm you wish to <span>Cancel Take Over</span> the role <span>`+oncallName+`</span> for <span>`+activeMemberName+`</span></p>`

                break;
            case 'CANCELHANDOVER':
                body = `<p>Please confirm you wish to <span>Cancel Hand Over</span> the role <span>`+oncallName+`</span> for <span>`+toUserName+`</span></p>`

                break;
            case 'ACCEPT':
                if(reqType == 1){
                    body = `<p>Please confirm you wish to <span>Accept Hand Over</span> the role <span>`+oncallName+`</span> for <span>`+fromUserName+`</span></p>`
    
                }else if(reqType == 2) {
                    body = `<p>Please confirm you wish to <span>Accept Take Over</span> the role <span>`+oncallName+`</span> for <span>`+fromUserName+`</span></p>`

                }

                break;
            case 'DECLINE':
                if(reqType == 1){
                    body = `<p>Please confirm you wish to <span>Decline Hand Over</span> the role <span>`+oncallName+`</span> for <span>`+fromUserName+`</span></p>`
    
                }else if(reqType == 2) {
                    body = `<p>Please confirm you wish to <span>Decline Take Over</span> the role <span>`+oncallName+`</span> for <span>`+fromUserName+`</span></p>`

                }

                break;
          
            default:
                body = '';
        }
    
        return body;
        
    },

    getOncallRequestParam: function(type){
        let requestParam;
        if(type == "HANDOVER"){
            requestParam = 1;
        }else if(type == "TAKEOVER") {
            requestParam = 2;
        }
        return requestParam;
    },
    getOncallStatusParam: function(type){
        let statusParam;
        if(type == "CANCELHANDOVER"){
            statusParam = 3;
        }else if(type == "CANCELTAKEOVER") {
            statusParam = 3;
        }else if(type == "ACCEPT") {
            statusParam = 1;
        }else if(type == "DECLINE") {
            statusParam = 2;
        }
        
        return statusParam;
    },
    
    oncallRoleListFilter:function(searchContent,page) {

        $('#oncallManageMentDiv .oncall_block').empty();
        $(".sideOncallRoleNavLoader").show()
        clearTimeout(oncallRoleTimeOut);
        oncallRoleTimeOut = setTimeout(() => {
          let oncallQueryParam;
          if (searchContent == '') {
            oncallQueryParam = '?size=' + language.locali().default_size_pagination + '&page=' + page;
            module.exports.onCallTakeOverHandoverPageHandling(oncallQueryParam,'oncallManageMentDiv');

          } else {
            if (searchContent.length > 0) {

              oncallQueryParam = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&page=' + page;
              module.exports.onCallTakeOverHandoverPageHandling(oncallQueryParam,'oncallManageMentDiv');

            } 
          }
        }, 300)
    },
    getOnCallHandoverEmptyCheck : function(pageNumber,elementId,elementName){
        if (pageNumber == 1) {
            if(elementName == 'oncallHandoverDetails'){
                $(elementId+" .onCallTrElement").empty();

            }else {
                $(elementId+" .member_list_block").children().remove();
                
            }
        }

    },
    getOnCallHanoverUserAppend : function(handoverObj){
        let name = handoverObj.name;
        let elementId   = handoverObj.elementId;
        let toUser      = handoverObj.username;
        let oncallId    = handoverObj.oncallId;
        let oncallName  = handoverObj.oncallName;
        let loginId     = handoverObj.userLoggedIn;
        let elementName = handoverObj.elementName;
        let activeMemberId = handoverObj.activeMemberId;
        let userList

        if(elementName == 'oncallHandoverDetails'){
            let statusText,statusColor;

            if(activeMemberId == toUser ) {
                statusText = "On Call";
                statusColor = "#4045D4";
            }else {
                statusText = "Not On Call";
                statusColor = "";
            }

            if(loginId == toUser){
                name = "You";
            }
            
            userList += `<tr class="onCallTrElement">
                                <td>` + name + `</td>
                                <td style="color: `+ statusColor +`">` + statusText + `</td>
                                <td>
                                    <img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg" style="
                                    height: 25px;width: 25px;">
                                </td>
                            </tr>`;   
            $(elementId+' tbody').append(userList);
            $(elementId).addClass('open');

        }else {
            if(loginId != toUser){

                userList = `<li class="onCallHandoverLiElement" data-type="HANDOVER"  data-toUser = `+toUser+` data-oncallName="`+oncallName+`" data-oncallid="`+oncallId+`" data-oncallName="`+oncallName+`" data-activeMember="`+name+`"><a href="#">`+name+`</a></li>`;
            }
            $(elementId+' .member_list_block').append(userList);
        }

    },
    onCallHandoverPageHandling : function(params,elementName){
        let elementId = "#"+elementName;
      
        let onCallHandoverData        = module.exports.getOncallHandoverData(params);
        let userLoggedIn        = stropheConnection.logininfo.userJID;
        let oncallId,oncallName,siteId,isActive,hasShared,activeMemberId;
        if(elementName == 'oncallHandoverDetails'){
            oncallId = $('#onCallHandoverUserId').val();
            oncallName =$('#onCallHandoverUserName').val();
            siteId    = $('#onCallHandoverSiteId').val();

        }else {
            oncallId = $('#handoverPageOnCallUserId').val();
            oncallName =$('#handoverPageOnCallUserName').val();
            siteId = '';
        }
        isActive  = $('#onCallHandoverIsActive').val();
        hasShared = $('#onCallHandoverHasShared').val();
        activeMemberId = $('#onCallHandoverActiveMemberId').val();


        let searchParams = new URLSearchParams(params),
            pageNumber   = searchParams.get('page');


        if(onCallHandoverData){
            let totElemnt =  onCallHandoverData.totalElements;
            let oncallHandoverList = onCallHandoverData.content;

            if(totElemnt > 0){
                module.exports.getOnCallHandoverEmptyCheck(pageNumber,elementId,elementName);

                if(elementName == 'oncallHandoverDetails'){
                    let siteName = utils.getSiteName(siteId)
                    $(".oncall_handover_details_text").text(oncallName);
                    $(".oncall_handover_details_site").text(siteName);
                }

                for (let keys in oncallHandoverList) {
                    let values      = oncallHandoverList[keys];
                    let name        = values.name;
                    let username    = values.username;

                    let handoverObj = {
                        elementId,
                        name,
                        oncallId,
                        isActive,
                        hasShared,
                        activeMemberId,
                        username,
                        oncallName,
                        userLoggedIn,
                        elementName
                    }
                    
                    module.exports.getOnCallHanoverUserAppend(handoverObj);

                }
                let handoverUsersLength = $(elementId+' .member_list_block').children().length;
               
                if(handoverUsersLength == 0){ //Hand Over List Empty Check
                    let noHandoverRecEl = `<div id="noHandovercontent">No members found</div>`
                    $("#oncallHandoverDiv .member_list_block").append(noHandoverRecEl)
                }

            }else {

                module.exports.oncallPageHandlingNoRec(elementId,elementName)
            }

        }
    },
    oncallPageHandlingNoRec : function(elementId,elementName){
        let noRecEl = `<div id="norecentcontent">No members found</div>`
        if(elementName == 'oncallHandoverDetails'){
            $(elementId+" .onCallTrElement").empty();
            $(elementId+' tbody').append(noRecEl);

        }else {
            $(elementId+' .member_list_block').children().remove();
            $(elementId+' .member_list_block').append(noRecEl);

        }

    },
    getAccRejNotificationBody: function(onCallName,from_user_name,request_status,request_type,to_user_name){

        let body,type;

        if(request_type == 1){
            type = "Hand Over"
        }else {
            type = "Take Over"
        }

        if(request_status == 1){
            body = ``+to_user_name+` accepted your `+type+` request for `+onCallName+``;

        }else if(request_status == 2) {
            body = ``+to_user_name+` declined your `+type+` request for `+onCallName+``;

        }else if(request_status == 3) {
            body = ``+from_user_name+` cancelled your `+type+` request for `+onCallName+``;

        }

        return body;

    },
    getNotifyOncallHandOverBody(onCallName,oncallMsgObj){
        let body;
        let request_type        = oncallMsgObj.request_type,
            request_status      = oncallMsgObj.request_status,
            from_user_name      = oncallMsgObj.from_user_name,
            to_user_name        = oncallMsgObj.to_user_name;

        if(request_status == 0){
            body = ``+from_user_name+` wants to Hand over for `+onCallName+` to you`
        }else {
            body = module.exports.getAccRejNotificationBody(onCallName,from_user_name,request_status,request_type,to_user_name)
        }

        return body;
    },
    getNotifyOncallTakeOverBody(onCallName,oncallMsgObj){
        let request_type        = oncallMsgObj.request_type,
        request_status      = oncallMsgObj.request_status,
        from_user_name      = oncallMsgObj.from_user_name,
        to_user_name        = oncallMsgObj.to_user_name;

        let body;
        if(request_status == 0){
            body = ``+from_user_name+` wants to Take over for `+onCallName+` from you`

        }else {
            body = module.exports.getAccRejNotificationBody(onCallName,from_user_name,request_status,request_type,to_user_name)
            
        }

        return body;
    },
    getNotifySetActiveStatusBodyObj(activeMemberId,loggedUserId,onCallName,oncall_id,myOncalls){
        let body;
        if(activeMemberId == loggedUserId){
            body = `You are now Active in `+onCallName+``;
            if ($.inArray(oncall_id, myOncalls) == -1) {
                myOncalls.unshift(oncall_id);
            }
        }else {
             let isValueInObj = utils.isValueInObject(myOncalls,oncall_id)
             if(isValueInObj){
                body = `You are now In Active in `+onCallName+``;
             }
             myOncalls = $.grep(myOncalls, function(e){ 
                return e != oncall_id; 
            });
        }
        let obj = {
            body,
            myOncalls
        }
        return obj;
    },
    getNotifyOncallBodyObj(onCallName,oncallMsgObj){
        let eventStatus    = oncallMsgObj.eventStatus,
            activeMemberId = oncallMsgObj.oncall_member,
            oncall_id      = oncallMsgObj.oncall_id;

        let loggedUserId   = stropheConnection.logininfo.userJID;

        let body;

        let myOncalls = JSON.parse(localStorage.getItem("myOncalls"));

            if(eventStatus == "user_added_oncall"){ // Add or New Oncall Status
                body = `You have been added to `+onCallName+``;
                if(activeMemberId == loggedUserId && $.inArray(oncall_id, myOncalls) == -1) {
                    myOncalls.unshift(oncall_id);
                }

            }else if(eventStatus == "user_removed_oncall"){ // Remove Oncall Status
                body = `You have been removed from `+onCallName+``;
                myOncalls = $.grep(myOncalls, function(e){ 
                    return e != oncall_id; 
                }); 

            }else if(eventStatus == "set_active_status"){ // Check Active or Not

                let setActiveStateObj = module.exports.getNotifySetActiveStatusBodyObj(activeMemberId,loggedUserId,onCallName,oncall_id,myOncalls);

                body      = setActiveStateObj.body;
                myOncalls = setActiveStateObj.myOncalls;

            }else if(eventStatus == "delete_oncall"){ // Delete Oncall Status
                body = ``+onCallName+` has been deleted `;

                myOncalls = $.grep(myOncalls, function(e){ 
                    return e != oncall_id; 
                }); 

            }else {
                body = "";
            }
            localStorage.setItem("myOncalls", JSON.stringify(myOncalls));

            return body;
    },
    getNotificationBody: function(onCallName,oncallMsgObj){
        let request_type        = oncallMsgObj.request_type;

        let body;

        if(request_type == 1){ // Handover Request
            body = module.exports.getNotifyOncallHandOverBody(onCallName,oncallMsgObj);

        }else if(request_type == 2){// Take Over Request type == 2
            body = module.exports.getNotifyOncallTakeOverBody(onCallName,oncallMsgObj);
            
        }else {

            body = module.exports.getNotifyOncallBodyObj(onCallName,oncallMsgObj);

        }

        return body;
        
    },
    oncallNotificationHandling : function(onCallName,oncallMsgObj){
        let notifyName = "On Call Notification";

        let notificationBody = module.exports.getNotificationBody(onCallName,oncallMsgObj)
       if(typeof notificationBody != 'undefined' && notificationBody != ""){
            /** Call getUserProfile method for Updating Oncall dashboard bar**/
            let userId = stropheConnection.logininfo.userJID;
            userProfile.getUserProfile(userId);
            
            Notification.requestPermission().then(function(permission) {
                if (permission === 'granted') {
                    // Permission is granted, create the push notification
                    Push.create(notifyName, {
                        body: notificationBody,
                        timeout: 6000,
                        onClick: function () {
                            window.focus();
                            this.close();
                        }
                    });
                } else if (permission === 'denied') {
                    // Permission is denied, show a message to the user
                    console.log('Push notification permission is denied. You may not receive notifications.');
            
                    // You can also provide a UI element or a button to prompt the user to grant permission
                    // Example: showPermissionPromptButton();
                } else {
                    // Permission is in the default state (neither granted nor denied)
                    console.log('Push notification permission is not granted yet.');
                }
            }).catch(function(error) {
                console.log('Error requesting push notification permission:', error);
            });
       }
    },

    oncallToastAlert: function(alertMst,is_success){
        if(is_success){
            $("#infoContentGreen").text(alertMst);
            $('#infoboxGreen').css("display", "block").delay(5000).fadeOut();
        }else{
            $("#infoContent").text(alertMst);
            $('#infobox').css("display", "block").delay(5000).fadeOut();
        }
    },
    getHandOverAndTakeOverBtnUpdate: function(oncallMsgObj,type){
        $('.sideOncallRoleNavLoader').show()
        let oncall_id       = oncallMsgObj.oncall_id;
        let param = "/onCalls/myOnCalls/"+oncall_id;

        let singleOncallList = module.exports.getSingleOncallList(param)
        if(singleOncallList){
            let activeMemberId      = (typeof singleOncallList.activeMemberId != 'undefined') ? singleOncallList.activeMemberId.trim() : "";
            let activeMemberName    = singleOncallList.activeMemberName.trim();
            let isChatUser          = singleOncallList.isChatUser;
            let onCallId            = singleOncallList.onCallId.trim();
            let onCallName          = singleOncallList.onCallName.trim();
            let siteId              = singleOncallList.siteId;
            let isActive            = singleOncallList.isActive;
            let hasSharedUser       = singleOncallList.hasSharedUser;
            let onCallRequest       = singleOncallList.onCallRequest;
            let onCallRequestsCount = singleOncallList.onCallRequestsCount;
            let sharedUserId        = singleOncallList.sharedUserId;
            let onCallDescription   = singleOncallList.onCallDescription;
    
            let userLoggedIn        = stropheConnection.logininfo.userJID;
        
                    
            let oncallElObj = {
                onCallName,
                onCallId,
                siteId,
                activeMemberName,
                activeMemberId,
                isActive,
                userLoggedIn,
                isChatUser,
                onCallRequest,
                onCallRequestsCount,
                hasSharedUser,
                sharedUserId
            }
            let activeMemberObj = module.exports.getOnCallsActiveMembObj(oncallElObj);
    
            activeMemberName        =   activeMemberObj.activeMemberName;
            let activeMemberColorName   =   activeMemberObj.activeMemberColorName;
            let siteName = utils.getSiteAbbreviation(siteId);
    
            $('#oncallList-'+oncall_id+' h4').text(onCallName);
            $('#oncallList-'+oncall_id+' h5').text(siteName);
            $('#oncallList-'+oncall_id+' h6').text(activeMemberName);
            $('#oncallList-'+oncall_id+' h6').css('color',activeMemberColorName);
            $('#oncallList-'+oncall_id+' .oncallDescriptionEl').text(onCallDescription);
    
            let handOverTakeOverBtn = module.exports.getHandOverAndTakeOverBtn(oncallElObj);
    
            if(type == "RECEIVER"){ //Receiver Side Notification Alert
                module.exports.oncallNotificationHandling(onCallName,oncallMsgObj);
            }
    
    
            return handOverTakeOverBtn;
        }else {
            module.exports.oncallToastAlert("Error!",0);
            
        }

        
    },

    oncallIqHandler : function(oncallMsgObj){
    try {
        let from_user_id  = oncallMsgObj.from_user_id;
        let userId        = stropheConnection.logininfo.userJID;
        let oncall_id     = oncallMsgObj.oncall_id;
        let eventStatus   = oncallMsgObj.eventStatus;

        let btnUpated;

        if(from_user_id == userId){ // Sender Side Oncall
            console.log("SENDER!!!!!!!!")
            btnUpated = module.exports.getHandOverAndTakeOverBtnUpdate(oncallMsgObj,'SENDER');
        }else { // Receiver Side Oncall
            console.log("RECEIVER!!!!!!!!")
            if(eventStatus == 'user_added_oncall' || eventStatus == 'user_removed_oncall' || eventStatus == 'update_oncall'){
                $("#onCallrolesSearchTxt").trigger('keyup');
                userProfile.getUserProfile(userId); // Oncall Header Div Live Update

            }else {

                btnUpated = module.exports.getHandOverAndTakeOverBtnUpdate(oncallMsgObj,'RECEIVER');
                module.exports.getOncallBatchCount();
            }
        }

        $('#oncallList-'+oncall_id+' .btn_block').children().remove();
        $('#oncallList-'+oncall_id+' .btn_block').append(btnUpated);


    }catch (error) {
        console.log("Oncall Handler Error :", error);
    }
    }
   
}

  $(".oncallHandAndTakeOverDiv").on("click", function (e) {
    $(".side-oncall-role").css({
        "left": "0",
        "display": "block"
    });
    $('.plus-circles').css('display','none');

    $(".sideOncallRoleNavLoader").show()
    $('#oncallManageMentDiv .oncall_block').empty();

      setTimeout(function () {
        $("#onCallrolesSearchTxt").trigger('keyup');
      },500)
    

  })


  $(".oncallRequestIcon").on("click", function (e) {
    $(".side-oncall-request").css({
        "left": "0",
        "display": "block"
    });
    $('.plus-circles').css('display','none');

    let params;
    let page = 1;
    let elementName = "oncallRequestDiv"

    params = '?page=' + page + '&requestType=all&size=' + language.locali().default_size_pagination;
    
    $(".sideOncallRequestNavLoader").show()
    $('#oncallRequestDiv .oncallrequest_block').empty();

      setTimeout(function () {
        module.exports.onCallRequestPageHandling(params,elementName);

      },500)
  });

  $(".oncall-request-back").on("click", function (e) {
    $(".side-oncall-request").css({
        "left": "-100%"
      });
  });

  $("body").on('click', '.onCallRoleBtn', function(e) {
    e.stopPropagation();
    let type             = $(this).attr('data-type');
    let oncallName       = $(this).attr('data-oncallName');
    let activeMemberName = $(this).attr('data-activeMember');
    let onCallIdParam    = $(this).attr('data-oncallid');
    let toUser           = $(this).attr('data-touser');
    let toUserName       = $(this).attr('data-tousername');
    let fromUser         = $(this).attr('data-requestfrom');
    let fromUserName     = $(this).attr('data-requestfromname');
    let reqType          = $(this).attr('data-reqType');
    let btnType          = $(this).attr('data-btntype');

    

    let oncallBtnObj = {
        type,
        oncallName,
        activeMemberName,
        toUser,
        toUserName,
        fromUser,
        fromUserName,
        onCallIdParam,
        reqType
    }

    if(type == "HANDOVER"){
        $(".side-oncall-handoverpage").css({
            "left": "0",
            "display": "block"
        });
        $('.plus-circles').css('display','none');
        
        let params;
        let page = 1;
        let elementName = "oncallHandoverDiv";
        let oncallId = onCallIdParam;
        let is_mandatory = $(this).attr('data-mandatory');

        if(is_mandatory == 1){
            $("#oncallHandoverDiv .member_heading").text("Choose Member");
            $('#onCallExitBtn').hide()
        }else {
            $("#oncallHandoverDiv .member_heading").text("Choose Member or Exit Role");
            $('#onCallExitBtn').show()

        }
        $('#handoverPageOnCallUserId').val(oncallId);
        $('#handoverPageOnCallUserName').val(oncallName);
        $('#handOverPageHeading').text(oncallName)
        $('#handOverPageHeading').attr('title',oncallName)

        
        params = '/onCalls/members/'+oncallId+'?&page=' + page + '&size=' + language.locali().default_size_pagination;
        
        module.exports.onCallHandoverPageHandling(params,elementName);
    }else {
        if(type == "MULTIREQUEST"){
            module.exports.getMulitRequestBodyView(oncallBtnObj);

        }else if(type != "SHAREDDEVICE"){
            let onCallCofirmationHeading = module.exports.getOncallConfirmHeading(oncallBtnObj)
            let onCallCofirmationBody    = (btnType == 'MultiRequestBtn')? module.exports.getMultiReqBtnConfirmBody(oncallBtnObj) : module.exports.getOncallConfirmBody(oncallBtnObj)
        
            if((btnType == 'MultiRequestBtn')){
                $('#onCallHandAndTakeoverConfirmPopup .dialogbox_heading').hide()
                $('#onCallHandAndTakeoverConfirmPopup .oncallConfirmBtn').html("Ok")
            }else {
                $('#onCallHandAndTakeoverConfirmPopup .dialogbox_heading').show()
                $('#onCallHandAndTakeoverConfirmPopup .oncallConfirmBtn').html("Confirm")
            }

            $('.oncallConfirmHeading').text(onCallCofirmationHeading);
            $('.oncallConfirmBody').children().remove()
            $('.oncallConfirmBody').append(onCallCofirmationBody);
        
        
            let onCallRequestTypeParam = module.exports.getOncallRequestParam(type);
            let toUserParam = $(this).attr('data-activememberid');
            let statusParam = module.exports.getOncallStatusParam(type);
            let requestIdParam = $(this).attr('data-requestid');
        
            $('#onCallIdParam').val(onCallIdParam);
            $('#onCallRequestTypeParam').val(onCallRequestTypeParam);
            $('#toUserParam').val(toUserParam);
            $('#statusParam').val(statusParam);
            $('#requestIdParam').val(requestIdParam);
            $('#btnTypeParam').val(type);
        
            $('#onCallHandAndTakeoverConfirmPopup').css('display','flex')
        }
       
    }

  });

  /** Oncall Role Search**/
  $("body").on('keyup', '#onCallrolesSearchTxt', function(e) {

    let searchContent     = $(this).val(),
        page              = 1;

        if(searchContent == ''){
            $("#oncallSearchRoleClose").hide();
            $(".oncallSearchRole").show();
        }else {
            searchContent = encodeURIComponent(searchContent);
            $("#oncallSearchRoleClose").show();
            $(".oncallSearchRole").hide();
        }
        module.exports.oncallRoleListFilter(searchContent,page)
             
   });
  $("body").on('click', '#oncallSearchRoleClose', function(e) {

        $("#onCallrolesSearchTxt").val('');
        $("#onCallrolesSearchTxt").trigger('keyup')
  });

  //on keydown, clear the countdown 
  $("body").on('keydown', '#onCallrolesSearchTxt', function(e) {

    clearTimeout(oncallRoleTimeOut);
  });

 

  $("body").on('click', '.oncallConfirmCancel', function(e) {
   $('#onCallHandAndTakeoverConfirmPopup').css('display','none')
  });

  $("body").on('click', '.oncallTakeoverUnregisterConfirmCancel', function(e) {
    $('#onCallTakeoverUnregisterUserConfirm').css('display','none')
   });

   $("body").on('click', '.oncallHandoverUnregisterConfirmCancel', function(e) {
    $('#onCallHandoverUnregisterUserConfirm').css('display','none')
   });

  $(".oncallHandoverPageBack").on("click", function (e) {
    $("#onCallrolesSearchText").val('');
    $('.singleOncallSearch').show();
    $('#singleOncallSearchClose').hide();
    $('#handoverPageOnCallUserId').val('')
    $(".side-oncall-handoverpage").css({
        "left": "-100%"
    });

    setTimeout(function () {
        $(".sideOncallRoleNavLoader").show()

        setTimeout(function () {
            $(".sideOncallRoleNavLoader").hide()
        },500)
    },500)
    
  });

  $("body").on('click', '.oncallConfirmBtn', function(e) {
    $('.oncallConfirmBtn').attr('disabled','disabled')
    let oncallId                = $("#onCallIdParam").val();
    let onCallRequestTypeParam  = $("#onCallRequestTypeParam").val();
    let toUserParam             = $("#toUserParam").val();
    let statusParam             = $("#statusParam").val();
    let requestIdParam          = $("#requestIdParam").val();
    let btnTypeParam            = $("#btnTypeParam").val();

    let ApiParams;
    let formData;
    let methodType; 

    if(btnTypeParam == "TAKEOVER"){
        ApiParams ='/onCalls/requests';

        formData = {
            onCallId            : oncallId,
            onCallRequestType   : onCallRequestTypeParam,
            toUser              : toUserParam
        }
        methodType = "POST";
        module.exports.oncallHandoverAndTakeoverRequest(ApiParams,formData,methodType)
        $('.onCallHandoverPageblur').addClass('overlay-con');

        
        setTimeout(() => {
            module.exports.OncallTakeoverUserRegisteredOrNot(toUserParam)

        },500)


    }else if(btnTypeParam == "CANCELHANDOVER" || btnTypeParam == "CANCELTAKEOVER" || btnTypeParam == "ACCEPT" || btnTypeParam == "DECLINE"){
        ApiParams ='/onCalls/requests/'+requestIdParam;

        formData = {
            status : statusParam, // statusParam = 3 for Cancel Request
            deviceType:'Web'
        }
        methodType = "PUT";


        module.exports.oncallHandoverAndTakeoverRequest(ApiParams,formData,methodType)

    }else if(btnTypeParam == "SHAREDDEVICE"){
        $("#infoContent").text('No Oncall Request For Shared Oncalls');
        $('#infobox').css("display", "block").delay(5000).fadeOut();
        $('#onCallHandAndTakeoverConfirmPopup').css('display','none')

    }


  });

  $("body").on('click', '.onCallHandoverLiElement', function(e) {

    $('.onCallHandoverPageblur').removeClass('overlay-con')
    let type             = $(this).attr('data-type');
    let oncallName       = $(this).attr('data-oncallName');
    let activeMemberName = $(this).attr('data-activeMember');
    let onCallIdParam    = $(this).attr('data-oncallid');

    let oncallBtnObj = {
        type,
        oncallName,
        activeMemberName
    }

    let onCallCofirmationHeading = module.exports.getOncallConfirmHeading(oncallBtnObj)
    let onCallCofirmationBody    = module.exports.getOncallConfirmBody(oncallBtnObj)

    $('.oncallHandoverConfirmHeading').text(onCallCofirmationHeading);
    $('.oncallHandoverConfirmBody').children().remove()
    $('.oncallHandoverConfirmBody').append(onCallCofirmationBody);


    let onCallRequestTypeParam = module.exports.getOncallRequestParam(type);
    let toUserParam = $(this).attr('data-toUser');
    let statusParam = module.exports.getOncallStatusParam(type);
    let requestIdParam = $(this).attr('data-requestid');

    $('#handOveronCallIdParam').val(onCallIdParam);
    $('#handOveronCallRequestTypeParam').val(onCallRequestTypeParam);
    $('#handOvertoUserParam').val(toUserParam);
    $('#handOverstatusParam').val(statusParam);
    $('#handOverrequestIdParam').val(requestIdParam);
    $('#handOverbtnTypeParam').val(type);

    $('#onCallHandoverPageConfirmPopup').css('display','flex')
  });


  $("body").on('click', '.handOveroncallConfirmCancel', function(e) {
    $('#onCallHandoverPageConfirmPopup').css('display','none')
   });
  
   $("body").on('click', '.handOveroncallConfirmBtn', function(e) {

    let oncallId                = $("#handOveronCallIdParam").val();
    let onCallRequestTypeParam  = $("#handOveronCallRequestTypeParam").val();
    let toUserParam             = $("#handOvertoUserParam").val();
    let statusParam             = $("#handOverstatusParam").val();
    let requestIdParam          = $("#handOverrequestIdParam").val();
    let btnTypeParam            = $("#handOverbtnTypeParam").val();

    let ApiParams;
    let formData;
    let methodType;
        

    if(btnTypeParam == "HANDOVER" || btnTypeParam == "TAKEOVER"){
        ApiParams ='/onCalls/requests';

        formData = {
            onCallId            : oncallId,
            onCallRequestType   : onCallRequestTypeParam,
            toUser              : toUserParam
        }
        methodType = "POST";
        module.exports.oncallHandoverAndTakeoverRequest(ApiParams,formData,methodType)

        setTimeout(() => {
            module.exports.OncallHandoverUserRegisteredOrNot(toUserParam)

        },500)

    }else if(btnTypeParam == "CANCELHANDOVER" || btnTypeParam == "CANCELTAKEOVER" || btnTypeParam == "ACCEPT" || btnTypeParam == "DECLINE"){
        ApiParams ='/onCalls/requests/'+requestIdParam;

        formData = {
            status : statusParam // statusParam = 3 for Cancel Request
        }
        methodType = "PUT";

        module.exports.oncallHandoverAndTakeoverRequest(ApiParams,formData,methodType)
    }

  });

  $("body").on('keyup', '#onCallrolesSearchText', function(e){
    let serachVal = $(this).val();
    
    let params;
    let page = 1;
    let elementName = "oncallHandoverDiv";

    let oncallId = $('#handoverPageOnCallUserId').val();

    if(serachVal == ''){
        $("#singleOncallSearchClose").hide();
        $(".singleOncallSearch").show();
    }else {
        serachVal = encodeURIComponent(serachVal);
        $("#singleOncallSearchClose").show();
        $(".singleOncallSearch").hide();
    }

    params = '/onCalls/members/'+oncallId+'?&page=' + page +'&search=' + serachVal +'&size=' + language.locali().default_size_pagination;

    clearTimeout(oncallHandoverSearchTimeOut);
    oncallRoleTimeOut = setTimeout(() => {
        module.exports.onCallHandoverPageHandling(params,elementName);
    },300)
        

  });

  $("body").on('keydown', '#onCallrolesSearchText', function(e) {

    clearTimeout(oncallHandoverSearchTimeOut);

  });

  $("body").on('click', '#singleOncallSearchClose', function(e) {

    $("#onCallrolesSearchText").val('');
    $("#onCallrolesSearchText").trigger('keyup')
});

  $("body").on('click', '.oncallMultiRequestPopupClose', function(e) {
    $('.oncallMultiRequestPopup').css('display','none')
  });

  $("body").on('click', '.oncall-roles-list', function(e) {
    let oncallId = $(this).attr('data-oncallId');
    let oncallName = $(this).attr('data-oncallName');
    let siteId = $(this).attr('data-siteId');
    let isActive = $(this).attr('data-isActive');
    let hasShared = $(this).attr('data-hasShared');
    let activeMemberId = $(this).attr('data-activeMemberId');
    
    $('#onCallHandoverUserName').val(oncallName);
    $('#onCallHandoverUserId').val(oncallId);
    $('#onCallHandoverSiteId').val(siteId);
    $('#onCallHandoverIsActive').val(isActive);
    $('#onCallHandoverHasShared').val(hasShared);
    $('#onCallHandoverActiveMemberId').val(activeMemberId);
    
    let page = 1;
    let elementName = 'oncallHandoverDetails';

    let params = '/onCalls/members/'+oncallId+'?&page=' + page + '&size=' + language.locali().default_size_pagination;
        
    module.exports.onCallHandoverPageHandling(params,elementName);

  });


  $("body").on('click', '#onCallExitBtn', function(e) {
    $("#onCallExitRolePopup").css('display','flex')
  });

  $("body").on('click', '.oncallExitConfirmCancel', function(e) {
    $("#onCallExitRolePopup").css('display','none')

  });

  $("body").on('click', '.oncallExitConfirmBtn', function(e) {
    $('.onCallHandoverExistblur').addClass('overlay-con');
    let oncallId = $('#handoverPageOnCallUserId').val();
    let params = '/onCalls/exitActiveRole/'+oncallId;

     module.exports.onCallExitRole(params);

     module.exports.oncallToastAlert(language.locali().oncall_inactive_alert,0);

    $("#onCallExitRolePopup").css('display','none')
    $(".oncallHandoverPageBack").trigger('click')

  });

  $("body").on('click', '.oncall-handover-details-close', function(e) {
    $("#oncallHandoverDetails").removeClass('open');
  });