let stropheConnection = require("./stropheConnection.js");
let lastActivity = require("./lastActivity.js");
let conversation = require("./conversation.js");
let utils = require("./utils.js");
let groups = require("./groups.js");
let timeFormat = require("./timeformat.js");
let storage = require("./storage.js");
let audio = require("./audio.js");
let language = require("./locale/language.js");
let originalUnRead = 0;
let imageColor = "";
let fwdImageDiv = "";
let image = "";
let emptyStatus = 0;
let priorityMessages = require("./priorityMessages.js");
let vCard = require("./vCard.js");
let mediaUrl = window._env_.ApiURL + "/media/";
let groupInfo,userInfo,broadcastProfile;
let userOncalls = require('./userOncalls.js');
const userprofile = require("./userprofile.js");


module.exports = {

  recentchatcontent: function (recentChatObj) {
    let nodeJid = recentChatObj.nodeJid;
    let name = recentChatObj.name;
    let image = recentChatObj.image;
    let msgcontent = recentChatObj.msgcontent;
    let time = recentChatObj.time;
    let status = recentChatObj.status;
    let messageid = recentChatObj.messageid;
    let unReadCount = recentChatObj.unReadCount;
    let tstatusImage = recentChatObj.tstatusImage;
    let updateRecent = recentChatObj.updateRecent;
    let recentChatMsgFrom = recentChatObj.recentChatMsgFrom;

    let isActiveTechnician =  0;
    let timediv = "";
    let userProfileStatus = "";
    let userProfileStatusImage = "";
    let userRoleDept = "";
    let roleDiv= "";
    let siteAbb = "";
    let statusmeta;

    let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
    let userJID = stropheConnection.logininfo.userJID;
    let classactive,toJID,headerdiv,imgDiv,contentdiv,scrolldiv,memberExist;
    $("#norecentcontent").remove();
    $("#norecent").remove();
    if (typeof name != "undefined") {
      if( $("[id='"+nodeJid+"']").find('.priority-message-recent').length == 0 || updateRecent != 0 ){
        originalUnRead =  $("[id='"+nodeJid+"']").find(".notify").text();
        $("#recentcontent").find("[id='"+nodeJid+"']").remove();
      } else {
        return;
      }

      $(".recentchatcontact").find("[id='forwardcontact-"+nodeJid+"']").remove();
      classactive = "";
      toJID = utils.toJID();
      headerdiv = '<div  class="row sideBar-body recentcontent' + classactive + '" id= "' + nodeJid + '" ><div class = "recenttext" ><div class="col-sm-3 col-xs-3 sideBar-avatar">';
      headerdiv = headerdiv + '<div class="avatar-icon chat-list">';

      let Icon ='';
      if(userInfo != null && typeof userInfo[nodeJid] != "undefined" && userInfo[nodeJid][7] == "1") {
        image = userInfo[nodeJid][2];
        if(typeof image != 'undefined' && ( utils.getHexRegexPattern().test(image) || image.startsWith("#"))) {
          let onCallIcon = userInfo[nodeJid][14];
          let onCallForegroundColor = userInfo[nodeJid][15].trim();
          let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";
          let onCallImage = image;
          Icon = utils.getOncallIcon(onCallIcon, fillColor, onCallImage);
        }
      }

      if(typeof image != 'undefined' && ( utils.getHexRegexPattern().test(image) || image.startsWith("#") )) {
        if(groupInfo != null && typeof groupInfo[nodeJid] != "undefined"){
          let onCallIcon = groupInfo[nodeJid][7];
          let onCallForegroundColor = groupInfo[nodeJid][8].trim();
          let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";
          let onCallImage = image;
          Icon = utils.getOncallIcon(onCallIcon, fillColor, onCallImage);
        }
        imageColor = utils.hexToRgbA(image);
        imgDiv = '<div class="color-code-recent" id="color-'+nodeJid+'">'+Icon+'</div>';
        fwdImageDiv = '<div class="color-code-fwd" id="color-fwd-'+nodeJid+'">'+Icon+'</div>';
      } else {
        let concatedLetter,imageElment,fwdImgElement;

        if(name != "") {
            concatedLetter = utils.getImageDisplayText(name);
        }

        if(typeof image !='undefined' && (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '')){
          
          let backgroundImg;
          if(groupInfo != null && typeof groupInfo[nodeJid] != 'undefined'){
            backgroundImg = 'src/images/avatar/group/group_four.svg'
          }else {
            backgroundImg = 'src/images/avatar/chat/profile_img_one.svg'
          }
          
            imageElment ='<img id="recentContacImg-'+nodeJid+'" class="other-prof-pic recentChatLazy" data-src="' + image + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'" src="'+backgroundImg+'">';
            fwdImgElement = '<img id="fwdRecentContacImg-'+nodeJid+'" class="forwardprofileicon" src="' + image + '" data-text="'+concatedLetter+'"></img>';
        }  else {
            imageElment = '<svg id ="recentContactImg-'+nodeJid+'" class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';

            fwdImgElement = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }

        imgDiv = imageElment;
        fwdImageDiv = fwdImgElement;
      }
      
      if (toJID != null && typeof toJID != "undefined" && nodeJid == stropheConnection.Strophe().getNodeFromJid(toJID)) {
        headerdiv = headerdiv + '<span class="notify" style="display:none;">0</span>'+imgDiv+'</div></div>';
      } else {
        if (unReadCount == 0 || typeof unReadCount == "undefined") {
          headerdiv = headerdiv + '<span class="notify" style="display:none;">0</span>'+ imgDiv +'</div></div>';
        } else if (unReadCount == -1) {
          if (originalUnRead == 0) {
            headerdiv = headerdiv + '<span class="notify" style="display:none;">0</span>'+ imgDiv +'</div></div>';
          } else {
          
              headerdiv = headerdiv + '<span class="notify" style="display:block;">' + originalUnRead + '</span>'+ imgDiv +'</div></div>';
            if (originalUnRead == 1) {
              if (document.title != "Ikonix Connect") {
                if (document.title.substring(1, 1) != 0) {
                  let count = parseInt(document.title.substring(1, 1)) + 1;
                  count = parseInt(count);
                  document.title = "(" + count + ") Ikonix Connect";
                }
              } else {
                document.title = "(" + 1 + ") Ikonix Connect";
              }
            }
          }
        } else {

          headerdiv = headerdiv + '<span class="notify" style="display:none;">0</span>'+ imgDiv +'</div></div>';
          if (document.title != "Ikonix Connect") {
            if (document.title.substring(1, 1) != 0) {
              let count = parseInt(document.title.substring(1, 1)) + 1;
              count = parseInt(count);
              document.title = "(" + count + ") Ikonix Connect";
            }
          } else {
            document.title = "(" + 1 + ") Ikonix Connect";
          }
        }
      }
      
      contentdiv = '<div class="col-sm-9 col-xs-9 sideBar-main">';
      let contentDivClass = "";
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        userRoleDept = utils.frameUserRoleDept(userInfo[nodeJid][4], userInfo[nodeJid][5], utils.getSiteAbbreviation(userInfo[nodeJid][6]));
        if(userRoleDept != "")
          contentDivClass = "oncall-main oncall-main-staus";
      }

      contentdiv = contentdiv + '<div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name '+contentDivClass+'">';

      scrolldiv = '<div class="dropdown prof-opt custom-down"><a class="dropdown-toggle"  type="button" data-toggle="dropdown" aria-expanded="false">';
      scrolldiv = scrolldiv + '<i class="option-arrow" aria-hidden="true"></i></a>';
      scrolldiv = scrolldiv + '<ul class="dropdown-menu head-option" role="menu" aria-labelledby="menu1">';
      scrolldiv = scrolldiv + '<li role="presentation"><a class="clear-msg-recent"  href="javascript:void(0);">' + language.locali().clear_messages + "</a></li>";
      memberExist = false;
      
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        statusmeta = '';
        if(nodeJid != storage.adminName()) {

          if (userInfo[nodeJid][16] == "1") {
            userProfileStatus = '';
          } else {
            userProfileStatus = utils.getStatusName(userInfo[nodeJid][3]);
            userProfileStatusImage = utils.getStatusImage(userInfo[nodeJid][3]);

            if(userProfileStatus == "") {
              if(userInfo[nodeJid][3] == '0'){
                  statusmeta = 'offline'
              }else{
                  statusmeta = ''
              }
              emptyStatus = 1;
            } else {
              emptyStatus = 0;
            }
          }
        }
        userRoleDept = utils.frameUserRoleDept(userInfo[nodeJid][4], userInfo[nodeJid][5], utils.getSiteAbbreviation(userInfo[nodeJid][6]));
        isActiveTechnician = userInfo[nodeJid][8];
        scrolldiv = scrolldiv + '<li role="presentation" class="delete_group"><a  class="exit-group-recent"  href="javascript:void();">' + language.locali().delete_chat + "</a></li></ul></div>";

      } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
        memberExist = true;
        if (groupDetail != null && typeof groupDetail[nodeJid] != "undefined") {
          let groupmember = groupDetail[nodeJid];
          for (let keys in groupmember) {
            let values = groupmember[keys];
            let itemvalue = values.user;
            if (itemvalue == userJID) {
              memberExist = false;
            }
          }

          if (!memberExist) {
            if(typeof(groupInfo[nodeJid][4]) == "undefined") {

              scrolldiv = scrolldiv + '<li role="presentation" class="exitclass"><a  class="exit-group-recent"  href="javascript:void();">' + language.locali().exit_group + " </a></li></ul></div>";
            }
          } else {

            scrolldiv = scrolldiv + '<li role="presentation" class="delete_group"><a  class="exit-group-delete"  href="javascript:void();">' + language.locali().delete_group + " </a></li></ul></div>";
          }

        }
        
        siteAbb  = utils.getSiteAbbreviation(groupInfo[nodeJid][9]);
        if(siteAbb != '')
          roleDiv = '<span class="profession" title="'+ siteAbb +'">' + siteAbb + "</span>";

        let lastGrpEl = $('#conversation'+nodeJid).find('.row.message-body').eq(-1)
        let secondLastGrpEl = $('#conversation'+nodeJid).find('.row.message-body').eq(-2)
        if((typeof lastGrpEl != "undefined" && lastGrpEl.attr('data-msgfrom')) == (typeof lastGrpEl != "undefined" && secondLastGrpEl.attr('data-msgfrom'))){
          lastGrpEl.find(".receiver").find('.receivefrom').remove();
        }

      } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
        scrolldiv = scrolldiv + '<li role="presentation" class="delete_group"><a  class="exit-group-recent"  href="javascript:void();">' + language.locali().delete_chat + "</a></li></ul></div>";
      }

      if (time == "") {
        time = timeFormat.currenttime();
      }

      timediv = '</div></div></div>' + scrolldiv + '</div>';
      contentdiv = contentdiv + '<div class="name-and-status-div"><span class="name-meta contact-list" title="' + name + '">' + minEmoji(name) + '</span>';
      if(userProfileStatus != "" && statusmeta != 'offline')
        contentdiv = contentdiv + '<span class="profile-status-meta status-all" title="'+userProfileStatus+'" style="color: '+userProfileStatusImage+'">' + userProfileStatus + '</span>';

      if(statusmeta == 'offline')
      {
          let Offline = 'Offline';
        contentdiv = contentdiv + '<span class="profile-status-meta status-all" title="offline" style="color: #e90e18;display: block">'+ Offline + '</span>';

      }
      if(userRoleDept != "")
        roleDiv = '<span class="profession" title="'+userRoleDept+'">' + userRoleDept + "</span>";

      if(userInfo != null && typeof userInfo[nodeJid] != "undefined" && userInfo[nodeJid][7] == '1'){
        let aliasUserSiteAbb = utils.getSiteAbbreviation(userInfo[nodeJid][6]);
        roleDiv = '<span class="profession" title="'+ aliasUserSiteAbb +'">' + aliasUserSiteAbb + "</span>";

      }
      contentdiv = contentdiv + '</div><div class="role-dept-site-div">'+ roleDiv + '</div>';


        contentdiv = contentdiv + '<div class="time-msg-block align-bottom" data-msgfrom = '+recentChatMsgFrom+'><span class="time">' + time + '</span><div class="sideBar-msg" id="' + messageid + '"></div></span><span class="name-status text-italic" >' + minEmoji(msgcontent) + "</span><span class='sideBar-msg typing-msg'>typing...</span>";

        if(isActiveTechnician == 1)
          contentdiv += '<span class="oncall-recent"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-recent-text">On Call</span></span>';
        
        contentdiv += '</div>';

      if(roleDiv != "")
        $("[id='"+nodeJid+"']").find(".profession").css("display", "none");
      
      contentdiv = contentdiv + '<div class="delivery-status-div"><span class="list-msg-status msg-sent-status">' + status + '</span></div></div>';
      $("#recentchatcontent").mCustomScrollbar("destroy");
      $("#recentcontent").prepend(headerdiv + contentdiv + timediv);

      if(userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        let imgEl = document.getElementById("recentContacImg-"+nodeJid);
        if(imgEl){
          utils.imgElementErrorCheck(imgEl)
        }
        

        
      }
      if(emptyStatus == 1 && statusmeta != 'offline') {
        $("[id='"+nodeJid+"']").find(".profile-status-meta").css("display", "none");
        $("[id='"+nodeJid+"']").find(".role-dept-meta").css("margin-top", "5px");
      } 

      $("[id='color-"+nodeJid+"']").css("background-color", imageColor);
      
      if ($("[id='"+nodeJid+"']").length &&  nodeJid != stropheConnection.Strophe().getNodeFromJid(toJID)) {

        if ($("[id='conversation"+nodeJid+"']").find("#chat-" + messageid).length == 0 && $("[id='conversation"+nodeJid+"']").find("#notify-" + messageid).length == 0) {
          $("[id='conversation"+nodeJid+"']").remove();
          if (toJID != null && typeof toJID != "undefined") {

            if (nodeJid == stropheConnection.Strophe().getNodeFromJid(toJID)) {

              conversation.MessageLayout(toJID, 'down', '');
            }
          }
        }
      }
      if ((blockUserInfo != null && typeof blockUserInfo[nodeJid] == "undefined") && (userInfo != null && typeof userInfo[nodeJid] != "undefined") || (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined")) {
        let contactDiv = '<div class="row sideBar-body contactcontent recentUserContactDiv" id="forwardcontact-' + nodeJid + '"><div class="select-cnt-blk"><input type="checkbox" class="select-message3" name="' + name + '" value="' + nodeJid + '" id="' + image + '"><label></label></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';
        let imageDiv = '<div class="avatar-icon recentUserImageDiv"> ' + fwdImageDiv + '</div> </div>';
        let nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main recentUserNameDiv"><div class="row"><div class="col-sm-12 col-xs-12  sideBar-name"><span class="name-meta">' + minEmoji(name) + '</span><span class="name-status fwdMsgSpan">' + minEmoji(msgcontent) + "</span></div></div></div></div>";
        $(".recentchatcontact").prepend(contactDiv + imageDiv + nameDiv);
        if (memberExist) {
          $(".recentchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
        }
        $(".rosterchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
      } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined" && groupInfo[nodeJid][4] != "2") {
        let contactDiv = '<div class="row sideBar-body contactcontent recentGroupContactDiv" id="forwardcontact-' + nodeJid + '"><div class="select-cnt-blk"><input type="checkbox" class="select-message3" name="' + name + '" value="' + nodeJid + '" id="' + image + '"><label></label></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';
        let imageDiv = '<div class="avatar-icon recentGroupImageDiv"> ' + fwdImageDiv + '</div> </div>';
        let nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main recentGroupNameDiv"><div class="row"><div class="col-sm-12 col-xs-12  sideBar-name"><span class="name-meta">' + minEmoji(name) + '</span><span class="name-status fwdMsgSpan">' + minEmoji(msgcontent) + "</span></div></div></div></div>";
        $(".recentchatcontact").prepend(contactDiv + imageDiv + nameDiv);
        if (memberExist) {
          $(".recentchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
        }
        $(".rosterchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
      }

      $("[id='color-fwd-"+nodeJid+"']").css("background-color", imageColor);
      if (tstatusImage) {
        let hoverExpandedDiv = tstatusImage;
        $("[id='"+nodeJid+"']").find('#' + messageid).parent().parent().find('.list-msg-status').append('<i class="tooltip-status">' + hoverExpandedDiv + '</i>');
      } 

      // Recent chat Lazy loader
      $(".recentChatLazy").each(function () {
        let $element = $(this);
        let dataSrc = $element.data('src');
        
        $element.attr('src', dataSrc);
        
        $element.on('load', function () {
          // Hide the loader when the image has loaded
        });
      });

    }
  },
  RecentChatDesign: function () {

    $(".sideBar-body").off("click");
    module.exports.Recentclick();
    module.exports.forwardContactClick();

    $("#forwardcontact").mCustomScrollbar("destroy");

    $("#forwardcontact").mCustomScrollbar();
    $("#recentchatcontent").mCustomScrollbar("destroy");

    $("#recentchatcontent").mCustomScrollbar({
      mouseWheelPixels: 500, //change this to a value, that fits your needs)
      callbacks: {
        onTotalScroll: function () { //Recent Scroll Update
          $("#recentchatcontent").mCustomScrollbar("update");
        }
    }
    });

    utils.determineDropDirection();
    utils.forwardmsg();
  },
  recentClickTitleUpdate: function(nodeJid){
    let title = "";
    let count = $("[id='" + nodeJid + "']").find(".notify").text();
    if ($("[id='" + nodeJid + "']").find(".notify").text() != 0) {
      if (document.title != "Ikonix Connect") {
        if (document.title.substring(1, 1) != 0) {
          count = document.title.substring(1, 1) - 1;
          count = parseInt(count);
          title = (count == 0) ? "Ikonix Connect" : "(" + count + ") Ikonix Connect";
        }
        document.title = title;
      }
    }
  },
  recentClickUserUpdate: function(nodeJid){

    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let toJID;
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      toJID = nodeJid + "@" + stropheConnection.Server();
      localStorage.setItem("toJID", JSON.stringify(toJID));
      lastActivity.lastActivity(toJID);
      $(".blocked-person").css("display", "none");
      $(".row.reply").css("display", "flex");
    } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
      toJID = nodeJid + "@mix." + stropheConnection.Server();
      localStorage.setItem("toJID", JSON.stringify(toJID));
      let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));

      if (groupDetail != null && typeof groupDetail[nodeJid] != "undefined") {
        groups.getgroupdetails(toJID);
      }
    } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
      toJID = nodeJid + "@" + stropheConnection.Server();
      localStorage.setItem("toJID", JSON.stringify(toJID));
      $(".blocked-person").css("display", "none");
      $(".row.reply").css("display", "flex");
    } else {
      toJID = nodeJid + "@" + stropheConnection.Server();
      localStorage.setItem("toJID", JSON.stringify(toJID));
      lastActivity.lastActivity(toJID);
      $(".row.reply").css("display", "flex");
      $(".blocked-person").css("display", "none");

    }

    return toJID;
  },
  recentClickAction: function (nodeJid,chatUser=null) {
      $('.heading-info').hide();
      let ReplyMsgContent = "";
      let ReplyMsgId = "";
      
      localStorage.setItem("ReplyMsgContent", ReplyMsgContent);
      localStorage.setItem("ReplyMsgId", ReplyMsgId);

      delete localStorage.replymsgInfo;
      $('#comment').attr("placeholder", "Shift + Enter to add a new line Start");
      $("#comment").val("");
      $('#comment').prop('disabled', false);
      $("#ack").val("0");
      $(".acknowledge-icon").find('img').attr('src', './src/images/acknowledgement_ic.svg');
      audio.stopRecording();
      $(".downarrow").hide();
      $(".reply-content").hide();
      $("#composeText").val("");
      $(".del-msg-wrap").hide();
      $(".row.reply").css("display", "flex");
      $("#recentchat").find(".recentcontent").removeClass("active");
      $("#index-chat-section").hide();
      $(".message-info-block").css({right: "-100%"});
      $(".message-info-block .right-msg-back").trigger("click");
      $(".media-detail-section .right-msg-back").trigger("click");
      $(".right-prof-back").trigger("click");
      $(".conversation").removeClass("small-pane");
      $('.msg-close').trigger('click');
      $("[id='" + nodeJid + "']").addClass("active");
      $("#con-head").css("display", "flex");
      $("#conversation").css("display", "block");
      $(".reply").css("display", "flex");

      module.exports.recentClickTitleUpdate(nodeJid)
     
      $("[id='" + nodeJid + "']").find(".notify").html(parseInt(0));
      $("[id='" + nodeJid + "']").find(".notify").css("display", "none");
     
      let toJID;
      $(".heading-online").html("");
      toJID = module.exports.recentClickUserUpdate(nodeJid)
     
      if (nodeJid == storage.adminName()) {
        toJID = nodeJid + "@" + stropheConnection.Server();
      }
      priorityMessages.getPriorityMessages(toJID);
      conversation.MessageLayout(toJID, "down", "",0);
      /** Show Message Character Count **/
      let Settings = JSON.parse(localStorage.getItem("settings"));
      let messageCharacterLimit = Settings.messageCharacterLimit;
      let CommentTextLength = $('#comment').html().length;
      if(CommentTextLength == messageCharacterLimit){
        $('#messageCharCount').css('color','red');
        $('#messageCharLimitCount').css('color','red');
      }else {
        $('#messageCharCount').removeAttr( 'style' );
        $('#messageCharLimitCount').removeAttr( 'style' );
      }
      $('#messageCharCount').html(CommentTextLength)

    if(chatUser == 0){
      $('.invitation_div').css('display','block!important')
      let profilename = $('.mCSB_container').children("[id='roster-"+nodeJid+"']").find('.contact-list').html()
      let email = $("[id='nonapp-"+nodeJid+"']").html();
      $(".block-con").hide();
      $(".unblock-con").show();
      $(".blocked-person").text("You cannot send messages to this chat.");
      $('.heading-attach').hide();
      $(".row.reply").css("display", "none");
      $(".blocked-person").css("display", "block");
      $(".contact-exit").css("display", "none");
      $(".contact-msg").css("display", "none");
      $(".contact-clearmsg").css("display", "none");
      $("#pager_right").css("display", "none");
      $(".heading-info").css("display", "none");
      $("#con-head .heading-dot").css("display", "none");
      $("#attachmentDiv").css("display", "none");
      $("#pager_list").css("display", "none");
      $("#replyemoji").hide();
      $("#microphone").hide();
      let iosInviteLink =  language.locali().IosInviteLink
      let andrioidInviteLink =  language.locali().andrioidInviteLink

      $(".msglist").html('<div class="invitation_div"><div class="invitation_class"><b style="font-size:13px;">Invitation to download Ikonix Connect</b></div><a  class="button_mail" href="mailto:'+email+'?subject=Invitation%20to%20use%20Ikonix%20Connect&body=Hi%20'+ profilename +'%2C%0A%0AIkonix%20Connect%20is%20a%20secure%20enterprise%20messaging%20app%20designed%20to%20connect%20people%20across%20our%20organisation.%0A%0ATo%20get%20started%2Cdownload%20it%20here%0A%0AiOS:%20'+encodeURIComponent(iosInviteLink)+'%0A%0AAndroid:'+encodeURIComponent(andrioidInviteLink)+'%0A%0A"><b>INVITE</b></a></div>')
      
      let isUnregister =$('.mCSB_container').find(`[data-member='`+nodeJid+`']`).first().attr('data-isunregister');
      if(isUnregister == 1){
        $(".blocked-person").css("display", "block");
      }else {
        $(".blocked-person").css("display", "none");
      }
    }else{

      $('.invitation_div').css('display','none!important')
    }
  },
  recentClickSettingUpdate: function(nodeJid){
    /** Initialy Set Msg Char Limit **/
    let Settings = JSON.parse(localStorage.getItem("settings"));
    if(Settings) {
        let messageCharacterLimit = Settings.messageCharacterLimit;
        $('#messageCharLimitCount').text(messageCharacterLimit);

        let str = $('#comment')[0].innerText;
        str = str.substring(0, messageCharacterLimit);;
        $("#comment").html(str)
        let strLength = str.length
        $('#messageCharCount').html(strLength)
       
        $('#messageCharCount').removeAttr( 'style' );
        $('#messageCharLimitCount').removeAttr( 'style' );

        let offlineText = JSON.parse(localStorage.getItem("offlineText"));
         if (offlineText == null) {
           offlineText = {};
         }

        offlineText[nodeJid] = [];
        offlineText[nodeJid].push('chatcontent');
        offlineText[nodeJid].push(str);
        localStorage.setItem("offlineText", JSON.stringify(offlineText));

        if (typeof offlineText != 'undefined' && offlineText[nodeJid] && offlineText[nodeJid][1] != "") {
         $("#send-icon").css("display", "block");
         $("#microphone").css("display", "none");
       } else {
           $("#send-icon").css("display", "none");
           $("#microphone").css("display", "block");
       }
    }
  },
  Recentclick: function () {
    $(".recenttext").off("click");
    $(".recentcontent").mouseover(function () {
      let recentId = $(this).attr("id");
      let recentmenuId = $(".dropdown.open").closest(".recentcontent").attr("id");
      if (recentId != recentmenuId) {
        $(".recentcontent").find(".dropdown").removeClass("open");
      }
    });

    $("body").on("click", ".recenttext",function(){
     
      $('.heading-info').hide();

      let nodeJid = $(this).closest(".recentcontent").attr("id");
      $("#rightSideHeaderId").val(nodeJid)
      $('.reply-box').removeClass("disabledDiv");
      let toJID = utils.toJID();
      
      if (toJID != null && stropheConnection.Strophe().getNodeFromJid(toJID) == nodeJid && $("#index-chat-section").css('display') == "none") {

        return true;
      }
      $("audio").each(function () {
        let audio_id = this.id;
        if (audio_id != "") {
          let audioResult = audio_id.split("-");
          
          if ($("[id='"+audioResult[0]+"-pButton']").length) {
            if ($("[id='"+audioResult[0]+"-pButton']").hasClass("pause")) {

              this.pause();
              $("[id='"+audioResult[0]+"-pButton']").removeClass("pause").addClass("play");

            }
          }
        }
      });
      module.exports.recentClickAction(nodeJid);
      module.exports.recentClickSettingUpdate(nodeJid);
      setTimeout(function () {
        conversation.mediaviewClick();
      }, 300);
     

    });
    module.exports.recentdropdownClick();

    return true;
  },
  recentdropdownClick: function () {
    $(".exit-group-recent").click(function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      $('#deleteforeveryone').hide();
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
      let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      let nodeJid = $(this).closest(".recentcontent").attr("id");
      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      let content;
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        $('.exit_and_del').hide();

        content = "<div>" + language.locali().delete_chat_with + ' ' + userInfo[nodeJid][1] + ' ?</div>';
        $("#exit-group").find(".save").html(language.locali().delete);
        $("#exit-group").find(".popup-mute").html(content);
        $("#exit-group").addClass("open");
      } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
        $('.exit_and_del').hide();

        let toJID = nodeJid + "@" + stropheConnection.Server();
        localStorage.setItem("toJID", JSON.stringify(toJID));

        content = "<div>" + language.locali().delete_chat_with + ' ' + broadcastProfile[nodeJid][1] + ' ?</div>';
        $("#exit-group").find(".save").html(language.locali().delete);
        $("#delete_broadcast").find(".popup-mute").html(content);
        $("#delete_broadcast").addClass("open");
        $("#delete_broadcast .exit_text").text('Delete')
      } else {
        $('.exit_and_del').show();
        let toJID = nodeJid + "@mix." + stropheConnection.Server();
        $("#exitAndDelTojid").val(toJID)
        content = "<div>" + language.locali().exit + ' ' + groupInfo[nodeJid][1] +" " + language.locali().group + ' ' + "?</div>";
        $("#exit-group").find(".save").html(language.locali().exit);
        $("#exit-group").find(".popup-mute").html(content);
      $("#exit-group").addClass("open");
      }
      
    });
    $(".exit-group-delete").click(function () {

      $('#deleteforeveryone').hide();
      $('.exit_and_del').hide();
      $("#isRecentDelClick").val(1)
      groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
      let nodeJid = $(this).closest(".recentcontent").attr("id");
      let toJID = nodeJid + "@mix." + stropheConnection.Server();
      $("#exitAndDelTojid").val(toJID)
      localStorage.setItem("toJID", JSON.stringify(toJID));

      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      let content;

      content = "<div>" + language.locali().delete + ' ' + groupInfo[nodeJid][1] +" "+ language.locali().group + '' + "?</div>";
      $("#exit-group").find(".save").html(language.locali().delete);

      $("#exit-group").find(".popup-mute").html(content);
      $("#exit-group").addClass("open");
    });
    $(".clear-msg-recent").click(function () {
      userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      groupInfo = JSON.parse(localStorage.getItem("groupProfile"));

      let nodeJid = $(this).closest(".recentcontent").attr("id");
      broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      if(broadcastProfile != null && typeof broadcastProfile[nodeJid] != 'undefined'){
        $("#clear-msg .clearHighPriorityTag").hide()
      }else {
        $("#clear-msg .clearHighPriorityTag").show()
      }
      
      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      $("#clear-msg").addClass("open");
    });
  },
  forwardContactClick: function () {
    $(".contactcontent").off("click");
    $(".contactcontent").click(function () {
      let result = $(this).attr("id").split(/-(.+)/);
      let selectedId = result[1];
      if ($(".contactcontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked")) {
        $(".contactcontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked", false).change();
      } else {
        $(".contactcontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked", true).change();
      }
    });
    
    $(".rosterchatcontact").off().on('click', '.contactfwdcontent', function () {
      let result = $(this).attr("id").split(/-(.+)/);
      let selectedId = result[1];
      if ($(".rosterchatcontact .contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked")) {
        $(".rosterchatcontact .contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", false).change();
        $(".contactcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", false).change();

      } else {
        $(".rosterchatcontact .contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", true).change();
        $(".contactcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", true).change();
      }
    });
  },
  updateRecentBroadcastImg: function(profileimage,mediaUrl,userToken){
    let image;
    if (profileimage !== "src/images/avatar/broadcast/profile_img_one.png" && profileimage.trim() !== "") {
      image = mediaUrl + profileimage + "?mf=" + userToken;
    } else if (profileimage.trim() == "") {
      image = "src/images/avatar/broadcast/profile_img_one.png";
    } else {
      image = profileimage;
    }
    return image;
  },
  updateRecentGroupImg: function(profileimage,mediaUrl,userToken){
    let image;
    if(utils.getHexRegexPattern().test(profileimage)) {
      image = profileimage;
    } else if (profileimage !== "src/images/avatar/group/group_four.svg" && profileimage.trim() !== ""){
      image = mediaUrl + profileimage + "?mf=" + userToken;
    } else if (profileimage.trim() == "") {
      image = "src/images/avatar/group/group_four.svg";
    } else {
      image = profileimage;
    }

    return image;
  },
  updateRecentUserImg: function(profileimage,mediaUrl,userToken,unregisteredUser){
    let image;
    if (unregisteredUser != 1) {
      if(utils.getHexRegexPattern().test(profileimage)) {
        image = profileimage;
      } else if (profileimage !== "src/images/avatar/chat/profile_img_one.svg" && profileimage.trim() !== ""){
        image = mediaUrl + profileimage + "?mf=" + userToken;
      } else if (profileimage.trim() == "") {
        image = "src/images/avatar/chat/profile_img_one.svg";
      } else {
        image = profileimage;
      }
    } else {
      image = "src/images/avatar/chat/profile_img_one.svg";
    }

    return image;
  },
  updateRecentGeneralImg: function(imageColor,imageId,userToken,mediaUrl){
    let image;
    if(typeof imageId == 'undefined'){
      imageId = imageColor ? imageColor : '';
    }
    if(utils.getHexRegexPattern().test(imageId)) {
      image = imageId;
    } else if (imageId !== "src/images/avatar/chat/profile_img_one.svg" && imageId.trim() !== ""){
      image = mediaUrl + imageId + "?mf=" + userToken;
    } else if (imageId.trim() == "") {
      image = "src/images/avatar/chat/profile_img_one.svg";
    } else {
      image = imageId;
    }

    return image;
  },
  Updaterecent: function (updateChatObj) {
    let nodeJid = updateChatObj.nodeJid;
    let msgtype = updateChatObj.msgtype;
    let status = updateChatObj.status;
    let messageid = updateChatObj.messageid;
    let unReadCount = updateChatObj.unReadCount;
    let n = updateChatObj.n;
    let tstatusImage = updateChatObj.tstatusImage;
    let notifierName = updateChatObj.notifierName;
    let imageId = updateChatObj.imageId;

    groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));

    let userToken = storage.getuserToken();

    if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == "broadcast") {
      let profileimage = broadcastProfile[nodeJid][2];
      image = module.exports.updateRecentBroadcastImg(profileimage,mediaUrl,userToken)
     
      let recentChatObj = {
        nodeJid       :nodeJid,
        name          :broadcastProfile[nodeJid][1],
        image         :image,
        msgcontent    :msgtype,
        time          :n,
        status        :status,
        messageid     :messageid,
        unReadCount   :0,
        tstatusImage  :tstatusImage,
        updateRecent  :1
      }

      module.exports.recentchatcontent(recentChatObj);
    } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
      let profileimage = groupInfo[nodeJid][2];
      image = module.exports.updateRecentGroupImg(profileimage,mediaUrl,userToken);
      let recentChatObj = {
        nodeJid       :nodeJid,
        name          :groupInfo[nodeJid][1],
        image         :image,
        msgcontent    :msgtype,
        time          :n,
        status        :status,
        messageid     :messageid,
        unReadCount   :unReadCount,
        tstatusImage  :tstatusImage,
        updateRecent  :1
      }
      
      module.exports.recentchatcontent(recentChatObj);
      $("[id='"+nodeJid+"']").find(".typing-msg").css("display", "none");
      $("[id='"+nodeJid+"']").find(".list-msg-status i").css("display", "inline-block");
      $(".heading-online").css("display", "block");
      $(".heading-status").css("display", "none");
    } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      let profileimage = userInfo[nodeJid][2];
      let unregisteredUser = userInfo[nodeJid][16];
      image = module.exports.updateRecentUserImg(profileimage,mediaUrl,userToken,unregisteredUser);
      
      let recentChatObj = {
        nodeJid       :nodeJid,
        name          :userInfo[nodeJid][1],
        image         :image,
        msgcontent    :msgtype,
        time          :n,
        status        :status,
        messageid     :messageid,
        unReadCount   :unReadCount,
        tstatusImage  :tstatusImage,
        updateRecent  :1
      }
 
      module.exports.recentchatcontent(recentChatObj);
    } else {
      image = module.exports.updateRecentGeneralImg(imageColor,imageId,userToken,mediaUrl);

      

      setTimeout(function () {
        vCard.getVcard(nodeJid + "@" + stropheConnection.Server());
      }, 0);
      
      setTimeout(function () {
        let recentChatObj = {
          nodeJid       :nodeJid,
          name          :notifierName,
          image         :image,
          msgcontent    :msgtype,
          time          :n,
          status        :status,
          messageid     :messageid,
          unReadCount   :1,
          tstatusImage  :tstatusImage,
          updateRecent  :1
        }
        module.exports.recentchatcontent(recentChatObj);
        /** Update Nonchat User Or New User Recent Chat**/
        module.exports.Updaterecent(updateChatObj);


        module.exports.recentChatNotificationCountUpdate(updateChatObj,nodeJid,blockUserInfo);

        

      }, 500);
    }
    module.exports.RecentChatDesign();
  },
  getNotificationCount: function(nodeJid){
    let notifycount;
    if ($("[id='"+nodeJid+"']").length && nodeJid != stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
      notifycount = $("[id='"+nodeJid+"']").find('.notify').text();
    }else {
        if($("[id='"+nodeJid+"']").length == 0){
            notifycount = $("[id='"+nodeJid+"']").find('.notify').text();
        
        }
    }
    return notifycount;
  },
  notificationCountAppend: function(msgfrom,userJID,blockUserInfo,nodeJid,notifycount){
    if (msgfrom != userJID) {
      if (blockUserInfo != null && typeof blockUserInfo[nodeJid] == "undefined") {
          $("[id='"+nodeJid+"']").find('.notify').html(parseInt(notifycount) + 1);
          $("[id='"+nodeJid+"']").find('.notify').css("display", "flex");
      }
    }
  },
  recentChatNotificationCountUpdate: function(updateChatObj,nodeJid,blockUserInfo){
    let msgfrom = updateChatObj.sendFrom;
    let userJID  = updateChatObj.userJid
    let notifycount;

    notifycount = module.exports.getNotificationCount(nodeJid)

    if ($("[id='"+nodeJid+"']").length && nodeJid != stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
      module.exports.notificationCountAppend(msgfrom,userJID,blockUserInfo,nodeJid,notifycount);
        
    }else {
        if($("[id='"+nodeJid+"']").length == 0){
          module.exports.notificationCountAppend(msgfrom,userJID,blockUserInfo,nodeJid,notifycount);
        }
    }
  },
  // action on clicking reminder message popup
  reminderClickAction: function(messageId, fromUser) {
    module.exports.recentClickAction(fromUser);
    conversation.priorityMessageClick(messageId);
  },
  profileImgError: function() {
    $('img').on("error", function () {
      this.onerror = "";
      this.src = "./src/images/avatar/chat/profile_img_one.svg";
    });
  }
};
