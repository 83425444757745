let qrvalidate = require('./qrvalidate.js');
let utils = require('./utils.js');
const settings = require('./settings.js');
let language = require("./locale/language.js");

module.exports = {

    // Function to authenticate with keycloak
    authenticateKeycloak: function (idToken, baseUrl, ipAddress) {
        let authenticateURL = window._env_.ApiURL + "/keycloak";
        let formData = {
            idToken: idToken,
            resourceIp: ipAddress
        };

        $.ajax({
            url: authenticateURL,
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(formData),
            async: true,
            crossDomain: true,
            statusCode: {
                204: function (response) {
                    console.log("Username does not exist");
                },
                500: function (response) {
                    console.log("Internal Server Error");
                }
            },
            success: function (response) {
                const tokenPayload = JSON.parse(atob(idToken.split('.')[1]));
                const sessionTimeoutInSeconds = tokenPayload.exp - tokenPayload.iat;
                let totalSessionTime = sessionTimeoutInSeconds * 1000;

                localStorage.setItem("sessionStartTime", Date.now().toString());
                localStorage.setItem("totalSessionTime", totalSessionTime);
                localStorage.setItem("idToken", idToken);

                setTimeout(module.exports.handleLogout, totalSessionTime);

                let redirectURLSuccess = window._env_.WebchatRedirectUri;
                $("#on-off-msg").removeClass("open");
                if(redirectURLSuccess != undefined) {
                    window.history.replaceState({}, document.title, redirectURLSuccess);
                } else {
                    window.history.replaceState({}, document.title, baseUrl);
                }
                if (typeof response.status != "undefined" && response.status == 400) {
                    module.exports.keycloakErrorHandling(response.message);
                    if(typeof response.message != 'undefined'){
                        let MessageList = response.message.split('.');
                        $('#keycloak-error').html(MessageList[0])
                        $('#keycloak-sol').html(MessageList[1])
                    } 
                } else if (typeof response.status != "undefined" && response.status == 500) {
                    module.exports.keycloakErrorHandling(response.message);
                } else if (typeof response.status != "undefined" && response.status == 200 && response.data == "1" ) {
                    module.exports.keycloakDeletedUserHandling(response.message);
                }
                else if (typeof response.status != "undefined" && response.status == 204 ) {
                    $(".main-screen-bg").css("display", "none");
                    $(".login-container").css("display", "flex");
                    $("#keycloak-sol-button").css("display", "none")
                    let maillBody = response.data.mailBody.replace("\n", "%0D%0A").replace("\n\n", "%0D%0A%0D%0A");
                    $("#mail_content").html(' <p class="note">'+response.data.messageContent+'</p>' +
                        '<div style="max-width: 37%;margin-left: 178px;" class="mail_div"><a style="float: left;"class="button_mail" href="mailto:'+response.data.mailTo+'?subject='+response.data.mailSubject+'&body='+maillBody+'">Send</a> <a style="float: right;" href="javascript:void(0)" id="reset_login">Login</a></div>');
                }
                else {
                    let encryptKey = language.locali().IV.repeat(3);
                    let decryptedResponse = JSON.parse(utils.decrypt(response.data.keycloakEncryptedString, encryptKey));
                    let token = decryptedResponse.token;
                    let username = decryptedResponse.username;
                    localStorage.setItem(language.locali().isPendingGroupParticipant, response.data.isPendingGroupParticipant);
                    localStorage.setItem("userToken", JSON.stringify(token));
                    let loginUserInfo = {};
                    loginUserInfo[0] = [];
                    loginUserInfo[0].push(username);
                    localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
                    qrvalidate.checklogin(decryptedResponse.username, decryptedResponse.password);
                }
            },
            error: function (jqXHR, exception) {
                let redirectURLError = window._env_.WebchatRedirectUri;
                if(redirectURLError != undefined) {
                    window.history.replaceState({}, document.title, redirectURLError);
                } else {
                    window.history.replaceState({}, document.title, baseUrl);
                }
                let keyClockErrMsg = "";
                if (jqXHR.status === 0) {
                    keyClockErrMsg = "Not connect.\n Verify Network.";
                    utils.reconnectionPopupShow();

                } else if (jqXHR.status == 404) {
                    keyClockErrMsg = "Requested page not found. [404]";
                } else if (jqXHR.status == 500) {
                    keyClockErrMsg = "Internal Server Error [500].";
                } else if (exception === "parsererror") {
                    keyClockErrMsg = "Requested JSON parse failed.";
                } else if (exception === "timeout") {
                    keyClockErrMsg = "Time out error.";
                } else if (exception === "abort") {
                    keyClockErrMsg = "Ajax request aborted.";
                } else {
                    keyClockErrMsg = "Uncaught Error.\n" + jqXHR.responseText;
                }

                module.exports.keycloakErrorHandling(keyClockErrMsg);
            }
        });
    }, 
    handleLogout : function(){
        localStorage.removeItem("sessionStartTime");
        localStorage.removeItem("totalSessionTime");

        localStorage.clear();
        sessionStorage.clear();

        $(".exp-overlay").addClass('active');
        $(".sesssionExp").addClass('active');
    },

    // Handle in case of keycloak API failure
    keycloakErrorHandling: function(errorMessage) {
        $("#on-off-msg").removeClass("open");
        $(".main-screen-bg").css("display", "none");
        $(".login-container").css("display", "flex");
    },

    // Handle in case of deleted users
    keycloakDeletedUserHandling: function(errorMessage) {
        $("#on-off-msg").removeClass("open");
        $(".main-screen-bg").css("display", "none");
        $(".user-deleted-container").css("display", "flex");
    },

    // function used to login with keycloak
    loginWithKeycloak: function() {
        let keycloak;
        let sessionAvail = localStorage.getItem('AnotherSessionOn');
        if(sessionAvail == 1) {
            $(".session-container").css("display", "flex");
        } else {
            if (window._env_.WebchatRedirectUri != undefined) {
                keycloak = Keycloak({
                    url: window._env_.KeycloakAuthURL,
                    realm: window._env_.KeycloakRealm,
                    clientId: window._env_.KeycloakClientId,
                    redirectUri: window._env_.WebchatRedirectUri
                });
            } else {
                keycloak = Keycloak({
                    url: window._env_.KeycloakAuthURL,
                    realm: window._env_.KeycloakRealm,
                    clientId: window._env_.KeycloakClientId
                });
            }

            keycloak.init({
                onLoad: 'login-required',
                flow: 'implicit'
            });
        }
    }


}
