let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');
let recentdesign = require('./recentdesign.js');
let timeFormat = require('./timeformat.js');
let audio = require('./audio.js');
let conversation = require('./conversation.js');
let chatdesign = require('./chatdesign.js');
let storage = require('./storage.js');
const vCard = require('./vCard.js');
let timer;
let typing = 0;
let specialGroup = 0;
let language = require('./locale/language.js');
let lastContent;
module.exports = {
    timer: timer,
    typing: typing,
      
    sendChatMessages: function (sendChatObj) {

        let msg = sendChatObj.msg;
        let messagetype = sendChatObj.messagetype;
        let messageid = sendChatObj.messageid;
        let filesize = sendChatObj.filesize;
        let options = sendChatObj.options;
        let touser = sendChatObj.touser;
        let ack = sendChatObj.ack;
        let forwardUserName = sendChatObj.forwardUserName;
        let forwardUserImage = sendChatObj.forwardUserImage;

        $("#messageemoji").hide();
        $('.loader').css("display", "none");
        let toUserName,image,nodeJid;
        if(forwardUserName == "") {
            toUserName = $(".heading-name-meta").text();
            image = $(".right-side-prof").attr("usemap");
        } else {
            toUserName = forwardUserName;
            image = forwardUserImage;
        }
        let myInfo = JSON.parse(localStorage.getItem("myInfo"));
        let tstatusImage = "";
        if (touser == "") {
            
            nodeJid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        } else {
            nodeJid = touser;
        }
        let offlineMessage = JSON.parse(localStorage.getItem("offlineMessage"));
        if (offlineMessage == null) {
            offlineMessage = {};
        }
        if (!stropheConnection.Connection().connected) {
            offlineMessage[messageid] = [];
            offlineMessage[messageid].push('message');
            offlineMessage[messageid].push(msg);
            offlineMessage[messageid].push(messagetype);
            offlineMessage[messageid].push(filesize);
            offlineMessage[messageid].push(options);
            offlineMessage[messageid].push(nodeJid);

            localStorage.setItem("offlineMessage", JSON.stringify(offlineMessage));
        }

        let offlineText = JSON.parse(localStorage.getItem("offlineText"));

        if (offlineText != null && typeof offlineText[nodeJid] != "undefined") {
            let items = {};
            for (let key in offlineText) {
                if (offlineText[key][0] == "chatcontent") {

                    if (nodeJid != key) {
                        items[key] = offlineText[key];
                    }
                }
            }
            localStorage.setItem("offlineText", JSON.stringify(items));

        }
        module.exports.typing = 0;
        $('.select-messages').show();
        $('.contact-clearmsg').show();
        let messageJson = {};
        let userJID = stropheConnection.logininfo.userJID;

        messageJson.message = utils.StripTags(msg);

        let mediacontentJson = {};
        let mediamessage = false;
        if (nodeJid != null && nodeJid != userJID) {
            messageJson.message_type = messagetype;
            let replymsgInfo = JSON.parse(localStorage.getItem("replymsgInfo"));
            if (replymsgInfo != null) {
                for (let key in replymsgInfo) {
                    let value = replymsgInfo[key];
                    let itemvalue = value.keyid;
                    messageJson.replyTo = itemvalue;
                }
            }

            delete localStorage.replymsgInfo;
            let filePath = '';
            if (messagetype == 'audio') {
                mediacontentJson.caption = "";
                mediacontentJson.file_size = filesize;
                mediacontentJson.file_name = options.name;
                mediacontentJson.file_url = msg;
                filePath = '-' + msg;
                mediacontentJson.thumb_image = "";
                mediacontentJson.local_path = '';
                mediacontentJson.is_uploading = "2";
                mediacontentJson.is_downloaded = 0;
                mediacontentJson.duration = options.duration;
                messageJson.media = mediacontentJson;

            } else if (messagetype != "text" && messagetype != "location" && messagetype != "contact") {
                mediacontentJson.caption = utils.StripTags(options.caption);
                mediacontentJson.file_size = filesize;
                mediacontentJson.file_name = options.name;
                mediacontentJson.file_url = msg;
                filePath = '-' + msg;
                mediacontentJson.thumb_image = options.thumb;
                mediacontentJson.local_path = '';
                mediacontentJson.is_uploading = "2";
                mediacontentJson.is_downloaded = 0;
                mediacontentJson.duration = options.duration;
                messageJson.media = mediacontentJson;
                mediamessage = true;


            } else if (messagetype == "location") {
                mediacontentJson = {};
                messageJson.message = '';
                mediacontentJson.latitude = options.latitude;
                mediacontentJson.longitude = options.longitude;
                messageJson.location = mediacontentJson;
            } else if (messagetype == "contact") {
                mediacontentJson = {};
                messageJson.message = '';
                mediacontentJson.name = options.name;
                mediacontentJson.phone_number = [];
                mediacontentJson.phone_number[0] = options.phone_number;
                messageJson.contact = mediacontentJson;
            }


            messageJson.message_from = myInfo[userJID][1];
            messageJson.message_to = toUserName;
            let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
            let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
            let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
            let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
            let blockUserList = JSON.parse(localStorage.getItem("blockListUserInfo"));
            let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
            if (blockUserInfo != null && typeof blockUserInfo[nodeJid] != "undefined") {
                userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
                if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                    let unblockUserInfo = {};
                    unblockUserInfo[0] = [];
                    unblockUserInfo[0].push(nodeJid);
                    localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));
                    let displayName = userInfo[nodeJid][1];

                    let content = "Unblock " + ' "' +displayName + '"?';
                    $("#unblock-contact").find('.popup-detail').html(content);
                    $("#unblock-contact").addClass("open");
                }
            } else {
                $("#comment").empty();
                $("#comment").focus();
                $("#send-icon").css("display", "none");
                $("#microphone").css("display", "block");
                $("#ack").val('0');
                $(".acknowledge-icon").find('img').attr('src', './src/images/acknowledgement_ic.svg');
                $(".reply-content").html('');
                $(".reply-content").hide();
                let messagecontent = JSON.stringify(messageJson);
                let n = timeFormat.currenttime();
                let tooltipUpdate,tooltipNodeJID,toid,messageBodyContent;
                if( $("[id='"+nodeJid+"']").find('.priority-message-recent').text() == language.locali().priority_recent) {
                    messageBodyContent = utils.checkMessageType(messageJson, messageid, 2);
                } else {
                    messageBodyContent = utils.checkMessageType(messageJson, messageid);
                }
                let msgcontent = messageBodyContent[1];
                let msgtype = messageBodyContent[0];
                let unknownUser = localStorage.getItem("isUnknown");
                if (userInfo != null && typeof userInfo[nodeJid] != "undefined" && unknownUser == null) {
                    touser = nodeJid + "@" + stropheConnection.Server();
                    $('.contact-info').find('a').html(language.locali().contact_info);
                    $('.contact-info').find('a').attr('title',language.locali().contact_info);
                    $('.contact-exit').find('a').html(language.locali().delete_chat);
                    $('.contact-exit').find('a').attr('title',language.locali().delete_chat);
                } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                    touser = nodeJid + "@mix." + stropheConnection.Server();
                    $('.contact-info').find('a').html(language.locali().group_info);
                    $('.contact-info').find('a').attr('title',language.locali().group_info);
                    $('.contact-exit').find('a').html(language.locali().exit_group);
                    $('.contact-exit').find('a').attr('title',language.locali().exit_group);
                } else  if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
                  // document why this block is empty
                  
                
                } else {
                    vCard.getVcard(nodeJid + "@" + stropheConnection.Server());
                    let saveRoster = stropheConnection.StropheJs().$iq({
                        type: 'set',
                        id: utils.getId(),
                        to: stropheConnection.Server()
                    }).c('query', {
                        xmlns: 'jabber:iq:user_profile',
                        status: 'update_roster',
                        contact: nodeJid,
                        contact_name: toUserName,
                        nick_name: myInfo[userJID][1]
                    });
                    if (stropheConnection.Connection().connected) {
                        stropheConnection.Connection().sendIQ(saveRoster);
                    }
                    touser = nodeJid + "@" + stropheConnection.Server();
                    $('.contact-info').find('a').html(language.locali().contact_info);
                    $('.contact-info').find('a').attr('title',language.locali().contact_info);
                    $('.contact-exit').find('a').html(language.locali().delete_chat);
                    $('.contact-exit').find('a').attr('title',language.locali().delete_chat); 
                    localStorage.removeItem("isUnknown");
                    
                }

                let broadcastDetail,broadcastMembersCount,receiptStatus,deliveredStatus,readStatus,sentStatus,ackStatus,rackStatus,statusImage,rtStatus,atStatus,defaultcontent,messagebody,senderBox;
                if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
                    broadcastDetail = JSON.parse(localStorage.getItem("broadcastDetails"));
                    broadcastMembersCount = _.size(broadcastDetail[nodeJid]);

                    receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span id='totalMembers'>"+broadcastMembersCount+"</span>, Read <span class='readCount'>0</span> of <span id='totalMembers'>"+broadcastMembersCount+"</span></span>";

                    deliveredStatus = "<span>Del'd <span class='deliveredCount'>0</span> of <span id='totDelCount'>"+broadcastMembersCount+"</span></span>";
                    readStatus = "<span>, Read <span class='readCount'>0</span> of <span id='totReadCount'>"+broadcastMembersCount+"</span></span>";
                    rtStatus = '<span class="deliverRecent">Delivered <span class="deliverCountRecent">0</span> of <span class="totalMemRecent">'+broadcastMembersCount+'</span>,</span><span class="readRecent">Read <span class="readCountRecent">0</span> of <span class="totalMemRecent">'+broadcastMembersCount+'</span>,</span>';

                    sentStatus = deliveredStatus + readStatus;
                    if(ack == 1){
                        ackStatus = "<span>, Ack'd <span class='ackCount'>0</span> of <span id='totAckCount'>"+broadcastMembersCount+"</span></span>";

                        rackStatus = "<span class='ackStatus'> Ack'd <span class='acknowledgeCount'>0</span> of <span id='totalMembers'>"+broadcastMembersCount+"</span></span>";

                        atStatus = '<span class="acknowledgeRecent">Acknowledged <span class="acknowledgeCountRecent">0</span> of <span class="totalMemRecent">'+broadcastMembersCount+'</span></span>';

                    } else if (ack == 2){
                        ackStatus = "<span>, (Acc'd <span class='accCount'>0</span>, Rej'd <span class='rejCount'>0</span>) of <span id='totAckCount'>"+broadcastMembersCount+"</span></span>";

                        rackStatus = "<span class='ackStatus'> (Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span id='totalMembers'>"+broadcastMembersCount+"</span></span>";
                        atStatus = '<span class="acceptRecent">Accepted <span class="acceptCountRecent">0</span> of <span class="totalMemRecent">'+broadcastMembersCount+'</span>,</span><span class="rejectRecent">Rejected <span class="rejectCountRecent">0</span> of <span class="totalMemRecent">'+broadcastMembersCount+'</span></span>';

                    } else {
                        ackStatus = '';
                        rackStatus = '';
                    }

                    if(rackStatus == ""){
                        statusImage = receiptStatus;
                        tstatusImage = rtStatus;
                    } else {
                        statusImage = receiptStatus +','+ rackStatus;
                        tstatusImage = rtStatus+atStatus;

                    }
                } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                    let groupsDetail = JSON.parse(localStorage.getItem("groupDetails"));
                    let groupMembersCount = _.size(groupsDetail[nodeJid]) - 1;

                    receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span id='totalMembers'>"+groupMembersCount+"</span>, Read <span class='readCount'>0</span> of <span id='totalMembers'>"+groupMembersCount+"</span></span>";

                    deliveredStatus = "<span>Del'd <span class='deliveredCount'>0</span> of <span id='totDelCount'>"+groupMembersCount+"</span></span>";
                    readStatus = "<span>, Read <span class='readCount'>0</span> of <span id='totReadCount'>"+groupMembersCount+"</span></span>";

                    sentStatus = deliveredStatus + readStatus;

                    rtStatus = '<span class="deliverRecent">Delivered <span class="deliverCountRecent">0</span> of <span class="totalMemRecent">'+groupMembersCount+'</span>,</span><span class="readRecent">Read <span class="readCountRecent">0</span> of <span class="totalMemRecent">'+groupMembersCount+'</span>,</span>';

                    if(ack == 1){
                        ackStatus = "<span>, Ack'd <span class='ackCount'>0</span> of <span id='totAckCount'>"+groupMembersCount+"</span></span>";

                        rackStatus = "<span class='ackStatus'> Ack'd <span class='acknowledgeCount'>0</span> of <span id='totalMembers'>"+groupMembersCount+"</span></span>";

                        atStatus = '<span class="acknowledgeRecent">Acknowledged <span class="acknowledgeCountRecent">0</span> of <span class="totalMemRecent">'+groupMembersCount+'</span></span>';
                    } else if (ack == 2){
                        ackStatus = "<span>, (Acc'd <span class='accCount'>0</span>, Rej'd <span class='rejCount'>0</span>) of <span id='totAckCount'>"+groupMembersCount+"</span></span>";

                        rackStatus = "<span class='ackStatus'> (Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span id='totalMembers'>"+groupMembersCount+"</span></span>";

                        atStatus = '<span class="acceptRecent">Accepted <span class="acceptCountRecent">0</span> of <span class="totalMemRecent">'+groupMembersCount+'</span>,</span><span class="rejectRecent">Rejected <span class="rejectCountRecent">0</span> of <span class="totalMemRecent">'+groupMembersCount+'</span></span>';
                    } else {
                        ackStatus = '';
                        rackStatus = '';
                        atStatus = '';
                    }

                    if(rackStatus == ""){
                        statusImage = receiptStatus;
                        tstatusImage = rtStatus;
                    } else {
                        statusImage = receiptStatus +','+ rackStatus;
                        tstatusImage = rtStatus+atStatus;
                    }

                    tooltipUpdate = 1;
                    tooltipNodeJID = nodeJid;
                } else {
                    sentStatus = '<span class="receiptStatus"> Sending...</span>';
                    receiptStatus = "<span class='receiptStatus'>Sending...</span>";
                    
                    if(ack == 1){
                        ackStatus = '<span class="ackStatus">, Ack Pending</span>';
                        rackStatus = "<span class='ackStatus'>Ack Pending</span>";
                    } else if (ack == 2) {
                        ackStatus = '<span class="ackStatus">, Acc/Rej Pending</span>';
                        rackStatus = "<span class='ackStatus'>Acc/Rej Pending</span>";
                    } else {
                        ackStatus = '';
                        rackStatus = '';
                    }

                    if(rackStatus == ""){
                        statusImage = receiptStatus;
                    } else {
                        statusImage = receiptStatus +', '+ rackStatus;
                    }
                }
                toid = JSON.parse(localStorage.getItem("toJID"));

                if (toid != null && stropheConnection.Strophe().getNodeFromJid(toid) == storage.adminName()) {
                    sentStatus = '<span class="receiptStatus">Sent</span>';
                    if(ack == 1){
                        ackStatus = '<span class="ackStatus">, Ack Pending</span>'
                    } else if (ack == 2) {
                        ackStatus = '<span class="ackStatus">, Acc/Rej Pending</span>'
                    } else {
                        ackStatus = '';
                    }
                }

                defaultcontent = '<li role="presentation"><a  class="message-info" >' + language.locali().message_info + '</a></li>';
                let today = new Date();
                let date = today.getUTCFullYear() + '-' + (today.getUTCMonth() + 1) + '-' + today.getUTCDate();
                let time = today.getUTCHours() + ":" + today.getUTCMinutes() + ":" + today.getUTCSeconds();
                let dateTime = date + ' ' + time;
                let msgactivitycontent = '<div class="dropdown msg-opt"><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">';
                msgactivitycontent += '<i class="option-arrow"></i></a><ul class="dropdown-menu head-option" role="menu" aria-labelledby="menu1">';
                msgactivitycontent += defaultcontent + '<li role="presentation"><a  class="forwardsingle" id="' + messageBodyContent[2] + '">' + language.locali().forward + "</a></li>";
                msgactivitycontent += '<li role="presentation"><a  class="deletesingle" data-msgtime="' + dateTime + '" >' + language.locali().delete + "</a></li>";

                        
                if (!(broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast')) {

                    msgactivitycontent += '<li role="presentation" class="favMenuDrop" id="fav-menu-' + messageid + '"><a  class="starmsg" >' + language.locali().star_message + '</a></li>';

                }


                msgactivitycontent += '<li role="presentation"><a  class="replysingle" >' + language.locali().reply + "</a></li></ul></div>";
                let divjid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
                if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {

                    if ($("[id='conversation"+divjid+"']").find(".current").length == 0) {
                        messagebody = '<div class="row message-previous current message-date"  id ="' + today + '"><div class="col-sm-12 previous"><a>Today</a></div></div>';
            
                        if (typeof lastContent == "undefined" || lastContent) {
                            $("[id='conversation"+divjid+"']").append(messagebody);
                        }
                    }
                    let msgSenderEl = '';
                    if(messageJson.message_type == 'image'){
                        msgSenderEl = 'sendImgEl'
                    }

                    senderBox = '<div class="row message-body" id="chat-' + messageid + '" data-priority="1"><div class="select-msg-blk"><input type="checkbox" class="select-message" value="' + messageid + "-" + messageBodyContent[2] + filePath + '"><label></label></div> <div class="col-sm-12 message-main-sender msg-continue" ><div class="sender '+msgSenderEl+'" >' + msgcontent + msgactivitycontent + '<span class="message-time"><span class="msg-sent-time pull-right">' + n + '</span><span class="msg-status-sign">' + sentStatus + '</span><span class="ackType">'+ ackStatus +"</span></span></div></div></div>";

                    if (typeof lastContent == "undefined" || lastContent) {
                        $("[id='conversation"+divjid+"']").append(senderBox);
                    }
        
                    if (typeof lastContent != "undefined" || lastContent === false) {
                        conversation.MessageLayout(utils.toJID(), "down", "");
                    }
                    
                    utils.scrollbottom("[id='conversation"+divjid+"']");
                }
                if (messagetype == 'audio') {
                $(document).ready(function () {
                    $(document).unbind().on("click", ".pButton", function() {
                        nodeJid = $(this).attr('id');
                        let result = nodeJid.split("-");
                        audio.audioDesign(result[0]);
                    });
                })
                }
                if (typeof messageJson.replyTo != "undefined") {
                    let ReplyMsgContent,ReplyMsgId;
                    ReplyMsgContent = localStorage.getItem("ReplyMsgContent");
                    ReplyMsgContent = new DOMParser().parseFromString(ReplyMsgContent, 'application/xml'); // Convert String to Obj
                  
                    localStorage.setItem("ReplyMsgId", messageid);
                
                    conversation.replyMessageCallBack(ReplyMsgContent); // Reply Message Callback Function
                    
                    ReplyMsgContent = "";
                    ReplyMsgId = "";

                    localStorage.setItem("ReplyMsgContent", ReplyMsgContent);
                    localStorage.setItem("ReplyMsgId", ReplyMsgId);
                }
                if (toid != null && stropheConnection.Strophe().getNodeFromJid(toid) == storage.adminName()) {
                    let randomValue = utils.cryptoRandomGenerate();

                    let msgid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
                    let cypherText = utils.encrypt(messagecontent, msgid);
                    let botiq = stropheConnection.StropheJs().$iq({ type: "get", id: msgid, to: stropheConnection.Server() }).c("query", {
                      xmlns: "jabber:iq:mod_bot",
                      search: cypherText,
                      messsage_id: msgid,
                      to_jid: toid
                    });
                    if (stropheConnection.Connection().connected) {
                      stropheConnection.Connection().sendIQ(botiq, module.exports.getBotChatCallBack);
                    } else {
                      offlineMessage[msgid] = [];
                      offlineMessage[msgid].push(botiq);
                      localStorage.setItem("offlineMessage", JSON.stringify(offlineMessage));
                    }
          
                  } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
                    let groupmember = broadcastDetails[nodeJid];
                    for (let keys in groupmember) {
                        let value = groupmember[keys];
                        let randomValue = utils.cryptoRandomGenerate();

                        let msgid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
                        let cypherText = utils.encrypt(messagecontent, msgid);
                        let blockuser = 0;
                        if (blockUserList != null && typeof blockUserList[value.user] != "undefined") {
                            blockuser = value.user;
                        }

                        let messageiq = stropheConnection.StropheJs().$msg({
                            to: value.user + "@" + stropheConnection.Server(),
                            type: "chat",
                            id: msgid
                        }).c('chatcontent', {
                            xmlns: 'urn:xmpp:content',
                            message_type: messagetype,
                            broadcast_id: nodeJid,
                            broadcast_msgid: messageid,
                            message_ack: ack,
                            message_priority: 1,
                            block_user: blockuser
                        }).up()
                            .c("body").t(cypherText);
                        if (stropheConnection.Connection().connected) {
                            stropheConnection.Connection().send(messageiq);
                        }

                    }
                    if (mediamessage) {

                        conversation.getBroadcastChatMedia(nodeJid + "@" + stropheConnection.Server(), '');
                    }
                } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                    let blockuser = 0;
                    let value = stropheConnection.Strophe().getNodeFromJid(touser);
                    if (blockUserList != null && typeof blockUserList[value] != "undefined") {
                        blockuser = value;
                    }
                    let cypherText = utils.encrypt(messagecontent, messageid);
                    
                    let messageiq = stropheConnection.StropheJs().$msg({
                        to: touser,
                        type: "chat",
                        id: messageid
                    }).c('chatcontent', {
                        xmlns: 'urn:xmpp:content',
                        message_type: messagetype,
                        broadcast_id: "",
                        message_ack: ack,
                        message_priority: 1,
                        block_user: blockuser
                    }).up()
                        .c("body").t(cypherText);
                    if (stropheConnection.Connection().connected) {
                        stropheConnection.Connection().send(messageiq);
                    }
                    if (mediamessage) {
                        conversation.getSingleChatMedia(touser, '');                    }

                } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                    specialGroup = (typeof(groupInfo[nodeJid][4]) == "undefined") ? 0 : 1;
                    let cypherText = utils.encrypt(messagecontent, messageid);
                    let messageiq = stropheConnection.StropheJs().$iq({
                        type: "set",
                        id: messageid,
                        to: touser
                    }).c('pubsub', {
                        xmlns: 'http://jabber.org/protocol/pubsub'
                    })
                        .c('publish', {
                            node: 'urn:xmpp:mix:nodes:messages'
                        })
                        .c('item')
                        .c('body', {
                            xmlns: 'jabber:client',
                            message_id: messageid,
                            message_type: messagetype,
                            message_ack: ack,
                            message_priority: 1,
                            block_user: 0,
                            sent_from: userJID + "@" + stropheConnection.Server(),
                            special_group: specialGroup
                        }).t(cypherText);
                    if (stropheConnection.Connection().connected) {
                        stropheConnection.Connection().send(messageiq);
                    }
                    if (mediamessage) {
                        conversation.getGroupChatMedia(touser, '');
                    }
                } else {
                    let unknownUser = localStorage.getItem("isUnknown");
                    if (typeof unknownUser === "undefined" || unknownUser === null) {
                        let blockuser = 0;
                        let value = stropheConnection.Strophe().getNodeFromJid(touser);
                        if (blockUserList != null && typeof blockUserList[value] != "undefined") {
                            blockuser = value;
                        }
                        let cypher_text = utils.encrypt(messagecontent, messageid);
                        
                        let messageIQ = stropheConnection.StropheJs().$msg({
                            to: touser,
                            type: "chat",
                            id: messageid
                        }).c('chatcontent', {
                            xmlns: 'urn:xmpp:content',
                            message_type: messagetype,
                            broadcast_id: "",
                            message_ack: ack,
                            message_priority: 1,
                            block_user: blockuser
                        }).up()
                            .c("body").t(cypher_text);
                        if (stropheConnection.Connection().connected) {
                            stropheConnection.Connection().send(messageIQ);
                        }
                        if (mediamessage) 
                            conversation.getSingleChatMedia(touser, '');
                    }
                }

                if (messagetype == "image" || messagetype == "video") {
                utils.mediadesign(messagetype, messageid);
                }
                
                utils.forwardmsg();
                conversation.mediaviewClick();
                time = timeFormat.currenttime();

                let userInSearch = $("#searchText").val();
                let updateChatObj = {
                    from         :userJID,
                    nodeJid      :nodeJid,
                    msgtype      :msgtype,
                    status       :statusImage,
                    messageid    :messageid,
                    unReadCount  :0,
                    n            :time,
                    tstatusImage :tstatusImage,
                    notifierName :toUserName,
                    imageId      :image,
                    sendFrom     : userJID,
                    userJid      : userJID
                  }
                      recentdesign.Updaterecent(updateChatObj); 
                if(tooltipUpdate == 1) 
                    $("[id='"+tooltipNodeJID+"']").find('.list-msg-status').append('<i class="tooltip-status">'+tstatusImage+'</i>');

                delete localStorage.replymsgInfo;

                if (toUserName.indexOf(userInSearch) == -1) 
                    $("#"+nodeJid).css("display", "none");

            }
        }

        if($('.side-profile').css('left') == '0px' || $('.side-two').css('left') == '0px' || $('.side-global-contact-list').css('left') == '0px' || $('.side-role').css('left') == '0px' || $('.side-global-role-list').css('left') == '0px' || $('.side-group').css('left') == '0px' || $('.side-global-team-contact-list').css('left') == '0px' || $('.side-settings').css('left') == '0px' || $('.blockedlist-sidenav').css('left') == '0px' || $('.locationid-list-sidenav').css('left') == '0px'){

            $('.filter.plus-circles').hide()
        
        }
    },
    getBotChatCallBack: function (botiq) {

        $(botiq).find('query').each(function () {


            if ($(this).find('message').length) {

                let time = timeFormat.convertTimestamp($(this).find('message').attr('time'), 'history');
                let msgbody = $(this).find('message').text();
                let randomValue = utils.cryptoRandomGenerate();

                let messageid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);

                let recMsgObj = {
                    from         :storage.adminName(),
                    typechat     :'bot',
                    msgid        :messageid,
                    sendfrom     :storage.adminName(),
                    msgbody      :msgbody,
                    time         :time,
                    forward      :false,
                }
                chatdesign.receivedmessage(recMsgObj);
            };
        });
    },
    sendTypingStatus: function () {
        if (module.exports.typing == 0) {

            if (utils.toJID() != '') {
                module.exports.typing = 1;
                let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
                let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
                let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
                let nodejid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
                if (userInfo != null && typeof userInfo[nodejid] != 'undefined') {
                    module.exports.sendTypingUserStatus()
                    
                } else if (broadcastProfile != null && typeof broadcastProfile[nodejid] != 'undefined') {
                    let groupmember = broadcastDetails[nodejid];
                    module.exports.sendTypingBroadcastStatus(groupmember)
                    
                } else {
                    module.exports.sendTypingGroupStatus()
                }

                clearInterval(timer);

                timer = setInterval(function () {
                    module.exports.sendGoneStatus();
                }, 3000);
            }
        }
    },
    sendTypingUserStatus: function(){
        let composeiq = stropheConnection.StropheJs().$msg({
            to: utils.toJID(),
            type: "chat",
            id: utils.getId()
        })
            .c('composing', {
                xmlns: 'http://jabber.org/protocol/chatstates'
            })
            .c('no-store', {
                xmlns: 'urn:xmpp:hints'
            });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(composeiq);
        }
    },
    sendTypingGroupStatus: function(){
        let composeiq = stropheConnection.StropheJs().$iq({
            type: 'set',
            to: utils.toJID(),
            id: utils.getId()
        }).c('query', {
            xmlns: 'jabber:iq:mixgroupchat',
            status: "composing"
        })
            .c('no-store', {
                xmlns: 'urn:xmpp:hints'
            });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(composeiq);
        }
    },
    sendTypingBroadcastStatus: function(groupmember){
        for (let keys in groupmember) {
            let value = groupmember[keys];
            let composeiq = stropheConnection.StropheJs().$msg({
                to: value + "@" + stropheConnection.Server(),
                type: "chat",
                id: utils.getId()
            })
                .c('composing', {
                    xmlns: 'http://jabber.org/protocol/chatstates'
                })
                .c('no-store', {
                    xmlns: 'urn:xmpp:hints'
                });
            if (stropheConnection.Connection().connected) {
                stropheConnection.Connection().send(composeiq);
            }
        }
    },
    sendGoneStatus: function () {
        module.exports.typing = 0;
        let nodejid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
        if (userInfo != null && typeof userInfo[nodejid] != 'undefined') {
            let goneiq = stropheConnection.StropheJs().$msg({
                to: utils.toJID(),
                type: "chat",
                id: utils.getId()
            })
                .c('gone', {
                    xmlns: 'http://jabber.org/protocol/chatstates'
                })
                .c('no-store', {
                    xmlns: 'urn:xmpp:hints'
                });
            if (stropheConnection.Connection().connected) {
                stropheConnection.Connection().send(goneiq);
            }
        } else if (broadcastProfile != null && typeof broadcastProfile[nodejid] != 'undefined') {
            let groupmember = broadcastDetails[nodejid];
            for (let keys in groupmember) {
                let value = groupmember[keys];
                let goneiq = stropheConnection.StropheJs().$msg({
                    to: value + "@" + stropheConnection.Server(),
                    type: "chat",
                    id: utils.getId()
                })
                    .c('gone', {
                        xmlns: 'http://jabber.org/protocol/chatstates'
                    })
                    .c('no-store', {
                        xmlns: 'urn:xmpp:hints'
                    });
                if (stropheConnection.Connection().connected) {
                    stropheConnection.Connection().send(goneiq);
                }
            }
        } else {
            let goneiq = stropheConnection.StropheJs().$iq({
                type: 'set',
                to: utils.toJID(),
                id: utils.getId()
            }).c('query', {
                xmlns: 'jabber:iq:mixgroupchat',
                status: "gone"
            })
                .c('no-store', {
                    xmlns: 'urn:xmpp:hints'
                });
            if (stropheConnection.Connection().connected) {
                stropheConnection.Connection().send(goneiq);
            }
        }
        clearInterval(timer);

    },
    sendforwardmsgTxt: function(msg,chatid){
        let Msg = $('#chat-' + chatid).find('.message-text').find('.statictext').text();

        if (typeof Msg == "undefined" || Msg == '') {
            Msg = $('#starredcontent-' + chatid).text();
        }

        return Msg;
    },
    sendforwardmsgAudio: function(msg,chatid,filesize,duration,ret,options){
        let Ret = ret;
        if (typeof msg == "undefined" || msg == '') {
            msg = $('#starredcontent-' + chatid).find('.starredcontent').find('audio').find('source').attr('src');
            filesize = $('#starredcontent-' + chatid).find('.starredcontent').find('.doc-size').html();
            duration = $('#starredcontent-' + chatid).find('.starredcontent').find('.doc-thumb').html();
        } else {
            filesize = $('#chat-' + chatid).find('.message-audio').find('.doc-size').html();
            duration = $('#chat-' + chatid).find('.message-audio').find('.doc-thumb').html();
        }
        Ret = filesize.split(" ");
        filesize = Ret[0];
        if (Ret[1] == 'KB') {
            filesize = filesize * 1024;
        } else if (Ret[1] == 'MB') {
            filesize = filesize * 1024 * 1024;
        }
        options.name = msg;
        options.duration = duration;
        let audioObj = {
            msg      :  msg,
            filesize :  filesize,
            options  :  options,
        }
        return audioObj;
    },
    sendforwardmsgVideo: function(msg,chatid,filesize,duration,ret,options,thumb){
        let Ret = ret;

        if (typeof msg == "undefined" || msg == '') {
            msg = $('#starredcontent-' + chatid).find('p').attr('src');
            options.name = $('#starredcontent-' + chatid).find('.doc-details').html();
            options.caption = $('#starredcontent-' + chatid).find('.selectable-text').html();
            filesize = $('#starredcontent-' + chatid).find('.doc-size').html();
            thumb = $('#chat-' + chatid).find('.doc-thumb').html();
        } else {
            options.name = $('#chat-' + chatid).find('.document-container').attr('title');
            options.caption = $('#chat-' + chatid).find('.selectable-text').html();
            filesize = $('#chat-' + chatid).find('.doc-size').html();
            thumb = $('#chat-' + chatid).find('.doc-thumb').html();
        }

        options.duration = $('#chat-' + chatid).find('.duration').html();
        Ret = filesize.split(" ");
        filesize = Ret[0];
        if (Ret[1] == 'KB') {
            filesize = filesize * 1024;
        } else if (Ret[1] == 'MB') {
            filesize = filesize * 1024 * 1024;
        }
        if (typeof options.caption == "undefined") {
            options.caption = '';
        }
        options.thumb = thumb;

        let videoObj = {
            msg      :  msg,
            filesize :  filesize,
            options  :  options,
        }
        return videoObj;
    },
    sendforwardmsgImage(msg,chatid,filesize,ret,options,thumb){
        let Ret = ret;
        if (typeof msg == "undefined" || msg == '') {
            msg = $('#starredcontent-' + chatid).find('p').attr('src');
            options.name = $('#starredcontent-' + chatid).find('.doc-details').html();
            options.caption = $('#starredcontent-' + chatid).find('.selectable-text').html();
            filesize = $('#starredcontent-' + chatid).find('.doc-size').html();
            thumb = $('#starredcontent-' + chatid).find('.doc-thumb').html();
        }
        else {
            options.name = $('#chat-' + chatid).find('.document-container').attr('title');
            options.caption = $('#chat-' + chatid).find('.selectable-text').html();
            filesize = $('#chat-' + chatid).find('.doc-size').html();
            thumb = $('#chat-' + chatid).find('.doc-thumb').html();
        }
        Ret = filesize.split(" ");
        filesize = Ret[0];
        if (Ret[1] == 'KB') {
            filesize = filesize * 1024;
        } else if (Ret[1] == 'MB') {
            filesize = filesize * 1024 * 1024;
        }
        if (typeof options.caption == "undefined") {
            options.caption = '';
        }
        options.thumb = thumb;

        let ImgObj = {
            msg      :  msg,
            filesize :  filesize,
            options  :  options,
        }
        return ImgObj;
    },
    sendforwardmsgFile: function(msg,chatid,filesize,ret,options){
        let Ret = ret;
        if (typeof msg == "undefined" || msg == '') {
            msg = $('#starredcontent-' + chatid).find('.document-container').attr('href');
            options.name = $('#starredcontent-' + chatid).find('.document-text').find('.doc-title').html();
            options.caption = $('#starredcontent-' + chatid).find('.doc-details').html();
            filesize = $('#chat-' + chatid).find('.message-doc').find('.doc-size').html();
        } else {
            options.name = $('#chat-' + chatid).find('.document-text').find('.doc-title').html();
            options.caption = $('#chat-' + chatid).find('.message-doc').find('.doc-details').html();
            filesize = $('#chat-' + chatid).find('.message-doc').find('.doc-size').html();
        }
        Ret = filesize.split(" ");
        filesize = Ret[0];
        if (Ret[1] == 'KB') {
            filesize = filesize * 1024;
        } else if (Ret[1] == 'MB') {
            filesize = filesize * 1024 * 1024;
        }
        if (typeof options.caption == "undefined") {
            options.caption = '';
        }

        let fileObj = {
            msg      :  msg,
            filesize :  filesize,
            options  :  options,
        }
        return fileObj;
    },
    sendforwardmsgLocation: function(chatid,options){
        options.longitude = $('#chat-' + chatid).find('.message-location').find('.loc-longitude').html();
        if (typeof options.longitude == "undefined" || options.longitude == '') {
            options.longitude = $('#starredcontent-' + chatid).find('.loc-longitude').html();
            options.latitude = $('#starredcontent-' + chatid).find('.loc-latitude').html();
        } else {
            options.latitude = $('#chat-' + chatid).find('.message-location').find('.loc-latitude').html();
        }

        let locObj = {
            options  :  options,
        }
        return locObj;
    },
    sendforwardmsgContact: function(chatid,options){
        options.name = $('#chat-' + chatid).find('.message-contact').find('.cont-name').html();
        if (typeof options.name == "undefined" || options.name == '') {
            options.name = $('#starredcontent-' + chatid).find('.cont-name').html();
            options.phone_number = $('#starredcontent-' + chatid).find('.cont-number').html();
        } else {
            options.phone_number = $('#chat-' + chatid).find('.message-contact').find('.cont-number').html();
        }

        let contactObj = {
            options  :  options,
        }
        return contactObj;
    },
    sendforwardmsg: function (chatid, chattype, touser, msg, messageId, forwardUserName, forwardUserImage) {
        let filesize = "";
        let options = {};
        let ret = "";
        let duration,thumb;
        if (chattype == "text") {
            msg = module.exports.sendforwardmsgTxt(msg,chatid)
            
        } else if (chattype == "audio") {
          let audioObj =   module.exports.sendforwardmsgAudio(msg,chatid,filesize,duration,ret,options)
          filesize = audioObj.filesize;
          options  = audioObj.options;
          msg      = audioObj.msg;

        } else if (chattype == "video") {
          let videoObj =   module.exports.sendforwardmsgVideo(msg,chatid,filesize,duration,ret,options,thumb)
          filesize = videoObj.filesize;
          options  = videoObj.options;
          msg      = videoObj.msg;
           
        } else if (chattype == "image") {
          let imgObj =   module.exports.sendforwardmsgImage(msg,chatid,filesize,ret,options,thumb)
          filesize = imgObj.filesize;
          options  = imgObj.options;
          msg      = imgObj.msg;
            
        } else if (chattype == "file") {
          let fileObj =   module.exports.sendforwardmsgFile(msg,chatid,filesize,ret,options)
          filesize = fileObj.filesize;
          options  = fileObj.options;
          msg      = fileObj.msg;
            
        } else if (chattype == "location") {
          let locObj =   module.exports.sendforwardmsgLocation(chatid,options)
          options  = locObj.options;
          msg = "";

        } else if (chattype == "contact") {
            let contactObj =   module.exports.sendforwardmsgContact(chatid,options)
            options  = contactObj.options;
            msg = "";
        }  

        let sendChatObj = {
            msg         	 :msg,
            messagetype      :chattype,
            messageid        :messageId,
            filesize         :filesize,
            options    	     :options,
            touser  	     :touser,
            ack              :0,
            forwardUserName  :forwardUserName,
            forwardUserImage :forwardUserImage,
        }

        module.exports.sendChatMessages(sendChatObj);
    }
};