let storage = require('./storage.js');
let userSession = require('./session.js');
let addressDiv = "";
let settings = require('./settings.js')
let utils = require('./utils.js');
let language = require('./locale/language.js');
let contact = require('./contact.js')

let self = module.exports = {
    // function used to get the siteDetails via API
    getSiteDetails: function (type) {
        let siteUrl = window._env_.ApiURL + "/users/sites";
        $.ajax({
            url: siteUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0]
                    accessTokenRegenerate(loginUserInfo);
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                console.log("Site Details Got Successfully!!")
                if(response.status == "204"){
                    return true;
                }
               
                    

                    module.exports.siteDetailsHeaderDivSet(type)
                    let countOfActiveSite=0,
                        singleSiteName,singleSiteId;
                    if(response.count > 0) {
                        let site = {};
                         for (let  keys in response.data) {
                             let values = response.data[keys];
                             if(values.countOfUsersOnSite > 0){
                              countOfActiveSite++;
                              singleSiteName = values.siteName;
                              singleSiteId   = values.siteId;

                             }
                             site[values.siteId] = {};
                             site[values.siteId].name = values.siteName;
                             site[values.siteId].abbreviation = values.abbreviation;
                             site[values.siteId].address = values.address;
                             site[values.siteId].countOfUsersOnSite = values.countOfUsersOnSite;
                             site[values.siteId].countOfOncallsOnSite = values.countOfOncallsOnSite;

                             module.exports.siteDetailsValueAppend(values,type)
                            
                         }
                         localStorage.setItem("siteDetails", JSON.stringify(site));
                     }
                     module.exports.siteDetailsParticipantRemove(type)
                     let site = {};

                     if(typeof type != 'undefined' && type != 'Role' && countOfActiveSite == 1){
                          let siteId = singleSiteId;
                          let siteName = singleSiteName;
                          let placeholderName;
                          if(type == 'Contact'){
                              siteName = "New Message";
                              placeholderName = "Search contacts";
                              module.exports.globalSiteContactTrigger(siteId,siteName,placeholderName);
      
                          }else if(type == 'Team'){
                              siteName = "Add Participants";
                              placeholderName = "Search contacts";
      
                              module.exports.globalSiteTeamTrigger(siteId,siteName,placeholderName);
      
                          }else{
                              siteName = "Add Participants";
                              placeholderName = "Search contacts";
      
                              module.exports.globalSiteBroadcastTrigger(siteId,siteName,placeholderName);
      
                          }
                          site['has_single_site'] = 1;
                          site['id'] = siteId;
                          site['name'] = siteName;
                      }else {
                          site['has_single_site'] = 0;
                          site['id'] = null;
                          site['name'] = null;
                      }
                      localStorage.setItem("singleActiveSiteDetails", JSON.stringify(site));

            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $(".sideContactNavLoader").hide();
                $(".sideRoleNavLoader").hide();
                $(".sideParticipantNavLoader").hide()
                $(".sideBroadcastParticipantNavLoader").hide()

            }
        });
        return true;
    },
    globalSiteContactTrigger(siteId,siteName,placeholderName){
       $("#currentSiteId").val(siteId)
       $(".side-global-contact-list").css({
        "left": "0px"
       });

       $('#globalSiteIdTxt').text(siteName)
       $('#globalSiteIdVal').val(siteId)
       $('#globalContactSearchTxt').attr('placeholder',placeholderName);
       
       $('#globalContactSearchTxt').val('');
       $("#globelSearchContactListClose").hide();
       $(".globelSearchContactList").show();

       $('.globalContactBackDiv').removeClass('globalContactListBack');
       $('.globalContactBackDiv').addClass('contacts-back');

        let searchContent     = '',
            isChatUser        = '',
            page              = 1;

            $('#globalContactLists').empty();
            $(".sideContactListNavLoader").show()

            setTimeout(() => {
                let globalQueryParams;
                if (searchContent == '') {
                  globalQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
                  contact.getGlobalContactsList(globalQueryParams,'globalContactLists');
      
                } else {
                  if (searchContent.length > 0) {
                    globalQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
                    contact.getGlobalContactsList(globalQueryParams,'globalContactLists');
      
                  } 
                }
              }, 500)


              $("body").on("click", ".contacts-back", function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                let singleActiveSiteDetails = JSON.parse(localStorage.getItem("singleActiveSiteDetails"))
                let hasSingleSite = singleActiveSiteDetails['has_single_site']
                $(".side-global-contact-list").css({
                    "left": "-100%"
                  });
                if(hasSingleSite == 1){
                  module.exports.mainPageDisplayControl();

                  $("#composeText").val("");
                
                  $(".side-two").css({
                    "left": "-100%"
                  });
                }
              
              });
             
    },

    globalSiteTeamTrigger(siteId,siteName,placeholderName){
        $("#currentSiteId").val(siteId)

        $("#globalParticipantCurrentSiteId").val(siteId)
  
         $(".side-global-team-contact-list").css({
          "left": "0px"
         });
  
         $('.side-global-team-contact-list .addedParticipantBox input').attr("placeholder", "Search selected site contacts");
         
         $(".addedParticipantBox input").attr('placeholder',placeholderName);

         $('#globalTeamSiteIdTxt').text(siteName)
         $('#globalTeamSiteIdVal').val(siteId)
  
         $(".searchParticipantOpen").show();
         $(".searchParticipantClose").hide();
  
         $(".addedParticipantBox").show();
         $(".globelSiteParticipantSearchDiv").hide();
  
         $(".side-global-team-contact-list .addedParticipantBox input").val('');
  
          let searchContent     = '',
              isChatUser        = 1,
              page              = 1;
              

        $('#globalTeamContactLists').empty();

        $(".sideTeamContactListNavLoader").show()

        setTimeout(() => {
            let globalTeamQueryParams;
            if (searchContent == '') {
            globalTeamQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
            contact.getNewGroupContacts(globalTeamQueryParams,'globalTeamContactLists',true);
            } else {
            if (searchContent.length > 0) {
                globalTeamQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
                contact.getNewGroupContacts(globalTeamQueryParams,'globalTeamContactLists',true);

            } 
            }
            $(".addedParticipantBox input").attr('placeholder',placeholderName);

        }, 500)

             $('.globalTeamBackDiv').removeClass('globalTeamContactListBack');
              $('.globalTeamBackDiv').addClass('participants-back');

                $("body").on("click", ".participants-back", function(e) {
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  $('#broadcast-participant-no-sites').hide()

                  let singleActiveSiteDetails = JSON.parse(localStorage.getItem("singleActiveSiteDetails"))
                  let hasSingleSite = singleActiveSiteDetails['has_single_site']
                    $(".side-global-team-contact-list").css({
                      "left": "-100%"
                    });
                    $('.addedParticipantBox input').attr('placeholder','Search contact or site name');

                   
                    if(hasSingleSite == 1){
                      module.exports.mainPageDisplayControl();
  
                      $(".compose-sideBar").removeClass('bubbleadded');
                      $(".compose-sideBar").removeClass('new-group-section');

                      $('#participantSitesText').val('');
                      $(".side-group").css({
                        "left": "-100%"
                      });
                    }else {
                      $('.globalTeamContactListBack').trigger('click')
                    }
                });
 
     },

    globalSiteBroadcastTrigger(siteId,siteName,placeholderName){
        $("#currentSiteId").val(siteId)
        $("#globalBroadcastParticipantCurrentSiteId").val(siteId)
  
         $(".side-global-broadcast-contact-list").css({
          "left": "0px"
         });
  
         $('.side-global-broadcast-contact-list .addedBroadcastParticipantBox input').attr("placeholder", "Search selected site contacts");
         
       $(".addedBroadcastParticipantBox input").attr('placeholder',placeholderName);

         $('#globalBroadcastSiteIdTxt').text(siteName)
         $('#globalBroadcastSiteIdVal').val(siteId)
  
         $(".searchBroadcastParticipantOpen").show();
         $(".searchBroadcastParticipantClose").hide();
  
         $(".addedBroadcastParticipantBox").show();
         $(".globelSiteBroadcastParticipantSearchDiv").hide();
  
       
  
         $(".side-global-broadcast-contact-list .addedBroadcastParticipantBox input").val('');
  
          let searchContent     = '',
              isChatUser        = 1,
              page              = 1;
              

      $('#globalBroadcastContactLists').empty();

      $(".sideBroadcastContactListNavLoader").show()

      setTimeout(() => {
        let globalTeamQueryParams;

        if (searchContent == '') {
          globalTeamQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
          contact.getNewBroadCastContacts(globalTeamQueryParams,'globalBroadcastContactLists',true);
        } else {
          if (searchContent.length > 0) {
            globalTeamQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
            contact.getNewBroadCastContacts(globalTeamQueryParams,'globalBroadcastContactLists',true);

          } 
        }
      }, 500)
               
      $('.globalBroadcastBackDiv').removeClass('globalBroadcastContactListBack');
      $('.globalBroadcastBackDiv').addClass('broadcast-participants-back');

        $("body").on("click", ".broadcast-participants-back", function(e) {
          e.preventDefault();
          e.stopImmediatePropagation();
          $('#broadcast-participant-no-sites').hide()

          let singleActiveSiteDetails = JSON.parse(localStorage.getItem("singleActiveSiteDetails"))
          let hasSingleSite = singleActiveSiteDetails['has_single_site']
          $(".side-global-broadcast-contact-list").css({
              "left": "-100%"
            });
            $('.addedBroadcastParticipantBox input').attr('placeholder','Search contact or site name');
        
          if(hasSingleSite == 1){
            module.exports.mainPageDisplayControl();
            $(".compose-sideBar").removeClass('bubbleadded');
            $(".compose-sideBar").removeClass('new-group-section');
            $('#searchBroadcastparticipant').val('');

            $('#broadcastParticipantSitesText').val('');
            $(".side-broadcast").css({
                "left": "-100%"
            });
          }else {
            $('.globalBroadcastContactListBack').trigger('click')

          }
          
        });
      
 
    },
     
    mainPageDisplayControl(){
        $(".side-group").css({
            "display": "block"
          });
          $(".profile-group").css({
            "display": "block"
          });
          $(".side-settings").css({
            "display": "block"
          });
          $(".side-profile").css({
            "display": "block"
          });
          $(".bot-settings").css({
            "display": "block"
          });
          $('.stared-list').css({
            "display": "block"
          });
          $(".side-two").css({
            "display": "block"
          });
          $(".blockedlist-sidenav").css({
            "display": "block",
            "left": "-100%"
          });
          $("#recentchat").css({
            "display": "block"
          });
        
          $(".locationid-list-sidenav").css({
            "display": "block",
            "left": "-100%"
          });
          $('.plus-circles').css('display','block');
    },
    
    siteDetailsValueAppend: function(values,type){

        let concatedLetter = '';
        if(values.siteName != "") {
            let splitedArr = values.siteName.split(' ');
            let splitedNameArr = splitedArr.filter(function(v){return v!==''});
            for (let index = 0; index < splitedNameArr.length; index++) {
                if(index<3){
                    concatedLetter += splitedNameArr[index][0].toUpperCase()
                }
                
            }
        }
        module.exports.siteDetailsDivAppend(values,type,concatedLetter)
    },
    siteDetailsDivAppend: function(values,type,concatedLetter){
        let contactDiv = '<div class="sitecontent contactlist row sideBar-body usersite_'+values.countOfUsersOnSite+' oncallsite_'+values.countOfOncallsOnSite+'"  id="site-' + values.siteId + '" data-countOfUsersOnSite ="'+values.countOfUsersOnSite+'" data-countOfOncallsOnSite ="'+values.countOfOncallsOnSite+'"  ><div class="select-cnt-blk"></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';

        let imageDiv = '<div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div> </div>';

        let nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main site-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name site-name"><div class="site-list-div"><span class="name-meta site-list">' + values.siteName + '</span></div></div>';

        if(typeof values.address != "undefined" && values.address != null && values.address != "") 
            addressDiv = '<div class="sideBar-msg role-div"><span class="name-status" title="'+values.address+'">' + values.address + ' </span></div></div></div>';
                
        if(type == 'Contact') {
            if(values.countOfUsersOnSite > 0)
                $("#sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);
                           
        } else if(type == 'Role') {
            if(values.countOfOncallsOnSite > 0)
            $("#roles-sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);

        } else if(type == 'Team') {
            if(values.countOfUsersOnSite > 0)
                $("#participant-sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);
        }else {
            if(values.countOfUsersOnSite > 0)
                $("#broadcast-participant-sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);
        }

    },
    sideContactDesignFun: function(){
        $('.chat-option').removeClass('menu');
        $('.plus-circles').css('display','none');
        $(".side-group").css({
          "display": "none"
        });
        $(".profile-group").css({
          "display": "none"
        });
        $(".side-settings").css({
          "display": "none"
        });
        $(".bot-settings").css({
          "display": "none"
        });
        $('.stared-list').css({
          "display": "none"
        });
        $(".blockedlist-sidenav").css({
          "display": "none"
        });
        $(".side-settings").css({
          "display": "none"
        });
        $(".side-profile").css({
          "display": "none"
        });
        $(".side-two").css({
          "left": "0",
          "display": "block"
        });
    },
    sideGrpAndBroadCastCmnDesign(){
      $(".side-settings").css({
        "display": "none"
      });
      $(".side-profile").css({
        "display": "none"
      });
      $(".side-two").css({
        "display": "none"
      });
      $(".bot-settings").css({
        "display": "none"
      });
      $('.stared-list').css({
        "display": "none"
      });
    },
    sideGroupDesignFun: function(){
        $('.grp-filter').removeClass("menu");
        $("#participants").scrollTop(0);
     
        $(".side-group").css({
          "left": "0",
          "display": "block"
        });
    },
    sideBroadcastDesignFun: function(){
        $('.grp-filter').removeClass("menu");
        $("#broadcast-participants").scrollTop(0);
     
        $(".side-broadcast").css({
          "left": "0",
          "display": "block"
        });
    },
    siteDetailsHeaderDivSet: function(type){
        if(type == 'Contact'){
            module.exports.sideContactDesignFun();
            $("#sites-info").empty();
            $("#sites-info").append('<div class="contactSitesHeaderDiv">Sites</div>');
        } else if(type == 'Role'){
            $("#roles-sites-info").empty();
            $("#roles-sites-info").append('<div class="roleSitesHeaderDiv">Sites</div>');
        } else if(type == 'Team'){
            /** Side Group Desing **/
            module.exports.sideGroupDesignFun();
            $("#participant-sites-info").empty();
            $("#participant-sites-info").append('<div class="participantSitesHeaderDiv" style="display:none">Sites</div>');
        }else if(type == 'Broadcast'){
            /** Side Broadcast Desing **/
            module.exports.sideBroadcastDesignFun();

            $("#broadcast-participant-sites-info").empty();
            $("#broadcast-participant-sites-info").append('<div class="broadCastParticipantSitesHeaderDiv" style="display:none">Sites</div>');
        }
    },
    teamParticipantDivDesign : function(){
        if($('#bubble-block').children().length > 0){
            $('#participants').css('padding','0px')
            $('#participant-sites-info').css('padding','0px')
        }else {
            $('#participants').css('padding','1px')
            $('#participant-sites-info').css('padding','1px')
        }
    },
    BroadcastParticipantDivDesign : function(){
        if($('#broadcast-bubble-block').children().length > 0){
            $('#broadcast-participants').css('padding','0px')
            $('#broadcast-participant-sites-info').css('padding','0px')
        }else {
            $('#broadcast-participants').css('padding','1px')
            $('#broadcast-participant-sites-info').css('padding','1px')
        }
    },
    addParticipantDivCalculation: function(elementId){
      let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId);
      let osType = utils.getDeviceOsType();

      if(osType == "Windows"){
        participantStaticDivHeight = participantStaticDivHeight-4;
      }

      $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });

      $(elementId).mCustomScrollbar({
          callbacks: {
              onTotalScroll: function () {
              }
          },
          scrollInertia:0

      });

      setTimeout(function(){
          $(elementId).mCustomScrollbar('update');
      },300);

      $(elementId).find('.mCSB_container').css('z-index',0)
      $(elementId).find('.mCSB_dragger').css('height','200px')
    },
    siteDetailsParticipantRemove: function(type){
        let siteLength;
        if(type == 'Contact')
            siteLength = $('#sites-info .sitecontent').length;
        else if(type == 'Role')
            siteLength = $('#roles-sites-info .sitecontent').length;
        else if(type == 'Team')
            siteLength = $('#participant-sites-info .sitecontent').length;
        else
            siteLength = $('#broadcast-participant-sites-info .sitecontent').length;

        if(siteLength == 0) {
            if(type == 'Contact'){
                $('#sites-info #noparticipant').remove();
                $("#sites-info").html("<div id='noparticipant'>" + language.locali().nosite_available + "</div>");
            }else if(type == 'Role'){
                $('#roles-sites-info #noparticipant').remove();
                $("#roles-sites-info").html("<div id='noparticipant'>" + language.locali().nosite_available + "</div>");
            }else { // Its Both Team and Broadcast Block
              $('#broadcast-participant-no-sites').show()
              $('#broadcast-participant-no-sites').text(language.locali().nosite_available);
            }
        }
        if(type == 'Contact'){
            $("#sites-info").mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                    }
                }
            });
            $('#sites-info').find('.mCSB_container').css('z-index',0)
            
        }else if(type == 'Role'){
            $("#roles-sites-info").mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                    }
                }
            });
            $('#roles-sites-info').find('.mCSB_container').css('z-index',0)
        }else if(type == 'Team'){
            module.exports.teamParticipantDivDesign()
           
            /** Team Participant Div Calculation and Update**/
            module.exports.addParticipantDivCalculation('#participant-sites-info')
            

        }else{
            module.exports.BroadcastParticipantDivDesign()

            /** Broad cast Participant Div Calculation and Update**/
            module.exports.addParticipantDivCalculation('#broadcast-participant-sites-info')

        }
    }
};

// function used to get the regenrate the acces token to get site details
async function accessTokenRegenerate(username) {
    await userSession.setSession(username);
    self.getSiteDetails('Contact');
}
