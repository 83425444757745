let storage = require('./storage.js');
let utils = require('./utils.js');
let language = require('./locale/language.js');
let stropheConnection = require('./stropheConnection.js');
let recentdesign = require('./recentdesign.js');
let shouldHandleScroll;

module.exports = {
    getOnCallsParams: function(siteId,search,page,type,unitName){
        let params; 
        if(siteId == null || siteId == "" || siteId.length == 0) {
            params = '?search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination;
        } else {
            let unitParam = (unitName != '') ? '&unit='+unitName : '';
            if(typeof type != 'undefined' && type != ''){
                params = '?siteId=' + siteId + '&search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination + '&sortby=' + type+unitParam;

            }else {
                params = '?siteId=' + siteId + '&search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination+unitParam;

            }
        }

        return params;
    },
    getOnCallsEmptyCheck(isEmpty,elementId){
        if (isEmpty == 1) 
            $(elementId).empty();
    },
    getOnCallsActiveMembObj: function(activeMemberName,activeMemberId,userLoggedIn){
        let activeMemberColorName;
        if(activeMemberName == "") {
            activeMemberColorName = "#999";
        } else if (activeMemberName != "" && activeMemberId === userLoggedIn) {
            activeMemberColorName = "#42AB14";
            activeMemberName = "You";
        } else {
            activeMemberColorName = "#454545";
        }

        let activeMembObj = {
            activeMemberName    :   activeMemberName,
            activeMemberColorName   :   activeMemberColorName
        }

        return activeMembObj;
    },
    getOnCallsIconixBadgeIcon(activeMemberName,isChatUser){
        let IconixBadgeIcon = '';
        if(activeMemberName != "") {
            if(isChatUser){
                IconixBadgeIcon = '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">'
            }
        }

        return IconixBadgeIcon;
    },
    getOnCallsMsgPagerNo: function(values){
        let msg_pager_no = (values.activeMemberDetails != null) ? values.activeMemberDetails.pagerNumber : "";
        return msg_pager_no;
    },
    getOnCallsSharedConditionCheck: function(values,activeMemberId,oncall_pager_no,msg_pager_no,profileVisibility,userLoggedIn){
        let isCheck;
        if((values.onCallReceiveDeviceId || (activeMemberId != "" && values.isActive) || (oncall_pager_no != "" || (msg_pager_no != "" && profileVisibility.pager_number)) && activeMemberId !== userLoggedIn )){
             isCheck = true;
        }else {
            isCheck = false;

        }

        return isCheck
    },
    getOnCallsSharedDevUserChatEl: function(values){
        let sharedDeviceUserChatEl;
        if(values.isChatUser == 1){

            sharedDeviceUserChatEl = '<li role="presentation"><a class="sharedDeviceUserChat" data-member="'+values.activeMemberId+'" data-chat-user="'+values.isChatUser+'"><img src="./src/images/svg/ikonixicon.svg" alt="" class="SharedDeviceIconImg">Connect Chat</a></li>';
        }else {
            sharedDeviceUserChatEl = ""
        }

        return sharedDeviceUserChatEl;
    },
    getOnCallsSharedDevListEl: function(values,activeMemberId,sharedDeviceUserChatEl,sharedDeviceRoleEl){
        let sharedDeviceListEl;
        if(values.onCallReceiveDeviceId && (activeMemberId != "" && activeMemberId != "null")){

            sharedDeviceListEl = sharedDeviceUserChatEl + sharedDeviceRoleEl;

        }else if((activeMemberId != "" && activeMemberId != "null")) {

            sharedDeviceListEl = sharedDeviceUserChatEl;

        }else if(values.onCallReceiveDeviceId) {

            sharedDeviceListEl =  sharedDeviceRoleEl;

        }else {
            sharedDeviceListEl = "";
        }

        return sharedDeviceListEl;
    },
    getOnCallsSharedDevOncallPagerNo: function(values,oncall_pager_no,pagerIconEl,sharedDeviceListEl){
        let oncall_pager_el;
        if(oncall_pager_no !=""){
            oncall_pager_el = '<li role="presentation"><a class="msg-role-pager" data-name="'+values.onCallName+'" data-pagerNo = "'+oncall_pager_no+'" data-nodeId="'+values.nodeId+'" data-type="Pager" data-toid="'+values.onCallId+'">'+pagerIconEl+ ' Message Role Pager</a></li>'

            sharedDeviceListEl += oncall_pager_el;
        }

        return sharedDeviceListEl;
    },
    getOnCallsSharedDevMsgEl: function(values,activeMemberId,msg_pager_no,userLoggedIn){
        let oncall_pager_no = values.onCallPagerNumber; 
        let sharedDeviceMsgEl;
        let pagerIconEl = '<img src="./src/images/pager.svg" class="SharedDeviceIconImg"/>';

        let pagerEl = "";
        let profileVisibility = JSON.parse(localStorage.getItem("settings")).profileVisibility;
        let msg_pager_el;
        
        let isCheck = module.exports.getOnCallsSharedConditionCheck(values,activeMemberId,oncall_pager_no,msg_pager_no,profileVisibility,userLoggedIn);

        if(isCheck){
            let SharedDevClass = (pagerEl == "") ? "sharedMsgIconMove" : "";

            let sharedDeviceUserChatEl = module.exports.getOnCallsSharedDevUserChatEl(values);

            let sharedDeviceRoleEl = '<li role="presentation"><a class="msg-received-device-pager" data-deviceName ="'+values.onCallReceiveDeviceName+'" data-pagerNo = "'+values.onCallReceiveDeviceId+'" data-nodeid="'+values.nodeId+'" data-type="ReceiveDevice" data-toid="'+values.onCallId+'"><img src="./src/images/svg/message.svg" alt="" class="SharedDeviceIconImg">Message '+values.onCallReceiveDeviceName+'</a></li>'

            let sharedDeviceListEl = module.exports.getOnCallsSharedDevListEl(values,activeMemberId,sharedDeviceUserChatEl,sharedDeviceRoleEl);

            sharedDeviceListEl = module.exports.getOnCallsSharedDevOncallPagerNo(values,oncall_pager_no,pagerIconEl,sharedDeviceListEl);

            if(msg_pager_no !="" && profileVisibility.pager_number){
                msg_pager_el = '<li role="presentation"><a href="javascript:void(0);" class="msg-pager" data-name="'+values.activeMemberName+'" data-pagerNo = "'+msg_pager_no+'" data-nodeId="'+values.activeMemberDetails.localUserNodeId+'" data-type="Pager" data-toid="'+values.activeMemberId+'" >'+pagerIconEl+' Message Pager</a></li>'
                sharedDeviceListEl += msg_pager_el;
            }
            

            if(sharedDeviceListEl != ""){
                sharedDeviceMsgEl = '<div class="dropdown sharedDevMsgDiv  '+SharedDevClass+' " ><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown"><svg class="sharedDeviceMsgIcon" data-onCallReceiveDeviceName = "'+values.onCallReceiveDeviceName+'" data-onCallReceiveDeviceId = "'+values.onCallReceiveDeviceId+'" data-member="'+values.activeMemberId+'" data-chat-user="'+values.isChatUser+'" data-isactive="'+values.isActive+'" data-nodeId="'+values.nodeId+'"  data-oncallName="'+values.onCallName+'" data-name="'+values.activeMemberName+'" data-oncall_pager_no="'+oncall_pager_no+'" data-msg_pager_no = "'+msg_pager_no+'" data-activeMemberId="'+values.activeMemberId+'"  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 20 20"><defs><style>.cls-1{fill:#f09343;}</style></defs><title></title><path class="cls-1" d="M5.83,10.66a.69.69,0,0,1-.51-.21.71.71,0,0,1-.21-.5.73.73,0,0,1,.71-.72H9.07a.72.72,0,0,1,0,1.43Zm0-.78Z"/><path class="cls-1" d="M5.28,17.41a1,1,0,0,1-.38-.07,1.05,1.05,0,0,1-.66-1V14.17H4a2,2,0,0,1-2-2V5A2,2,0,0,1,4,3H15.66a2,2,0,0,1,2,2v7.14a2,2,0,0,1-2,2H8.83L6,17.09A1,1,0,0,1,5.28,17.41ZM4,4.44A.58.58,0,0,0,3.4,5v7.14a.58.58,0,0,0,.58.58H5a.71.71,0,0,1,.72.71v1.94L8,13a.7.7,0,0,1,.51-.22h7.14a.58.58,0,0,0,.58-.58V5a.58.58,0,0,0-.58-.58Z"/><path class="cls-1" d="M13.71,8.12H5.93a.72.72,0,1,1,0-1.43h7.78a.75.75,0,0,1,.51.21.71.71,0,0,1-.51,1.22Z"/></svg></a><ul class="dropdown-menu contact-menu sharedDeviceUlEl" role="menu" aria-labelledby="menu1">'+sharedDeviceListEl+'</ul></div>'
            }else {
                sharedDeviceMsgEl = "";

            }
            
            }else {
            sharedDeviceMsgEl = "";
            }

            return sharedDeviceMsgEl;
    },
    getOnCallsActiveMemberDiv: function(countOfOnCallMembers,isActive,activeMemberColorName,activeMemberName,sharedDeviceSpanHtml){
        let activeMemberDiv = "";
        if(countOfOnCallMembers != 0 && isActive) {

            if(activeMemberName != "" ) {
                activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" title="'+activeMemberName+'" style="color: '+ activeMemberColorName +'">' + activeMemberName + ' </span>'+sharedDeviceSpanHtml+'</div>';
            } else {

                let content = module.exports.getOncallRoleType(isActive);
                activeMemberColorName = module.exports.getOncallActiveMemberColorName(isActive);


                activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" style="color: '+ activeMemberColorName +'">'+content+'</span>'+sharedDeviceSpanHtml+'</div>';
            }
        }else {
            let content = module.exports.getOncallRoleType(isActive);
            activeMemberColorName = module.exports.getOncallActiveMemberColorName(isActive);

            activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" style="color: '+ activeMemberColorName +'">'+content+'</span>'+sharedDeviceSpanHtml+'</div>';
        }

        return activeMemberDiv;
    },
    getOncallRoleType: function(isActive){
        let content;
        content = isActive ? "Shared Device" : "Role not active";
      
        return content;
    },
      
    getOncallActiveMemberColorName: function(isActive){
    let colorCode;
    colorCode = isActive ? "#454545" : "#999";
    
    return colorCode;
    },
    getOnCallSiteDiv: function(siteName,onCallUnit){
        let siteDiv = "";
        if(siteName != ""){
            onCallUnit = (onCallUnit != null) ? onCallUnit : '';
            siteDiv = '<div class="sideBar-msg site-div"><span class="name-status site-name" title="'+siteName+" " +onCallUnit+'">' + siteName + " "+onCallUnit+ '</span></div>';
        }
    
        return siteDiv;
    },
    getOnCallElementAppend: function(page,elementId,contentDiv,detailsDiv){
        if (page == 1 || page == "") {
            $(elementId).append(contentDiv + detailsDiv);
        } else {
            $(elementId).find('.mCSB_container').append(contentDiv + detailsDiv);
        }
    },
    getOnCallCustomScrollBarset: function(oncallObj,elementId,elementName){
        let search      = oncallObj.search,
            page        = oncallObj.page,
            type        = oncallObj.type,
            unitName    = oncallObj.unitName;
        if (page == 1 || page == "") {
            $(elementId).mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                        let siteId = $("#currentSiteId").val();
                        if(siteId == 0) {
                            siteId = "";
                        }
                        let rolesCount = $(''+elementId+' .roles-content').length;
                        let rolesLength = rolesCount / language.locali().default_size_pagination;
                        page = rolesLength + 1;
                        if (rolesCount % language.locali().default_size_pagination == 0) {
                            let oncallObj = {
                                siteId      :siteId,
                                search      :search,
                                page        :page,
                                isEmpty     :'',
                                elementName :elementName,
                                type        :type,
                                unitName    :unitName,
                            
                              }
                            module.exports.getOnCalls(oncallObj);
                        }
                    }
                }
            });
        } else {
            $(elementId).mCustomScrollbar("update");
        }
    },
    getOnCallEmptyParticipantCheck: function(rolesLength,elementId){
        if(rolesLength == 0) {
            $(''+elementId+' #noparticipant').remove();
            $(elementId).html("<div id='noparticipant'>" + language.locali().noroles_available + "</div>");
        }  
    },
    getOnCallErrorMsgReturn: function(jqXHR,exception){
        let getOnCallErrMsg;
        if (jqXHR.status === 0) {
            getOnCallErrMsg = 'Not connect.\n Verify Network.';
            utils.reconnectionPopupShow();

        } else if (jqXHR.status == 404) {
            getOnCallErrMsg = 'Requested page not found. [404]';
        } else if (jqXHR.status == 500) {
            getOnCallErrMsg = 'Internal Server Error [500].';
        } else if (exception === 'parsererror') {
            getOnCallErrMsg = 'Requested JSON parse failed.';
        } else if (exception === 'timeout') {
            getOnCallErrMsg = 'Time out error.';
        } else if (exception === 'abort') {
            getOnCallErrMsg = 'Ajax request aborted.';
        } else {
            getOnCallErrMsg = 'Uncaught Error.\n' + jqXHR.responseText;
        }

        return getOnCallErrMsg;
    },
    getOnCalls: function (oncallObj) {
        let siteId      = oncallObj.siteId,
            search      = oncallObj.search,
            page        = oncallObj.page,
            isEmpty     = oncallObj.isEmpty,
            elementName = oncallObj.elementName,
            type        = oncallObj.type,
            unitName    = oncallObj.unitName;

    let oncallsUrl = window._env_.ApiURL + "/onCalls/";
    let elementId =  "#"+elementName;
    let params;
    $("#currentSiteId").val(siteId)
     params = module.exports.getOnCallsParams(siteId,search,page,type,unitName);
        $.ajax({
            url: oncallsUrl + params,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    let oncallObj = {
                        siteId      :siteId,
                        search      :search,
                        page        :page,
                        isEmpty     :isEmpty,
                        elementName :elementName,
                        type        :type,
                        unitName    :unitName,
                    
                      }
                    module.exports.getOnCalls(oncallObj)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                module.exports.getOnCallsEmptyCheck(isEmpty,elementId);

                for (let keys in response.data) {
                    let values = response.data[keys];
                    let onCallImage = values.onCallImage.trim();
                    let onCallForegroundColor = values.onCallForegroundColor.trim();
                    let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";
                    let onCallUnit = values.onCallUnit
                    let Icon;
                    switch (values.onCallIcon) {
                        case 'Ambulance':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26848" data-name="Group 26848" transform="translate(-9318 -11373)">\n' +
                                '    <g id="Group_26842" data-name="Group 26842">\n' +
                                '      <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9318 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '      <path id="ambulance" d="M49.471,177.472a4.948,4.948,0,1,1,1.449,3.5A4.767,4.767,0,0,1,49.471,177.472Zm14.841-19.789H49.471v-9.894h6.107a1.607,1.607,0,0,1,.85.348l7.537,7.537a2.823,2.823,0,0,1,.348.85ZM14.841,177.472a4.948,4.948,0,0,1,4.947-4.947,4.947,4.947,0,1,1-3.5,8.445A4.766,4.766,0,0,1,14.841,177.472ZM9.894,151.5v-7.421a1.191,1.191,0,0,1,1.237-1.237h8.657v-8.658a1.191,1.191,0,0,1,1.237-1.237h7.421a1.191,1.191,0,0,1,1.237,1.237v8.658H38.34a1.191,1.191,0,0,1,1.237,1.237V151.5a1.191,1.191,0,0,1-1.237,1.237H29.683v8.657a1.191,1.191,0,0,1-1.237,1.237H21.025a1.191,1.191,0,0,1-1.237-1.237v-8.657H11.131A1.191,1.191,0,0,1,9.894,151.5ZM0,130.474V175a2.377,2.377,0,0,0,.734,1.739,2.377,2.377,0,0,0,1.739.734H9.894a9.533,9.533,0,0,0,2.9,7,9.533,9.533,0,0,0,7,2.9,9.533,9.533,0,0,0,7-2.9,9.533,9.533,0,0,0,2.9-7H44.524a9.893,9.893,0,0,0,16.89,7,9.533,9.533,0,0,0,2.9-7H69.26a2.474,2.474,0,0,0,0-4.947V156.446a6.027,6.027,0,0,0-.5-2.242,5.876,5.876,0,0,0-1.237-1.971l-7.653-7.653a5.875,5.875,0,0,0-1.971-1.237,6.026,6.026,0,0,0-2.242-.5H49.471V130.474A2.508,2.508,0,0,0,47,128H2.474a2.377,2.377,0,0,0-1.739.734A2.377,2.377,0,0,0,0,130.474Z" transform="translate(9357 11290)"    fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>'
                            break;
                        case "Group":
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26856" data-name="Group 26856" transform="translate(-9312 -11567)">\n' +
                                '    <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9312 11567)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="group_12_" data-name="group (12)" transform="translate(9343 11612.5)">\n' +
                                '      <circle id="Ellipse_260" data-name="Ellipse 260" cx="7.304" cy="7.304" r="7.304" transform="translate(7.304 11.978)"    fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20434" data-name="Path 20434" d="M24.357,14.461a15.3,15.3,0,0,0-6.1,12.234v.913H2.739A2.759,2.759,0,0,1,0,24.868V23.042A10.037,10.037,0,0,1,10.042,13h9.129A9.977,9.977,0,0,1,24.357,14.461Z" transform="translate(0 17.237)"    fill="'+fillColor+'"/>\n' +
                                '      <circle id="Ellipse_261" data-name="Ellipse 261" cx="7.304" cy="7.304" r="7.304" transform="translate(65.732 11.978)"     fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20435" data-name="Path 20435" d="M41.687,23.042v1.826a2.759,2.759,0,0,1-2.739,2.739H23.429v-.913a15.3,15.3,0,0,0-6.1-12.234A9.977,9.977,0,0,1,22.516,13h9.129A10.037,10.037,0,0,1,41.687,23.042Z" transform="translate(45.956 17.237)"  fill="'+fillColor+'"/>\n' +
                                '      <circle id="Ellipse_262" data-name="Ellipse 262" cx="10.279" cy="10.279" r="10.279" transform="translate(33.065 6.5)" fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20436" data-name="Path 20436" d="M36.627,14H16.542A10.051,10.051,0,0,0,6.5,24.042V29.52a2.74,2.74,0,0,0,2.739,2.739H43.931A2.74,2.74,0,0,0,46.67,29.52V24.042A10.051,10.051,0,0,0,36.627,14Z" transform="translate(17.237 19.888)" fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Role':
                            Icon =  '<svg class="svg-code-roles" xmlns="http://www.w3.org/2000/svg" width="24px" height="51px" viewBox="0 0 12.195 10.085">\n' +
                                '  <g id="Group_27174" data-name="Group 27174" transform="translate(0.15 0.15)">\n' +
                                '    <g id="Id_Card" data-name="Id Card" transform="translate(0 0)">\n' +
                                '      <path id="Path_10935" data-name="Path 10935" d="M11.147,23.379H.747A.75.75,0,0,1,0,22.63V16.147A.747.747,0,0,1,.747,15.4H4.512a.18.18,0,0,1,0,.361H.747a.386.386,0,0,0-.387.387V22.63a.387.387,0,0,0,.387.387h10.4a.387.387,0,0,0,.387-.387V16.147a.386.386,0,0,0-.387-.387H7.564a.18.18,0,0,1,0-.361h3.583a.747.747,0,0,1,.747.747V22.63A.749.749,0,0,1,11.147,23.379Z" transform="translate(0 -13.594)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                                '      <path id="Path_10937" data-name="Path 10937" d="M9.127,26.377a1.084,1.084,0,1,1,1.084-1.084A1.085,1.085,0,0,1,9.127,26.377Zm0-1.808a.723.723,0,1,0,.723.723A.724.724,0,0,0,9.127,24.569Zm1.857,4.163H7.27a.179.179,0,0,1-.18-.18,2.037,2.037,0,1,1,4.074,0A.179.179,0,0,1,10.984,28.732ZM7.46,28.371h3.332a1.676,1.676,0,0,0-3.332,0Zm8.363-3.278H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Z" transform="translate(-5.772 -20.766)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                                '      <g id="Group_14070" data-name="Group 14070" transform="translate(4.33 1.5)">\n' +
                                '        <path id="Path_10938" data-name="Path 10938" d="M26.21,15.451h-2.4a.506.506,0,0,1-.506-.506v-.69a.506.506,0,0,1,.506-.506h2.4a.506.506,0,0,1,.506.506v.69A.5.5,0,0,1,26.21,15.451Zm-2.4-1.338a.145.145,0,0,0-.145.145v.69a.145.145,0,0,0,.145.145h2.4a.145.145,0,0,0,.145-.145v-.69a.145.145,0,0,0-.145-.145Z" transform="translate(-23.3 -13.75)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                                '      </g>\n' +
                                '      <g id="Group_14071" data-name="Group 14071" transform="translate(5.12)">\n' +
                                '        <path id="Path_10939" data-name="Path 10939" d="M29.21,7.542a.179.179,0,0,1-.18-.18V6.6a.559.559,0,1,0-1.119,0v.762a.18.18,0,1,1-.361,0V6.6a.92.92,0,1,1,1.84,0v.762A.18.18,0,0,1,29.21,7.542Z" transform="translate(-27.55 -5.68)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                                '      </g>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Doctor':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26853" data-name="Group 26853" transform="translate(-9312 -11948)">\n' +
                                '    <g id="doctor" transform="translate(9356.984 11988.932)">\n' +
                                '      <path id="Path_12306" data-name="Path 12306" d="M55.695,47.823c-4.232-8.041-8.464-6.771-13.12-7.195A13.047,13.047,0,0,1,43,44.861c6.771,1.693,8.464,9.734,8.464,14.389v4.232H43V59.25H47.23s0-10.58-6.348-10.58-6.348,10.157-6.348,10.58h4.232v4.232H30.3V59.25c0-4.655,1.693-13.12,8.464-14.389a14.707,14.707,0,0,0-.846-5.5,2.858,2.858,0,0,1-1.693-2.539c0-2.539,3.386-1.693,5.925-6.348,0,0,3.809-9.734,2.539-18.2h0a7.779,7.779,0,0,0-.3-2.18,10.056,10.056,0,0,0-.543-1.9v-.15C42.575,3.809,38.343,0,30.3,0S18.029,3.809,16.336,8.464h0a15.761,15.761,0,0,0-.578,2.07c-.109.9-.129.653-.268,2.162h0c-.846,8.464,2.539,18.2,2.539,18.2,2.539,4.232,5.925,3.386,5.925,6.348,0,2.116-2.116,2.963-4.655,3.386-.846.846-1.693,2.539-1.693,5.925v5.079a6.365,6.365,0,0,1,4.232,5.925c0,2.963-2.963,5.925-6.348,5.925a6.509,6.509,0,0,1-6.348-6.348,5.977,5.977,0,0,1,4.232-5.925V46.13a21.442,21.442,0,0,1,.846-5.5c-2.963.423-6.348,1.693-9.311,7.195C2.37,52.479,1.1,67.714,1.1,67.714H59.08c.423,0-.846-15.236-3.386-19.891Z" transform="translate(0)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_12307" data-name="Path 12307" d="M8.232,15.116A2.116,2.116,0,1,1,6.116,13,2.116,2.116,0,0,1,8.232,15.116Z" transform="translate(9.373 42.018)" fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'User':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26854" data-name="Group 26854" transform="translate(-9312 -12119)">\n' +
                                '    <circle id="Ellipse_266" data-name="Ellipse 266" cx="75" cy="75" r="75" transform="translate(9312 12119)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="Group_26845" data-name="Group 26845">\n' +
                                '      <g id="Group_26843" data-name="Group 26843" transform="translate(322.872 437.125)">\n' +
                                '        <g id="Group_26844" data-name="Group 26844" transform="translate(9028 11724.451)">\n' +
                                '          <path id="person_4_" data-name="person (4)" d="M19.073,26.468c-.333.016-.667.04-1.267.1C8.89,27.467,0,35.076,0,44.443v8.988H71.9V44.443c0-9.3-8.908-16.865-18.393-17.909-.122-.016-.526-.047-.93-.066-4.853,5.482-9.347,5.019-16.627,5.019S23.927,31.95,19.073,26.468Z" transform="translate(0 11.085)"  fill="'+fillColor+'"/>\n' +
                                '          <circle id="Ellipse_263" data-name="Ellipse 263" cx="19" cy="19" r="19" transform="translate(17.128 0.423)"  fill="'+fillColor+'"/>\n' +
                                '        </g>\n' +
                                '      </g>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Network':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26849" data-name="Group 26849" transform="translate(-9607 -11373)">\n' +
                                '    <circle id="Ellipse_267" data-name="Ellipse 267" cx="75" cy="75" r="75" transform="translate(9607 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <path id="Subtraction_7" data-name="Subtraction 7" d="M-8660.631-11598.523h-12.716a1.5,1.5,0,0,1-1.529-1.471v-12.213a1.49,1.49,0,0,1,1.431-1.465h12.946a1.5,1.5,0,0,1,1.4,1.465v12.213A1.5,1.5,0,0,1-8660.631-11598.523Zm-29.813,0h-12.715a1.5,1.5,0,0,1-1.526-1.471v-12.213a1.491,1.491,0,0,1,1.436-1.465h12.9a1.494,1.494,0,0,1,1.438,1.465v12.213A1.5,1.5,0,0,1-8690.444-11598.523Zm-29.809,0h-12.717a1.5,1.5,0,0,1-1.525-1.471v-12.213a1.491,1.491,0,0,1,1.435-1.465h12.9a1.493,1.493,0,0,1,1.437,1.465v12.213A1.5,1.5,0,0,1-8720.253-11598.523Zm25.978-17.613h0l-5.048,0v-7.1h-24.763v7.1h-5.048v-9.527a2.48,2.48,0,0,1,2.524-2.426h27.286v-8.592h-7.946a2.015,2.015,0,0,1-2.051-1.969v-16.377a2.015,2.015,0,0,1,2.051-1.971h20.941a2.014,2.014,0,0,1,2.049,1.971v16.377a2.014,2.014,0,0,1-2.049,1.969h-7.946v8.592h27.286a2.477,2.477,0,0,1,2.521,2.426v9.525l-5.047,0v-7.1h-24.761v7.105Z" transform="translate(18379 23076.344)"  fill="'+fillColor+'"/>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Fire':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26857" data-name="Group 26857" transform="translate(-8957 -11849)">\n' +
                                '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(8957 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <path id="Path_20450" data-name="Path 20450" d="M68.919,114.61c-10.275-.177-17.855-5.146-22.613-13.92-5-9.228-4.048-18.452,1.977-27.1,1.28-1.837,2.606-3.935,5.2-3.171,2.793.82,2.189,3.478,2.272,5.615.12,3.186-.106,6.408.29,9.555a7.515,7.515,0,0,0,6.571,6.959c3.591.612,6.868-.025,9.194-3.181a7.847,7.847,0,0,0,.162-9.631c-1.923-2.8-4.564-5.1-6.848-7.659-7.828-8.757-4.5-16.6,1.388-24.524,1.751-2.36,4.134-2.164,4.773.9,1.862,8.931,9.039,13.7,14.691,19.726,14.352,15.3,10.13,37.574-8.383,44.97A24.585,24.585,0,0,1,68.919,114.61Z" transform="translate(8962.914 11844.05)"  fill="'+fillColor+'"/>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Heartbeat':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26850" data-name="Group 26850" transform="translate(-9607 -11557)">\n' +
                                '    <circle id="Ellipse_268" data-name="Ellipse 268" cx="75" cy="75" r="75" transform="translate(9607 11557)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="cardiogram" transform="translate(9647 11600)">\n' +
                                '      <path id="Path_12304" data-name="Path 12304" d="M35.1,30.829C50.4,17.452,69.844,29.2,70.163,45.139A20.689,20.689,0,0,1,66.739,56.8H51.615l-2.69-4.483a2.055,2.055,0,0,0-3.5-.046l-5.676,8.919L31.48,43.726a2.057,2.057,0,0,0-3.667-.095L20.721,56.8H3.468C-9.312,36.827,15.946,14.073,35.1,30.829Z" transform="translate(0 -25.006)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_12305" data-name="Path 12305" d="M65.784,211.935l5.747-10.672,8.126,17.155a2.057,2.057,0,0,0,3.592.223l5.888-9.253,1.578,2.629a2.055,2.055,0,0,0,1.763,1h13.036c-.409.428,1.769-1.746-26.934,26.809a2.056,2.056,0,0,1-2.9,0c-28.261-28.115-26.526-26.382-26.934-26.809H63.974a2.055,2.055,0,0,0,1.81-1.081Z" transform="translate(-42.026 -177.11)"  fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Security':
                            Icon ='<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26851" data-name="Group 26851" transform="translate(-9607 -11759)">\n' +
                                '    <circle id="Ellipse_269" data-name="Ellipse 269" cx="75" cy="75" r="75" transform="translate(9607 11759)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="Group_26846" data-name="Group 26846" transform="translate(333.508 -74.384)">\n' +
                                '      <path id="Subtraction_8" data-name="Subtraction 8" d="M24.859,56.546h0l-.662-.265A39.91,39.91,0,0,1,0,19.556V7.093L24.859,0,49.718,7.093V19.556a39.91,39.91,0,0,1-24.2,36.725l-.662.265ZM24.852,6.895V49.288l.8-.431a33.322,33.322,0,0,0,17.435-29.3V12.1Z" transform="translate(9321 11883)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20439" data-name="Path 20439" d="M256,7.093V19.556a39.924,39.924,0,0,0,24.2,36.725l.663.265V0Z" transform="translate(9065 11883)"  fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Hospital':
                            Icon ='<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26852" data-name="Group 26852" transform="translate(-9607 -11961)">\n' +
                                '    <circle id="Ellipse_270" data-name="Ellipse 270" cx="75" cy="75" r="75" transform="translate(9607 11961)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="Group_26847" data-name="Group 26847" transform="translate(484.601 311.972)">\n' +
                                '      <path id="Subtraction_5" data-name="Subtraction 5" d="M46.3,77H3.7A3.735,3.735,0,0,1,0,73.244V16.9a3.735,3.735,0,0,1,3.7-3.757H16.667V2a2,2,0,0,1,2-2H33.185a2,2,0,0,1,2,2V13.146H46.3A3.735,3.735,0,0,1,50,16.9V73.244A3.735,3.735,0,0,1,46.3,77ZM17.267,50.707a.6.6,0,0,0-.6.6V70.765a.6.6,0,0,0,.6.6H30.881a.6.6,0,0,0,.6-.6V51.307a.6.6,0,0,0-.6-.6ZM28.377,33.8a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm20.37-13.146a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6ZM21.533,6.4a1.173,1.173,0,0,0-1.163,1.18V8.76a1.173,1.173,0,0,0,1.163,1.18h2.676v2.713a1.173,1.173,0,0,0,1.164,1.18h1.163a1.173,1.173,0,0,0,1.164-1.18V9.94h2.675a1.173,1.173,0,0,0,1.164-1.18V7.58A1.173,1.173,0,0,0,30.374,6.4H27.7V3.686a1.173,1.173,0,0,0-1.164-1.179H25.373a1.173,1.173,0,0,0-1.164,1.179V6.4Z" transform="translate(9172.399 11686.028)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Rectangle_7797" data-name="Rectangle 7797" d="M1,0H60a1,1,0,0,1,1,1V4a0,0,0,0,1,0,0H0A0,0,0,0,1,0,4V1A1,1,0,0,1,1,0Z" transform="translate(9167.399 11759.028)"  fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'Operator':
                            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                                '  <g id="Group_26858" data-name="Group 26858" transform="translate(-9116 -11849)">\n' +
                                '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(9116 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                                '    <g id="call-center_Svg" data-name="call-center Svg" transform="translate(9058.147 11838.974)">\n' +
                                '      <path id="Subtraction_10" data-name="Subtraction 10" d="M62.066,22.4H.071a5.923,5.923,0,0,1-.061-1.28A22.167,22.167,0,0,1,17.948.054,1.8,1.8,0,0,1,18.3.016a1.2,1.2,0,0,1,.76.27A18.868,18.868,0,0,0,30.291,3.87a27.757,27.757,0,0,0,5.093-.316A18.724,18.724,0,0,0,43.075.287,1.25,1.25,0,0,1,43.864,0a1.643,1.643,0,0,1,.322.034,21.907,21.907,0,0,1,13.453,8.4,21.68,21.68,0,0,1,4.484,13.14,4.633,4.633,0,0,1-.058.816Z" transform="translate(101.853 97.488)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20448" data-name="Path 20448" d="M189.076,137.441a13.047,13.047,0,0,1-6.894-.922,17.627,17.627,0,0,1-7.565-6.2,20.3,20.3,0,0,1-3.646-8.613,16.357,16.357,0,0,1,3.185-13.181,16.617,16.617,0,0,1,29.59,7.565,17.791,17.791,0,0,1-1.278,9.724c-.482,1.132-1.111,2.2-1.677,3.29a.66.66,0,0,1-.356.272c-.9.21-1.823.4-2.724.566a.655.655,0,0,1-.4-.084,4.679,4.679,0,0,0-2.871-.9h-3.206a4.355,4.355,0,0,0-2.766.964,4.515,4.515,0,0,0,.147,7.167A5.149,5.149,0,0,0,189.076,137.441Z" transform="translate(-54.418 -40.296)"  fill="'+fillColor+'"/>\n' +
                                '      <path id="Path_20449" data-name="Path 20449" d="M167.212,90.5a17.245,17.245,0,0,1-3.583,2.012,24.651,24.651,0,0,1-6.915,1.739h-.063a1.369,1.369,0,0,0-1.236.587,2.144,2.144,0,0,1-1.823.838H150.3a2.483,2.483,0,0,1-.021-4.967c1.09-.021,2.2,0,3.29,0a2.523,2.523,0,0,1,1.991.943.571.571,0,0,0,.335.168,20.427,20.427,0,0,0,9.409-3.08.354.354,0,0,0,.189-.482A4.252,4.252,0,0,1,165.305,87c-.021-2.745-.021-5.511,0-8.257a4.023,4.023,0,0,1,2.1-3.625.311.311,0,0,0,.189-.356,20.888,20.888,0,0,0-2.6-8.885,21.265,21.265,0,0,0-31.958-6.329,20.587,20.587,0,0,0-3.9,4.086,26.417,26.417,0,0,0-1.572,2.515,20.923,20.923,0,0,0-2.452,8.592.285.285,0,0,0,.168.335,4.037,4.037,0,0,1,2.117,3.688v8.11a4.183,4.183,0,0,1-2.724,4,4.35,4.35,0,0,1-4.82-1.257,3.925,3.925,0,0,1-.943-2.452c-.021-2.892-.042-5.8,0-8.7a3.873,3.873,0,0,1,2.117-3.395.431.431,0,0,0,.251-.4,23.147,23.147,0,0,1,.65-4.485,24.909,24.909,0,0,1,9.263-14.082,24.249,24.249,0,0,1,11.945-4.883,25.157,25.157,0,0,1,27.054,17.079,23.78,23.78,0,0,1,1.216,6.412.341.341,0,0,0,.23.356,4.083,4.083,0,0,1,2.159,3.709c0,2.682-.021,5.365,0,8.047a4.249,4.249,0,0,1-6.413,3.751C167.338,90.586,167.3,90.544,167.212,90.5Z" transform="translate(-13.463)"  fill="'+fillColor+'"/>\n' +
                                '    </g>\n' +
                                '  </g>\n' +
                                '</svg>';
                            break;
                        case 'None':
                            Icon = "";
                            break;
                        default:
                            Icon = "";
                    }

                    let userLoggedIn = stropheConnection.logininfo.userJID;
                    let activeMemberId = (values.activeMemberId) ? values.activeMemberId.trim() : "";
                    let activeMemberName = values.activeMemberName.trim();
                    let isChatUser = values.isChatUser;
                    let onCallId = values.onCallId.trim();
                    let onCallName = values.onCallName.trim();
                    let siteId = values.siteId;
                    let isActive = values.isActive;

                    let siteDiv = "";
                    let activeMemberDiv = "";
                    let activeMemberColorName = "";
                    let pagerEl = "";


                    let activeMemberObj = module.exports.getOnCallsActiveMembObj(activeMemberName,activeMemberId,userLoggedIn);

                    activeMemberName        =   activeMemberObj.activeMemberName;
                    activeMemberColorName   =   activeMemberObj.activeMemberColorName;

                    let IconixBadgeIcon = module.exports.getOnCallsIconixBadgeIcon(activeMemberName,isChatUser);

                    let contentDiv = '<div class="roles-content row sideBar-body" id="role-' + onCallId + '" data-member="'+activeMemberId+'" data-chat-user="'+isChatUser+'" data-isunregister="'+values.isUnregisteredUser+'" data-isactive="'+isActive+'"><div  class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><div class="color-code-roles" style="background-color: '+ utils.hexToRgbA(onCallImage) +';">'+Icon+IconixBadgeIcon+'</div></div> </div><div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name contact-name"><div class="contact-list-div"><span class="name-meta oncall-name" title="'+onCallName+'">' + onCallName + '</span></div></div></div>';

                    let siteName = utils.getSiteAbbreviation(siteId);
                

                    let msg_pager_no = module.exports.getOnCallsMsgPagerNo(values);
                    let sharedDeviceMsgEl = module.exports.getOnCallsSharedDevMsgEl(values,activeMemberId,msg_pager_no,userLoggedIn)

                    let sharedDeviceSpanHtml;
                    sharedDeviceSpanHtml = '<span class="sharedDeviceSpanHtml">'+sharedDeviceMsgEl+pagerEl+'</span>';
                
                    let countOfOnCallMembers = values.countOfOnCallMembers;

                    activeMemberDiv = module.exports.getOnCallsActiveMemberDiv(countOfOnCallMembers,isActive,activeMemberColorName,activeMemberName,sharedDeviceSpanHtml);

                    siteDiv = module.exports.getOnCallSiteDiv(siteName,onCallUnit);

                    let detailsDiv = siteDiv + activeMemberDiv +  '</div></div>';
                    
                    module.exports.getOnCallElementAppend(page,elementId,contentDiv,detailsDiv);
                   
                }

                module.exports.getOnCallCustomScrollBarset(oncallObj,elementId,elementName);

                let rolesLength = $(''+elementId+' .roles-content').length;

                module.exports.getOnCallEmptyParticipantCheck(rolesLength,elementId);
                      
            },
            error: function (jqXHR, exception) {
                let getOnCallErrMsg = '';
                getOnCallErrMsg = module.exports.getOnCallErrorMsgReturn(jqXHR,exception)
               
                console.log(getOnCallErrMsg);
            },
            complete: function(){
                $(".sideRoleNavLoader").hide()
                $(".sideRoleListNavLoader").hide()
            }
        });
     
        return true;
    },
   
    getSiteIdforRoleOrGlobalRole: function(sideBarIdName){
        let siteId;
        let locationId = JSON.parse(localStorage.getItem("locationId"));        
        if(sideBarIdName == 'globalRoleLists'){
            siteId = $('#globalRoleSiteId').val();
        }else {
            if(locationId != null && typeof locationId != "undefined"){
                siteId = locationId.mysite_role;
            }else {
                siteId = $('#siteDetails').text();
            }
        }

        return siteId;
    },
    unitBasedOncallRecordsScrollHandling(rolesCount,siteId,unitName,sideBarIdName,page,searchVal){
        if (rolesCount % language.locali().default_unit_based_roles_pagination == 0) {
            module.exports.getUnitBasedRoles(siteId,unitName,sideBarIdName,page,searchVal);

        }else {
            module.exports.disableScrollHandler();
        }
    },
    /** Oncall Units Append and Scroll bar update **/
    getOnCallUnitsCustomScrollBarset: function(page,elementId,elementName){
        if (page == 1 || page == "") {
            $(elementId).mCustomScrollbar({
                scrollInertia: false,
                callbacks: {
                    whileScrolling: function(){
                       
                        if (!shouldHandleScroll) {// Skip Or Stop Scroll event After Reach Last Element
                            return; // Skip handling the event if the flag is false
                        }

                        let container = $(this);
                        let scrollTop = container.scrollTop(); // Current scroll position

                        let containerTop = container.offset().top;
                        let containerHeight = container.height();

                        let targetElement = $('.acc__title.active').parent().find('.acc__panel').children().last();
                        
                        if(targetElement.length > 0){
                            let elementTop = targetElement.offset().top - containerTop-300;

                            // Calculate the bottom position of the container
                            let scrollBottom = scrollTop + containerHeight;

                            if (scrollBottom >= elementTop) {
                                console.log('Scroll reached target element!!!!!!!');
                                let siteId;
                                let sideBarIdName = elementName;
                                let unitName = $('.acc__title.active').text();

                                let searchVal = $('#rolesText').val()
                                siteId = module.exports.getSiteIdforRoleOrGlobalRole(sideBarIdName)

                                let rolesCount = $('.acc__title.active').parent().find('.acc__panel').children().length;
                                let rolesLength = rolesCount / language.locali().default_unit_based_roles_pagination;
                                page = rolesLength + 1;

                                module.exports.unitBasedOncallRecordsScrollHandling(rolesCount,siteId,unitName,sideBarIdName,page,searchVal);
                                

                                $(elementId).mCustomScrollbar('update')
                                
                                // Perform actions here when scroll reaches the element
                            }
                        }
                    
                    },
                   
                }
            });
        } else {
            $(elementId).mCustomScrollbar("update");
        }
    },
    /** Oncall Units Element Append **/
    getOnCallUnitsElementAppend: function(page,elementId,rolesUnitDiv){
        if (page == 1 || page == "") {
            $(elementId).append(rolesUnitDiv);
        } else {
            $(elementId).find('.mCSB_container').append(rolesUnitDiv);
        }
    },
    /** Get Oncall Units **/
    getOncallUnits: function(siteId,elementName){
        
        let oncallsUrl = window._env_.ApiURL + "/onCalls/units?siteId="+siteId+"";
        let elementId =  "#"+elementName;
        siteId = parseInt(siteId);
        let page = 1;
        $(elementId).empty(); 
      
        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getOncallUnits(siteId, elementName)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                let rolesUnitDiv = '';
                if(response.status == 200){
                    $('.mysiteRoleSearchDiv').show();
                   
                    for (let keys in response.data) {
                        let value = response.data[keys];
    
                        rolesUnitDiv += `<div class="role-screen-content">
                                            <div class="acc">
                                                <div class="acc__card">
                                                    <div class="acc__title">`+value+`</div>
                                                    <div class="acc__panel">
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`
                    }
    
                    module.exports.getOnCallUnitsElementAppend(page,elementId,rolesUnitDiv);
                    module.exports.getOnCallUnitsCustomScrollBarset(page,elementId,elementName);
                    if(response.data.length == 1){
                        $(elementId).find('.role-screen-content').first().find('.acc__title').trigger('click')
                        module.exports.scrollMoveToActiveStateDiv(elementName)
                    }
             
                }else if(response.status == 204){
                    $('.mysiteRoleSearchDiv').hide();

                    $(''+elementId+' #noparticipant').remove();
                    let el = `<div class='noparticipant-role'>
                                    <i class="role-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="98"
                                            height="79"
                                            viewBox="0 0 318 279">
                                            <path
                                                fill="#eaf1fd"
                                                stroke="#eaf1fd"
                                                stroke-width="0.3"
                                                d="M293.7,266.23L24.3,266.23A19.43,19.43 0,0 1,4.95 246.83L4.95,78.91A19.35,19.35 0,0 1,24.3 59.56h97.54a4.67,4.67 0,1 1,0 9.34L24.3,68.9A10.01,10.01 0,0 0,14.29 78.91L14.29,246.83a10.04,10.04 0,0 0,10.01 10.01L293.65,256.84a10.04,10.04 0,0 0,10.01 -10.01L303.67,78.91A10.01,10.01 0,0 0,293.65 68.9L200.88,68.9a4.67,4.67 0,0 1,0 -9.34h92.82a19.35,19.35 0,0 1,19.35 19.35L313.05,246.83A19.4,19.4 0,0 1,293.7 266.23Z" />
                                            <path
                                                fill="#eaf1fd"
                                                d="M13.09,64.19h293.93v195.35h-293.93Z" />
                                            <path
                                                fill="#fff"
                                                stroke="#fff"
                                                stroke-width="0.3"
                                                d="M91.84,158.12A28.07,28.07 0,1 1,119.9 130.06,28.1 28.1,0 0,1 91.84,158.12ZM91.84,111.28A18.73,18.73 0,1 0,110.57 130.01,18.75 18.75 0,0,0 91.84,111.28ZM139.93,219.12L43.74,219.12a4.64,4.64 0,0 1,-4.67 -4.67,52.76 52.76 0,1,1 105.53,0A4.64,4.64 0,0 1,139.93 219.12ZM48.65,209.78h86.32a43.4,43.4 0,0 0,-86.32 0ZM265.29,124.86L161.4,124.86a4.67,4.67 0,0 1,0 -9.34h103.89a4.67,4.67 0,0 1,0 9.34ZM265.29,153.36L161.4,153.36a4.67,4.67 0,0 1,0 -9.34h103.89a4.67,4.67 0,0 1,0 9.34ZM265.29,181.81L161.4,181.81a4.67,4.67 0,0 1,0 -9.34h103.89a4.67,4.67 0,0 1,0 9.34ZM265.29,210.26L161.4,210.26a4.67,4.67 0,0 1,0 -9.34h103.89a4.67,4.67 0,0 1,0 9.34Z" />
                                            <path
                                                fill="#eaf1fd"
                                                stroke="#eaf1fd"
                                                stroke-width="0.3"
                                                d="M180.56,61.01a4.64,4.64 0,0 1,-4.67 -4.67L175.89,36.6a14.49,14.49 0,1 0,-28.98 0L146.91,56.34a4.67,4.67 0,1 1,-9.34 0L137.57,36.6a23.83,23.83 0,1 1,47.66 0L185.23,56.34A4.67,4.67 0,0 1,180.56 61.01Z" />
                                            <path
                                                fill="#4045d4"
                                                stroke="#eaf1fd"
                                                stroke-width="0.3"
                                                d="M192.5,95.67h-62.3A13.11,13.11 0,0 1,117.11 82.58L117.11,64.72A13.11,13.11 0,0 1,130.2 51.62h62.3a13.11,13.11 0,0 1,13.1 13.1L205.6,82.58A13.08,13.08 0,0 1,192.5 95.67ZM130.2,61.01a3.76,3.76 0,0 0,-3.76 3.76L126.44,82.63a3.76,3.76 0,0 0,3.76 3.76h62.3a3.76,3.76 0,0 0,3.76 -3.76L196.25,64.77a3.76,3.76 0,0 0,-3.76 -3.76Z" />
                                            <path
                                                fill="#fff"
                                                d="M198.33,90.43L198.33,56.74L120.88,56.74L120.88,90.43Z" />
                                            <path
                                                fill="#eaf1fd"
                                                stroke="#eaf1fd"
                                                stroke-width="0.3"
                                                d="M192.5,95.67h-62.3A13.11,13.11 0,0 1,117.11 82.58L117.11,64.72A13.11,13.11 0,0 1,130.2 51.62h62.3a13.11,13.11 0,0 1,13.1 13.1L205.6,82.58A13.08,13.08 0,0 1,192.5 95.67ZM130.2,61.01a3.76,3.76 0,0 0,-3.76 3.76L126.44,82.63a3.76,3.76 0,0 0,3.76 3.76h62.3a3.76,3.76 0,0 0,3.76 -3.76L196.25,64.77a3.76,3.76 0,0 0,-3.76 -3.76Z" />
                                        </svg>

                                    </i>
                                    <h4>There are no roles at Disability Services. You can change the site displayed here in Settings.</h4>
                                    <a class='prefer rolesMysitePref'>My Site Preferences</a>
                                </div>`;

                    $(elementId).html("<div id='noparticipant'>" +el+ "</div>");
                }
                

            },
            error: function (jqXHR, exception) {
                let getOnCallErrMsg = '';
                getOnCallErrMsg = module.exports.getOnCallErrorMsgReturn(jqXHR,exception)
                console.log(getOnCallErrMsg);
            },
            complete: function(){
                $(".sideRoleNavLoader").hide()
                $(".sideRoleListNavLoader").hide()
            }
        });
        
    },
    /** Oncall Units Scroll Update **/
    oncallUnitsScrollUpdate: function(elementName){
        let elementId =  "#"+elementName;
        $(elementId).mCustomScrollbar("update");
    },
    /** Get Roles Records based on Oncall Units **/
    getUnitBasedRoles: function(siteId,unitName,elementName,pageNo,searchVal){

        let  params = 'page='+pageNo+'&search='+searchVal+'&siteId='+siteId+'&size='+language.locali().default_unit_based_roles_pagination+'&sortby=oncallname&unit='+unitName;

        let oncallsUrl = window._env_.ApiURL + "/onCalls/?"+params;

        $.ajax({
            url: oncallsUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    storage.setSession(username)
                    module.exports.getUnitBasedRoles(siteId,unitName,elementName,pageNo,searchVal)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                if(response.status == 200){
                    for (let keys in response.data) {
                        let values = response.data[keys];
                        let onCallImage = values.onCallImage.trim();
                        let onCallForegroundColor = values.onCallForegroundColor.trim();
                        let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";
                        let onCallIcon = values.onCallIcon;
                        let onCallUnit = values.onCallUnit;
    
                        let Icon;
    
                        Icon = utils.getOncallIcon(onCallIcon, fillColor, onCallImage);
    
    
                        let userLoggedIn = stropheConnection.logininfo.userJID;
                        let activeMemberId = (values.activeMemberId) ? values.activeMemberId.trim() : "";
                        let activeMemberName = values.activeMemberName.trim();
                        let isChatUser = values.isChatUser;
                        let onCallId = values.onCallId.trim();
                        let onCallName = values.onCallName.trim();
                        let siteId = values.siteId;
                        let isActive = values.isActive;
    
                        let siteDiv = "";
                        let activeMemberDiv = "";
                        let activeMemberColorName = "";
                        let pagerEl = "";
    
    
                        let activeMemberObj = module.exports.getOnCallsActiveMembObj(activeMemberName,activeMemberId,userLoggedIn);
    
                        activeMemberName        =   activeMemberObj.activeMemberName;
                        activeMemberColorName   =   activeMemberObj.activeMemberColorName;
    
                        let IconixBadgeIcon = module.exports.getOnCallsIconixBadgeIcon(activeMemberName,isChatUser);
    
                        let contentDiv = '<div class="roles-content row sideBar-body" id="role-' + onCallId + '" data-member="'+activeMemberId+'" data-chat-user="'+isChatUser+'" data-isunregister="'+values.isUnregisteredUser+'" data-isactive="'+isActive+'"><div  class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><div class="color-code-roles" style="background-color: '+ utils.hexToRgbA(onCallImage) +';">'+Icon+IconixBadgeIcon+'</div></div> </div><div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name contact-name"><div class="contact-list-div"><span class="name-meta oncall-name" title="'+onCallName+'">' + onCallName + '</span></div></div></div>';
    
                        let siteName = utils.getSiteAbbreviation(siteId);
                    
    
                        let msg_pager_no = module.exports.getOnCallsMsgPagerNo(values);
                        let sharedDeviceMsgEl = module.exports.getOnCallsSharedDevMsgEl(values,activeMemberId,msg_pager_no,userLoggedIn)
    
                        let sharedDeviceSpanHtml;
                        sharedDeviceSpanHtml = '<span class="sharedDeviceSpanHtml">'+sharedDeviceMsgEl+pagerEl+'</span>';
                    
                        let countOfOnCallMembers = values.countOfOnCallMembers;
    
                        activeMemberDiv = module.exports.getOnCallsActiveMemberDiv(countOfOnCallMembers,isActive,activeMemberColorName,activeMemberName,sharedDeviceSpanHtml);
    
                        siteDiv = module.exports.getOnCallSiteDiv(siteName,onCallUnit);
    
                        let detailsDiv = siteDiv + activeMemberDiv +  '</div></div>';
    
                        let activeUnitEl = $('.acc__title.active').parent().find('.acc__panel');
    
                        module.exports.getUnitBasedRolesAppend(activeUnitEl,contentDiv,detailsDiv);
    
                    }
                }else{
                    let activeUnitEl = $('.acc__title.active').parent().find('.acc__panel');

                    module.exports.getUnitBasedRolesNoRecAppend(activeUnitEl);

                    module.exports.disableScrollHandler()
                }
            },
            error: function (jqXHR, exception) {
                let getOnCallErrMsg = '';
                getOnCallErrMsg = module.exports.getOnCallErrorMsgReturn(jqXHR,exception)
                console.log(getOnCallErrMsg);
            },
            complete: function(){
                $(".sideRoleNavLoader").hide()
                $(".sideRoleListNavLoader").hide()

            }
        });
    },
    getUnitBasedRolesAppend: function(activeUnitEl,contentDiv,detailsDiv){
        let oncallDivEl = contentDiv+detailsDiv;

        activeUnitEl.append(oncallDivEl)
    },
    getUnitBasedRolesNoRecAppend: function(activeUnitEl){
        let divEl = `<div class="row no-unit-rec-sideBar-body noUnitRecord" ><div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="sideBar-msg site-div"><span class="name-status site-name">`+language.locali().noroles_available+`</span></div></div></div>`;

        activeUnitEl.append(divEl)

    },

    /** Scroll Move to Active Units Div **/
    scrollMoveToActiveStateDiv:function(elementName){
        let elementId =  "#"+elementName;

        let scrollableDiv = $(elementId);
        let targetDiv = $(".acc__title.active");

        let targetOffset = targetDiv.offset();

        // Get the offset of the scrollable div relative to the document
        let scrollableDivOffset = scrollableDiv.offset();

        // Calculate the position of the target element relative to the scrollable div
        let positionTop = targetOffset.top - scrollableDivOffset.top + scrollableDiv.scrollTop()-50;
        
        $(elementId).mCustomScrollbar("scrollTo",positionTop,{
            moveDragger:true,
            
        });

    },

    /** My Site Role Search Handling **/
    mySiteRoleSearchHandling: function(siteId,content){
        let activeDataType = $('.rolesFilter:visible').attr('data-type');
        let type;

        if(activeDataType == 'Roles'){
            type = 'oncallname'
        }else{
            type = 'unitname'

        }

        if (content == '') {
            if(type == 'oncallname'){
                let oncallObj = {
                    siteId      :siteId,
                    search      :'',
                    page        :1,
                    isEmpty     :1,
                    elementName :'roles',
                    type        :'',
                    unitName    :'',
                
                }
                module.exports.getOnCalls(oncallObj);

            }else{

                module.exports.getOncallUnits(siteId,'roles');
            }
            
        } else {
        
            content = encodeURIComponent(content);
            let oncallObj = {
                siteId      :siteId,
                search      :content,
                page        :1,
                isEmpty     :1,
                elementName :'roles',
                type        :type,
                unitName    :'',
            
            }
            module.exports.getOnCalls(oncallObj);
        }
    },
    
    /** Global Role Search Handling **/
    globalSiteRoleSearchHandling: function(content){
        let activeDataType = $('.globalRoleFilter:visible').attr('data-type');
        let siteId = $('#globalRoleSiteId').val();

        let type;

        if(activeDataType == 'Roles'){
            type = 'oncallname'
        }else{
            type = 'unitname'

        }

        if (content == '') {
            if(type == 'oncallname'){
                let oncallObj = {
                    siteId      :siteId,
                    search      :'',
                    page        :1,
                    isEmpty     :1,
                    elementName :'globalRoleLists',
                    type        :type,
                    unitName    :'',
                
                }
                module.exports.getOnCalls(oncallObj);

            }else{

                module.exports.getOncallUnits(siteId,'globalRoleLists',type);
            }


        } else {
          if (content.length > 0){
            content = encodeURIComponent(content);
            let oncallObj = {
                siteId      :siteId,
                search      :content,
                page        :1,
                isEmpty     :1,
                elementName :'globalRoleLists',
                type        :type,
                unitName    :'',
            
            }
            module.exports.getOnCalls(oncallObj);
          }
        }
    },
    /** Function to disable the event handler **/
    disableScrollHandler:function() {
        shouldHandleScroll = false;
    },

    /** Function to enable the event handler **/
    enableScrollHandler:function() {
        shouldHandleScroll = true;
    }

}

$('.side-role .filter').click(function () {
    $('.side-role .filter-options').toggleClass("menu")
});

//action on clicking a role in roles tab
$("body").on('click', ".roles-content", function (e) {
    let activeMemberId = $(this).attr("data-member").trim();
    let chatUser = $(this).attr("data-chat-user");
    let isActive = $(this).attr("data-isactive");

    if(activeMemberId == "") {
        $("#Role-details").find(".popupcontent").text(language.locali().noone_active);
        $("#Role-details").addClass("open");
    } else if (activeMemberId == stropheConnection.logininfo.userJID) {
        $("#Role-details").find(".popupcontent").text(language.locali().you_are_active_oncall);
        $("#Role-details").addClass("open");
    } else if (activeMemberId && isActive == 0){
        $("#Role-details").find(".popupcontent").text(language.locali().noone_oncall);
        $("#Role-details").addClass("open");
    } else {
        if(chatUser == 0) {
            recentdesign.recentClickAction(activeMemberId,chatUser);
            $(".side-role").css({
                "left": "-100%"
            });
            $(".side-global-role-list").css({
                "left": "-100%"
            });

            $(".blocked-person").text("You cannot send messages to this chat.");
        } else  {
            recentdesign.recentClickAction(activeMemberId);
            $(".side-role").css({
                "left": "-100%"
            });
            $(".side-global-role-list").css({
                "left": "-100%"
            });
        }
    }
});

//action on clicking ok in a roles tab
$("#close-roles-popup").click(function() {
    $("#Role-details").removeClass("open");
});

/** shared Device Pager Icon Drop down Open**/
$("body").on('click', ".sharedDevicePagerIcon", function (e) {
    e.stopPropagation();
    $('.pager_massage').val('');
    $(".sharedDevPagerDiv").removeClass('open');
    $(".sharedDevMsgDiv").removeClass('open');

    let oncallPagerNo = $(this).attr("data-oncall_pager_no");
    let msgPagerNo = $(this).attr("data-msg_pager_no");
    let profileVisibility = JSON.parse(localStorage.getItem("settings")).profileVisibility;
    let activeMemberId = $(this).attr("data-activememberid");
    let userLoggedIn = stropheConnection.logininfo.userJID;


    if(oncallPagerNo != "" && (msgPagerNo != "" && profileVisibility.pager_number && activeMemberId !== userLoggedIn)){
        let selectedRoleId = $(this).closest('.roles-content').attr('id'); 
        let firstRoleId = $(".roles-content:first-child").attr('id'); //Role List First El
        let lastRoleId = $(".roles-content:nth-last-child(1)").attr('id'); //Role List Last El
        let selectedUlEl = $(this).parent().parent().children().last();
         if(firstRoleId != selectedRoleId && selectedRoleId == lastRoleId){
             selectedUlEl.addClass('sharedDeviceChangeUl') 
         }

        $(this).parent().parent().addClass('open')

    }else {

        $(this).parent().parent().addClass('open')

        
    }
});
function sharedDeviceMsgIconFirstAlignment(sharedDevEvent,isOpened){
    let activeMemberId = sharedDevEvent.attr("data-member").trim();
    if(activeMemberId != "" && activeMemberId != "null"){
        let selectedRoleId = sharedDevEvent.closest('.roles-content').attr('id'); 
        let firstRoleId = $(".roles-content:first-child").attr('id'); //Role List First El
        let lastRoleId = $(".roles-content:nth-last-child(1)").attr('id'); //Role List Last El
        let selectedUlEl = sharedDevEvent.parent().parent().children().last();
        if(firstRoleId != selectedRoleId && selectedRoleId == lastRoleId){
            selectedUlEl.addClass('sharedDeviceChangeUl') 
        }
        sharedMsgIconKebabActionHandling(sharedDevEvent,isOpened)
    }else {
        sharedMsgIconKebabActionHandling(sharedDevEvent,isOpened)
    }
};
function sharedMsgIconKebabActionHandling(sharedDevEvent,isOpened){
    if(!isOpened){
        sharedDevEvent.parent().parent().addClass('open')
    }else {
        sharedDevEvent.parent().parent().removeClass('open')
    }
}
function lastElementDropdownPopupHandling(sharedDevEvent){
    let parentElId = sharedDevEvent.closest('.mCSB_container').parent().parent().attr('id')
    parentElId = '#'+parentElId;
    let selectedRoleId = sharedDevEvent.closest('.roles-content').attr('id'); 
    let firstRoleId = $(parentElId+" .roles-content:first-child:visible").attr('id'); //Role List First El
    let lastRoleId = $(parentElId+" .roles-content:nth-last-child(1):visible").attr('id'); //Role List Last El
    let selectedUlEl = sharedDevEvent.parent().parent().children().last();
    if(firstRoleId != selectedRoleId && selectedRoleId == lastRoleId){
        selectedUlEl.addClass('sharedDeviceChangeUl') 
    }


}
function sharedDeviceMsgIconSecondAlignment(sharedDevEvent,oncallPagerNo,msgPagerNo,profileVisibility,userLoggedIn,isOpened){
    let activeMemberId = sharedDevEvent.attr("data-member").trim();
    if(oncallPagerNo != "" || (msgPagerNo != "" && profileVisibility.pager_number && activeMemberId !== userLoggedIn)){
        
        sharedMsgIconKebabActionHandling(sharedDevEvent,isOpened)
    }else {
        if(activeMemberId == "") {
            $("#Role-details").find(".popupcontent").text(language.locali().noone_active);
            $("#Role-details").addClass("open");
        } else if (activeMemberId == stropheConnection.logininfo.userJID) {
            $("#Role-details").find(".popupcontent").text(language.locali().you_are_active_oncall);
            $("#Role-details").addClass("open");
        } else {
            sharedMsgIconKebabActionHandling(sharedDevEvent,isOpened)
        }
    }
    lastElementDropdownPopupHandling(sharedDevEvent)

}
/** shared Device Msg Icon Drop down Open**/

$("body").on('click', ".sharedDeviceMsgIcon", function (e) {
    e.stopPropagation();
    $('.pager_massage').val('');
    let isOpened = $(this).parent().parent().hasClass('open');
    if(!isOpened){
        $(".sharedDevMsgDiv").removeClass('open');
        $(".sharedDevPagerDiv").removeClass('open');
    }
    let onCallReceiveDeviceId = $(this).attr("data-oncallreceivedeviceid").trim();
    let oncallPagerNo = $(this).attr("data-oncall_pager_no");
    let msgPagerNo = $(this).attr("data-msg_pager_no");
    let profileVisibility = JSON.parse(localStorage.getItem("settings")).profileVisibility;
    let userLoggedIn = stropheConnection.logininfo.userJID;
    let sharedDevEvent = $(this);
    if(onCallReceiveDeviceId != '' && typeof onCallReceiveDeviceId != "undefined"){
        sharedDeviceMsgIconFirstAlignment(sharedDevEvent,isOpened);
    }else {
        sharedDeviceMsgIconSecondAlignment(sharedDevEvent,oncallPagerNo,msgPagerNo,profileVisibility,userLoggedIn,isOpened);
    }

});

/** shared Device Ikonix Chat Click Event**/

$("body").on('click', ".sharedDeviceUserChat", function (e) {
    e.stopPropagation();
    let activeMemberId = $(this).attr("data-member").trim();
    let chatUser = $(this).attr("data-chat-user");
    if(activeMemberId == "") {
        $("#Role-details").find(".popupcontent").text(language.locali().noone_active);
        $("#Role-details").addClass("open");
    } else if (activeMemberId == stropheConnection.logininfo.userJID) {
        $("#Role-details").find(".popupcontent").text(language.locali().you_are_active_oncall);
        $("#Role-details").addClass("open");
    } else {
        if(chatUser == 0) {
            recentdesign.recentClickAction(activeMemberId,chatUser);
            $(".side-role").css({
                "left": "-100%"
            });
            $(".side-global-role-list").css({
                "left": "-100%"
            });
           
        } else  {
            recentdesign.recentClickAction(activeMemberId);
            $(".side-role").css({
                "left": "-100%"
            });
            $(".side-global-role-list").css({
                "left": "-100%"
            });
        }
    }

});

/** shared Device Message Role Pager Click Event**/

$("body").on('click', ".msg-role-pager", function (e) {
    e.stopPropagation();
    let pagerNo = $(this).data('pagerno');
    let roleName = $(this).data('name');
    let pagerNodeId = $(this).data('nodeid');
    let pagerDeviceType = $(this).data('type');
    let pagerToId = $(this).data('toid');


    $('#myModal').modal('show');
    $('.pagerHeader').html('Pager Message to '+roleName);
    $('#PagerNumberId').attr("data-id", pagerNo);
    $('#pagerNodeId').attr("data-nodeid",pagerNodeId);
    $('#pagerDeviceType').attr("data-type",pagerDeviceType);
    $('#pagerToId').attr("data-toid",pagerToId);
    $("#isReceivedDevice").attr('data-isreceiveddevice',0);

    $(".sharedDevPagerDiv").removeClass('open');
    $(".sharedDevMsgDiv").removeClass('open');
    let pagerSettings = JSON.parse(localStorage.getItem("settings"));
    $('.pager_length').text(pagerSettings.pagerMaxCharLimit);
    $('.pager_massage').attr('maxLength',pagerSettings.pagerMaxCharLimit).attr('minLength',pagerSettings.pagerMinCharLimit)
});

/** shared Device Message Pager Click Event**/

$("body").on('click', ".msg-pager", function (e) {
    e.stopPropagation();
    let pagerNo = $(this).data('pagerno');
    let name = $(this).data('name');
    let nodeId = $(this).data('nodeid');
    let pagerDeviceType = $(this).data('type');
    let pagerToId = $(this).data('toid');


    $('#myModal').modal('show');
    $('.pagerHeader').html('Pager Message to '+name);
    $('#PagerNumberId').attr("data-id", pagerNo);
    $('#pagerNodeId').attr("data-nodeid",nodeId);
    $('#pagerDeviceType').attr("data-type",pagerDeviceType);
    $('#pagerToId').attr("data-toid",pagerToId);

    $("#isReceivedDevice").attr('data-isreceiveddevice',0);


    $(".sharedDevPagerDiv").removeClass('open');
    $(".sharedDevMsgDiv").removeClass('open');
    let settings = JSON.parse(localStorage.getItem("settings"));
    $('.pager_length').text(settings.pagerMaxCharLimit);
    $('.pager_massage').attr('maxLength',settings.pagerMaxCharLimit).attr('minLength',settings.pagerMinCharLimit)

});

/** shared Device Received Device Click Event**/

$("body").on('click', ".msg-received-device-pager", function (e) {
    e.stopPropagation();
    let pagerNo = $(this).data('pagerno');
    let deviceName = $(this).attr('data-deviceName');
    let nodeId = $(this).attr("data-nodeid");
    let pagerDeviceType = $(this).attr("data-type");
    let pagerToId = $(this).attr("data-toid");


    $('#myModal').modal('show');
    $('.pagerHeader').html('Message '+deviceName)
    $('#PagerNumberId').attr("data-id", pagerNo);
    $('#pagerNodeId').attr("data-nodeid",nodeId);
    $('#pagerDeviceType').attr("data-type",pagerDeviceType);
    $('#pagerToId').attr("data-toid",pagerToId);
    $("#isReceivedDevice").attr('data-isreceiveddevice',1);

    $(".sharedDevPagerDiv").removeClass('open');
    $(".sharedDevMsgDiv").removeClass('open');
    let settings = JSON.parse(localStorage.getItem("settings"));
    $('.pager_length').text(settings.receiveDeviceMessageLength);
    $('.pager_massage').attr('maxLength',settings.receiveDeviceMessageLength).attr('minLength',settings.receiveDeviceMessageLength)

});

/** Oncall Shared Device ul Element Hover Event**/
$(document).on({
    mouseenter: function() {
        $('.roles-content').css('background','#fff')
    },
    mouseleave: function() {
        $('.roles-content').css('background','')
    }
}, '.sharedDeviceUlEl');

/** Oncall Pager ul Element Hover Event**/
$(document).on({
    mouseenter: function() {
        $('.roles-content').css('background','#fff')
    },
    mouseleave: function() {
        $('.roles-content').css('background','')
    }
}, '.pagerDeviceUlEl');


$("body").on('click', "#scrollBottomClick", function (e) {
    let bottom = $('.acc__title.active').parent().find('.acc__panel').children().last().position();
    $('#roles').mCustomScrollbar("scrollTo",bottom.top,{
        moveDragger:true,
        
    });
});
/** Roles Organization Feature Click Events Start**/

$("body").on('click', ".acc__title", function (j) {
    let dropDown = $(this).closest('.acc__card').find('.acc__panel');
    $(this).closest('.acc').find('.acc__panel').not(dropDown).slideUp();

    let sideBarIdName = $(this).parent().parent().parent().parent().parent().parent().attr('id');
    
    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
    } else {
        $('.acc__title.active').parent('.acc__card').find('.acc__panel').hide()
        $('.acc__title.active').removeClass('active')
        $(this).addClass('active');
        let siteId;
        let unitName = $(this).text();
        let pageNo = 1;
        let searchVal = $('#rolesText').val()
        let locationId = JSON.parse(localStorage.getItem("locationId"));        
        if(sideBarIdName == 'globalRoleLists'){
            siteId = $('#globalRoleSiteId').val();
        }else {
            if(locationId != null && typeof locationId != "undefined"){
                siteId = locationId.mysite_role;
            }else {
                siteId = $('#siteDetails').text();
            }
        }
        
        module.exports.enableScrollHandler();

        $(this).parent().find('.acc__panel').children().remove() // Remove All Children Element with in the Unit div

        module.exports.getUnitBasedRoles(siteId,unitName,sideBarIdName,pageNo,searchVal);
        module.exports.oncallUnitsScrollUpdate(sideBarIdName);

    }

    let thisVal = $(this);
    
    dropDown.stop(false, true).slideToggle();
    j.preventDefault();
    $(".sideRoleNavLoader").show()

    setTimeout(function () {
        module.exports.oncallUnitsScrollUpdate(sideBarIdName);
        $(".sideRoleNavLoader").hide()
        setTimeout(function () {

            if ((thisVal.hasClass('active'))) {
                module.exports.scrollMoveToActiveStateDiv(sideBarIdName)
            }
        }, 100);

    }, 500);

});

$("body").on('click', ".RolesSort", function (e) {
    if($(this).hasClass('active')){
        $('.roleFilterPopup').hide()

        return
    }
    $('.rolesFilter').removeClass('unitfilter');
    $('.rolesFilter').removeClass('alapfilter');
    $(".sideRoleNavLoader").show()

    let siteId;
    let type;
    let searchVal;
    let locationId = JSON.parse(localStorage.getItem("locationId"));   
    searchVal = $('#rolesText').val()     
    if(locationId != null && typeof locationId != "undefined"){
      siteId = locationId.mysite_role;
    }else {
      siteId = $('#siteDetails').text();
    }

    if($(this).attr('data-type') == 'Roles'){
        type = 'oncallname'
        $('.rolesFilterAlphaIcon').addClass('unitfilter');
        $('.rolesFilterUnitIcon').addClass('alapfilter');
      
        setTimeout(function () {
            let oncallObj = {
                siteId      :siteId,
                search      :searchVal,
                page        :1,
                isEmpty     :1,
                elementName :'roles',
                type        :type,
                unitName    :'',
            
            }
            module.exports.getOnCalls(oncallObj);
        }, 500);

    }else {
        type = 'unitname'

        $('.rolesFilterUnitIcon').addClass('unitfilter');
        $('.rolesFilterAlphaIcon').addClass('alapfilter');
        setTimeout(function () {
            if(searchVal == ''){
                module.exports.getOncallUnits(siteId,'roles');
            }else {
                let oncallObj = {
                    siteId      :siteId,
                    search      :searchVal,
                    page        :1,
                    isEmpty     :1,
                    elementName :'roles',
                    type        :type,
                    unitName    :'',
                
                }
                module.exports.getOnCalls(oncallObj);
            }
        }, 500);

    }
    $('.roleFilterPopup').hide()

});



$("body").on('click', ".rolesFilter", function (e) {
    e.stopPropagation();
    let isToggleDivVisible = false;
    if($('.roleFilterPopup:visible').length > 0) {
        $('.roleFilterPopup').hide()
    }else {
    isToggleDivVisible = true;

        if($(this).attr('data-type') == 'Roles'){
            $('.RolesSortUnit').removeClass('active')
            $('.RolesSortAlpha').addClass('active')

        }else {
            $('.RolesSortUnit').addClass('active')
            $('.RolesSortAlpha').removeClass('active')
        }
        $('.roleFilterPopup').show()
    }
    $(document).click(function(event) {
        event.stopPropagation()

        if (isToggleDivVisible && !$(event.target).closest('.roleFilterPopup, .rolesFilter').length) {
          $('.roleFilterPopup').hide();
          isToggleDivVisible = false;
        }
      });
    

});

$("body").on('click', ".globalRoleFilter", function (e) {
    e.stopPropagation()
    let isToggleDivVisible = false;

    if($('.globalRoleFilterPopup:visible').length > 0) {
        $('.globalRoleFilterPopup').hide()
    }else {
        isToggleDivVisible = true;

        if($(this).attr('data-type') == 'Roles'){
            $('.globalRolesSortUnit').removeClass('active')
            $('.globalRolesSortAlpha').addClass('active')

        }else {
            $('.globalRolesSortUnit').addClass('active')
            $('.globalRolesSortAlpha').removeClass('active')
        }
        $('.globalRoleFilterPopup').show()
    }

    $(document).click(function(event) {
        event.stopPropagation()
        if (isToggleDivVisible && !$(event.target).closest('.globalRoleFilterPopup, .globalRoleFilter').length) {
          $('.globalRoleFilterPopup').hide();
        }
      });

});

$("body").on('click', ".globalRolesSort", function (e) {
    if($(this).hasClass('active')){
        $('.globalRoleFilterPopup').hide()
        return
    }

    $('.globalRoleFilter').removeClass('unitfilter');
    $('.globalRoleFilter').removeClass('alapfilter');
    $(".sideRoleListNavLoader").show()

    let siteId;
    let type;
    let searchVal;
    searchVal = $('#globalRoleSearchTxt').val()     
    siteId = $('#globalRoleSiteId').val();

    if($(this).attr('data-type') == 'Roles'){
        type = 'oncallname'

        $('.globalRoleFilterAlphaIcon').addClass('unitfilter');
        $('.globalRoleFilterUnitIcon').addClass('alapfilter');
        
        setTimeout(function () {
            let oncallObj = {
                siteId      :siteId,
                search      :searchVal,
                page        :1,
                isEmpty     :1,
                elementName :'globalRoleLists',
                type        :type,
                unitName    :'',
            
            }
            module.exports.getOnCalls(oncallObj); // Get & apped Oncalls Based On Site Id

        }, 500);

    }else {
        type = 'unitname'

        $('.globalRoleFilterUnitIcon').addClass('unitfilter');
        $('.globalRoleFilterAlphaIcon').addClass('alapfilter');
        setTimeout(function () {
            if(searchVal == ''){
                module.exports.getOncallUnits(siteId,'globalRoleLists');

            }else {
                let oncallObj = {
                    siteId      :siteId,
                    search      :searchVal,
                    page        :1,
                    isEmpty     :1,
                    elementName :'globalRoleLists',
                    type        :type,
                    unitName    :'',
                
                }
                module.exports.getOnCalls(oncallObj); // Get & apped Oncalls Based On Site Id

            }
        }, 500);

    }
    $('.globalRoleFilterPopup').hide()

});

/** Roles Organization Feature Click Events End**/

$("body").on('click', ".rolesMysitePref", function (e) {
    $(".locationid-list-sidenav").css({
        "display": "block",
        "left": "0px"
    });
    localStorage.setItem("isSetRoleSitePreference", 1);
    
});

