require('./js/lib/emoji/js/minEmoji.js');
require('./js/lib/search/highlight.js');
require('./js/media.js');
require('./js/lib/bootstrap/bootstrap3.3.7.min.js')

let output = document.getElementById("output");
let userJID = "";
let toJID = "";
let user = "";
let inputname = "";
let language = require('./js/locale/language.js');
const CryptLib = require('cryptlib');
const strophe = require('strophe.js');
const mCustomScrollbar = require('./js/lib/scrollbar/jquery.mCustomScrollbar.concat.min.js');
const uploadjs = require('./js/fileupload.js');
const vCard = require('./js/vCard.js');
const userprofile = require('./js/userprofile.js');
const blockUser = require('./js/blockUser.js');
const utils = require('./js/utils.js');
const sendMessage = require('./js/sendMessage.js');
const recent = require('./js/recent.js');
const conversation = require('./js/conversation.js');
const groups = require('./js/groups.js');
const storage = require('./js/storage.js');
const groupDesign = require('./js/groupdesign.js');
const trigger = require('./js/trigger.js');
const attachment = require('./js/attachment.js');
const favourite = require('./js/favourite.js');
const audio = require('./js/audio.js');
const stropheConnection = require('./js/stropheConnection.js');
const roster = require('./js/roster.js');
const contact = require('./js/contact.js');
const lastActivity = require('./js/lastActivity.js');
const broadcast = require('./js/broadcast.js');
const clearallMessage = require('./js/clearmessage.js');
const userSession = require('./js/session.js');
const receivedMessage = require('./js/receivemessage.js');
let MediaStream;
let userToken;
let mediaUrl;
let pressed = false;
const trans = require('./js/locale/translator.js');
const mediaUpload = require('./js/mediaupload.js');
const keycloakApi = require('./js/keycloakApi.js');
const profileStatus = require('./js/profileStatus.js');
let siteDetails = require('./js/siteDetails.js');
const roles = require('./js/roles.js');
const pagerMsg = require('./js/pager.js');
const settings = require('./js/settings.js');
const userOncalls = require('./js/userOncalls.js');
const userOncallManagement = require('./js/userOncallManagement.js');

let indexDb = require('./js/indexDb.js');
const broadcastContact = require('./js/broadcastContact.js');
let contactTimeout, newGroupTimeout, existingGroupTimeout, forwardTimeout, rolesTimeout,globalContactTimeout,globalRolesTimeout,globalTeamContactTimeout,globalContactSiteSearchFilter,globalRoleSiteSearchFilter,globalAddParticipanSiteSearchFilter,newBroadcastTimeout,globalBroadcastAddParticipanSiteSearchFilter;

String.prototype.isNumber = function () {
  return /^\d+$/.test(this);
}

async function chatConnect(loginusername) {
  let username = stropheConnection.Strophe().getNodeFromJid(loginusername);
  let logsession,loginUserInfo,securekey,body,authinfo;
  if (username == null) {
    username = loginusername;
  }
  setInterval(function () {
    logsession = sessionStorage.getItem("Info");
    loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    securekey = CryptLib.getHashSha256(loginUserInfo[0][0], 32);

    body = CryptLib.decrypt(logsession, securekey, loginUserInfo[0][0]);
    authinfo = JSON.parse(body);

    userSession.getSession(loginUserInfo[0][0], authinfo[1]);
  }, 900 * 1000);

  await userSession.setSession(username);
  userToken = storage.getuserToken();
  if (typeof userToken == "undefined") {
    userSession.setSession(username);
  }
  localStorage.removeItem("selectedSitesInfo");
  mediaUrl = window._env_.ApiURL + "/media/";
  logsession = sessionStorage.getItem("Info");
  let key = CryptLib.getHashSha256(username, 32);
  let logininfo = stropheConnection.logininfo;
  logininfo.userJID = username;
  loginUserInfo = {};
  loginUserInfo[0] = [];
  loginUserInfo[0].push(username);
  localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
  body = CryptLib.decrypt(logsession, key, username);
  authinfo = JSON.parse(body);
  let webReosurce = stropheConnection.Resource();
  username = authinfo[0] + "@" + stropheConnection.Server() + "/Web-" + webReosurce;
  if (!stropheConnection.Connection().connected) {
    setTimeout(function () {
      stropheConnection.Connection().connect(username, authinfo[1], connectHandler);
    }, 500);
  }
}
function setLoginInfo(loginInfo){
  let loginUserInfo;
  if(loginInfo == null) {
    let logininfo = stropheConnection.logininfo;
    loginUserInfo = {};
    loginUserInfo[0] = [];
    loginUserInfo[0].push(logininfo.userJID);
    localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
  }
};

function setBotStatus(){
  if (storage.botStatus() == 1) {
    $('#bot-change').prop("checked", true);
  } else {
    $('#bot-change').prop("checked", false);
  }
};

function handlerSendChatMsg() {
  let offlineMsg = JSON.parse(localStorage.getItem("offlineMsg"));
      if (offlineMsg != null) {
        for (let key in offlineMsg) {
          if (offlineMsg[key][0] == "message") {
            let sendChatObj = {
              msg         	   :offlineMsg[key][1],
              messagetype      :offlineMsg[key][2],
              messageid        :key,
              filesize         :offlineMsg[key][3],
              options    	     :offlineMsg[key][4],
              touser  	       :offlineMsg[key][5],
            }
            sendMessage.sendChatMessages(sendChatObj);
          }
        }
        delete localStorage.offlineMsg;

      }
};
function isParticipantGroupcheck(toJID){
  let isPendingGroupParticipant = JSON.parse(localStorage.getItem(language.locali().isPendingGroupParticipant));
  if(isPendingGroupParticipant !== undefined && isPendingGroupParticipant != null && isPendingGroupParticipant)
    settings.updateGroups();
  if ($("#index-chat-section").css('display') == "block") {
    delete localStorage.toJID;
  } else {
    if (toJID != null && typeof toJID != "undefined") {

      let nodeJid = stropheConnection.Strophe().getNodeFromJid(toJID);
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        lastActivity.lastActivity(toJID);
      }
      $("#recentcontent").find(".recentcontent").removeClass("active");
      $('#message').css("display", "block");
      $('#con-head').css('display', 'flex');
      $('#conversation').css('display', 'block');
      $("[id='"+nodeJid+"']").addClass("active");


    }
  }
};
function navigatorOnlineCheck(){
  let loginUserInfo;

  if (navigator.onLine) {
    setTimeout(function () {
      console.log("call disconnected");
      if (!stropheConnection.Connection().connected) {
        stropheConnection.NewConnect();
        loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
        chatConnect(loginUserInfo[0][0]);
      }
    }, 6000)
  }
};
function connectHandler(cond) {
  if (cond == stropheConnection.Strophe().Status.CONNECTED) {
    console.log("STROPHE CONNECTED...");
    let loginInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    setLoginInfo(loginInfo)
   
    stropheConnection.Connection().addHandler(presenceHandler, null, "presence", null);
    stropheConnection.Connection().addHandler(streamHandler, null, 'failure', null);
    stropheConnection.Connection().addHandler(pingHandler, "urn:xmpp:ping", "iq", "get");
    stropheConnection.Connection().addHandler(vCard.vcardHandler, "vcard-temp", "iq", "result");
    stropheConnection.Connection().addHandler(receivedMessage.iqHandler, "urn:xmpp:oncall", "iq", "result");
    stropheConnection.Connection().addHandler(receivedMessage.messageHandler, null, 'message', null, null, null);
    stropheConnection.Connection().addHandler(blockUser.getblockuserCallBack, 'urn:xmpp:blocking', "iq", "set");
    
    stropheConnection.Connection().addHandler(roster.getRosterCallback, "jabber:iq:roster", "iq", "result");
    stropheConnection.Connection().addHandler(broadcast.getBroadcastCallback, "jabber:iq:mod_broadcast", "iq", "result");
    stropheConnection.Connection().addHandler(favourite.getfavouritemsglistCallBack, "jabber:iq:user_fav", "iq", "result");
    stropheConnection.Strophe().log = function (lvl, msg) {
    };
    toJID = JSON.parse(localStorage.getItem("toJID"));
    userJID = stropheConnection.logininfo.userJID;
    
    $('.loader-wrapper').css("display", "block");
    $('#re-connection').css('visibility', 'hidden');
    $('#re-connection').css('opacity', '0');
    $('#main-screen').css("display", "block");
    $('#con-head').css('display', 'none');
    $('#conversation').css('display', 'none');

    setBotStatus()
   
    if (stropheConnection.Connection().connected) {
      userprofile.getUserProfile(userJID);
      stropheConnection.Connection().send(strophe.$pres().c("priority").t("1"));
      carbonEnable();

      handlerSendChatMsg()
      
      utils.getConnectCanLogin();
      vCard.getVcard(userJID + "@" + stropheConnection.Server());
      setTimeout(function () {
        broadcast.getBroadcast();
        blockUser.blockuserlist();
        $.when(groups.getGroups()).done(function () {
          $.when(roster.getRoster()).done(function () {
            setTimeout(function () {
              vCard.getVcard(storage.adminName() + "@" + stropheConnection.Server());
              blockUser.blocklist();
              $("#recentcontent").html("");
              recent.getRecentChat();
              favourite.favouritemsglist();
              $('.heading-dot').show();
              $('.loader-wrapper').css("display", "none");
            }, 1000);

          });
        });
      }, 500);

      profileStatus.getProfileStatus();
      siteDetails.getSiteDetails();
      userOncallManagement.getOncallBatchCount(); // Oncall Batch count Get and Append
      pagerMsg.getPager();
      isParticipantGroupcheck(toJID)
      
      localStorage.removeItem("isUnknown");
    }
  } else if (cond == stropheConnection.Strophe().Status.CONNECTING) {
    console.log("STROPHE CONNECTING.....");
  } else if (cond == stropheConnection.Strophe().Status.DISCONNECTING) {
    console.log("STROPHE DISCONNECTING.....");
  } else if (cond == stropheConnection.Strophe().Status.DISCONNECTED) {
    console.log("STROPHE DISCONNECTED.....", navigator.onLine);
    navigatorOnlineCheck()
    
  } else if (cond == stropheConnection.Strophe().Status.ATTACHED) {
    console.log("STROPHE ATTACHED.....");
  } else if (cond == stropheConnection.Strophe().Status.AUTHFAIL) {
    logout();
  } else if (cond == stropheConnection.Strophe().Status.CONNFAIL) {
    console.log("STROPHE CONNFAIL.....", navigator.onLine);
  } else if (cond == stropheConnection.Strophe().Status.ERROR) {
    console.log("STROPHE CONNERROR.....");
  } else if (cond == stropheConnection.Strophe().Status.CONNTIMEOUT) {
    $('#re-connection').css('visibility', 'visible');
    $('#re-connection').css('opacity', '1');
    console.log("STROPHE CONNTIMEOUT.....");
  }
}
function carbonEnable() {
  let carbon = strophe.$iq({
    type: "set",
    id: utils.getId()
  }).c('enable', {
    xmlns: 'urn:xmpp:carbons:2'
  });
  if (stropheConnection.Connection().connected) {
    stropheConnection.Connection().send(carbon);
  }
}

function presenceHandler(presence) {
  try {
    toJID = JSON.parse(localStorage.getItem("toJID"));
    let from = presence.getAttribute("from");
    if (toJID != null && typeof toJID != "undefined") {
      if (stropheConnection.Strophe().getNodeFromJid(from) == stropheConnection.Strophe().getNodeFromJid(toJID)) {
        setTimeout(function () {
          lastActivity.lastActivity(toJID);
        }, 1000)
      }
    }
    $(presence).find('x').each(function (index) {
      vCard.getVcard(stropheConnection.Strophe().getBareJidFromJid(from));
    });
  } catch (err) {
    console.log("Presence Handler", err);
    console.log("presence iq", presence);
  }
  return true;
}

function streamHandler(failure) {
  console.log("stream failure");
}

function pingHandler(ping) {
  try {
    let pingId = ping.getAttribute("id");
    let from = ping.getAttribute("from");
    let to = ping.getAttribute("to");
    let pong = strophe.$iq({
      type: "result",
      "to": from,
      id: pingId,
      "from": to
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().send(pong);
    }
    // Indicate that this handler should be called repeatedly
  } catch (err) {
    console.log("Ping Handler", err);
    console.log("ping iq", ping);
  }
  return true;
}
//resize just happened, pixels changed
$(window).resize(function() {
  let recentChatTotHeight = $('#recentchat').height();
  let recentSearchBarHeight = $('#recentchat .searchBox').height()
  let recentHeight = recentChatTotHeight-recentSearchBarHeight;
  $("#recentchatcontent").css('height',recentHeight)
  $("#recentchatcontent").mCustomScrollbar("update");
});

$(".status-edit").click(function () {
  $(".status-view .dropdown-status-ul").toggle();
  let statusId = $(this).attr('data-id')
  $('.dropdown-status .dropdown-status-ul a').removeClass('active')
  $('.dropdown-status .dropdown-status-ul').find('[name="'+statusId+'"]').addClass('active')
});

$("#composeText").keyup(function (e) {

  let searchContent     = $(this).val().trim(),
      isChatUser        = $('.my-site-contacts').hasClass('active') ? 1 : '',
      siteId            = $('#siteDetails').text(),
      page              = $('#page').val();

      let locationId = JSON.parse(localStorage.getItem("locationId"));        
      if(locationId != null && typeof locationId != "undefined"){

         siteId = locationId.mysite_contact;
      }else {
         siteId = $('#siteDetails').text();
      }
  
  $("#noroster").remove();
  clearTimeout(contactTimeout);
  contactTimeout = setTimeout(() => {
    let appContactQueryParams;
    if (searchContent == '') {
      appContactQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
      contact.getMessageContacts(appContactQueryParams);
    } else {
      if (searchContent.length > 0) {
        appContactQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
        contact.getMessageContacts(appContactQueryParams);
      } 
    }
  }, 700)

  if (searchContent == '') {

    $("#roster").find('.mCSB_container').children().show();
    $(".searchContact").show();
    $("#searchContactClose").hide();

  } else {

    $(".searchContact").hide();
    $("#searchContactClose").show();
    $("#roster").mCustomScrollbar("scrollTo", 0);
    $("#noroster").remove();
    $("#roster").find('.mCSB_container').children().hide();
    $("#roster").find('.sideBar-body').each(function () {
      let rosterval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = searchContent.toUpperCase();
      if (rosterval.indexOf(searchtxt) != -1) {
        $("#noroster").remove();
        $(this).show();
      }
    });

  }
  $("#roster").mCustomScrollbar("destroy");

  $("#roster").mCustomScrollbar({
    mouseWheelPixels: 500 //change this to a value, that fits your needs
  });

});

//on keydown, clear the countdown 
$("#composeText").on('keydown', function () {
  clearTimeout(contactTimeout);
});

// function used on site search
$("#sitesText").on("keyup", function(e) {
  let content = $(this).val().trim();
  $("#sites-info #noparticipant").remove();
  if (content == '') {
    $("#globelSearchContactClose").trigger("click");


  } else {
    $("#globelSearchContactClose").show();
    $(".globelSearchContact").hide();
    $("#sites-info").find('.sideBar-body').hide();
    let searchtxt;
    $("#sites-info").find('.sitecontent').each(function () {
      let siteval = $(this).find('.name-meta').text().toUpperCase();
      searchtxt = content.toUpperCase();
      if (siteval.indexOf(searchtxt) != -1) {
        $(this).show();
      }
    });
    searchtxt = encodeURIComponent(searchtxt);

    let appSitesQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=&page=1&search=' + searchtxt;
    clearTimeout(globalContactSiteSearchFilter);
   
    $(".sideContactNavLoader").show()

    if($('#sites-info').find('.sitecontent:visible').length){
      $("#sites-info").find('.contactSitesHeaderDiv').show();
      $("#sites-info #noparticipant").remove();
    }else {
      $("#sites-info").find('.contactSitesHeaderDiv').hide();
    } 

      globalContactSiteSearchFilter = setTimeout(() => {
        $("#sites-info").find('.rosterfwdcontent').remove();
        $("#sites-info").find('.contactHeaderDiv').remove();

        let contactHeaderDiv = '<div class="contactHeaderDiv">Contacts</div>';
        $("#sites-info").find('.mCSB_container').append(contactHeaderDiv)

        getSiteAndMessageContacts(appSitesQueryParams)
        let filterContactsLength = $("#sites-info").find('.rosterfwdcontent').length;
        let filterSitessLength = $("#sites-info").find('.sitecontent:visible').length;

        if(filterContactsLength == 0 && filterSitessLength == 0){
          $("#sites-info").find('.mCSB_container').append("<div id='noparticipant'>" + language.locali().nosite_or_contacts_found + "</div>");
        }
        
        $("#sites-info").mCustomScrollbar("update");
      $('#sites-info').find('.mCSB_container').css('z-index',0)

      },700);

  }
});

//on keydown, clear the countdown 
$("#sitesText").on('keydown', function () {
  clearTimeout(globalContactSiteSearchFilter);
});
function searchTextContentEmpty(){
  $("#recentcontent").children().show();
  $("#roster").children().show();
  $("#rostersidebar").children().hide();
  $("#rostersidebar").hide();
  $(".searchOpen").show();
  $("#searchClose").hide();
  $("#recentcontent").removeHighlight();
  $("#rostersidebar").removeHighlight();
}

function searchTextContentnotEmpty(searchTxtEvnt,txt,content){
  $("#recentcontent").children().hide();
  $("#rostersidebar").children().hide();
  $("#rostersidebar").show();
  $("#recentcontent").prepend("<div id='norecentcontent'>" + language.locali().no_chats_contacts_messages_found + "</div>");
  $(".searchOpen").hide();
  $("#searchClose").show();

  $("#rostersidebar").find('.sideBar-body').each(function () {
    let rosterval = $(this).find('.name-meta').text().toUpperCase();
    let searchtxt = content.toUpperCase();
    if (rosterval.indexOf(searchtxt) != -1) {
      $("#norecentcontent").remove();
      // remove any old highlighted terms
      $(this).find('.name-meta').removeHighlight();
      $(this).find('.name-meta').highlight(searchtxt);
      $(this).show();
    }
  });

  $("#recentcontent").find('.recentcontent').each(function () {
    let recentval = $(this).find('.name-meta').text().toUpperCase();
    let searchtxt = content.toUpperCase();
    if (recentval.indexOf(searchtxt) != -1 ) {
      $("#norecentcontent").remove();
      // remove any old highlighted terms
      $(this).find('.name-meta').removeHighlight();
      $(this).find('.name-meta').highlight(searchtxt);
      $("#rostersidebar").find("[id='rostersearch-"+$(this).attr('id')+"']").hide();
      $(this).show();
    }
  });

  if (!pressed) {
    if (searchTxtEvnt.keyCode == 13) {
      pressed = true;
      searchTxtEvnt.preventDefault();
      if ($("#recentcontent").find('.recentcontent').not(":hidden").length == 1) {
        $("#recentcontent").find('.recentcontent').not(":hidden").find(".recenttext").trigger('click');
        $("#searchText").val('');
      }
      setTimeout(function () {
        pressed = false;
      }, 2000);
    }
  }
}

$("#searchText").keyup(function (e) {
  let txt = $(this).val();
  let content = txt.trim();
  let searchTxtEvnt = e;
  $("#norecentcontent").remove();
  if ($("#recentcontent").find('#norecent').length == 0) {
    if (content == '') {
      searchTextContentEmpty();
    } else {
      searchTextContentnotEmpty(searchTxtEvnt,txt,content);

    }
    $("#recentchatcontent").mCustomScrollbar("destroy");
    $("#recentchatcontent").mCustomScrollbar({
      mouseWheelPixels: 500 //change this to a value, that fits your needs
    });
  } else {
    $("#norecentcontent").remove();
  }
});
$("#starSearch").keyup(function (e) {
  let txt = $(this).val();
  let content = txt.trim();
  $("#nostarcontent").remove();
  if (content == '') {
    $("#star-content").find('.mCSB_container').children().show();
  } else {
    $("#star-content").find('.mCSB_container').children().hide();
    $("#star-content").find('.staredmsg-sideBar').each(function () {
      let staruser = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (staruser.indexOf(searchtxt) != -1) {
        $("#nostarcontent").remove();
        $(this).show();
      }
      let captionval = $(this).find('.image-caption').text().toUpperCase();
      if (captionval.indexOf(searchtxt) != -1) {
        $("#nostarcontent").remove();
        $(this).show();
      }
      let msgval = $(this).find('.starredmsg').text().toUpperCase();
      if (msgval.indexOf(searchtxt) != -1) {
        $("#nostarcontent").remove();
        $(this).show();
      }
    });
  }
  if($("#star-content").find('.staredmsg-sideBar').length > 0 && $("#star-content").find('.staredmsg-sideBar:visible').length == 0){
    $("#star-content").prepend("<div id='nostarcontent'>" + language.locali().no_favourite_message_yet + "</div>");

  }else {
    $("#star-content").find('.no-favmsg-cnts').show();
  }

  $("#star-content").mCustomScrollbar("destroy");
  $("#star-content").mCustomScrollbar({
    mouseWheelPixels: 500 //change this to a value, that fits your needs
  });
});

/**  Forward Contact Tab Click Event Start **/
$(".fwdContactTab").on("click", function (e) {
  $('#rosterchatcontact').children().remove()
  
  let tabType = $(this).attr('data-tabtype');
  if(tabType == "contact")
      $("#forwardText").attr("placeholder","Search contacts");
  else 
      $("#forwardText").attr("placeholder","Search Recently contacted");

 
  let i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabType).style.display = "block";
  e.currentTarget.className += " active";

  $('#forwardText').val("");
  $("#forwardText").trigger('keyup')
});
/**  Forward Contact Tab Click Event End **/


$("#forwardText").keyup(function (e) {
  $(".fwdrosters").scrollTop(0);
  let txt = $(this).val();
  let content = txt.trim();
  let appForwardQueryParams,searchtxt;
  let tabType = $('.fwdContactTab.active').attr('data-tabtype');

  $("#nofwdrecentcontent").remove();
  $("#nofwdrostercontent").remove();

  clearTimeout(forwardTimeout);
  forwardTimeout = setTimeout(() => {
    if (content == '') {
      $("#forward-msg").find('.recentchatcontact').children().show();
      $("#forward-msg").find('.rosterchatcontact').children().show();
      
      appForwardQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&page=1';
      contact.getForwardContacts(appForwardQueryParams);
    } else {
      if (content.length > 0) {
        content = encodeURIComponent(content);
        appForwardQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&page=1&search=' + content;
        contact.getForwardContacts(appForwardQueryParams);
        $("#forward-msg").find('.recentchatcontact').children().hide();
        $("#forward-msg").find('.recentchatcontact').prepend("<div id='nofwdrecentcontent'>" + language.locali().no_chats_contacts_messages_found + "</div>");
        $("#forward-msg").find('.rosterchatcontact').children().hide();
        $("#forward-msg").find('.rosterchatcontact').prepend("<div id='nofwdrostercontent'>" + language.locali().no_contacts_found + "</div>");
        $("#forward-msg").find('.sideBar-body').each(function () {
            let rosterval = $(this).find('.name-meta').text().toUpperCase();
            searchtxt = content.toUpperCase();
            if (rosterval.indexOf(searchtxt) != -1) {
                $("#nofwdrecentcontent").remove();
                $("#nofwdrostercontent").remove();
                $(this).show();
            }
        });
        if(tabType == 'recent-contact'){
          $(".recentchatcontact").find('.contactcontent').each(function () {
            let recentval = $(this).find('.name-meta').text().toUpperCase();
            let recentcont = $(this).find('.text-italic').text().toUpperCase();
            searchtxt = content.toUpperCase();
            rosterChatContactVisibleCheck(recentval,recentcont,searchtxt,$(this))
           
        });
        }
      }  
    }
  }, 300);
  $(".fwdrosters").find(".priority-message-recent").remove();
});

function rosterChatContactVisibleCheck(recentval,recentcont,searchtxt,element){
  if (recentval.indexOf(searchtxt) != -1 || recentcont.indexOf(searchtxt) != -1) {
    $(".rosterchatcontact").find('#' + element.attr('id')).css('display', 'none');
  }
}

//on keydown, clear the countdown 
$("#forwardText").on('keydown', function () {
  clearTimeout(forwardTimeout);
});

$("#partticipantText").keyup(function (e) {

  let siteId, appParticipantQueryParams;
  if ($('.mySiteExistTeam').hasClass('active') === false) {
    siteId = '';

  }
  else{

    let locationId = JSON.parse(localStorage.getItem("locationId"));  
    let partticipantTextLocationId;      
    if(locationId != null && typeof locationId != "undefined"){
      partticipantTextLocationId = locationId.mysite_contact;
    }else {
      partticipantTextLocationId = $('#siteDetails').text();
    }
    siteId = partticipantTextLocationId;
  } 

  let search = $(this).val().trim();

  clearTimeout(existingGroupTimeout);
  existingGroupTimeout = setTimeout(() => {
    if (search == "") {
      appParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';
    }else{
      search = encodeURIComponent(search);
      appParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + search;
    } 
    
    contact.getExistingGroupContacts(appParticipantQueryParams);
  }, 700);
  
});

//on keydown, clear the countdown 
$("#partticipantText").on('keydown', function () {
  clearTimeout(existingGroupTimeout);
});

$("#searchparticipant, .side-global-team-contact-list #searchparticipant").keyup(function (e) {
  let content = $(this).val().trim();
  $("#nonewparticipant").remove();
  let siteId, appSearchQueryParams;

  if (content == "") {
    $(".searchParticipantOpen").show();
    $(".searchParticipantClose").hide();
  }else {
    $(".searchParticipantOpen").hide();
    $(".searchParticipantClose").show();
  }

   let locationId = JSON.parse(localStorage.getItem("locationId"));    
   let searchparticipantLocationId;    
  if(locationId != null && typeof locationId != "undefined"){
    searchparticipantLocationId = locationId.mysite_contact;
  }else {
    searchparticipantLocationId = $('#siteDetails').text();
  }

  if($('.mySiteParticipantTab').hasClass('active'))
   siteId = searchparticipantLocationId;
  else 
   siteId = $("#globalParticipantCurrentSiteId").val();


  clearTimeout(newGroupTimeout);
  $(".sideTeamContactListNavLoader").show()
  newGroupTimeout = setTimeout(() => {
  
    if (content == "") {

      appSearchQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';  
    } else {
      content = encodeURIComponent(content);
      appSearchQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + content;
    } 

    if($('.mySiteParticipantTab').hasClass('active')){
      contact.getNewGroupContacts(appSearchQueryParams,'participants');
    }else {
      contact.getNewGroupContacts(appSearchQueryParams,'globalTeamContactLists');
    } 
    
  }, 700);

});

//on keydown, clear the countdown 
$("#searchparticipant, .side-global-team-contact-list #searchparticipant").on('keydown', function () {
  clearTimeout(newGroupTimeout);
});

function broadCastKeyupFunctionCallback(content){
  let siteId, appSearchQueryParams;

  if (content == "") {
    $(".searchBroadcastParticipantOpen").show();
    $(".searchBroadcastParticipantClose").hide();
  }else {
    $(".searchBroadcastParticipantOpen").hide();
    $(".searchBroadcastParticipantClose").show();
  }

   let locationId = JSON.parse(localStorage.getItem("locationId"));    
   let searchparticipantLocationId;    
  if(locationId != null && typeof locationId != "undefined"){
    searchparticipantLocationId = locationId.mysite_contact;
  }else {
    searchparticipantLocationId = $('#siteDetails').text();
  }

  if($('.mySiteBroadcastParticipantTab').hasClass('active'))
   siteId = searchparticipantLocationId;
  else 
   siteId = $("#globalBroadcastParticipantCurrentSiteId").val();


  clearTimeout(newBroadcastTimeout);
  $(".sideBroadcastContactListNavLoader").show()
  newBroadcastTimeout = setTimeout(() => {
  
    if (content == "") {

      appSearchQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';  
    } else {
      content = encodeURIComponent(content);
      appSearchQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + content;
    } 
    if($('.mySiteBroadcastParticipantTab').hasClass('active')){
      contact.getNewBroadCastContacts(appSearchQueryParams,'broadcast-participants');
    }else {
      contact.getNewBroadCastContacts(appSearchQueryParams,'globalBroadcastContactLists');
    } 
    
  }, 700);

}

/** Broad cast Input Search for MysiteContacts and Global Site Contacts Start **/
$("#searchBroadcastparticipant, .side-global-broadcast-contact-list #searchBroadcastparticipant").keyup(function (e) {
  let content = $(this).val().trim();
  $("#nonewparticipant").remove();

  broadCastKeyupFunctionCallback(content)
  
});

//on keydown, clear the countdown 
$("#searchBroadcastparticipant, .side-global-broadcast-contact-list #searchBroadcastparticipant").on('keydown', function () {
  clearTimeout(newBroadcastTimeout);
});

/** Broad cast Input Search for MysiteContacts and Global Site Contacts End **/


let acc = document.getElementsByClassName("accordion");
let i;
for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");
    if ($("#" + this.id).hasClass("active")) {
      $("#" + this.id).find(".close").css("display", "block");
      $("#" + this.id).find(".open").css("display", "none");
    } else {
      $("#" + this.id).find(".close").css("display", "none");
      $("#" + this.id).find(".open").css("display", "block");
    }
    /* Toggle between hiding and showing the active panel */
    let panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}
$("#send-icon").on("click", function () {
  $('#messageCharCount').html(0);
  $('#messageCharCount').removeAttr( 'style' );
  $('#messageCharLimitCount').removeAttr( 'style' );
  $('#comment').trigger(
    $.Event('keypress', { keyCode: 13, which: 13 })
  );
  if($('.side-profile').css('left') == '0px' || $('.side-two').css('left') == '0px' || $('.side-global-contact-list').css('left') == '0px' || $('.side-role').css('left') == '0px' || $('.side-global-role-list').css('left') == '0px' || $('.side-group').css('left') == '0px' || $('.side-global-team-contact-list').css('left') == '0px' || $('.side-settings').css('left') == '0px' || $('.blockedlist-sidenav').css('left') == '0px' || $('.locationid-list-sidenav').css('left') == '0px'){
    $('.filter.plus-circles').hide()
  }
});

/** Offline Text Update in Local Storage **/
function offLineTextUpdate(offlineText,nodeJid,str,messageCharacterLimit) {
  let CommentTextLength = $('#comment').html().length;

  $('#messageCharCount').html(CommentTextLength)
  if (str.length <= messageCharacterLimit) {
    if (offlineText == null) {
      offlineText = {};
    }
    if(str.length < messageCharacterLimit){
      $('#messageCharCount').removeAttr( 'style' );
      $('#messageCharLimitCount').removeAttr( 'style' );
    }
    let strLength = str.length
    $('#messageCharCount').html(strLength)
    offlineText[nodeJid] = [];
    offlineText[nodeJid].push('chatcontent');
    offlineText[nodeJid].push(str);
    localStorage.setItem("offlineText", JSON.stringify(offlineText));
  }
  else {
    $('#messageCharCount').css('color','red');
    $('#messageCharLimitCount').css('color','red');
    str = $('#comment')[0].innerText;
    str = str.substring(0, messageCharacterLimit);;
    $("#comment").html(str)
    let strLength = str.length
    $('#messageCharCount').html(strLength)
    offlineText[nodeJid] = [];
    offlineText[nodeJid].push('chatcontent');
    offlineText[nodeJid].push(str);
    localStorage.setItem("offlineText", JSON.stringify(offlineText))
    $("#long-message").show();
  }
}

$("#comment").keyup(function (e) {
 let tojid = JSON.parse(localStorage.getItem("toJID"));
 let divJid,height,MessageHeight,offlineText,nodeJid;
  if (tojid != null) {
    if ($("#comment").height() >= 20) {
      divJid = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
      $("[id='conversation"+divJid+"']").css({ height: "calc(100% - 135px)" });
      MessageHeight = $("[id='conversation"+divJid+"']").height() - $("#comment").height() + 30;
      $("[id='conversation"+divJid+"']").height(MessageHeight);
      height = 0;
      $("[id='conversation"+divJid+"']").find('.row').each(function (i, value) {
        height += parseInt($(this).height());
      });
      $("#messageemoji").find('.emojiPicker').css({ top: -349 - $("#comment").height() + 30 });
      $("[id='conversation"+divJid+"']").scrollTop(height);
      $(".reply-content").css({ bottom: $("#comment").height() + 65 });
    } else {
      $("[id='conversation"+divJid+"']").css({ height: "calc(100% - 135px)" });
      $(".reply-content").css({ bottom: "75px" });
    }
    offlineText = JSON.parse(localStorage.getItem("offlineText"));
    nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    let str = $("#comment")[0].innerText;
    let Settings = JSON.parse(localStorage.getItem("settings"));
    let messageCharacterLimit = Settings.messageCharacterLimit;

    if (str.trim() != '') {
      offLineTextUpdate(offlineText,nodeJid,str,messageCharacterLimit)
      
    } else {
      $('#messageCharCount').html(0);
      $('#messageCharCount').removeAttr( 'style' );
      $('#messageCharLimitCount').removeAttr( 'style' );
      if (offlineText != null && typeof offlineText[nodeJid] != "undefined") {
        delete offlineText[nodeJid];
        localStorage.setItem("offlineText", JSON.stringify(offlineText));
      }
    }
  }
});

function exceptKeyHandling(key,ctrl,CommentTextLength,messageCharacterLimit){
  // Except Enter,Shift,Backspace,Ctrl,Delete Keys
  if(!(key ==17 || key ==8 || key ==46 || key ==13 || key ==16  || key ==18 || key ==20 || key ==27 || key ==35 || key ==36 || key 
    ==37 || key ==38 || key ==39 || key ==40 || key ==45 || key ==112 || key ==113 || key ==114 || key ==115 || key ==116 || key 
    ==117 || key ==118 || key ==119 || key ==120 || key ==121 || key ==122 || key ==123 || (ctrl && key == 65) || (ctrl && key == 
    67) || (ctrl && key == 86) || (ctrl && key == 88) || (ctrl && key == 90) )){ 
        if (CommentTextLength >= messageCharacterLimit) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          $('#messageCharCount').css('color','red');
          $('#messageCharLimitCount').css('color','red');
          $("#long-message").show();
          return;
        }
  }
}

$("#comment").keydown(function (e) {
  let key = e.which || e.keyCode; // keyCode detection
  let ctrl;
  if(e.ctrlKey){ // ctrl detection
    ctrl = e.ctrlKey
  } else {
    ctrl = ((key === 17))
  }
  let CommentTextLength = $('#comment').html().length;
  let Settings = JSON.parse(localStorage.getItem("settings"));
  let messageCharacterLimit = Settings.messageCharacterLimit;
  exceptKeyHandling(key,ctrl,CommentTextLength,messageCharacterLimit);

});

function msgSendingFunction(str){
  if (str.trim() != '') {
    if (str.length > 64000) {
      $("#long-message").show();
    } else {
      msgSendfunction();
      msgBoxDivHeight();
      $(document).trigger('click');
    }
  }
}

function msgSendfunction(){
  let str = $("#comment").html();
  let regex = /<br\s*\/?>/gi;
  str = str.replace(regex, "\n");
  $("#comment").html(str);
  str = $("#comment").text();
  storage.setCommentPosition(0);
  let ack = $("#ack").val();
  let randomValue = utils.cryptoRandomGenerate();

  let sendChatObj = {
    msg         	   :str.trim(),
    messagetype      :'text',
    messageid        :randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9),
    filesize         :'',
    options    	     :'',
    touser  	       :'',
    ack              :ack,
    forwardUserName  :'',
    forwardUserImage :'',
  }

  sendMessage.sendChatMessages(sendChatObj);

  if($('.side-profile').css('left') == '0px' || $('.side-two').css('left') == '0px' || $('.side-global-contact-list').css('left') == '0px' || $('.side-role').css('left') == '0px' || $('.side-global-role-list').css('left') == '0px' || $('.side-group').css('left') == '0px' || $('.side-global-team-contact-list').css('left') == '0px' || $('.side-settings').css('left') == '0px' || $('.blockedlist-sidenav').css('left') == '0px' || $('.locationid-list-sidenav').css('left') == '0px'){
    
    $('.filter.plus-circles').hide();
  
  }
 
}

function msgBoxDivHeight(){
  let divJid,height;

  if ($("#comment").height() >= 20) {
    divJid = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
    $("[id='conversation"+divJid+"']").css({ height: "calc(100% - 135px)" });
    let MessageHeight = $("[id='conversation"+divJid+"']").height() - $("#comment").height() + 30;
    $("[id='conversation"+divJid+"']").height(MessageHeight);
    height = 0;
    $("[id='conversation"+divJid+"']").find('.row').each(function (i, value) {
      height += parseInt($(this).height());
    });
    $("#messageemoji").find('.emojiPicker').css({ top: -349 - $("#comment").height() + 30 });
    $("[id='conversation"+divJid+"']").scrollTop(height);
    $(".reply-content").css({ bottom: $("#comment").height() + 65 });
  } else {
    $("[id='conversation"+divJid+"']").css({ height: "calc(100% - 135px)" });
    $(".reply-content").css({ bottom: "75px" });
  }
}

$("#comment").keypress(function (e) {
  if (e.keyCode == 13) {
    let str = $("#comment").text();
    if (e.shiftKey) {
      if (str == '') {
        e.preventDefault();
      } else {
        e.stopPropagation();
      }
    } else {
      e.preventDefault();
      if($('#long-message').is(':visible')){
        e.stopPropagation();

      }
      else {
        msgSendingFunction(str)
      }
     
    }
  } else {
    sendMessage.sendTypingStatus();
  }
});

$("#rolesText").keyup(function (e) {
  $(".sideRoleNavLoader").show()
  let txt = $(this).val();
  let content = txt.trim();
  let siteId;
  let locationId = JSON.parse(localStorage.getItem("locationId"));        
  if(locationId != null && typeof locationId != "undefined"){
    siteId = locationId.mysite_role;
  }else {
    siteId = $('#siteDetails').text();
  }

  $("#noroster").remove();
  clearTimeout(rolesTimeout);
  rolesTimeout = setTimeout(() => {
    roles.mySiteRoleSearchHandling(siteId,content);
    
  }, 700);
  if (content == '') {
    $("#roles").find('.mCSB_container').children().show();
    $(".searchRole").show();
    $("#searchRoleClose").hide();
  } else {
    $(".searchRole").hide();
    $("#searchRoleClose").show();
    $("#noroster").remove();
    $("#roles").find('.mCSB_container').children().hide();
    $("#roles").find('.sideBar-body').each(function () {
      let rosterval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (rosterval.indexOf(searchtxt) != -1) {
        $("#noroster").remove();
        $(this).show();
      }
    });
  }
});

//on keydown, clear the countdown 
$("#rolesText").on('keydown', function () {
  clearTimeout(rolesTimeout);
});

$("#long-message-send").click(function () {
  $("#long-message").hide();
});
$("#long-message-close").click(function () {
  $("#long-message").hide();
});

function selectedUserInfoUpdate(selectedUsers,is_checked,id,name,image){
  let selecteduserInfo = {};

  if (selectedUsers != null) {
    if (typeof id != 'undefined') {
      selecteduserInfo[id] = [];
      selecteduserInfo[id].push(id);
      selecteduserInfo[id].push(name.trim());
      selecteduserInfo[id].push(image);
    }
    if(is_checked)
      Object.assign(selectedUsers, selecteduserInfo);
    else
      delete selectedUsers[id];
    
    if(Object.keys(selectedUsers).length > 0){
      localStorage.setItem("selecteduserInfo", JSON.stringify(selectedUsers));
    }else {
      delete localStorage.selecteduserInfo;
    }
  }else {
    if (typeof id != 'undefined') {
        selecteduserInfo[id] = [];
        selecteduserInfo[id].push(id);
        selecteduserInfo[id].push(name.trim());
        selecteduserInfo[id].push(image);
        localStorage.setItem("selecteduserInfo", JSON.stringify(selecteduserInfo));
    }
  }
}

$("#participantcontact").off('click').on('click', '.contactfwdcontent', function () {
  toJID = JSON.parse(localStorage.getItem("toJID"));
  let isBroadcast = $('.add-participant-msg-send').attr('data-isbroadcast');
  let groupsDetail = JSON.parse(localStorage.getItem("groupDetails"));
  let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
  let groupMembersCount;
  if(isBroadcast == 1){
    groupMembersCount = _.size(broadcastDetails[toJID.split("@")[0]]);

  }else {
    groupMembersCount = _.size(groupsDetail[toJID.split("@")[0]]);

  }
  let selectedUsersCount = _.size(JSON.parse(localStorage.getItem("selecteduserInfo")));

  if ((groupMembersCount + selectedUsersCount) == language.locali().group_limit) {
    $("#infoContent").text(language.locali().limit_exceeded);
    $('#infobox').css("display", "block").delay(4000).fadeOut();
  } else {
    let result = $(this).attr("id").split(/-(.+)/);
    let selectedId = result[1];
    if ($(".contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked")) {
      $(".contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", false).change();
    } else {
      $(".contactfwdcontent").find("input[type=checkbox][value='" + selectedId + "']").prop("checked", true).change();
    }
  }
  let selectedUsers = JSON.parse(localStorage.getItem("selecteduserInfo"));
  delete localStorage.selecteduserInfo;

  if(typeof($(this).find('.select-message3')[0]) == 'undefined'){
    $("#teamCreateUserAlert").addClass('open');
    return false;
  }
  let is_checked = $(this).find('.select-message3')[0].checked;
  let id = $(this).find('.select-message3').val();
  let name = $(this).find('.select-message3').attr('name');
  let image = $(this).find('.select-message3').attr('id');

  selectedUserInfoUpdate(selectedUsers,is_checked,id,name,image);

});

$("#recent-contact").on('change', '.select-message3', function(e) {
  let tabType = $('.fwdContactTab.active').attr('data-tabtype');
  if(tabType == 'recent-contact'){
    delete localStorage.selecteduserInfo;
    let selecteduserInfo = {};
    let index = 0;
    $(document).find('#recent-contact .contactcontent .select-message3').each(function () {
        let id = (this.checked ? $(this).val() : "");
        let name = (this.checked ? $(this).attr('name') : "");
        let image = (this.checked ? $(this).attr('id') : "");
        if (id != '') {
            selecteduserInfo[index] = [];
            selecteduserInfo[index].push(id);
            selecteduserInfo[index].push(name);
            selecteduserInfo[index].push(image);
            localStorage.setItem("selecteduserInfo", JSON.stringify(selecteduserInfo));
            index++;
        }
    });
  }

});

$("#contact").on('change', '.select-message3', function(e) {
  let tabType = $('.fwdContactTab.active').attr('data-tabtype');

  if(tabType == 'contact'){
    delete localStorage.selecteduserInfo;
    let selecteduserInfo = {};
    let index = 0;
    $(document).find('#contact .contactfwdcontent .select-message3').each(function () {
        let id = (this.checked ? $(this).val() : "");
        let name = (this.checked ? $(this).attr('name') : "");
        let image = (this.checked ? $(this).attr('id') : "");
        if (id != '') {
            selecteduserInfo[index] = [];
            selecteduserInfo[index].push(id);
            selecteduserInfo[index].push(name);
            selecteduserInfo[index].push(image);
            localStorage.setItem("selecteduserInfo", JSON.stringify(selecteduserInfo));
            index++;
        }
    });
  }
  
});

/**  Add Participant For Existing Team  My site Contact Click Event **/

$('.mySiteExistTeam').click(function () {
  
  let locationId = JSON.parse(localStorage.getItem("locationId"));  
  let mySiteExistTeamLocationId,siteId;     
  if(locationId != null && typeof locationId != "undefined"){
    mySiteExistTeamLocationId = locationId.mysite_contact;
  }else {
    mySiteExistTeamLocationId = $('#siteDetails').text();
  }
  siteId = mySiteExistTeamLocationId;
  
  let mySiteQueryParams;
  $('.globalSiteExistTeam').removeClass('active');
  $('#partticipantText').attr("placeholder", "Search my site contacts");
  $(this).addClass('active');
  $("#partticipantText").val('');

  let search =   $("#partticipantText").val();
  if (search == "") 
    mySiteQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';
  else 
    mySiteQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + search;
 
  contact.getExistingGroupContacts(mySiteQueryParams);
  $("#participantcontact").scrollTop(0)
});

/**  Add Participant For Existing Team  Global site Contact Click Event **/

$('.globalSiteExistTeam').click(function () {
  $('.mySiteExistTeam').removeClass('active');
  $('#partticipantText').attr("placeholder", "Search contacts");
  $("#partticipantText").val('');
  let globalSiteQueryParams;
  let search = $("#partticipantText").val();
  if (search == ""){
    globalSiteQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&page=1';
  } else {
    globalSiteQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&page=1&search=' + search;
  }
  
    contact.getExistingGroupContacts(globalSiteQueryParams);

    $("#participantcontact").scrollTop(0)

});



$(".compose-msg").click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();

  $('#recentchat').removeClass('pencil-icon-overlay')
  $("#opened-side-profile").text("contacts");
  $('.chat-option').removeClass('menu');
  $("#noroster").remove();
  $("#sitesText").val("");
  $(".searchContact").show();
  $("#searchContactClose").hide();
  $(".header-second .prof-opt").removeClass("open");
  let locationId = JSON.parse(localStorage.getItem("locationId"));  
  let composeMsgLocationId;      
  if(locationId != null && typeof locationId != "undefined"){
    composeMsgLocationId = locationId.mysite_contact;
  }else {
    composeMsgLocationId = $('#siteDetails').text();
  }

  let composeMsgQueryParams = '',
      page              = 1,
      siteId            = composeMsgLocationId;


  composeMsgQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=&siteId=' + siteId + '&page='+page+'';
  $("#page").val(page);
  contact.getMessageContacts(composeMsgQueryParams);

  globalContactEventTriggerFun();
});

$('#user_on_off').on('click', function (e) {
  e.preventDefault();
  e.stopPropagation();
  let userId = stropheConnection.logininfo.userJID;
  $("#on-off-msg .oncall-rolelist tbody").html('');
  if($('#checkbox-switch').prop('checked')){
    let activeOncalls = userOncalls.getOncallNames(userId, 1, 1, 0, '');
    let oncallListDiv = '';

    if(activeOncalls.length == 0) {
      $(".offline-popup").css("display", "none");
      $(".online-popup").css("display", "block");
      $('.alert-msg').html('Please confirm you wish to go offline. In offline mode, you will still receive messages, but notifications will be turned off. Other Connect users cannot call you while you are offline.');
    } else {
      let thead = '<tr><th> Role </th><th> Status</th><tr>';
      $('.offline-popup thead').children().remove();
      $('.offline-popup thead').append(thead)
      $(".online-popup").css("display", "none");
      $(".offline-popup").css("display", "block");
      $.each(activeOncalls , function(index, val) {
        oncallListDiv += '<tr><td>'+val+'</td><td><span class="highlighted">On Call</span></td></tr>';
      });
      $("#on-off-msg .oncall-rolelist tbody").append(oncallListDiv);
    }
    
    $('#on-off-msg').addClass("open"); 
    $('.slider-online').show();
    $('.slider-offline').hide();
  } else {
    $('.slider-online').show();
    $('.slider-offline').hide();
    $(".conform-on-off").trigger('click')
   
  }
});
$(".cancel-on").click(function () {
  if($('#checkbox-switch').prop('checked')){
    $('#checkbox-switch').prop('checked', true);
    $('.slider-online').show();
    $('.slider-offline').hide();
  }else{
    $('#checkbox-switch').prop('checked', false);
    $('.slider-online').hide();
    $('.slider-offline').show();
  }
  $("#on-off-msg").removeClass("open");
});
$(".conform-on-off").click(function () {
  let myProfileInfo = JSON.parse(localStorage.getItem("myInfo"));
  user = stropheConnection.logininfo.userJID;
  let username = myProfileInfo[user][1] == $('#profileName').html()?myProfileInfo[user][1]:$('#profileName').html();
  let image = myProfileInfo[user][2];
  let searchparticipant = $('#checkbox-switch').prop('checked')?0:1;
  localStorage.setItem("myStatus", searchparticipant);
  $('#checkbox-switch').prop('checked')?$('.status-edit').css('display','block'):$('.status-edit').css('display','none');

  let setVcardNameList = { displayname : username, userimage : image, userstatus : searchparticipant, role : myProfileInfo[user][6], department : myProfileInfo[user][9], siteId : myProfileInfo[user][13], mobileNumber : myProfileInfo[user][4], workNumber : myProfileInfo[user][10],pagerNumber : myProfileInfo[user][11], nodes : myProfileInfo[user][15], personalMobileNumber : myProfileInfo[user][16]
  }

  vCard.setVcard(setVcardNameList);

  $("#on-off-msg").removeClass("open");
  if($('#checkbox-switch').prop('checked')) {
    $('.slider-online').hide();
    $('.slider-offline').show();
  }else {
  
    $('.slider-online').show();
    $('.slider-offline').hide();
  }
});

$(".oncall-msg").click(function (e) {
  e.stopPropagation()

  $('#recentchat').removeClass('pencil-icon-overlay')
  $("#opened-side-profile").text("roles");
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none');
  $('#rolesText').val('');
  $('#rolesSitesText').val('');
  $(".searchRole").show();
  $("#searchRoleClose").hide();

  $('.rolesFilter').removeClass('unitfilter');
  $('.rolesFilter').removeClass('alapfilter');
  $('.rolesFilterUnitIcon').addClass('unitfilter');
  $('.rolesFilterAlphaIcon').addClass('alapfilter');

  let locationId = JSON.parse(localStorage.getItem("locationId"));
  let siteId;        
  if(locationId != null && typeof locationId != "undefined"){
    siteId = locationId.mysite_role;
  }else {
    siteId = $('#siteDetails').text();
  }
  let oncallObj = {
    siteId      :siteId,
    search      :'',
    page        :1,
    isEmpty     :1,
    elementName :'roles',
    type        :'',
    unitName    :'',

  }

  roles.getOnCalls(oncallObj);


  $(".side-group").css({
    "display": "none"
  });
  $(".profile-group").css({
    "display": "none"
  });
  $(".side-settings").css({
    "display": "none"
  });
  $(".bot-settings").css({
    "display": "none"
  });
  $('.stared-list').css({
    "display": "none"
  });
  $(".blockedlist-sidenav").css({
    "display": "none"
  });
  $(".side-settings").css({
    "display": "none"
  });
  $(".side-profile").css({
    "display": "none"
  });
  $(".side-two").css({
    "display": "none"
  });
  $(".side-role").css({
    "left": "0",
    "display": "block"
  });
  $(".mySiteRoleTab").trigger('click');
});
$(".newMessage-back").click(function () {
  let groupaddUserInfo = {};
  $(".contact-bubble-block").html('');
  
  delete localStorage.isSetRoleSitePreference;

  localStorage.setItem("groupaddUserInfo", JSON.stringify(groupaddUserInfo));
  displaycontrol();
  $(".side-two").css({
    "left": "-100%"
  });
  $('.side-set').trigger('click');
});
function profileEditabilityHideShow(profileEditability){
  if((typeof profileEditability != "undefined")) { 
    profileEditabilityShow(profileEditability);
  }else {
    $('.mobileNumberEdit').hide();
    $('.personalMobileNumberEdit').hide();
    $('.WorkTelephoneEdit').hide();
    $('.PagerNumberEdit').hide();
  }
}

function profileEditabilityShow(profileEditability){
  if((typeof profileEditability.mobile_number != "undefined") && profileEditability.mobile_number)
  $('.mobileNumberEdit').show();
  else
  $('.mobileNumberEdit').hide();
  if((typeof profileEditability.personal_mobile_number != "undefined") && profileEditability.personal_mobile_number)
  $('.personalMobileNumberEdit').show();
  else
  $('.personalMobileNumberEdit').hide();
  if((typeof profileEditability.work_telephone != "undefined") && profileEditability.work_telephone)
  $('.WorkTelephoneEdit').show();
  else
  $('.WorkTelephoneEdit').hide();
  if((typeof profileEditability.pager_number != "undefined") && profileEditability.pager_number)
  $('.PagerNumberEdit').show();
  else
  $('.PagerNumberEdit').hide();
}

function chatOptionDesingFun(){
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none')
    
  $(".side-group").css({
    "display": "none"
  });
  $(".profile-group").css({
    "display": "none"
  });
  $(".side-settings").css({
    "display": "none"
  });
  $(".side-two").css({
    "display": "none"
  });
  $('.stared-list').css({
    "display": "none"
  });
  $(".blockedlist-sidenav").css({
    "display": "none"
  });
  $(".message-info-block").css({
    "right": "-100%"
  });
  $(".side-frd-profile").css({
    "right": "-100%"
  });
  $(".side-profile").css({
    "left": "0",
    "display": "block"
  });
}
function commonNotePaddingSet(profileInfo){
  if(profileInfo.commNotes == '') 
    $('#profile').find('.profile-cmnt').css('padding','20px 0');
  else 
    $('#profile').find('.profile-cmnt').css('padding','10px 0');
}
function profileMobilePaddingSet(profileInfo){
  if(profileInfo.mobile == '') 
  $('#profile').find('.profile-mobile').css('padding','20px 0');
else 
  $('#profile').find('.profile-mobile').css('padding','10px 0');
}

function profilePersonalMobilePaddingSet(profileInfo){
  if(profileInfo.personalMobNo == '') 
    $('#profile').find('.profile-personal-mobile').css('padding','20px 0');
  else 
    $('#profile').find('.profile-personal-mobile').css('padding','10px 0');
}

function profileTelephonePaddingSet(profileInfo){
  if(profileInfo.telephone == '') 
    $('#profile').find('.profile-telephone').css('padding','20px 0');
  else
    $('#profile').find('.profile-telephone').css('padding','10px 0');
}

function profilePagerPaddingSet(profileInfo){
  if(profileInfo.pager == '') 
    $('#profile').find('.profile-pager').css('padding','20px 0');
  else 
    $('#profile').find('.profile-pager').css('padding','10px 0');
}

function profileAddressPaddingSet(profileInfo){
  if(profileInfo.address == '') 
    $('#profile').find('.profile-address').css('padding','20px 0');
  else 
    $('#profile').find('.profile-address').css('padding','10px 0');
}

function userProfileDesign(profileInfo){
  commonNotePaddingSet(profileInfo)

  profileMobilePaddingSet(profileInfo)

  profilePersonalMobilePaddingSet(profileInfo)
  
  profileTelephonePaddingSet(profileInfo)

  profilePagerPaddingSet(profileInfo)

  profileAddressPaddingSet(profileInfo)
  
  if(profileInfo.loc == '') 
    $('#profile').find('.profile-loc').css('padding','20px 0');
  else 
    $('#profile').find('.profile-loc').css('padding','10px 0');
  if(profileInfo.subDept == '') 
    $('#profile').find('.profile-subDept').css('padding','20px 0');
  else 
    $('#profile').find('.profile-subDept').css('padding','10px 0');
}

function userProfileSecondDesign(profileInfo){
  $('#profile').find(".profile-name").html(minEmoji(profileInfo.nickname));
  $('#profile').find(".profile-email").html('<a href="mailto:'+minEmoji(profileInfo.email)+'">'+minEmoji(profileInfo.email)+'</a>');
  $('#profile').find(".profile-mobile").html(minEmoji(profileInfo.mobile));
  $('#profile').find('.profile-mobile').css('color','#808080')
  $('#profile').find('#mobile-icon-normal').css('display','block');
  $('#profile').find('#mobile-icon-error').css('display','none');
  if(profileInfo.status == 0){
    $('#profile').find(".profile-status").html('Offline');
    $('#profile').find(".profile-status").css("color", '#e90e18');
    $('#profile').find(".profile-status").attr("data-id", profileInfo.status);
  }else {
    $('#profile').find(".profile-status").html(minEmoji(utils.getStatusName(profileInfo.status)));
    $('#profile').find(".profile-status").css("color", utils.getStatusImage(profileInfo.status));
    $('#profile').find(".profile-status").attr("data-id", profileInfo.status);
  }
  $('#profile').find(".profile-role").html(minEmoji(profileInfo.role));
  $('#profile').find(".profile-address").html(minEmoji(profileInfo.address));
  $('#profile').find(".profile-loc").html(minEmoji(profileInfo.loc));
  $('#profile').find(".profile-subDept").html(minEmoji(profileInfo.subDept));
  $('#profile').find(".profile-telephone").html(minEmoji(profileInfo.telephone));
  $('#profile').find('.profile-telephone').css('color','#808080')
  $('#profile').find('#telephone-icon-normal').css('display','block');
  $('#profile').find('#telephone-icon-error').css('display','none');
  $('#profile').find(".profile-personal-mobile").html(minEmoji(profileInfo.personalMobNo));
  $('#profile').find('.profile-personal-mobile').css('color','#808080')
  $('#profile').find('#personal-mobile-normal').css('display','block');
  $('#profile').find('#personal-mobile-error').css('display','none');
  $('#profile').find(".profile-pager").html(minEmoji(profileInfo.pager));
  $('#profile').find('.profile-pager').css('color','#808080')
  $('#profile').find('#pager-icon-normal').css('display','block');
  $('#profile').find('#pager-icon-error').css('display','none');
}

function userProfileThirdDesign(profileInfo){
  $("#profilename").val(profileInfo.nickname);
  $("#mystatus").val(profileInfo.status);
  $("#myrole").val(profileInfo.role);
  $("#myaddress").val(profileInfo.address);
  $("#myloc").val(profileInfo.loc);
  $("#mysubDept").val(profileInfo.subDept);
  $("#mytelephone").val(profileInfo.telephone);
  $("#mypager").val(profileInfo.pager);
  $(".profile-cmnt").html(profileInfo.commNotes)
  $('.profile-name').css('display', 'block');
  $('.get-my-name').css('display', 'none');
  $('.char-counter').css('display', 'none');
  $('.profile-status').css('display', 'block');
  $('.get-my-status').css('display', 'none');
  $('.profile-role').css('display', 'block');
  $('.get-my-role').css('display', 'none');
  $('.profile-responsibility').css('display', 'block');
  $('.get-my-responsibility').css('display', 'none');
  $('.profile-address').css('display', 'block');
  $('.get-my-address').css('display', 'none');
  $('.profile-subDept').css('display', 'block');
  $('.get-my-subDept').css('display', 'none');
  $('.profile-loc').css('display', 'block');
  $('.get-my-loc').css('display', 'none');
  $('.profile-telephone').css('display', 'block');
  $('.get-my-telephone').css('display', 'none');
  $('.profile-pager').css('display', 'block');
  $('.get-my-pager').css('display', 'none');
  $('.nameEdit').css('display', 'block');
  $('.statusEdit').css('display', 'block');
  $('.status-char-counter').css('display', 'none');
  $('.roleEdit').css('display', 'block');
  $('.role-char-counter').css('display', 'none');
  $('.responsibilityEdit').css('display', 'block');
  $('.responsibility-char-counter').css('display', 'none');
  $('.addressEdit').css('display', 'block');
  $('.address-char-counter').css('display', 'none');
  $('.locEdit').css('display', 'block');
  $('.subDeptEdit').css('display', 'block');
  $('.loc-char-counter').css('display', 'none');
  $('.subDept-char-counter').css('display', 'none');
  $('.telephoneEdit').css('display', 'block');
  $('.telephone-char-counter').css('display', 'none');
  $('.pagerEdit').css('display', 'block');
  $('.pager-char-counter').css('display', 'none');
  $(".conversation").removeClass("small-pane");
}
function userProfileImgTextFun(profileInfo){
  let concatedLetter;
  if(profileInfo.profileImage !== 'src/images/avatar/chat/profile_img_one.svg' && profileInfo.profileImage.trim() !== ''){
    $('.profileImageParentEl svg').hide();
    $('.profileImageParentEl img').show();
  }else {
    $('.profileImageParentEl svg').show();
    $('.profileImageParentEl img').hide();
    if(profileInfo.nickname != "") {
      concatedLetter = utils.getImageDisplayText(profileInfo.nickname);
      $('.profileImageParentEl svg text').text(concatedLetter)
    }
  }
}

function getProfileInfoNickname(myInfo){
  let nickname = ((typeof myInfo[userJID][1] == "undefined") || myInfo[userJID][1] == "") ? "" : myInfo[userJID][1];
  return nickname;
}

function getProfileInfoMobile(myInfo){
  let mobile = ((typeof myInfo[userJID][4] == "undefined") || myInfo[userJID][4] == "") ? "" : myInfo[userJID][4];
  return mobile;
}

function getProfileInfoRole(myInfo){
  let role = ((typeof myInfo[userJID][6] == "undefined") || myInfo[userJID][6] == "") ? "" : myInfo[userJID][6];
  return role;
}

function getProfileInfoAddress(myInfo){
  let address = ((typeof myInfo[userJID][8] == "undefined") || myInfo[userJID][8] == "") ? "" : myInfo[userJID][8];
  return address;
}

function getProfileInfoLocation(myInfo){
  let loc = ((typeof myInfo[userJID][9] == "undefined") || myInfo[userJID][9] == "") ? "" : myInfo[userJID][9];
  return loc;
}

function getProfileInfoTelephone(myInfo){
  let telephone = ((typeof myInfo[userJID][10] == "undefined") || myInfo[userJID][10] == "") ? "" : myInfo[userJID][10];
  return telephone;
}

function getProfileInfoPager(myInfo){
  let pager = ((typeof myInfo[userJID][11] == "undefined") || myInfo[userJID][11] == "") ? "" : myInfo[userJID][11];
  return pager;
}

function profileInfoObj(){
  let myInfo = JSON.parse(localStorage.getItem("myInfo"));
  let email = myInfo[userJID][5];
  let status = myInfo[userJID][3];
  let nickname = getProfileInfoNickname(myInfo);
  let mobile = getProfileInfoMobile(myInfo);
  let role = getProfileInfoRole(myInfo);
  let address = getProfileInfoAddress(myInfo);
  let loc = getProfileInfoLocation(myInfo);
  let telephone = getProfileInfoTelephone(myInfo);
  let pager = getProfileInfoPager(myInfo);
  let subDept = ((typeof myInfo[userJID][12] == "undefined") || myInfo[userJID][12] == "") ? "" : myInfo[userJID][12];
  let commNotes = ((typeof myInfo[userJID][15] == "undefined") || myInfo[userJID][15] == "") ? "" : myInfo[userJID][15];
  let personalMobNo = ((typeof myInfo[userJID][16] == "undefined") || myInfo[userJID][16] == "") ? "" : myInfo[userJID][16];
  let profileImage = ((typeof myInfo[userJID][2] == "undefined") || myInfo[userJID][2] == "") ? "" : myInfo[userJID][2];

  let profileInfo = {
    email         :  email,
    status        :  status,
    nickname      :  nickname,
    mobile        :  mobile,
    role          :  role,
    address       :  address,
    loc           :  loc,
    telephone     :  telephone,
    pager         :  pager,
    subDept       :  subDept,
    commNotes     :  commNotes,
    personalMobNo :  personalMobNo,
    profileImage  :  profileImage,
  }
  return profileInfo;
}
$(".heading-avatar-img").click(function () {
  let setting = JSON.parse(localStorage.getItem("settings"));
  let profileEditability = setting.profileEditability;
  let userToken = storage.getuserToken();
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');

  //Profile Editability Icon Show and Hide
  profileEditabilityHideShow(profileEditability)
  /** Chat option icons desing part**/
  chatOptionDesingFun()
  /** Profile Info Obj Value **/
  let profileInfo = profileInfoObj();

  if(profileInfo.profileImage != '' && profileInfo.profileImage != 'src/images/avatar/chat/profile_img_one.svg'){
    if($('#profile .profile-image:visible').length > 0){
      let currentImg =mediaUrl+ profileInfo.profileImage+"?mf="+userToken;
      utils.imageTokenRegenerate(currentImg,'#leftSideProfileInfoImgId',userJID,'profileImages');
    }
  }

  /** User profile design **/
  userProfileDesign(profileInfo);
  /** User profile design **/
  userProfileSecondDesign(profileInfo);
  /** User profile design **/
  userProfileThirdDesign(profileInfo);
  /** Profile Image Text  **/
  userProfileImgTextFun(profileInfo)
});
$('#profile-click').click(function (e) {
  e.preventDefault();
  e.stopPropagation()
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.heading-avatar-img').trigger('click');
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none')

  $(this).parent().parent().siblings().attr('aria-expanded', false);
  $(this).parent().parent().parent().removeClass('open');
});

$(".sideBar-body").click(function () {
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display', 'block')
});
$(".side-set").click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none')
  $(".side-group").css({
    "display": "none"
  });
  $(".profile-group").css({
    "display": "none"
  });
  $(".side-profile").css({
    "display": "none"
  });
  $(".side-two").css({
    "display": "none"
  });
  $(".bot-settings").css({
    "display": "none"
  });
  $('.stared-list').css({
    "display": "none"
  });
  $(".side-settings").css({
    "left": "0",
    "display": "block"
  });

  $(this).parent().parent().siblings().attr('aria-expanded', false);
  $(this).parent().parent().parent().removeClass('open');
  /** Left side setting page Profile **/
  let myProfileInfo = JSON.parse(localStorage.getItem("myInfo"));
  let user = stropheConnection.logininfo.userJID;
  let name = (myProfileInfo[user] != "") ? myProfileInfo[user][1] : '';
  let image = (myProfileInfo[user] != "") ? myProfileInfo[user][2] : '';
  if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
    $('.set-prof-pic img').show();
    $('.set-prof-pic svg').hide();
  }else {
    let concatedLetter;
    if(name != "") {
        concatedLetter = utils.getImageDisplayText(name);
    }
    $('.set-prof-pic img').hide();
    $('.set-prof-pic svg').show();
    $('.set-prof-pic svg text').text(concatedLetter);

  }

});
$("#star-click").click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('.sideStarListNavLoader').show();
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none')
  $(".side-group").css({
    "display": "none"
  });
  $(".profile-group").css({
    "display": "none"
  });
  $(".side-profile").css({
    "display": "none"
  });
  $(".side-two").css({
    "display": "none"
  });
  $("#recentchat").css({
    "display": "none"
  });
  $(".bot-settings").css({
    "display": "none"
  });
  $('.stared-list').css({
    "left": "0",
    "top": "0px",
    "display": "block"
  });
  $(".side-settings").css({

    "display": "none"
  });
  $('.unstardrop-all').find('.prof-opt').removeClass('open')
  $(this).parent().parent().siblings().attr('aria-expanded', false);
  $(this).parent().parent().parent().removeClass('open');
  /** Update Favorite Message Start **/
  let updateStarmessage = stropheConnection
  .StropheJs()
  .$iq({
      type: "set",
      id: utils.getId(),
      to: stropheConnection.Server()
  })
  .c("query", {
      xmlns: "jabber:iq:user_fav",
      status: "update_favourite_message",
  });
  if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().send(updateStarmessage);
  }
  setTimeout(() => {
    
    $('.sideStarListNavLoader').hide();
  }, 1500);

/** Update Favorite Message End **/
});
$(".roles-back , .contacts-back ,.star-back,.settings-back,.participants-back,.broadcast-participants-back,.oncall-roles-back").click(function () {
  $('.plus-circles').css('display','block');
})

function sideGroupSecondDesignFun(){
  $(".participantcontent").css({
    "display": "flex"
  });
  $(".contact-bubble-block").html('');
  $('.group-sec').addClass('show');
  $(".group-sec").removeClass("hide");
  $('.group-profile').addClass('hide');
  $('.group-imageinputarea').attr('src', './src/images/avatar/group/group_four.svg');
}
function participantContentAddUserInfoEmptyCheck(groupaddUserInfo){
  if (groupaddUserInfo == null) {
    groupaddUserInfo = {};
  }

  return groupaddUserInfo;
}

function participantContentHeaderTabCheck(elementId){
  let is_header_tab = (elementId == '#globalTeamContactLists') ? true : false;

  return is_header_tab;
}

function participantEventClickFun(){
  $(document).off('click');
  $(document).on('click', '.participantcontent', function () {
    if($('#bubble-block').children().length > 0){
      $('#participants').css('padding','0px')
      $('#participant-sites-info').css('padding','0px')
    }else {
      $('#participants').css('padding','1px')
      $('#participant-sites-info').css('padding','1px')
    }
    let parentEl = $(this).parent().parent().parent().attr('id');
    if(typeof parentEl == 'undefined'){
     parentEl = $(this).parent().attr('id');
    }
    let elementId = '#'+parentEl;
    let isChatUser = $(this).attr('data-ischatuser')
    let isUnregisterUser = $(this).attr('data-isunregisteruser')
    if(isChatUser == 0 || isUnregisterUser == 1){
      $("#teamCreateUserAlert").addClass('open');
      return false;
    }
    if ($('#bubble-block li').length == language.locali().group_limit) {
      $("#infoContent").text(language.locali().limit_exceeded);
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    } else {
      let txt = $('#searchparticipant').val();
      let content = txt.trim();
      let id = $(this).attr("id");
      let result = id.split(/-(.+)/);
      let contactName = $(this).find('.name-meta').text();
      let contactImage = $(this).find('img').attr('usemap');
      let blockListUserInfo = JSON.parse(localStorage.getItem("blockListUserInfo"));
      let nodeJid = result[1];
      let userInfo;
      let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
      let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
      if (blockUserInfo != null && typeof blockUserInfo[nodeJid] != "undefined") {
        userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let displayName = userInfo[nodeJid][1];
        content = "Unblock " + ' "' + displayName + '"?';
        $("#unblock-contact")
          .find(".popup-detail")
          .html(content);
        $("#unblock-contact").addClass("open");
        let unblockUserInfo = {};
        unblockUserInfo[0] = [];
        unblockUserInfo[0].push(nodeJid);
        localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));
      } else {
        $(this).css({
          display: "none"
        });
        groupaddUserInfo = participantContentAddUserInfoEmptyCheck(groupaddUserInfo)
        
        groupaddUserInfo[nodeJid] = [];
        groupaddUserInfo[nodeJid].push(nodeJid);
        groupaddUserInfo[nodeJid].push(contactName.trim());
        localStorage.setItem("groupaddUserInfo", JSON.stringify(groupaddUserInfo));

        contactImage = getContactImgForBlockedUser(blockListUserInfo,nodeJid)
      
        groupDesign.addParticipant(nodeJid, contactName, contactImage);
        contact.getNewGroupParticipantCheck(elementId);
        /** Participant Div Calculation and Update**/
        let is_header_tab = participantContentHeaderTabCheck(elementId); 
        let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId,is_header_tab);

        let newBrVisibleElLength = $(""+elementId+" .broadCastParticipantcontent:visible").length;
        let siteLength = $(""+elementId+" .sitecontent:visible").length;
  
        checkBroadcastEmptyEl(newBrVisibleElLength,siteLength,elementId)

        let newTeamVisibleLength = $(""+elementId+" .participantcontent:visible").length;
        
        checkTeamVisibleDivCheck(newTeamVisibleLength,siteLength,elementId)

        let osType = utils.getDeviceOsType();
        participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight);
        
        $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
        $(elementId).mCustomScrollbar("update");
      }
    }
  });
}
function getContactImgForBlockedUser(blockListUserInfo,nodeJid){
  let contactImage;

  if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
    contactImage = "";
  }

  return contactImage;
}

function checkBroadcastEmptyEl(newBrVisibleElLength,siteLength,elementId){
  if(newBrVisibleElLength == 0) {
    broadCastSplitFunction(siteLength,elementId)
  }
}

function checkTeamVisibleDivCheck(newTeamVisibleLength,siteLength,elementId){
  if(newTeamVisibleLength == 0) {
    particiaptnSplitFunction(siteLength,elementId)
  }else {
    $('#broadcast-participant-no-sites').hide()
  }
}

function broadcastParticipantDivDesignFunc(){
  if($('#broadcast-bubble-block').children().length > 0){
    $('#broadcast-participants').css('padding','0px')
    $('#broadcast-participant-sites-info').css('padding','0px')
  }else {
    $('#broadcast-participants').css('padding','1px')
    $('#broadcast-participant-sites-info').css('padding','1px')
  }
}
function broadCastParticipantEventClickFun(){
  $(document).off('click');
  $(document).on('click', '.broadCastParticipantcontent', function () {
    broadcastParticipantDivDesignFunc()
    let parentEl = $(this).parent().parent().parent().attr('id');
    if(typeof parentEl == 'undefined'){
      parentEl = $(this).parent().attr('id')
    }
    let elementId = '#'+parentEl;
    let isChatUser = $(this).attr('data-ischatuser')
    let isUnregisterUser = $(this).attr('data-isunregisteruser')
    if(isChatUser == 0 || isUnregisterUser == 1){
      $("#teamCreateUserAlert").addClass('open');
      return false;
    }
    if ($('#bubble-block li').length == language.locali().group_limit) {
      $("#infoContent").text(language.locali().limit_exceeded);
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    } else {
      let txt = $('#searchparticipant').val();
      let content = txt.trim();
      let id = $(this).attr("id");
      let result = id.split(/-(.+)/);
      let contactName = $(this).find('.name-meta').text();
      let contactImage = $(this).find('img').attr('usemap');
      let blockListUserInfo = JSON.parse(localStorage.getItem("blockListUserInfo"));
      let nodeJid = result[1];
      let userInfo;
      let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
      let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));
      if (blockUserInfo != null && typeof blockUserInfo[nodeJid] != "undefined") {
        userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let displayName = userInfo[nodeJid][1];
        content = "Unblock " + ' "' + displayName + '"?';
        $('.unBlockNodeJid').val(nodeJid)
        $("#unblock-contact")
          .find(".popup-detail")
          .html(content);
        $("#unblock-contact").addClass("open");
        let unblockUserInfo = {};
        unblockUserInfo[0] = [];
        unblockUserInfo[0].push(nodeJid);
        localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));
      } else {
        $(this).css({
          display: "none"
        });
        broadcastAddUserInfo = participantContentAddUserInfoEmptyCheck(broadcastAddUserInfo)
        
        broadcastAddUserInfo[nodeJid] = [];
        broadcastAddUserInfo[nodeJid].push(nodeJid);
        broadcastAddUserInfo[nodeJid].push(contactName.trim());
        localStorage.setItem("broadcastAddUserInfo", JSON.stringify(broadcastAddUserInfo));

        contactImage = getContactImgForBlockedUser(blockListUserInfo,nodeJid)

        groupDesign.addBroadcastParticipant(nodeJid, contactName, contactImage);
        contact.getNewBroadCastParticipantCheck(elementId);
        
        /** Participant Div Calculation and Update**/
        let is_header_tab = participantContentHeaderTabCheck(elementId); 
        let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
        let osType = utils.getDeviceOsType();

        participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight)
     

        $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
        $(elementId).mCustomScrollbar("update");
      }

      let newBrVisibleElLength = $(""+elementId+" .broadCastParticipantcontent:visible").length;
      let siteLength = $(""+elementId+" .sitecontent:visible").length;

      if(newBrVisibleElLength == 0) {
          broadCastSplitFunction(siteLength,elementId)
          
      }else {
          let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId);
          let osType = utils.getDeviceOsType();
          participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight)

          $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
          $(elementId).mCustomScrollbar("update");
      }

    }
  });
}

function broadCastSplitFunction(siteLength,elementId){
  if(siteLength == 0){
    $('.broadCastParticipantHeaderDiv').hide()
    $('#broadcast-participant-no-sites').show()
    
    if(elementId == "#globalBroadcastContactLists"){
      $('#broadcast-participant-no-sites').text(language.locali().no_contacts_found)

    }else {
      $('#broadcast-participant-no-sites').text(language.locali().nosite_or_contacts_found)

    }
  }else {
    $('#broadcast-participant-no-sites').hide()
  }
};

function particiaptnSplitFunction(siteLength,elementId){
  if(siteLength == 0){
    $('.participantHeaderDiv').hide()
    $('#broadcast-participant-no-sites').show()
    if(elementId == "#globalTeamContactLists"){
      $('#broadcast-participant-no-sites').text(language.locali().no_contacts_found)

    }else {
      $('#broadcast-participant-no-sites').text(language.locali().nosite_or_contacts_found)

    }
  }else {
    $('#broadcast-participant-no-sites').hide()
  }
};
$(".side-grp, .side-team").click(function (e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none');
  $("#opened-side-profile").text("team");

  let locationId = JSON.parse(localStorage.getItem("locationId"));   
  let siteId;     
  if(locationId != null && typeof locationId != "undefined"){
     siteId = locationId.mysite_contact;
  }else {
     siteId = $('#siteDetails').text();
  }

  let isChatUser = 1,page = 1;

  let  grpQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser='+isChatUser+'&siteId=' + siteId + '&page='+page+'';
  contact.getNewGroupContacts(grpQueryParams,'participants');

  
  delete localStorage.groupaddUserInfo;

  /** Side Group Desing **/
  sideGroupSecondDesignFun();
  
  participantEventClickFun()
  
  globalSiteParticipantEventTriggerFun();
  $('#broadcast-participant-no-sites').hide()

});

function sideBroadcastDesignFun(){
  $('.grp-filter').removeClass("menu");
  $("#broadcast-participants").scrollTop(0);
  $(".side-settings").css({
    "display": "none"
  });
  $(".side-profile").css({
    "display": "none"
  });
  $(".side-two").css({
    "display": "none"
  });
  $(".bot-settings").css({
    "display": "none"
  });
  $('.stared-list').css({
    "display": "none"
  });
  $(".side-broadcast").css({
    "left": "0",
    "display": "block"
  });
}
function sideBroadcastSecondDesignFun(){
  $(".broadCastParticipantcontent").css({
    "display": "flex"
  });
  $(".contact-bubble-block").html('');
  $('.group-sec').addClass('show');
  $(".group-sec").removeClass("hide");
  $('.group-profile').addClass('hide');
  $('.group-imageinputarea').attr('src', './src/images/avatar/broadcast/profile_img_one.png');
}

/** Broadcast Icon Click event Trigger Start **/
$(".side-broadcast-icon").click(function (e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  $(".broadcast-profile").removeClass('show');
  $(".broadcast-profile").addClass('hide');

  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');
  $('.plus-circles').css('display','none');
  $("#opened-side-profile").text("team");

  let locationId = JSON.parse(localStorage.getItem("locationId"));   
  let siteId;     
  if(locationId != null && typeof locationId != "undefined"){
     siteId = locationId.mysite_contact;
  }else {
     siteId = $('#siteDetails').text();
  }

  let isChatUser = 1,page = 1;

  let  grpQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser='+isChatUser+'&siteId=' + siteId + '&page='+page+'';
  contact.getNewBroadCastContacts(grpQueryParams,'broadcast-participants');

  
  delete localStorage.broadcastAddUserInfo;

  /** Side Broadcast Desing **/
  sideBroadcastSecondDesignFun();
  
  broadCastParticipantEventClickFun()
  
  globalSiteBroadcastParticipantTabEventTrigger();
  $('#broadcast-participant-no-sites').hide()

});
/** Broadcast Icon Click event Trigger End **/

$(".settings-back").click(function () {
  displaycontrol();
  $(".side-settings").css({
    "left": "-100%"
  });
});
$(".profile-back").click(function () {
  displaycontrol();
  
});

$(".star-back").click(function () {
  displaycontrol();
  $("#starSearch").val("");
  $("#starSearch").trigger("keyup");
  $(".stared-list").css({
    "left": "-100%"
  });
});
$(".contacts-back").click(function (e) {
  displaycontrol();
  $("#composeText").val("");
  $("#sitesText").val("");
  $(".side-two").css({
    "left": "-100%"
  });
});
$(".roles-back").click(function () {
  displaycontrol();
  $("#rolesText").val("");
  $(".side-role").css({
    "left": "-100%"
  });
  $(".side-settings").css({
    "left": "-100px",
    "display": "none"
  });
});
$(".oncall-roles-back").click(function () {
  displaycontrol();
  $("#rolesText").val("");
  $("#onCallrolesSearchTxt").val("");

  $(".side-role").css({
    "left": "-100%"
  });
});
$(".participants-back").click(function (e) {
  displaycontrol();
  $(".compose-sideBar").removeClass('bubbleadded');
  $(".compose-sideBar").removeClass('new-group-section');
  $('#participantSitesText').val('');
  $('#broadcast-participant-no-sites').hide()

  $(".side-group").css({
    "left": "-100%"
  });



});
$(".broadcast-participants-back").click(function () {
  displaycontrol();
  $(".compose-sideBar").removeClass('bubbleadded');
  $(".compose-sideBar").removeClass('new-group-section');
  $('#broadcastParticipantSitesText').val('');
  $('#broadcast-participant-no-sites').hide()

  $(".side-broadcast").css({
    "left": "-100%"
  });
});

$(".search-data").click(function () {
  $(".side-three").css({
    "right": "-70%",
    "width": "30%"
  });
});
$(".search-back").click(function () {
  $(".side-three").css({
    "right": "-100%"
  });
});

function rightsideProDesing(){
  $(".side-frd-profile").css({
    "right": "calc(-100% + 316px)",
    "width": "316px"
  });
  $(".message-info-block").css({
    "right": "-100%",
    "width": "316px",
    "top": "0"
  });
  $(".media-detail-section").css({
    "right": "-102%",
    "width": "30%",
    "top": "-100%"
  });
  $("#userprofile").css({
    "width": "100%",
    "display": "block",
  });
  $(".infohead").css({
    "display": "block",
  });
}
function rightSideUserBlobImageCheck(userInfo,toUser){
  let imageId = userInfo[toUser][2];
  let image;
  if (imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId !== '') {
    let userToken = storage.getuserToken();

    image = mediaUrl + imageId + "?mf=" + userToken;
    if (image.trim() != '' && image !== undefined) {
      $('#userprofile').find('.profile-inner').find('img').attr('id', 'rightSideUserInfoImg-'+toUser+'');
      indexDb.blobImageConversion(image,'#rightSideUserInfoImg-'+toUser+'',toUser,'profileImages');
    }
  }
}
function rightSideUserBlockCheck(toUser){
  let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
  if( (blockUserInfo != null && typeof blockUserInfo != "undefined") && typeof blockUserInfo[toUser] != "undefined" ){
    $('#blck-unblck-del .block-con').hide();
    $('#blck-unblck-del .unblock-con').show();
  }else {
    $('#blck-unblck-del .block-con').show();
    $('#blck-unblck-del .unblock-con').hide();
  }
}
function rightUserTypeCheck(userInfo,toUser){
  if(userInfo[toUser]){
    let isUnregister = userInfo[toUser][16];
    if(typeof isUnregister == "undefined" || isUnregister == 1){
      $('#blck-unblck-del .block-con').hide();
      $('#blck-unblck-del .unblock-con').hide();
    
    }else {
      
      rightSideUserBlockCheck(toUser)
      rightSideUserBlobImageCheck(userInfo,toUser)
    }
  }
}

$(".heading-name-meta, #right-side-prof").click(function () {
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');

  let toid = JSON.parse(localStorage.getItem("toJID"));
  let usersProfileInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
  let toUser = stropheConnection.Strophe().getNodeFromJid(toid);
  if ((toid != null && toUser == storage.adminName()) || (usersProfileInfo != null && typeof usersProfileInfo[toUser] != "undefined" && usersProfileInfo[toUser][7] == "1")) {
    return true;
  }
  let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
  let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
  let nodeJid = stropheConnection.Strophe().getNodeFromJid(toid)
  let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
  if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
    $('#groupparticipant').show();
 
  }
  if (!$(".conversation").hasClass("small-pane")) {
    $(".conversation").addClass("small-pane");
    setTimeout(function () {
      if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
        conversation.getBroadcastChatMedia(toid, '');
      } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        conversation.getSingleChatMedia(toid, '');
      } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
        conversation.getGroupChatMedia(toid, '');
      }
    }, 1000);

  }
  /** Right Side Profile Design **/
  rightsideProDesing()
  /** Right Side Profile User is Register or Blocked user check **/
  rightUserTypeCheck(userInfo,toUser)
});
$(".contact-info").click(function () {
  $('#right-side-prof').trigger('click');
  $(this).parent().parent().first().attr('aria-expanded', false);
  $(this).parent().parent().removeClass('open')
  $('#recentchat').removeClass('pencil-icon-overlay')
  $('.chat-option').removeClass('menu');

});

$("body").on('click', '#rightSideSvgprof', function (e) {
  $('#right-side-prof').trigger('click');
});

function displaycontrol() {
  $(".side-group").css({
    "display": "block"
  });
  $(".profile-group").css({
    "display": "block"
  });
  $(".side-settings").css({
    "display": "block"
  });
  $(".side-profile").css({
    "display": "block"
  });
  $(".bot-settings").css({
    "display": "block"
  });
  $('.stared-list').css({
    "display": "block"
  });
  $(".side-two").css({
    "display": "block"
  });
  $(".blockedlist-sidenav").css({
    "display": "block",
    "left": "-100%"
  });
  $("#recentchat").css({
    "display": "block"
  });

  $(".locationid-list-sidenav").css({
    "display": "block",
    "left": "-100%"
  });
  $(".side-oncall-role").css({
    "display": "block",
    "left": "-100%"
  });
  
}
groupDesign.triggerCameraPic();
groupDesign.groupFirstProfileUpload();
function logout() {
  setTimeout(function () {
    sessionStorage.clear();
    location.reload(true);
    window.location.replace(storage.getKeycloakLogoutURL());
  }, 1000)
}

$(".newgroup-create").click(function () {
  let s = $('#new-gp-name').val().trim();
  let tempStorage = JSON.parse(localStorage.getItem("temp"));
  if (s) {
    if (tempStorage) {
      tempStorage.name = s;
      localStorage.setItem("temp", JSON.stringify(tempStorage));
      groupDesign.createGroup();
      $('#new-gp-name').val('');
    } else {
      let temp = {};
      temp.name = s;
      temp.image = "empty";
      localStorage.setItem("temp", JSON.stringify(temp));
      groupDesign.createGroup();
      $('#new-gp-name').val('');
    }
    $(".group-profile").removeClass("show");
    $(".group-profile").addClass("hide");
    $(".side-group").css({
      "left": "-100%"
    });
    $('#participantSitesText').val('');
    $(".contact-bubble-block").html('');
    $('.plus-circles').css('display', 'block')
  } else {
    $("#infoContent").text(language.locali().entergroup_name);
    $('#infobox').css("display", "block").delay(4000).fadeOut();;
  }
});
/** New Broadcast Create Event Trigger Start **/
$(".newbroadcast-create").click(function () {
  let s = $('#new-broadcast-name').val().trim();
  let tempStorage = JSON.parse(localStorage.getItem("tempBroadcast"));
  if (s) {
    if (tempStorage) {
      tempStorage.name = s;
      localStorage.setItem("tempBroadcast", JSON.stringify(tempStorage));
      broadcast.createBroadcast();
      $('#new-broadcast-name').val('');
    } else {
      let temp = {};
      temp.name = s;
      temp.image = "empty";
      localStorage.setItem("tempBroadcast", JSON.stringify(temp));
      broadcast.createBroadcast();
      $('#new-broadcast-name').val('');
    }
    $(".group-profile").removeClass("show");
    $(".group-profile").addClass("hide");
    $(".side-broadcast").css({
      "left": "-100%"
    });
    $(".contact-bubble-block").html('');
    $('.plus-circles').css('display', 'block');
    $('#broadcastParticipantSitesText').val('');
  } else {
    $("#infoContent").text(language.locali().enterbroadcast_name);
    $('#infobox').css("display", "block").delay(4000).fadeOut();;
  }
});
/** New Broadcast Create Event Trigger End **/

function pagerNumericCheck(key){
  let ok;
  ok = (key == 8) || (key == 9) || (key == 13) || (key == 46) || (key == 110) || (key == 190) || (key >= 35 && key <= 40) || (key >= 96 && key <= 105);
  if (!ok){
    e.preventDefault();
  }
}

function pagerMsgKeyCheck(eventEl,filteredResults){
  if(filteredResults.length > 0){
    let key,k,ok;
    if(filteredResults[0].PagerType == 'Numeric'){
       key = eventEl.which;
       pagerNumericCheck(key);
    }
    else{
       k = eventEl.which;
       ok = (k >= 65 && k <= 90) || (k >= 96 && k <= 105) || (k >= 35 && k <= 40) || (k == 8) || (k >= 48 && k <= 57);
      if (!ok){
        eventEl.preventDefault();
      }
    }
  }
}

$(".pager_massage").on("keyup", function(e) {
  $('.msg_length').html(parseInt($(".pager_massage").val().length))
  $('.error_pager').html('')
  let pager = JSON.parse(localStorage.getItem("pager"));
  let userPager = parseInt($('#profile-pager-number').html());
  let filteredResults = pager.filter(key => key.LowerLimit <= userPager && key.UpperLimit >=userPager)
  let eventEl = e;
  pagerMsgKeyCheck(eventEl,filteredResults);
  
});
$("body").on('click', "#reset_login", function (e) {
  window.location.replace(storage.getKeycloakLogoutURL());
});

$("body").on('click', "#pager_id,.pager_btn", function (e) {
    let pagerSettings = JSON.parse(localStorage.getItem("settings"));
    $('.pager_length').text(pagerSettings.pagerMaxCharLimit);
    $('.pager_massage').attr('maxLength',pagerSettings.pagerMaxCharLimit).attr('minLength',pagerSettings.pagerMinCharLimit)
});

$("body").on('click', ".pager_cancel", function (e) {
  $('.pager_massage').val('');
  $('.msg_length').html(0)
  $('.error_pager').html('')
});
$("body").on('click', function (e) {
  if ($('.attachment').hasClass('hov')) {
    $("#attachmentDiv").removeClass("hov");
    $('.attachmentclass').css('display','none')
  }
});
$(".attachment").on('click', function (e) {
  if($("#attachmentDiv").hasClass("hov")) {
    $('.attachmentclass').css('display','block')
    $("#attachmentDiv").addClass("hov");
    return;
  }
  $("#attachmentDiv").removeClass("hov");
  $('.attachmentclass').css('display','none')  
});
$("body").on('click', ".pager_ok", function (e) {
  let pagerSettings = JSON.parse(localStorage.getItem("settings"));
  let isReceivedDevice = $('#isReceivedDevice').attr('data-isreceiveddevice');
  if(isReceivedDevice == 1){
    if($(".pager_massage").val().length < 1){
      $(".error_pager").text('Enter minimum 1 character');
    
    }else {
      pagerMsg.postPagerMassage()

    }
  }else {
    if($(".pager_massage").val().length < pagerSettings.pagerMinCharLimit){
      if(pagerSettings.pagerMinCharLimit == 1){
        $(".error_pager").text('Enter minimum '+pagerSettings.pagerMinCharLimit+' character');
  
      }else {
        $(".error_pager").text('Enter minimum '+pagerSettings.pagerMinCharLimit+' characters');
      }
      e.stopPropagation();
      return false;
    }else{
      pagerMsg.postPagerMassage()
    }
  }
 
});
$("body").on('click', '#copy_button', function (e) {
  let text =  window._env_.InviteLink
  let input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  document.body.removeChild(input);
  $("#infobox").css({"display":"block", "background-color":"#0e740f"});
  $("#infoContent").text('Copied!');
  $('#infobox').css("display", "block").delay(4000).fadeOut();

})
$("body").on('click', '#replyemoji', function (e) {
  e.stopPropagation();
  inputname = "comment";
  $("#profileemoji").hide();
  $("#statusemoji").hide();
  $("#groupemoji").hide();
  $("#groupupdateemoji").hide();
  $("#messageemoji").find('.emojiPicker').css({ top: -349 - $("#comment").height() + 30 });
  if ($('#messageemoji').css('display') == 'block') {
    $("#messageemoji").find(".wrap").find('em').unbind('click');
    $("#messageemoji").hide();
  } else {
    $("#messageemoji").show();
    utils.updateemojiclick(inputname);
  }
});
$(document).on('click', "#profilenameemoji", function (e) {
  e.stopPropagation();
  inputname = "profilename";
  $('#profileemoji').html('');
  $('#profileemoji').html($('#messageemoji').html());
  $("#messageemoji").hide();
  $("#statusemoji").hide();
  $("#groupemoji").hide();
  $("#groupupdateemoji").hide();
  if ($('#profileemoji').css('display') == 'block') {
    $("#profileemoji").find(".wrap").find('em').unbind('click');
    $("#profileemoji").hide();
  } else {
    $("#profileemoji").show();
    utils.updateemojiclick(inputname);
  }
});
$(document).on('click', "#statususeremoji", function (e) {
  e.stopPropagation();
  inputname = "mystatus";
  $('#statusemoji').html('');
  $('#statusemoji').html($('#messageemoji').html());
  $("#messageemoji").hide();
  $("#profileemoji").hide();
  $("#groupemoji").hide();
  $("#groupupdateemoji").hide();
  if ($('#statusemoji').css('display') == 'block') {
    $("#statusemoji").find(".wrap").find('em').unbind('click');
    $("#statusemoji").hide();
  } else {
    $("#statusemoji").show();
    utils.updateemojiclick(inputname);
  }
});
$(document).on('click', "#groupnameemoji", function (e) {
  e.stopPropagation();
  inputname = "new-gp-name";
  $('#groupemoji').html('');
  $('#groupemoji').html($('#messageemoji').html());
  $("#messageemoji").hide();
  $("#profileemoji").hide();
  $("#statusemoji").hide();
  $("#groupupdateemoji").hide();
  if ($('#groupemoji').css('display') == 'block') {
    $("#groupemoji").find(".wrap").find('em').unbind('click');
    $("#groupemoji").hide();
  } else {
    $("#groupemoji").show();
    utils.updateemojiclick(inputname);
  }
});
$(document).on("keypress", function (e) {
  if (e.which == 13) {
    $('#groupemoji').hide();
    $("#profileemoji").hide();
    $("#statusemoji").hide();
    $("#groupupdateemoji").hide();
    if ($('#messageemoji').css('display') == 'block') {
      let str = $("#comment").text();
      if (str.trim() != '') {
        $('#comment').trigger(
          $.Event('keypress', { keyCode: 13, which: 13 })
        );
      }
    }
    $("#messageemoji").hide();
  }
});
$(".right-prof-back").click(function () {
  $(".conversation").removeClass("small-pane");
  $(".side-frd-profile").css({
    "right": "-100%"
  });
  let groupid;
  let tojid = JSON.parse(localStorage.getItem("toJID"));
  if (tojid != null) {
    groupid = stropheConnection.Strophe().getNodeFromJid(tojid);
    conversation.updateuserprofile(groupid);
  }
});
$(document).on('click', '#right-side-prof', function () {
  let toid = JSON.parse(localStorage.getItem("toJID"));
  let usersProfileInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
  let toUser = stropheConnection.Strophe().getNodeFromJid(toid);
  if ((toid != null && toUser == storage.adminName()) || (usersProfileInfo != null && typeof usersProfileInfo[toUser] != "undefined" && usersProfileInfo[toUser][7] == "1")) {
    return true;
  }
  if (!$(".conversation").hasClass("small-pane")) {
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(toid)
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    $(".conversation").addClass("small-pane");
    setTimeout(function () {
      if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
        conversation.getBroadcastChatMedia(toid, '');
      } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        conversation.getSingleChatMedia(toid, '');
      } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
        conversation.getGroupChatMedia(toid, '');
      }
    }, 1000);
  }
  $(".side-frd-profile").css({
    "right": "calc(-100% + 316px)",
    "width": "316px"
  });
  $(".message-info-block").css({
    "width": "316px",
    "top": "0"
  });
  $(".media-detail-section").css({
    "right": "-102%",
    "width": "30%",
    "top": "-100%"
  });
  $("#userprofile").css({
    "width": "100%",
    "display": "block",
  });
  $(".infohead").css({
    "display": "block",
  });
});
$('#retry-camera').click(function () {
  $('#start_camera').trigger('click');
  $("#camerapic h3").text("Adjust the camera");
});
$('#start_camera').click(function () {
  let videoDiv,video;
  if(utils.checkStatus()) {
    videoDiv = "<video id = 'camera-stream'></video><p id = 'error-message'></p><a id = 'pro-img-select' class = 'image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><circle cx='12' cy='12' r='3.2' fill='#f07000'/><path d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z' fill='#f07000'/></svg></i></a><canvas> </canvas>";
    $('#change-camera').html(videoDiv);
    $('#camerapic').css('visibility', 'visible');
    $('#camerapic').css('opacity', '1');
    $("#camerapic h3").text("Adjust the camera");
    $('#retry-camera').show();
    video = document.querySelector('#camera-stream');
    $('#camerapic .close').click(function () {
      if (typeof MediaStream != "undefined" && MediaStream != '') {
        MediaStream.stop();
      }
      $('#camerapic').css('visibility', 'hidden');
      $('#camerapic').css('opacity', '0');
    });
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
        MediaStream = stream.getTracks()[0];
        $('#error-message').hide();
        $('#pro-img-select').show();
        $('#retry-camera').show();
        triggerImageSelect();
      })
      .catch(function (err) {
        displayErrorMessage("Camera permission should be enabled.", true);
      });
  }
});
function getLoginUserInfo(logsession,userInfo){
  let loginUserInfo;
  if (logsession != null) {
    loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  } else if (userInfo != null && typeof userInfo[0][0] != "undefined") {
    sessionStorage.Info = userInfo[0][0];
    loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  }

  return loginUserInfo;
};
function setLocalization(currentItem){
  if (currentItem != null) {
    localStorage.setItem('localization', currentItem);
  }
};
function conHeadAndConversationDesign(){
  if (localStorage.getItem("toJID")) {
    $('#con-head').css('display', 'flex');
    $('#conversation').css('display', 'block');
  } else {
    $('#con-head').css('display', 'none');
    $('#conversation').css('display', 'none');
  }
};

function setCurrentLang(){
  let currLang = localStorage.getItem('localization');
  if (currLang != null) {
    $("input[name=languages][value=" + currLang + "]").attr('checked', 'checked');
    $("input[name=languages][value=" + currLang + "]").parent().addClass('active');
  } else {
    $("input[name=languages][value=enUS]").attr('checked', 'checked');
    $("input[name=languages][value=enUS]").parent().addClass('active');
  }
};
function documentKeyupEventTrigger(keyCode){
  if (keyCode == 27) {
    $('#media-upload-popup .close').trigger('click');
    $('.cam-close').trigger('click');
    $('#media-upload-popup .close').trigger('click');
    $('#camerapic .close').trigger('click');
    $("#auth-failed").trigger('click');
    $("#logout-contact .mute-bts .cancel").trigger('click');
    $("#unblock-contact .mute-bts .cancel").trigger('click');
    $("#block-contact .mute-bts .cancel").trigger('click');
    $("#exit-group .mute-bts .cancel").trigger('click');
  }
};

function documentKeydownEventTrigger(objEvent){
  if (objEvent.keyCode == 9) { //tab pressed
    objEvent.preventDefault(); // stops its action
  }
};

function loginClickEventChatConnect(loginUserInfo){
  if (loginUserInfo != null) {
    settings.getSettings();
    stropheConnection.NewConnect();
    chatConnect(loginUserInfo[0][0]);
  }
};

function langClickEventTrigger(){
  let radioValue = $("input[name='languages']:checked").val();
    if (radioValue == 'enUS') {
      localStorage.setItem('localization', 'mnMN');
      trans.textChanger();
      location.reload();
    } else {
      localStorage.setItem('localization', 'enUS');
      trans.textChanger();
      location.reload();
    }
};

function newGrpNameLimitSet(text_max,text_length){
  let text_remaining,limitValue;
    if (text_length <= 25) {
      text_remaining = text_max - text_length;
      $('.char-counter').html(text_remaining);
    } else {
      limitValue = $('#new-gp-name').val().substring(0, 25);
      $('#new-gp-name').val(limitValue);
    }
};
function newBroadcastNameLimitSet(text_max,text_length){
  let text_remaining,limitValue;
    if (text_length <= 25) {
      text_remaining = text_max - text_length;
      $('.broadcast-char-counter').html(text_remaining);
    } else {
      limitValue = $('#new-broadcast-name').val().substring(0, 25);
      $('#new-broadcast-name').val(limitValue);
    }
};
function camClossEventTriggerFun(MediaStream){
  if (typeof MediaStream != "undefined" && MediaStream != '') {
    MediaStream.stop();
  }
};

function tickAudioSideProfileHide(){
  if($('.side-profile').css('left') == '0px' || $('.side-two').css('left') == '0px' || $('.side-global-contact-list').css('left') == '0px' || $('.side-role').css('left') == '0px' || $('.side-global-role-list').css('left') == '0px' || $('.side-group').css('left') == '0px' || $('.side-global-team-contact-list').css('left') == '0px' || $('.side-settings').css('left') == '0px' || $('.blockedlist-sidenav').css('left') == '0px' || $('.locationid-list-sidenav').css('left') == '0px'){

    $('.filter.plus-circles').hide();
    
  }
};

function acknowledgeMsgEventTrigger() {
  let ackValue = $("#ack").val();
  if (ackValue == 1) {
    $("#ack").val("0");
    $(".acknowledge-icon").find('img').attr('src', './src/images/acknowledgement_ic.svg');
  } else {
    $("#ack").val("1");
    $(".acknowledge-icon").find('img').attr('src', './src/images/Acknowledge_color-ic.svg');
  }
};

function accAndRejMsgEventTrigger(){
  let accRejValue = $("#ack").val();
    if (accRejValue == 2) {
      $("#ack").val("0");
      $(".acknowledge-icon").find('img').attr('src', './src/images/acknowledgement_ic.svg');
    } else {
      $("#ack").val("2");
      $(".acknowledge-icon").find('img').attr('src', './src/images/Accept-Reject_ic.svg');
    }
};

function selectMsgEventTrigger() {
  let  divJid = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
    if ($("[id='conversation"+divJid+"']").find('.forwardsingle').length) {
      $('.select-msg-blk').each(function(){
       
          let messageId = $(this).find(".select-message").val().split("-")[0];
          $("#chat-"+messageId).find(".select-msg-blk").show();
      });
      $('.del-msg-wrap').show();
      $('.row.reply').hide();
      $(".multi-count").html("0 selected");
    } else {
      $("#infoContent").text("No messages to select");
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
};

function msgCloseEventTrigger(tojid,blockUserInfo){
  if (tojid != null &&  (blockUserInfo != null && typeof blockUserInfo[stropheConnection.Strophe().getNodeFromJid(tojid)] != "undefined") ) {
    $('.row.reply').hide();
    $(".blocked-person").css("display", "block");
  } else {
    $('.row.reply').css('display', 'flex');
    $(".blocked-person").css("display", "none");
  }
}

function setOfflineMsgInCommentBox(nodeJid){
  let offlineText = JSON.parse(localStorage.getItem("offlineText"));
    if(offlineText!=null){
      if(offlineText[nodeJid] !=null){
        $('#comment').text(offlineText[nodeJid][1])
      }
    }
};
function unRegisterUserCmtBoxDesign(userInfo,nodeJid){
  let unregisteredUser = userInfo[nodeJid] ? userInfo[nodeJid][16] : '';
  if (unregisteredUser != 1) {
      $(".unblock-con").hide();
      $('.block-con').show()
      $('.heading-attach').show()
      $(".row.reply").css("display", "flex");
      $('#comment').attr("data-text", "Start typing...");
  }else {
      $(".blocked-person").css("display", "block");
      $('.blocked-person').text("You cannot send messages to this chat.");
      $('.block-con').hide();
  }
};

function newMemberNxtGrpSectionSet(){
  let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
  if (groupaddUserInfo == null || utils.ObjectLength(groupaddUserInfo) <= 1) {
    $("#infoContent").text(language.locali().addone_member);
    $('#infobox').css("display", "block").delay(4000).fadeOut();
  } else {
    $('#broadcast-participant-no-sites').hide()
    $(".add-icon").html('ADD TEAM ICON');
    $(".group-profile").removeClass('hide');
    $(".group-sec").removeClass("show");
    $(".group-sec").addClass("hide");
    $(".group-profile").addClass('show');
    $('.group-name-input').find('.emojiPickerIconWrap').show();
    $(".side-global-team-contact-list").css({
      "left": "-100%"
    });
  }
};

function broadcastNewMemberNxtGrpSectionSet(){
  let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));
  if (broadcastAddUserInfo == null || utils.ObjectLength(broadcastAddUserInfo) <= 1) {
    $("#infoContent").text(language.locali().addone_member);
    $('#infobox').css("display", "block").delay(4000).fadeOut();
  } else {
    $('#broadcast-participant-no-sites').hide()
    $(".add-icon").html('ADD TEAM ICON');
    $(".broadcast-profile").removeClass('hide');
    $(".group-sec").removeClass("show");
    $(".group-sec").addClass("hide");
    $(".broadcast-profile").addClass('show');
    $('.group-name-input').find('.emojiPickerIconWrap').show();
    $(".side-global-broadcast-contact-list").css({
      "left": "-100%"
    });
  }
};

function profilePicStatusCheck(){
  if(utils.checkStatus()){
    $('#profile-pic-rm').css('visibility', 'visible');
    $('#profile-pic-rm').css('opacity', '1');
  }
};

function profilePicRemoveImgTxtSet(user,imageId,myInfo) {
  if (imageId !== undefined || imageId !== 'undefined') {
    mediaUpload.fileDelete(imageId, '');
  }
  let name = (myInfo[user] != "") ? myInfo[user][1] : '';
  let concatedLetter;
  if(name != "") {
      concatedLetter = utils.getImageDisplayText(name);
  }
  
  $('.profileImageParentEl svg').show();
  $('.profileImageParentEl svg text').html(concatedLetter)
};

function viewProfileGetImage(imageId,mediaUrl,userToken){
  let image;
  if (imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId !== '') {
    image = mediaUrl + imageId + "?mf=" + userToken;
    if (image.trim() == '' || image === undefined) {
      image = imageId;
    }else {
      utils.imageTokenRegenerate(image,'.prof-big img',imageId,'profileImages');
    }
  } else if (imageId.trim() == '') {
    image = 'src/images/avatar/chat/profile_img_one.svg';
  } else {
    image = imageId;
  }

  return image;
};

function uploadPhotoEventTrigger(){
  if(utils.checkStatus()){
    $("#profile-upload").trigger("click");
    trigger.profileUpload();
  }
}

function emojiDesignCondition(){
  if ($('#statusemoji').css('display') == 'block') {
    $("#statusemoji").find(".wrap").find('em').unbind('click');
    $("#statusemoji").hide();
  }
  if ($('#groupemoji').css('display') == 'block') {
    $("#groupemoji").find(".wrap").find('em').unbind('click');
    $("#groupemoji").hide();
  }
  if ($('#profileemoji').css('display') == 'block') {
    $("#profileemoji").find(".wrap").find('em').unbind('click');
    $("#profileemoji").hide();
  }
  if ($('#messageemoji').css('display') == 'block') {
    $("#messageemoji").find(".wrap").find('em').unbind('click');
    $("#messageemoji").hide();
  }
  if ($('#groupupdateemoji').css('display') == 'block') {
    $("#groupupdateemoji").find(".wrap").find('em').unbind('click');
    $("#groupupdateemoji").hide();
  }
  if ($('.attachment').hasClass('hov')) {
    $('.first-child').off('click');
    $('.second-child').off('click');
    $('.third-child').off('click');
    if ($(".prof-opt").hasClass("open")) {
      $(".prof-opt").removeClass("open");
    }
    $('.first-child').css('animation', 'bright 1s .3s forwards');
    $('.second-child').css('animation', 'bdeg 1s .2s forwards');
    $('.third-child').css('animation', 'bbottom 1s .1s forwards');
    $('.attachment').removeClass('hov');
  }
};  

$(document).ready(function () {
  let userInfo,url,idToken,currentItem,ChatWebId;
  let logsession = sessionStorage.getItem("Info");
  userInfo = JSON.parse(localStorage.getItem("UserInfo"));
  let loginUserInfo = '';

  loginUserInfo = getLoginUserInfo(logsession,userInfo)
  
  // get the full url and find pattern og id_token
  url = window.location.href;
  idToken = url.match(/&(?:id_token)=([\S\s]*?)&/);
  if (loginUserInfo != null && loginUserInfo != '') {
    settings.getSettings();
    stropheConnection.NewConnect();
    currentItem = localStorage.getItem('localization');
    ChatWebId = localStorage.getItem('ChatWebId');
    setLocalization(currentItem)
    
    localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
    localStorage.setItem("ChatWebId", ChatWebId);
    let UserInfo = {};
    UserInfo[0] = [];
    UserInfo[0].push(sessionStorage.Info);
    localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
    chatConnect(loginUserInfo[0][0]);
    $("#login-screen").css("display", "none");
    $('#main-screen').css('display', 'block');
    $('.row.reply').hide();
    conHeadAndConversationDesign();

  } else if (idToken !== null) {
    // loginUser if not present, login using keycloak
    let getUrl = window.location;
    let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
    //function to send with empty ip address in register
    keycloakApi.authenticateKeycloak(idToken[1], baseUrl, "");
  } else {
    keycloakApi.loginWithKeycloak();
  }
  setCurrentLang()

  trans.textChanger();
  // function used for close in site search
  $("#globelSearchContactClose").on("click", function () {

    $("#sitesText").val('');
    globalContactEventTriggerFun();
  });
  $(".plus-circle").on("click", function () {
     $('.chat-option').toggleClass('menu')
     $('#recentchat').toggleClass('pencil-icon-overlay')
  });
  $("#searchClose").on("click", function () { 
    $("#searchText").val('');
    $("#searchText").trigger("keyup");
  });
  $("#searchContactClose").on("click", function () {
    $("#composeText").val('');
    $("#composeText").trigger("keyup");
  });
  $("#searchParticipantClose").on("click", function () {
    $("#partticipantText").val('');
    $("#partticipantText").trigger("keyup");
  });

  $("#forwardClose").on("click", function () {
    $("#forwardText").val('');
    $("#forwardText").trigger("keyup");
  });
  $("#searchRoleClose").on("click", function () {
    $("#rolesText").val('');
    $("#rolesText").trigger("keyup");
  });

  $(".searchParticipantClose").on("click", function () {
    $(".addedParticipantBox input").val('');
    $("#searchparticipant").trigger("keyup");
  });

  /** BroadCast Contact Search Text Close icon Click Event**/
  $(".searchBroadcastParticipantClose").on("click", function () {
    $(".addedBroadcastParticipantBox input").val('');
    $("#searchBroadcastparticipant").trigger("keyup");
  });

  $(".searchGlobalParticipantClose").on("click", function () {
    $("#participantSitesText").val('');
    $('#broadcast-participant-no-sites').hide()
    
    globalSiteParticipantEventTriggerFun();
  });

  $(".searchGlobalBroadcastParticipantClose").on("click", function () {
    $("#broadcastParticipantSitesText").val('');
    $("#broadcast-participant-no-sites").hide()
    globalSiteBroadcastParticipantTabEventTrigger();
  });

  $(document).keyup(function (e) {
    let keyCode = e.keyCode;
    documentKeyupEventTrigger(keyCode)
   
  });
  $(document).keydown(function (objEvent) {
    documentKeydownEventTrigger(objEvent);
  })

  history.pushState(null, null, '');
  window.addEventListener('popstate', function () {
    history.pushState(null, null, '');
  });
  $('#login').on('click', function () {
    $('.loader').css("display", "block");
    $("#login-screen").css("display", "none");
    $('.row.reply').hide();
    loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    loginClickEventChatConnect(loginUserInfo)
   
  });
  $('#logout').on('click', function () {
    $("#logout-contact").addClass("open");
  });
  //login with keycloak
  $('#keycloak-login-button').on('click', function () {
    window.location.replace(storage.getKeycloakLogoutURL());
  });

  $('.unstar-all').click(function () {
    $("#unstarall-msg").addClass("open");
  });

  $("#unstarall-msg .mute-bts .unstar").click(function () {
    favourite.unfavouriteallmsg();
    $("#unstarall-msg").removeClass("open");
  });
  $("#unstarall-msg .mute-bts .cancel").click(function () {
    $("#unstarall-msg").removeClass("open");
  });
  $('#lang').click(function () {
    langClickEventTrigger()
    
  });
  $("#re-connection .mute-bts .logout").click(function () {
    logout();
  });
  $(".mute-bts .logout").click(function () {
    logout();
  });
  $("#re-connection .mute-bts .loginretry").click(function () {
    location.reload(true);
  });
  $("#auth-failed").click(function () {
    $('#auth-failed').css('visibility', 'hidden');
    $('#auth-failed').css('opacity', '0');
    $('#logout').trigger('click');
  });
  $('#new-gp-name').on('input', function () {
    let text_max = 25;
    let text_length = $('#new-gp-name').val().length;
    newGrpNameLimitSet(text_max,text_length)
    
  });
  $('#new-broadcast-name').on('input', function () {
    let text_max = 25;
    let text_length = $('#new-broadcast-name').val().length;
    newBroadcastNameLimitSet(text_max,text_length)

  });
  $(".blocked-list").click(function () {
    $(".side-settings").css({
      "left": "-100px",
      "display": "none"
    });
    $(".blockedlist-sidenav").css({
      "left": "0",
      "display": "block"
    });
  });
  $(".cam-close").click(function () {
    utils.closeProfilePopUp('camerapic-upload');
    camClossEventTriggerFun(MediaStream)
    
  });
  $('#media-upload-popup .close').click(function (e) {
    e.stopImmediatePropagation();
    $('#media-upload-popup').css('visibility', 'hidden');
    $('#media-upload-popup').css('opacity', '0');
    $('.loader').css("display", "none");
  });
  $(".search-data").click(function () {
    $(".conversation").addClass("small-pane");
  });
  $(".search-back").click(function () {
    $(".conversation").removeClass("small-pane");
  });
  $("#microphone").click(function () {
    $(".reply-recording").hide();
    $("#audio-pop").css('display', 'block');
    $(".noUserAvilableInBroadcast").css('display','none')
    audio.startRecording();
  });
  $("#tick-audio").click(function () {
    $('#live-time').text('00:00')
    $('.loader').css("display", "block");
    $('.loader').addClass(" innerpage-loader");
    stoprecord('stop');

    tickAudioSideProfileHide()
    
    $('.reply-box').removeClass("disabledDiv");
  });
  $("#close-audio").click(function () {
    $('#live-time').text('00:00')
    $(".reply-recording").show();
    $("#audio-pop").hide();
    $('.reply-box').removeClass("disabledDiv");
    stoprecord('close');
  });
  $(".acknowledgeMsg").click(function () {
    acknowledgeMsgEventTrigger()
   
    $(".ack-popup").removeClass("view");
    $('#comment').focus();
  });
  $(".acceptRejectMsg").click(function () {
    accAndRejMsgEventTrigger()
    
    $(".ack-popup").removeClass("view");
    $('#comment').focus();
  });
  $(".select-messages").click(function () {
    $('#infobox').css("display", "none");
    $('#recentchat').removeClass('pencil-icon-overlay')
    $('.chat-option').removeClass('menu');

    $('.msg-close').trigger('click');
    selectMsgEventTrigger()
    
  });
  $('.msg-close').click(function () {
    let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    $('.select-msg-blk').hide();
    $('.del-msg-wrap').hide();
    msgCloseEventTrigger(tojid,blockUserInfo)
   
    delete localStorage.selectedmsgInfo;
    $(".multi-count").html('0 selected');
    $('input:checkbox.select-message').prop('checked', false);
  });  
  $(".unblock-con").hide();
  $(".mute-bts .block").click(function () {
    blockUser.blockuser();
    $(".unblock-con").show();
    $(".block-con").hide();
  });
  $(".unblock-con").click(function () {
    userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    let displayName = userInfo[nodeJid][1];
    let content = "Unblock " + ' "' + displayName + '"?';
    $("#unblock-contact").find('.popup-detail').html(content);
    $("#unblock-contact").addClass("open");
  });
  $("#block-contact .mute-bts .unblock").click(function () {
    $("#block-contact").removeClass("open");
  });
  $(".block-con").show();
  $(".mute-bts .unblock").click(function () {
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid; 

    if(tojid == null){
      nodeJid = $('.unBlockNodeJid').val();

    }else{
      nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    }
    setOfflineMsgInCommentBox(nodeJid)
    
    blockUser.unblockuser();
    $("#unblock-contact").removeClass("open");
    delete localStorage.unblockUserInfo;
      /**  Check User is RegisterUser or Not **/
      vCard.getVcard(nodeJid + "@" + stropheConnection.Server());
      unRegisterUserCmtBoxDesign(userInfo,nodeJid)
      setTimeout(function () {
        $('.fave_icone').attr('src','./src/images/svg/ikonixicon.svg')
      }, 2000);
     
  });
  $(".block-con").click(function () {
    userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    let displayName = userInfo[nodeJid][1];
    let content = "Block " + ' "' + displayName + '"?';
    $("#block-contact").find('.popup-detail').html(content);
    $("#block-contact").addClass("open");
  });
  $("#block-contact .mute-bts .block").click(function () {
    $("#block-contact").removeClass("open");
  });
  $("#block-contact .mute-bts .cancel").click(function () {
    $("#block-contact").removeClass("open");
  });
  $("#unblock-contact .mute-bts .cancel").click(function () {
    delete localStorage.unblockUserInfo;
    $("#unblock-contact").removeClass("open");
  });
  $("#logout-contact .mute-bts .cancel").click(function () {
    $("#logout-contact").removeClass("open");
  });
  $("#make-grp-admin .mute-bts .cancel").click(function () {
    $("#make-grp-admin").removeClass("open");
  });
  $("#make-grp-admin .mute-bts .save").click(function () {
    let adminUserInfo = JSON.parse(localStorage.getItem("adminUserInfo"));
    let memberJid = adminUserInfo[0][0] + '@' + stropheConnection.Server();
    let memberName = adminUserInfo[0][1];
    groups.makeadmin(memberJid, memberName);
    $("#make-grp-admin").removeClass("open");
  });
  /** Drop Down Click Event Initialize **/
  $(document).off('click');
  $(document).on('click', '#recentcontent .option-arrow', function () {

    console.log('Recent Clicked')
  });
  /** Drop Down Click Event Initialize **/

  $("#remove-grp-cnt .mute-bts .cancel").click(function () {
    $("#remove-grp-cnt").removeClass("open");
  });
  $("#remove-grp-cnt .mute-bts .save").click(function () {
    let adminUserInfo = JSON.parse(localStorage.getItem("adminUserInfo"));
    let memberJid = adminUserInfo[0][0] + '@' + stropheConnection.Server();
    let memberName = adminUserInfo[0][1];
    groups.removemember(memberJid, memberName);
    $("#participantcontact").find("[id='forwardcontact-"+adminUserInfo[0]+"']"
    ).css('display', 'block');
    $("#remove-grp-cnt").removeClass("open");
  });  

  /**  Remove Broadcast Participant Cancel and Save Event  **/
  $("#remove-broadcast-cnt .mute-bts .cancel").click(function () {
    $("#remove-broadcast-cnt").removeClass("open");
  });
  $("#remove-broadcast-cnt .mute-bts .save").click(function () {
    let broadcastRmvUserInfo = JSON.parse(localStorage.getItem("broadcastRmvUserInfo"));
    let memberJid = broadcastRmvUserInfo[0][0];
    let memberName = broadcastRmvUserInfo[0][1];
    let broadcastId = broadcastRmvUserInfo[0][2];
    let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));

    if(Object.keys(broadcastDetails[broadcastId]).length > 2){ // broadcast must have At least 2 members
      broadcast.removemember(memberJid, memberName,broadcastId);
      $("#participantcontact").find("[id='forwardcontact-"+broadcastRmvUserInfo[0]+"']"
      ).css('display', 'block');
    }else { // Broadcast Contain Only One Last Participant
      $("#infoContent").text(language.locali().at_least_two_broadcast_member);
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }

    $("#remove-broadcast-cnt").removeClass("open");
  });  


  $('.newMember-next').click(function () {
    $('.char-counter').show()
    $('.char-counter').html(25);
    $(".compose-sideBar").removeClass('bubbleadded');
    $(".compose-sideBar").removeClass('new-group-section');
    newMemberNxtGrpSectionSet()
    
  });

  $('.broadcast-newMember-next').click(function () {
    $('.broadcast-char-counter').show()

    let text_max = 25;
    let text_length = $('#new-broadcast-name').val().length;
    newBroadcastNameLimitSet(text_max,text_length)
    $(".compose-sideBar").removeClass('bubbleadded');
    $(".compose-sideBar").removeClass('new-group-section');
    broadcastNewMemberNxtGrpSectionSet()
    
  });

  $(".groupprofile-back").click(function () {
    let type = $(this).attr('data-type');
    $("#new-gp-name").val("");
    $('.char-counter').html(25);
    $(".group-sec").removeClass('hide');
    $(".group-profile").removeClass("show");
    $(".group-profile").addClass("hide");
    $(".group-sec").addClass("show");
    let singleActiveSiteDetails = JSON.parse(localStorage.getItem("singleActiveSiteDetails"))
    let hasSingleSite = singleActiveSiteDetails['has_single_site']
    if(type == "Team"){
      teamHasSingleSiteHandling(hasSingleSite)
    }else{
      broadcastHasSingleSiteHandling(hasSingleSite)
    }
  });

  
  $("#rm-pro-pic").click(function () {
    profilePicStatusCheck()
    
  });
  //need to do validation for status
  $("#profilePicRemove").click(function () {
    $('#profile-pic-rm').css('visibility', 'hidden');
    $('#profile-pic-rm').css('opacity', '0');
    let user = stropheConnection.logininfo.userJID;
    let imageId = $('.profile-image').attr('data-imageid');
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));

    profilePicRemoveImgTxtSet(user,imageId,myInfo);

    $('.profile-image').attr('src', 'src/images/avatar/chat/profile_img_one.svg');
    $('.profile-image').attr('data-imageid', 'src/images/avatar/chat/profile_img_one.svg');
    $('.profileImageParentEl img').hide();


    $('#profilepicturepick li:last-child').hide();
    $('#profilepicturepick li:first-child').hide();
  });
  $("#view-pro-pic").click(function () {
    let myProfileInfo = JSON.parse(localStorage.getItem("myInfo"));
    user = stropheConnection.logininfo.userJID;
    userToken = storage.getuserToken();
    let imageId = myProfileInfo[user][2];
    let image;
    image = viewProfileGetImage(imageId,mediaUrl,userToken)
    
    $("#auth-failed").click(function () {
      $('#auth-failed').css('visibility', 'hidden');
      $('#auth-failed').css('opacity', '0');
      $('#logout').trigger('click');
    });
    $('#new-gp-name').on('input', function () {
      let text_max = 25;
      let text_length = $('#new-gp-name').val().length;
      newGrpNameLimitSet(text_max,text_length)

    });
    $(".blocked-list").click(function () {
      $(".side-settings").css({
        "left": "-100px",
        "display": "none"
      });
      $(".blockedlist-sidenav").css({
        "left": "0",
      });
    });
    $(".cam-close").click(function () {
      utils.closeProfilePopUp('camerapic-upload');
      camClossEventTriggerFun(MediaStream)
    });
    $(".closepopup1").click(function () {
      utils.closeProfilePopUp('popup1');
    });
    $('.popup').find('.popup-head').find('img').css('display', 'block');
    $('.popup').find('.prof-big').find('img').css('display', 'block');
    $('.popup').find('.popup-head').find('video').css('display', 'none');
    $('.popup').find('.prof-big').find('video').css('display', 'none');
    $('.popup').find('.popup-head').find('img').attr('src', image);
    $('.content').find('.prof-big').find('img').attr('src', image);
    $('#popup-full').css('visibility', 'visible');
    $('#popup-full').css('opacity', '1');
    $('.popup').find('.prof-big').find('#audiocontent').css('display', 'none');
  });

  $('.upload_photo').click(function () {
    uploadPhotoEventTrigger()
    
  });
  
  /** Add Participant Group Icon Trigger Function **/
  $('.upload_group_photo').click(function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    $("#group-first-profile-upload").trigger("click");
  });

  $(document).click(function (e) {
    emojiDesignCondition()
    
  });
});

function teamHasSingleSiteHandling(hasSingleSite){
  if(hasSingleSite == 1){
    $(".side-global-team-contact-list").css({
      "left": "0px"
    });

    if($('.participantcontent:visible').length == 0){
      $('#broadcast-participant-no-sites').show()

    }else {
      $('#broadcast-participant-no-sites').hide()

    }

  }else {
    $('.globalTeamContactListBack').trigger('click')

  }
}

function broadcastHasSingleSiteHandling(hasSingleSite){
  if(hasSingleSite == 1){

    $(".side-global-broadcast-contact-list").css({
      "left": "0px"
    });

    if($('.broadCastParticipantcontent:visible').length == 0){
      $('#broadcast-participant-no-sites').show()

    }else {
      $('#broadcast-participant-no-sites').hide()

    }
  }else {
    $('.globalBroadcastContactListBack').trigger('click')
    
  }
}

function displayErrorMessage(error_msg, error) {
  MediaStream = '';
  error = error || "";
  if (error) {
    console.log(error_msg);
  }
  $('#error-message').html(error_msg);
  $('#error-message').show();
  $('#change-camera-1').find('#upload-stream').hide();
  $('#change-camera-1').find('#error-message').show();
  $('#change-camera').find('#camera-stream').hide();
  $('#change-camera').find('#error-message').show();
  $('#upload-img-select').hide();
  $('#pro-img-select').hide();
  $('#retry-camera').hide();
}
function triggerImageSelect() {
  $('#pro-img-select').click(function () {
    output = takeSnapshot();
    if (typeof MediaStream != "undefined" && MediaStream != '') {
      MediaStream.stop();
    }
    let ImgDiv = "<a id='pro-img-crop' class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='20' height='20' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a>";
    $('#change-camera').html(ImgDiv);
    $("#camerapic h3").text("Drag the image to adjust");
    let el = document.getElementById('change-camera');
    let vanilla = new Croppie(el, {
      viewport: {
        width: 300,
        height: 300,
        type: 'square'
      },
      boundary: {
        width: 300,
        height: 300
      },
      showZoomer: true,
    });
    vanilla.bind({
      url: output,
    });
    //on button click
    $("#pro-img-crop").click(function () {
      $('.loader').css("display", "block");
      vanilla.result('blob').then(function (blob) {
        uploadjs.profileUploadToS3(blob, 'camerapic');
      });
    $('.profileImageParentEl img').show();
    $('.profileImageParentEl svg').hide();
    });
    $(".closecamerapic").click(function () {
      utils.closeProfilePopUp('camerapic');
      if (typeof MediaStream != "undefined" && MediaStream != '') {
        MediaStream.stop();
      }
    });
  });
}
function takeSnapshot() {
  // Here we're using a trick that involves a hidden canvas element.  
  let video = document.querySelector('#camera-stream');
  let hidden_canvas = document.querySelector('canvas');
  context = hidden_canvas.getContext('2d');
  let width = video.videoWidth,
    height = video.videoHeight;
  if (width && height) {
    // Setup a canvas with the same dimensions as the video.
    hidden_canvas.width = width;
    hidden_canvas.height = height;
    // Make a copy of the current frame in the video on the canvas.
    context.drawImage(video, 0, 0, width, height);
    // Turn the canvas image into a dataURL that can be used as a src for our photo.
    return hidden_canvas.toDataURL('image/png');
  }
}
$('#popup1 .close').click(function () {
  $('#popup1').css('visibility', 'hidden');
  $('#popup1').css('opacity', '0');
});
$("#locationclose").click(function () {
  $("#location-sharing").css("visibility", "hidden");
  $("#location-sharing").css("opacity", "0");
});

$('#profile-pic-rm .cancel').click(function () {
  $('#profile-pic-rm').css('visibility', 'hidden');
  $('#profile-pic-rm').css('opacity', '0');
});
$('#popup-full .close').click(function () {
  $('#popup-full').css('visibility', 'hidden');
  $('#popup-full').css('opacity', '0');
  let music = "";
  if ($('.prof-big').hasClass('audio')) {
    $('.prof-big').removeClass('audio');
    music = document.getElementById("audioview-music");
  } else if ($('.prof-big').hasClass('video')) {
    $('.prof-big').removeClass('video');
    music = document.getElementById("videocontent");
  } else {
    $('.prof-big').removeClass('image');
    music = undefined;
  }
  if (typeof music != "undefined") {
    music.pause();
  }
  $("#audioview-pButton").removeClass("pause").addClass("play");
});
$(".message-info-block .right-msg-back").click(function () {
  $(".message-info-block").css({
    "right": "-100%"
  });
});

$(".singlechat-message-info-block .singlechat-right-msg-back").click(function () {
  $(".singlechat-message-info-block").css({
    "right": "-100%"
  });
});

/** Clear All Conversation Tab Click Event **/
$(".clearmessages").click(function () {
  $("#clear-all-conversation").find('.cancel').html("No")
  $("#clear-all-conversation").addClass("open");
});

/** Clear All Conversation Confirm Btn Click Event **/
$(".clear_all_conversation").click(function () {
  $("#clear-all-conversation").removeClass("open");
  clearallMessage.clearallMessage();
  $(".settings-back").trigger('click');
});
/** Clear All Conversation Cancel Btn Click Event **/
$("#clear-all-conversation .mute-bts .cancel").click(function () {
  $("#clear-all-conversation").removeClass("open");
});

$(".deletemessages").click(function () {
  clearallMessage.deleteallconversation();
  $(".settings-back").trigger('click');
});
$(".media-detail-section .right-msg-back").click(function () { 
  $(".media-detail-section").css({
    "right": "-100%"
  });
  $(".side-frd-profile").css({
    "right": "calc(-100% + 316px)",
    "width": "316px"
  });
  $(".conversation").addClass("small-pane");
  $("#userprofile").css({
    "width": "100%",
    "display": "block",
    "top": "0",
    "right": "0"
  });
  $(".infohead").css({
    "display": "block",
  });
});
function stoprecord(status) {
  // Use wav format
  let _AudioFormat = "audio/wav";
  // You can use mp3 to using the correct mimetype
  audio.stopRecording(function (AudioBLOB, recordtime) {
    // // Note:
    // // Use the AudioBLOB for whatever you need, to download
    // // directly in the browser, to upload to the server, you name it !
    // // In this case we are going to add an Audio item to the list so you
    // // can play every stored Audio
    if (status != 'close') {
      let randomValue = utils.cryptoRandomGenerate();

      let messageid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
      let fileSize = AudioBLOB.size;
      let options = {};
      options.duration = recordtime;
      let reader = new FileReader();
      reader.onload = function () {
        let filename = user + utils.getTimestamp();
        options.name = filename;
        let file = new File([AudioBLOB], filename + ".mp3");
        attachment.fileUploadToS3(file, 'audio', messageid, recordtime, fileSize, options);
      };
      reader.readAsDataURL(AudioBLOB);
    }
  }, _AudioFormat);
}

(function () {
  let isOnline = function () {
    $('#infobox').css("display", "none");
    document.title = "Ikonix Connect"
    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    if (loginUserInfo != null) {

      setTimeout(function () {
        console.log("internet up login called");
        if (!stropheConnection.Connection().connected) {
          stropheConnection.NewConnect();
          chatConnect(loginUserInfo[0][0]);
          /** Show Right side Default template **/
          $("#message").hide();
          $("#index-chat-section").show();
        }
      }, 6000);
    }
  };
    let isOffline = function () {
      $("#infoContent").text("Check your Internet connection");
      $('#infobox').css("display", "block");
      console.log("internet down");
      stropheConnection.Connection().flush();
      stropheConnection.Connection().options.sync = true;
      stropheConnection.Connection().disconnect();
    };

  if (window.addEventListener) {
    /*
        Works well in Firefox and Opera with the 
        Work Offline option in the File menu.
        Pulling the ethernet cable doesn't seem to trigger it.
        Later Google Chrome and Safari seem to trigger it well
    */
    window.addEventListener("online", isOnline, false);
    window.addEventListener("offline", isOffline, false);
  } else {
    /*
        Works in IE with the Work Offline option in the 
        File menu and pulling the ethernet cable
    */
    document.body.ononline = isOnline;
    document.body.onoffline = isOffline;
  }
})();
function notificationTitleSet(){
  let count,title;

  if (document.title != "Ikonix Connect") {
    if (document.title.split('(').pop().split(')')[0] != 0) {
      count = document.title.split('(').pop().split(')')[0] - 1;
      count = parseInt(count);
      if(count == 0) {
        title = "Ikonix Connect";
      }else {
        title = "(" + count + ") Ikonix Connect";
      }
    }
    document.title = title;
  }
}

function initialDocTitleSet() {
  toJID = JSON.parse(localStorage.getItem("toJID"));
  if (toJID != null && typeof toJID != "undefined") {
  let nodeJid = stropheConnection.Strophe().getNodeFromJid(toJID);
    if ($("[id='"+nodeJid+"']").find(".notify").text() != 0) {
      notificationTitleSet();
    }else {
      let notifyCount = $('#recentcontent .notify:visible').length;
      if(notifyCount > 0){
        document.title = "(" + notifyCount + ") Ikonix Connect";
      }else {
        document.title = "Ikonix Connect";
      }
    }
    $("[id='"+nodeJid+"']").find(".notify").html(parseInt(0));
    $("[id='"+nodeJid+"']").find(".notify").css("display", "none");
  }
  storage.setbrowserStatus(false);
}

function chatMsgSeenPendingCheck(chatSeenPendingMsgData){
  let seenmessage;
  if(chatSeenPendingMsgData){
    let statusTo = chatSeenPendingMsgData.statusTo;
    let seenid = chatSeenPendingMsgData.seenid;
    let msgid = chatSeenPendingMsgData.msgid;
    let msgPriority = chatSeenPendingMsgData.msgPriority;
    let msgAck = chatSeenPendingMsgData.msgAck;
    let nodeJid = chatSeenPendingMsgData.nodeJid;
    seenmessage = stropheConnection.StropheJs().$msg({
        to: statusTo,
        type: 'chat',
        id: seenid
    }).c('seen', {
        xmlns: 'urn:xmpp:receipts',
        id: msgid,
        message_priority: msgPriority,
        message_ack: msgAck,
        to_user: nodeJid
    });
    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().sendIQ(seenmessage);
        $('#chat-' + msgid).attr('data-status', 2);
    }
    delete localStorage.chatSeenPendingMsgData;
  }
}

function broadcastMsgSeenPendingCheck(broadcastSeenPendingMsgData){
  let seenmessage;
  if(broadcastSeenPendingMsgData){
    for (let key in broadcastSeenPendingMsgData) {
      let to = broadcastSeenPendingMsgData[key].to;
      let seenid = broadcastSeenPendingMsgData[key].seenid;

      let msgPriority = broadcastSeenPendingMsgData[key].msgPriority;
      let msgAck = broadcastSeenPendingMsgData[key].msgAck;
      let msgid = broadcastSeenPendingMsgData[key].msgid;
      let nodeJid = broadcastSeenPendingMsgData[key].nodeJid;
      let broadcast_msgid = broadcastSeenPendingMsgData[key].broadcast_msgid;
      seenmessage = stropheConnection.StropheJs().$msg({
        to: to,
        type: 'chat',
        id: seenid
      }).c('seen', {
          xmlns: 'urn:xmpp:receipts',
          message_priority: msgPriority,
          message_ack: msgAck,
          id: msgid,
          to_user: nodeJid,
          broadcastmsg_id:broadcast_msgid
      });
      if (stropheConnection.Connection().connected) {
          stropheConnection.Connection().sendIQ(seenmessage);
          $('#chat-' + msgid).attr('data-status', 2);
      }

    }
    delete localStorage.broadcastSeenPendingMsgData;
      
  }
}

function groupMsgSeenPendingCheck(groupSeenPendingMsgData){
  let seenmessage;
  if(groupSeenPendingMsgData){
    for (let key in groupSeenPendingMsgData) {
      let to = groupSeenPendingMsgData[key].to;
      let messageid = groupSeenPendingMsgData[key].messageid;
      let group_id = groupSeenPendingMsgData[key].group_id;
      let msgPriority = groupSeenPendingMsgData[key].msgPriority;
      let msgAck = groupSeenPendingMsgData[key].msgAck;
      let msgid = groupSeenPendingMsgData[key].nodeJid;
      seenmessage = stropheConnection.StropheJs().$msg({
        to: to,
        type: 'chat',
        id: messageid
      }).c('seen', {
          xmlns: 'urn:xmpp:receipts',
          group_id: group_id,
          message_priority: msgPriority,
          message_ack: msgAck,
          id: msgid,
      });
      if (stropheConnection.Connection().connected) {
          stropheConnection.Connection().sendIQ(seenmessage);
          $('#chat-' + msgid).attr('data-status', 2);
      }
    }
    delete localStorage.groupSeenPendingMsgData;
      
  }
}

document.addEventListener('visibilitychange', function () {
  // fires when user switches tabs, apps, goes to homescreen, etc.
  if (document.visibilityState === 'hidden') {
    storage.setbrowserStatus(true);
  }
  // fires when app transitions from prerender, user returns to the app / tab.
  if (document.visibilityState === 'visible') {
    initialDocTitleSet();
  }

  if(document.visibilityState === 'visible' ){
   let chatSeenPendingMsgData = JSON.parse(localStorage.getItem("chatSeenPendingMsgData"));
   let groupSeenPendingMsgData = JSON.parse(localStorage.getItem("groupSeenPendingMsgData"));
   let broadcastSeenPendingMsgData = JSON.parse(localStorage.getItem("broadcastSeenPendingMsgData"));

   chatMsgSeenPendingCheck(chatSeenPendingMsgData);
   groupMsgSeenPendingCheck(groupSeenPendingMsgData);
   broadcastMsgSeenPendingCheck(broadcastSeenPendingMsgData);

}

});
$('#comment').on('input', function () {
  let val = $(this).text().trim();
  if (val === "" || val === null) {
    $("#send-icon").css("display", "none");
    $("#microphone").css("display", "block");
  } else {
    $("#send-icon").css("display", "block");
    $("#microphone").css("display", "none");
  }
});
$('#comment').on('paste', function(e) {
  e.preventDefault();

  // Get the clipboard data
  const clipboardData = (e.originalEvent || e).clipboardData || window.clipboardData;
  const text = clipboardData.getData('text/plain');

  // Get the selection and insert the text at the cursor position
  const comment = document.getElementById('comment');
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  
  // Delete the current content of the selection (optional)
  range.deleteContents();

  // Insert the text at the cursor position
  range.insertNode(document.createTextNode(text));
  
  // Optional: Move the cursor to the end of the inserted text
  range.setStartAfter(comment.lastChild);
  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
});

// On clicking ok in keycloak error message popup
$(".api-alert-ok").click(function(){
  $(".alert-popup").css("display", "none");
});

function onElementHeightChange(elm, callback) {
  let lastHeight = elm.clientHeight, newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight != newHeight)
      callback();
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer)
      clearTimeout(elm.onElementHeightChangeTimer);

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}
/** Profile Option Click Event**/
$(".header-second .prof-opt").click(function(e){
  e.preventDefault();
  e.stopPropagation();
  if($(this).hasClass('open')){
    $(this).find('a').attr('aria-expanded', false);
    $(this).removeClass('open');
  }else {
    $(this).find('a').attr('aria-expanded', true);
    $(this).addClass('open');
  }
  
});

onElementHeightChange(document.body, function () {
  $("#comment").trigger("keyup");
});

$("#pager_icon").click(function(e){
  $('.pager_massage').val('')
  let pagerNo = ($('#profile-pager-number').html() == '') ? '' : parseInt($('#profile-pager-number').html());
  let pagerName = $(this).parent().parent().parent().parent().find('.heading-name-meta').html()
  let pagerNodeId = $(this).attr('data-nodeid');
  let pagerDeviceType = $(this).attr('data-devicetype');
  let pagerToId = $(this).attr('data-toid');

  $('#PagerNumberId').attr("data-id", pagerNo);
  $('.pagerHeader').html('Pager Message to '+pagerName+'');
  $('#pagerNodeId').attr("data-nodeid",pagerNodeId);
  $('#pagerDeviceType').attr("data-type",pagerDeviceType);
  $('#pagerToId').attr("data-toid",pagerToId);

  $("#isReceivedDevice").attr('data-isreceiveddevice',0);
});

$("#profile a").click(function(e){
  e.preventDefault();
  e.stopPropagation();
  $(this).attr('aria-expanded', true);
  $(this).parent().addClass('open');
});



/**  function used for Location setting side nav open **/

$(".locationIdSetting").click(function (e) {
  $(".side-settings").css({
    "left": "-100px",
    "display": "none"
  });
  $(".locationid-list-sidenav").css({
    "left": "0",
    "display": "block"
  });

  siteIdTxtChange(); // Site Id Text Append


})
/** Webchat Logout Start **/
$(".webchatLogout").click(function(e) {
  e.preventDefault();
  e.stopPropagation();
  let userId = stropheConnection.logininfo.userJID;
  $("#logOutPopup .oncall-rolelist tbody").html('');

    let activeOncalls = userOncalls.getOncallNames(userId, 1, 1, 0, '');
    let oncallListDiv = '';

    if(activeOncalls.length == 0) {
      $(".logout-withoncall-popup").css("display", "none");
      $(".logout-withoutoncall-popup").css("display", "block");
      $('.alert-msg').html('Are you sure you want to log out of your account? You will no longer be contactable on Ikonix Connect.');
    } else {
      let thead = '<tr><th> Role </th><th> Status</th><tr>';
      $('.logout-withoncall-popup thead').children().remove();
      $('.logout-withoncall-popup thead').append(thead)

      $(".logout-withoutoncall-popup").css("display", "none");
      $(".logout-withoncall-popup").css("display", "block");

      $.each(activeOncalls , function(index, val) {
        oncallListDiv += '<tr><td>'+val+'</td><td><span class="highlighted">On Call</span></td></tr>';
      });
      $("#logOutPopup .oncall-rolelist tbody").append(oncallListDiv);

    }
    
    $('#logOutPopup').addClass("open"); 

})

/** Webchat Confirm Logout **/
$(".conformLogout").click(function(e) {
  webChatLogout();

});

/** Webchat Cancel Logout **/
$(".cancelLogout").click(function () {
  $("#logOutPopup").removeClass("open");
});

function webChatLogout() {
  setTimeout(function () {
    sessionStorage.clear();
    window.location.replace(storage.getKeycloakLogoutURL());
  }, 1000)
}
/** Webchat Logout End **/

/**  function used for Location Id Popup div Append **/

function locationSettingDivAppend(AscOrderSiteArr,type){
  AscOrderSiteArr.forEach(function(value){
    let key = value[0];
    value = value[1];
    let concatedLetter = '';
    if(value.name != "") {
        let splitedArr = value.name.split(' ');
        let splitedNameArr = splitedArr.filter(function(v){return v!==''});

        for (let index = 0; index < splitedNameArr.length; index++) {
            if(index<3){
                concatedLetter += splitedNameArr[index][0].toUpperCase()
            }
        }
    }

    let contactDiv = '<div class="sitecontent row sideBar-body" id="site-' + key + '"><div class="select-cnt-blk"><input type="checkbox" class="select-message3" name="' + value.name + '" value="' + key + '"><label></label></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';

    let imageDiv = '<div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div> </div>';

    let nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main site-list-main padRgt-30Imp"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name site-name"><div class="site-list-div"><span class="name-meta site-list">' + value.name + '</span></div></div>';

    let addressDiv = ''

    if(typeof value.address != "undefined" && value.address != null && value.address != "") 
        addressDiv = '<div class="sideBar-msg role-div"><span class="name-status" title="'+value.address+'">' + value.address + ' </span></div></div></div>';

    if(type == "Contact"){
      if(value.countOfUsersOnSite > 0){
        $("#location-sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);
      }

    }else {
      if(value.countOfOncallsOnSite > 0){
        $("#location-sites-info").append(contactDiv + imageDiv + nameDiv + addressDiv);
      }

    }
  })
  
  $("#locationIdType").val(type);
}

/**  function used for Location Id Checkbox checked or not **/

function locationIdCheckboxCheck(siteDetails,type){
  let locationId = JSON.parse(localStorage.getItem("locationId"));  
  let siteTxt;      
  if(locationId != null && typeof locationId != "undefined"  && locationId != "") {
    let checkLocationId;
    let originalSiteId = $('#siteDetails').text();
    if(type == "Contact"){ // Check Type is Contact Or Role
      siteTxt = '';
      checkLocationId = locationId.mysite_contact;
    }else {
      siteTxt = language.locali().my_site_role_header;
      checkLocationId = locationId.mysite_role;
    }
    if(typeof siteDetails[checkLocationId] == 'undefined')
      checkLocationId = originalSiteId
    if(checkLocationId != null && typeof checkLocationId != "undefined" && checkLocationId != ""){
      $("#location-sites-details .sitecontent").find("input[type=checkbox][value=" + checkLocationId + "]").prop("checked", true).change
  ();
    }
  }
  $('.location-sites-details-text').text(siteTxt);
}

/**  function used for sites popup Open **/

$(".mySiteLocationIdSetting").click(function (e) {
  $("#location-sites-info #noparticipant").remove();
  $("#location-sites-details").css("display", "block");
  $("#location-sites-details").addClass("open");
  $("#location-sites-info").scrollTop(0);
  $("#location-sites-details").find("input[type=checkbox]").prop("checked", false);
  $("#locationSitesText").val("");
  $("#locationSiteSearchClose").hide();
  $(".search-sites").show();

  let type = $(this).attr("data-type");
  let siteDetails = JSON.parse(localStorage.getItem("siteDetails"));
  if(siteDetails != null && typeof siteDetails != "undefined"  && siteDetails.length != 0) {
    $('#location-sites-info').children().remove();
    //Convert Sites Object into SiteArray format    
    let AscOrderSiteArr =  Object.entries(siteDetails).sort(function(a, b) {
      let textA = a[1].name.toUpperCase();
      let textB = b[1].name.toUpperCase();
      let result;

      if(textA < textB)
        result = -1;
      else if(textA > textB)
        result = 1;
      else
        result = 0;

      return result;
    });

    locationSettingDivAppend(AscOrderSiteArr,type);
  }else {
    $('#location-sites-info #noparticipant').remove();
    $("#location-sites-info").html("<div id='noparticipant'>" + language.locali().nosite_found + "</div>");

  }
  
  locationIdCheckboxCheck(siteDetails,type)
});


  /**  function used for sites popup close **/

  $("#location-sites-details .location-sites-details-msg-close").click(function () {
    $("#selectedLocationId").attr('data-location-id','');
    $("#location-sites-details").css("display", "none");
    $("#location-sites-details").removeClass("open");
    
  });


    /**  Function for Location Site  checkbox click **/

    $("#location-sites-details").off().on('click', '.sitecontent', function () {

      let result = $(this).attr("id").split("-");
      let selectedId = result[1];
      if (!($("#location-sites-details .sitecontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked"))) {
        $("#location-sites-details .sitecontent").find("input[type=checkbox]").prop("checked",false);

        $("#location-sites-details .sitecontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked", true).change();

        $("#selectedLocationId").attr('data-location-id',selectedId);

      }
    });

  // function used for sites popup success click
  $("#location-sites-details .location-sites-details-select").on("click", function (e) {
    let locationId = $("#location-sites-details .sitecontent").find("input[type=checkbox]:checked").val()
    let type = $("#locationIdType").val();

    if(typeof locationId != "undefined" && locationId != ""){
      let siteDetailsLocationId;
      let locationid_details = JSON.parse(localStorage.getItem("locationId"));        
      if(locationid_details != null && typeof locationid_details != "undefined"  && locationid_details != "") {
        siteDetailsLocationId = locationid_details
      }else {
        siteDetailsLocationId = {
          mysite_contact: '',
          mysite_role : ''
        }
      }
      if(type == "Contact") {
        siteDetailsLocationId.mysite_contact = locationId;
        siteDetailsLocationId.is_contact_update = true;
      }
      else {
        siteDetailsLocationId.mysite_role = locationId
        siteDetailsLocationId.is_role_update = true;
      } 



      localStorage.setItem("locationId",JSON.stringify(siteDetailsLocationId));


      siteIdTxtChange(); // Site Id Text Append

      $("#location-sites-details").css("display", "none");
      $("#location-sites-details").removeClass("open");

      let isSetRoleSitePreference = JSON.parse(localStorage.getItem("isSetRoleSitePreference"));   
      
      if(typeof isSetRoleSitePreference != 'undefined' && isSetRoleSitePreference == 1){
        
        delete localStorage.isSetRoleSitePreference;

        $(".locationid-list-sidenav").css({
          "display": "block",
          "left": "-100%"
        });
        
        $(".side-settings").css({
          "left": "-100%"
        });

        $(".mySiteRoleTab").trigger('click');

      }


    }else {
      $("#infoContent").text('Site Id is Required!!!');
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }

  });

// function used on site search
$("#locationSitesText").on("keyup", function() {
  let content = $(this).val().trim();
  $("#location-sites-info #noparticipant").remove();
  if (content == '') {
    $("#locationSiteSearchClose").hide();
    $(".search-sites").show();
    $("#location-sites-info").children().show();
  } else {
    $("#locationSiteSearchClose").show();
    $(".search-sites").hide();
    $("#location-sites-info").children().hide();
    $("#location-sites-info").prepend("<div id='noparticipant'>" + language.locali().nosite_found + "</div>");
    $("#location-sites-info").find('.sideBar-body').each(function () {
      let siteval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (siteval.indexOf(searchtxt) != -1) {
        $("#location-sites-info #noparticipant").remove();
        $(this).show();
      }
    });
  }
});


 // function used for close in site search
 $("#locationSiteSearchClose").on("click", function () {
  $("#locationSitesText").val('');
  $("#locationSitesText").trigger("keyup");
 });


function mySiteRoleTxtAppend(mysite_role_details){
  if(typeof mysite_role_details != "undefined" && mysite_role_details != ""){
    let abbName = mysite_role_details.abbreviation;
    let name = mysite_role_details.name;
    if(typeof abbName != "undefined" && abbName != "") {
      $("#mySiteRoleTxt").html(abbName)
    }else {
      $("#mySiteRoleTxt").html(name)
      
    }
  }
}
function getMysiteContactDetails(siteDetails,mysite_contact_id,originalLocationId){
  let mysite_contact_details;

  // If Selected Contact Sites Not Availabe
  if(typeof siteDetails[mysite_contact_id] != "undefined" ){
    mysite_contact_details = siteDetails[mysite_contact_id]
  }else {
  if(typeof originalLocationId != "undefined" && originalLocationId != "")
    mysite_contact_details = siteDetails[originalLocationId]
  }

  return mysite_contact_details;
}

function getMysiteRoleDetails(siteDetails,mysite_role_id,originalLocationId){
  let mysite_role_details;

  // If Selected Roles Sites Not Availabe
  if(typeof siteDetails[mysite_role_id] != "undefined" ){
    mysite_role_details = siteDetails[mysite_role_id]
  }else {
    if(typeof originalLocationId != "undefined" && originalLocationId != "")
    mysite_role_details = siteDetails[originalLocationId]
  }

  return mysite_role_details;
}

function siteIdTxtChange() {
  let originalLocationId = $('#siteDetails').text();
  let locationId = JSON.parse(localStorage.getItem("locationId"));        
  let siteDetails = JSON.parse(localStorage.getItem("siteDetails"));
  if(siteDetails != null && typeof siteDetails != "undefined"  && siteDetails.length != 0) {
      if(locationId != null && typeof locationId != "undefined" && locationId != ""){
        let mysite_role_id = locationId.mysite_role;
        let mysite_role_details = getMysiteRoleDetails(siteDetails,mysite_role_id,originalLocationId);

        mySiteRoleTxtAppend(mysite_role_details);
      }
  }
}

$("#teamCreateUserAlert .mute-bts .cancel").click(function () {
  $("#teamCreateUserAlert").removeClass("open");
});

$('.teamCreateUserAlertBtn').click(function() {
  $("#teamCreateUserAlert").removeClass("open");

})

/** Contact page Mysite & Global Tab Click Event Start **/

function globalContactEventTriggerFun(e) {


  $('.globelContactSearchDiv').show();
  $('.mysiteContactSearchDiv').hide();
  $("#sites-info").show();
  $("#roster").hide();
  $(".sideContactNavLoader").show()
  $('#sites-info').empty();
  $("#globelSearchContactClose").hide();
  $(".globelSearchContact").show();

  setTimeout(function () {
    siteDetails.getSiteDetails('Contact');

    $("#sites-info .sitecontent").click(function () {
      let siteName = $(this).find('.name-meta.site-list').text();
      let siteId = $(this).attr('id').split('-')[1];
      $("#currentSiteId").val(siteId)
       $(".side-global-contact-list").css({
        "left": "0px"
       });

       $('#globalSiteIdTxt').text(siteName)
       $('#globalSiteIdVal').val(siteId)
       
       $('#globalContactSearchTxt').val('');
       $("#globelSearchContactListClose").hide();
       $(".globelSearchContactList").show();
       $('#globalContactSearchTxt').attr('placeholder',"Search selected site contacts");
       

        let searchContent     = '',
            isChatUser        = '',
            page              = 1;
            globalContactFilter(searchContent,isChatUser,page,siteId)
    });

   function globalContactFilter(searchContent,isChatUser,page,siteId) {

        $('#globalContactLists').empty();
        $(".sideContactListNavLoader").show()
        clearTimeout(globalContactTimeout);
        globalContactTimeout = setTimeout(() => {
          let globalQueryParams;
          if (searchContent == '') {
            globalQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
            contact.getGlobalContactsList(globalQueryParams,'globalContactLists');

          } else {
            if (searchContent.length > 0) {
              searchContent = encodeURIComponent(searchContent);

              globalQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
              contact.getGlobalContactsList(globalQueryParams,'globalContactLists');

            } 
          }
        }, 500)
    }

    $('.globalContactListBack').click(function(e) {
      
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      $("#sitesText").val("");
      $(".side-global-contact-list").css({
        "left": "-100%"
      });

      globalContactEventTriggerFun();
    });

    $('.globalContactSearchTxt').keyup(function(e) {

      let siteId = $('#globalSiteIdVal').val();
      let searchContent     = $('#globalContactSearchTxt').val(),
          isChatUser        = '',
          page              = 1;

          if(searchContent == ''){
            $("#globelSearchContactListClose").hide();
            $(".globelSearchContactList").show();
          }else {
            $("#globelSearchContactListClose").show();
            $(".globelSearchContactList").hide();
          }
          globalContactFilter(searchContent,isChatUser,page,siteId)
           
    });

    //on keydown, clear the countdown 
    $("#globalContactSearchTxt").on('keydown', function () {
      clearTimeout(globalContactTimeout);
    });

    $("#globelSearchContactListClose").on('click', function (e) {

      $("#globelSearchContactListClose").hide();
      $(".globelSearchContactList").show();
      $('#globalContactSearchTxt').val('');

      let siteId = $('#globalSiteIdVal').val();

      let searchContent     = $('#globalContactSearchTxt').val(),
      isChatUser        = '',
      page              = 1;
      globalContactFilter(searchContent,isChatUser,page,siteId)
    });

  },500)

}
/** Contact page Mysite & Global Tab Click Event End **/



 function globalSiteBroadcastParticipantTabEventTrigger(){

  $(".searchGlobalBroadcastParticipantOpen").show();
  $(".searchGlobalBroadcastParticipantClose").hide();


  $(".addedBroadcastParticipantBox").hide();
  $(".globelSiteBroadcastParticipantSearchDiv").show();
  $("#broadcast-participant-sites-info").show();
  $("#broadcast-participants").hide();
  $(".sideBroadcastParticipantNavLoader").show()
  $('#broadcast-participant-sites-info').empty();

  $(".side-global-broadcast-contact-list #searchBroadcastParticipant").keyup(function (e) {
    let content = $(this).val().trim();
    $("#nonewparticipant").remove();
    broadCastKeyupFunctionCallback(content) 
   })

   $(".side-global-broadcast-contact-list #searchBroadcastParticipant").keydown(function (e) {
    clearTimeout(newBroadcastTimeout);
   })

  setTimeout(function () {
    siteDetails.getSiteDetails('Broadcast'); //Get & Append site Details

    $("#broadcast-participant-sites-info .sitecontent").click(function () {
      let siteName = $(this).find('.name-meta.site-list').text();
      let siteId = $(this).attr('id').split('-')[1];
      $("#currentSiteId").val(siteId)

      $("#globalBroadcastParticipantCurrentSiteId").val(siteId)

       $(".side-global-broadcast-contact-list").css({
        "left": "0px"
       });

       $('.side-global-broadcast-contact-list .addedBroadcastParticipantBox input').attr("placeholder", "Search selected site contacts");
       
       $('#globalBroadcastSiteIdTxt').text(siteName)
       $('#globalBroadcastSiteIdVal').val(siteId)

       $(".searchBroadcastParticipantOpen").show();
       $(".searchBroadcastParticipantClose").hide();

       $(".addedBroadcastParticipantBox").show();
       $(".globelSiteBroadcastParticipantSearchDiv").hide();

     

       $(".side-global-broadcast-contact-list .addedBroadcastParticipantBox input").val('');

        let searchContent     = '',
            isChatUser        = 1,
            page              = 1;
            
            globalBroadcastContactFilter(searchContent,isChatUser,page,siteId)

    });

    $('.globalBroadcastContactListBack').click(function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      $("#broadcastParticipantSitesText").val("");
      $('#broadcast-participant-no-sites').hide()

      $(".side-global-broadcast-contact-list").css({
        "left": "-100%"
      });

      globalSiteBroadcastParticipantTabEventTrigger();
    });

    function globalBroadcastContactFilter(searchContent,isChatUser,page,siteId) {
      $('#globalBroadcastContactLists').empty();

      $(".sideBroadcastContactListNavLoader").show()

      clearTimeout(globalTeamContactTimeout);
      globalTeamContactTimeout = setTimeout(() => {
        let globalTeamQueryParams;
        if (searchContent == '') {
          globalTeamQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
          contact.getNewBroadCastContacts(globalTeamQueryParams,'globalBroadcastContactLists',true);
        } else {
          if (searchContent.length > 0) {
            searchContent = encodeURIComponent(searchContent);
            globalTeamQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
            contact.getNewBroadCastContacts(globalTeamQueryParams,'globalBroadcastContactLists',true);

          } 
        }
      }, 500)
    }

    $('.broadcastParticipantSitesText').keyup(function(e) {
      let siteId = $('#globalBroadcastSiteIdVal').val();

      $("#globelSearchContactListClose").show();
      $(".globelSearchContactList").hide();

      let searchContent     = $('#globalContactSearchTxt').val(),
          isChatUser        = '',
          page              = 1;
          globalBroadcastContactFilter(searchContent,isChatUser,page,siteId)
    });


  },500)
  
  
}



 function globalSiteParticipantEventTriggerFun(){

  $(".searchGlobalParticipantOpen").show();
  $(".searchGlobalParticipantClose").hide();


  $(".addedParticipantBox").hide();
  $(".globelSiteParticipantSearchDiv").show();
  $("#participant-sites-info").show();
  $("#participants").hide();
  $(".sideParticipantNavLoader").show()
  $('#participant-sites-info').empty();


  setTimeout(function () {
    siteDetails.getSiteDetails('Team'); //Get & Append site Details

    $("#participant-sites-info .sitecontent").click(function () {
      let siteName = $(this).find('.name-meta.site-list').text();

      let siteId = $(this).attr('id').split('-')[1];
      $("#currentSiteId").val(siteId)
      $("#globalParticipantCurrentSiteId").val(siteId)

       $(".side-global-team-contact-list").css({
        "left": "0px"
       });

       $('.side-global-team-contact-list .addedParticipantBox input').attr("placeholder", "Search selected site contacts");
       
       $('#globalTeamSiteIdTxt').text(siteName)
       $('#globalTeamSiteIdVal').val(siteId)

       $(".searchParticipantOpen").show();
       $(".searchParticipantClose").hide();

       $(".addedParticipantBox").show();
       $(".globelSiteParticipantSearchDiv").hide();

       $(".side-global-team-contact-list .addedParticipantBox input").val('');

        let searchContent     = '',
            isChatUser        = 1,
            page              = 1;
            
            globalTeamContactFilter(searchContent,isChatUser,page,siteId)

    });

$("body").on('click', '.globalTeamContactListBack', function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      $("#broadcast-participant-no-sites").hide();
      $("#participantSitesText").val("");
      $(".side-global-team-contact-list").css({
        "left": "-100%"
      });

      globalSiteParticipantEventTriggerFun();
    });

    function globalTeamContactFilter(searchContent,isChatUser,page,siteId) {
      $('#globalTeamContactLists').empty();

      $(".sideTeamContactListNavLoader").show()

      clearTimeout(globalTeamContactTimeout);
      globalTeamContactTimeout = setTimeout(() => {
        let globalTeamQueryParams;
        if (searchContent == '') {
          globalTeamQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
          contact.getNewGroupContacts(globalTeamQueryParams,'globalTeamContactLists',true);
        } else {
          if (searchContent.length > 0) {
            globalTeamQueryParams = '?search=' + searchContent + '&size=' + language.locali().default_size_pagination + '&isChatUser=' + isChatUser + '&siteId=' + siteId + '&page=' + page;
            contact.getNewGroupContacts(globalTeamQueryParams,'globalTeamContactLists',true);

          } 
        }
      }, 500)
    }

    $('.participantSitesText').keyup(function(e) {
      let siteId = $('#globalSiteIdVal').val();

      $("#globelSearchContactListClose").show();
      $(".globelSearchContactList").hide();

      let searchContent     = $('#globalContactSearchTxt').val(),
          isChatUser        = '',
          page              = 1;
          globalContactFilter(searchContent,isChatUser,page,siteId)
    });


  },500)
  
  
}

/** Add Participant page Mysite & Global Tab Click Event End **/


/** Roles page Mysite & Global Tab Click Event Start **/

$('.mySiteRoleTab').click(function(e) {
  $(this).addClass('active')
  $(this).siblings().removeClass('active')

  $('.globelsiteRoleSearchDiv').hide();

  $("#roles").show();
  $("#roles-sites-info").hide();

  $('#searchRoleClose').hide();
  $('.searchRole').show();

  $(".sideRoleNavLoader").show()
  $("#roles").empty();

  setTimeout(function () {

    $("#rolesText").trigger('keyup');
  },500)

})

$('.globelSiteRoleTab').click(function(e) {
  $(this).addClass('active')
  $(this).siblings().removeClass('active')

  $('.globelsiteRoleSearchDiv').show();
  $('.mysiteRoleSearchDiv').hide();
  $("#roles-sites-info").show();
  $("#roles").hide();
  $(".sideRoleNavLoader").show()
  $('#roles-sites-info').empty();

  $('#globelSearchRoleClose').hide();
  $('.globelSearchRole').show();

  setTimeout(function () {
    siteDetails.getSiteDetails('Role'); // get & append Site details
    $("#rolesSitesText").trigger('keyup');

    $("#roles-sites-info .sitecontent").click(function () {
      let siteName = $(this).find('.name-meta.site-list').text();
      let siteId = $(this).attr('id').split('-')[1];

      $(".side-global-role-list").css({
        "left": "0px"
       });

       $('#globalRoleSiteId').val(siteId)
       $('#globalRoleSiteIdTxt').text(siteName)

       $('#globalRoleSearchTxt').val('');
       $('#globelSearchRoleListClose').hide();
       $('.globelSearchRoleList').show();
            
       $('#globalRoleLists').empty();
       $(".sideRoleListNavLoader").show()

       $('.globalRoleFilter').removeClass('unitfilter');
       $('.globalRoleFilter').removeClass('alapfilter');
 
       $('.globalRoleFilterAlphaIcon').addClass('alapfilter');
      $('.globalRoleFilterUnitIcon').addClass('unitfilter');

       setTimeout(function () {
        roles.getOncallUnits(siteId,'globalRoleLists');
       },500)

    });

    $('.globalRoleListBack').click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      $(".side-global-role-list").css({
        "left": "-100%"
      });

      $('.globelSiteRoleTab').trigger('click');

    });

    $("#globalRoleSearchTxt").keyup(function (e) {
      e.stopPropagation();

      let txt = $(this).val();
      let content = txt.trim();

      if(content == ''){
        $("#globelSearchRoleListClose").hide();
        $(".globelSearchRoleList").show();
      }else {
        $("#globelSearchRoleListClose").show();
        $(".globelSearchRoleList").hide();
      }
      
      $(".sideRoleListNavLoader").show()
      clearTimeout(globalRolesTimeout);
      globalRolesTimeout = setTimeout(() => {
        roles.globalSiteRoleSearchHandling(content);
      }, 700);

    });
    
    //on keydown, clear the countdown 
    $("#globalRoleSearchTxt").on('keydown', function () {
      clearTimeout(globalRolesTimeout);
    });

  },500)

})

// function used for close in Role search
$("#globelSearchRoleListClose").on("click", function () {
  $("#globalRoleSearchTxt").val('');
  $("#globalRoleSearchTxt").trigger("keyup");
});


/** Roles page Mysite & Global Tab Click Event Start **/


// function used on site search
$("#rolesSitesText").on("keyup", function(e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  let content = $(this).val().trim();
  $("#roles-sites-info #noparticipant").remove();

  if (content == '') {
    $("#globelSearchRoleClose").hide();
    $(".globelSearchRole").show();
    $("#roles-sites-info").find('.sideBar-body').show();
    $("#roles-sites-info").find(".roles-content").remove()
    $("#roles-sites-info").find('.roleSitesHeaderDiv').hide();
    $("#roles-sites-info").find('.rolesHeaderDiv').remove();
    $(".sideRoleNavLoader").hide();
    $("#roles-sites-info").mCustomScrollbar("update");

  } else {
    $("#globelSearchRoleClose").show();
    $(".globelSearchRole").hide();
    $("#roles-sites-info").find('.sideBar-body').hide();
    $("#roles-sites-info").find('.sitecontent').each(function () {
      let siteval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (siteval.indexOf(searchtxt) != -1) {
        $(this).show();
      }

    });
    $(".sideRoleNavLoader").show()

    if($('#roles-sites-info').find('.sitecontent:visible').length){
      $("#roles-sites-info").find('.roleSitesHeaderDiv').show();
      $("#roles-sites-info #noparticipant").remove();

    }else {
      $("#roles-sites-info").find('.roleSitesHeaderDiv').hide();
    } 

    globalRoleSiteSearchFilter = setTimeout(() => {
      $("#roles-sites-info").find('.mCSB_container #noparticipant').remove();
      $("#roles-sites-info").find('.rosterfwdcontent').remove();
      $("#roles-sites-info").find('.rolesHeaderDiv').remove();

      let rolesHeaderDiv = '<div class="rolesHeaderDiv">Roles</div>';
      $("#roles-sites-info").find('.mCSB_container').append(rolesHeaderDiv)

      let siteId = '';
      let searchTxt = $("#rolesSitesText").val();
      searchTxt = encodeURIComponent(searchTxt);
      getSiteAndOnCalls(siteId, searchTxt, 1, 1,'roles-sites-info');

      let filterContactsLength = $("#roles-sites-info").find('.roles-content').length;
      let filterSitessLength = $("#roles-sites-info").find('.sitecontent:visible').length;

      if(filterContactsLength == 0 && filterSitessLength == 0){
        $("#roles-sites-info").find('.mCSB_container').append("<div id='noparticipant'>" + language.locali().nosite_or_roles_found + "</div>");
      }

      $("#roles-sites-info").mCustomScrollbar("update");

    },700);
  }

  $('#roles-sites-info').find('.mCSB_container').css('z-index',0)

});

$("#rolesSitesText").on("keydown", function() {
  clearTimeout(globalRoleSiteSearchFilter);

})

$("#globelSearchRoleClose").on("click", function () {
  $("#rolesSitesText").val('');
  $("#rolesSitesText").trigger("keyup");
});

function globalParticipantEmptyDesign(){
  $(".searchGlobalParticipantClose").trigger("click")
}

function globalParticipanSiteSearchFilter() {

  globalAddParticipanSiteSearchFilter = setTimeout(() => {
    $("#participant-sites-info").find('.participantcontent').remove();
    $("#participant-sites-info").find('.participantHeaderDiv').remove();
    $("#participant-sites-info").find('.mCSB_container #noparticipant').remove();
    let rolesHeaderDiv = '<div class="participantHeaderDiv">Contacts</div>';
    $("#participant-sites-info").find('.mCSB_container').append(rolesHeaderDiv)
    let siteId = '';
    let globalAddParticipantQueryParams;
    let searchTxt = $("#participantSitesText").val();
    if (searchTxt == "") 
      globalAddParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';  
    else 
      globalAddParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + 
  searchTxt;
    getNewSiteAndGroupContacts (globalAddParticipantQueryParams,'participant-sites-info');
    let filterParticipantLength = $("#participant-sites-info").find('.participantcontent').length;
    let filterSitessLength = $("#participant-sites-info").find('.sitecontent:visible').length;
    if(filterSitessLength == 0 && filterParticipantLength == 0){
      $("#participant-sites-info").find('.mCSB_container').append("<div id='noparticipant'>" + language.locali().
  nosite_or_contacts_found + "</div>");
    }
    $('#participant-sites-info').find('.mCSB_container').css('z-index',0)
  });
}

// function used on site search
$("#participantSitesText").on("keyup", function(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  let content = $(this).val().trim();
  $('#broadcast-participant-no-sites').hide()

  if (content == '') {
    globalParticipantEmptyDesign()
  } else {
    $(".searchGlobalParticipantOpen").hide();
    $(".searchGlobalParticipantClose").show();

    $("#globelParticipantSearchContactClose").show();
    $(".globelparticipantSearchContact").hide();
    $("#participant-sites-info").find('.sideBar-body').hide();
    $("#participant-sites-info").find('.sitecontent').each(function () {
      let siteval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (siteval.indexOf(searchtxt) != -1) {
        $(this).show();
      }

    });
    $(".sideParticipantNavLoader").show()

    if($('#participant-sites-info').find('.sitecontent:visible').length){
      $("#participant-sites-info").find('.participantSitesHeaderDiv').show();
      $('#broadcast-participant-no-sites').hide()


    }else {
      $("#participant-sites-info").find('.participantSitesHeaderDiv').hide();
    } 
    globalParticipanSiteSearchFilter()
    
  }
});

$("#participantSitesText").on("keydown", function() {
  clearTimeout(globalAddParticipanSiteSearchFilter);

});


function globalBroadcastParticipantEmptyDesign(){
  $(".searchGlobalBroadcastParticipantClose").trigger("click")

}

function globalBroadcastParticipanSiteSearchFilter() {

  globalBroadcastAddParticipanSiteSearchFilter = setTimeout(() => {
    $("#broadcast-participant-sites-info").find('.broadCastParticipantcontent').remove();
    $("#broadcast-participant-sites-info").find('.broadCastParticipantHeaderDiv').remove();
    $('#broadcast-participant-no-sites').hide()

    let rolesHeaderDiv = '<div class="broadCastParticipantHeaderDiv">Contacts</div>';
    $("#broadcast-participant-sites-info").find('.mCSB_container').append(rolesHeaderDiv)
    let siteId = '';
    let globalAddParticipantQueryParams;
    let searchTxt = $("#broadcastParticipantSitesText").val();
    if (searchTxt == "") 
      globalAddParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1';  
    else 
      globalAddParticipantQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=1&search=' + 
  searchTxt;
  getNewSiteAndBroadCastContacts (globalAddParticipantQueryParams,'broadcast-participant-sites-info');
    let filterParticipantLength = $("#broadcast-participant-sites-info").find('.broadCastParticipantcontent:visible').length;
    let filterSitessLength = $("#broadcast-participant-sites-info").find('.sitecontent:visible').length;
    if(filterSitessLength == 0 && filterParticipantLength == 0){
      $('#broadcast-participant-no-sites').show()
      $('#broadcast-participant-no-sites').text(language.locali().nosite_or_contacts_found)
      $("#broadcast-participant-sites-info").find('.broadCastParticipantHeaderDiv').remove();

    }

    if(filterParticipantLength == 0){
      $("#broadcast-participant-sites-info").find('.broadCastParticipantHeaderDiv').remove();

    }
    $('#broadcast-participant-sites-info').find('.mCSB_container').css('z-index',0)
  });
}

// function used on site search
$("#broadcastParticipantSitesText").on("keyup", function() {
  let content = $(this).val().trim();
  $('#broadcast-participant-no-sites').hide()

  if (content == '') {
    globalBroadcastParticipantEmptyDesign()
  } else {
    $(".searchGlobalBroadcastParticipantOpen").hide();
    $(".searchGlobalBroadcastParticipantClose").show();

    $("#broadcast-participant-sites-info").find('.sideBar-body').hide();
    $("#broadcast-participant-sites-info").find('.sitecontent').each(function () {
      let siteval = $(this).find('.name-meta').text().toUpperCase();
      let searchtxt = content.toUpperCase();
      if (siteval.indexOf(searchtxt) != -1) {
        $(this).show();
      }

    });
    $(".sideBroadcastParticipantNavLoader").show()

    if($('#broadcast-participant-sites-info').find('.sitecontent:visible').length){
      $("#broadcast-participant-sites-info").find('.broadCastParticipantSitesHeaderDiv').show();
      $('#broadcast-participant-no-sites').hide()


    }else {
      $("#broadcast-participant-sites-info").find('.broadCastParticipantSitesHeaderDiv').hide();
    } 
    globalBroadcastParticipanSiteSearchFilter()
    
  }
});

$("#broadcastParticipantSitesText").on("keydown", function() {
  clearTimeout(globalBroadcastAddParticipanSiteSearchFilter);

});

/** Get Globel Contacts Element Append Start **/

function getGlContactDisplayName(name,givenName,surName,values){
  let displayName = "";
  if(name == null){
    if((givenName != null && givenName != '') && (surName != null && surName != '')){
        displayName = givenName + " " + surName;

    }else if((givenName != null && givenName != '')){
        displayName = givenName;

    }else if((surName != null && surName != '')){
        displayName = surName;

    }else {
        displayName = values.ikonixUsername;
    }
  }else {
      displayName = name;
  }

  return displayName;
};

function getGlImageElement(imageId,values,nodeJid,concatedLetter){
  let userToken           = storage.getuserToken(),
      userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
      blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));
  let image,imageElment;
    if (imageId != null && imageId != "src/images/avatar/chat/profile_img_one.svg" &&imageId.trim() != "") {
        if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
            
            let last2 = values.username.slice(-2);
            if(last2 == '==')
                imageId = userInfo[values.username.toLowerCase()][2];
            
            image = mediaUrl + imageId + "?mf=" + userToken;
        } else{
            image = mediaUrl + imageId + "?mf=" + userToken;
        }
        indexDb.blobImageConversion(image,'#globalSiteContacImg-'+nodeJid+'',nodeJid,'profileImages');

        imageElment = '<img id="globalSiteContacImg-'+nodeJid+'" class="contact-image contactImg-'+nodeJid+' " src="' + image + '" usemap="' + imageId + '" onerror="' + contact.profileImgError() + '" data-text="'+concatedLetter+'">';
    } else {
        imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
    }


    if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
        imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';

      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
          userInfo[nodeJid][2] = image;
          userInfo[nodeJid][3] = language.locali().default_status;
          localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
      }
    }

    return imageElment;
};

function getGlContactOncallImgClass(statusId,isUnregisteredUser){
  let oncallImageNameClass = '';
      if(!(utils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
          oncallImageNameClass = 'global-contact-oncall';

  return oncallImageNameClass;
};

function getGlContactFavIcon(values){
  let favIcon     = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';

  return favIcon;
}

function getGlContactSingleNameAlignClass(department,siteAbbreviation,position,activeTechnician){
  let singleNameAlignClass = '';
    if (department == "" && siteAbbreviation == "" && (position == "" && activeTechnician) ) {
        singleNameAlignClass = 'no-destination'
    }

    return singleNameAlignClass
};

function getGlContactProfileStatusDiv(statusId,isUnregisteredUser,values){
  let profileStatusDiv;
  if(utils.getStatusName(statusId) != ""&& isUnregisteredUser == 0) 
      profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + utils.getStatusName(statusId) + '" style="color:' + utils.getStatusImage(statusId) + '">'+ utils.getStatusName(statusId) + '</span></div>' : '';
  else 
      profileStatusDiv = "";

  return profileStatusDiv;
};

function getGlContactOncallElementTopClass(department,position,profileStatusDiv){
  let onCallTopClass = "";

  if((department != "" || position != "") && (profileStatusDiv == '') ){
      onCallTopClass = 'onCallTopMove';
  }

  return onCallTopClass;
};

function getGlContactPositionDiv(position,activeTechnician,onCallTopClass){
  let positionDiv = "";
  if(position != "") {
    positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span>';

    if(activeTechnician){
      positionDiv += '<span class="oncall-contact name-status pull-right '+onCallTopClass+'"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
    }
    positionDiv += '</div>';

  }else {

    if(activeTechnician)
      positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'" style="display:none;">-</span><span class="oncall-contact name-status pull-right '+onCallTopClass+'"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span></div>';

  }

  return positionDiv;
};

function getGlContactDeptSite(department,siteAbbreviation){
  let deptSite = "";
  if (department != "" && siteAbbreviation != "") {
    deptSite = department + ", " + siteAbbreviation;
  } else if (department == "" && siteAbbreviation != "") {
      deptSite = siteAbbreviation;
  } else if (department != "" && siteAbbreviation == "") {
      deptSite = department;
  } 

  return deptSite;
};

function getGlContactDeptSiteDiv(deptSite){
  let deptSiteDiv = '<div class="sideBar-msg role-div">';
  if(deptSite != "")
      deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span>';

  return deptSiteDiv;

};

function getGlContactStatusClassName(deptSite,positionDiv,profileStatusDiv){
  let statusClassName = '';
  if(deptSite == '' && positionDiv == '' && profileStatusDiv != ''){
      statusClassName = 'contact-list-status';
  }

  return statusClassName;
};

function getGlContactScrollSet(pageNumber){
  if (pageNumber == 1 || pageNumber == "") {
    $("#sites-info").mCustomScrollbar("destroy");

      $("#sites-info").mCustomScrollbar({
          callbacks: {
              onTotalScroll: function () {
                    let  siteId            = '',
                      rosterCount       = $('#sites-info .rosterfwdcontent').length,
                      rosterLength      = rosterCount / language.locali().default_size_pagination,
                      page              = Math.round(rosterLength) + 1,
                      search            = $("#sitesText").val();

                 
                  
                  if (rosterCount % language.locali().default_size_pagination == 0) {
                      let glContactQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser='+ '&siteId=' + siteId + '&page=' + page + '&search='+search;
                      getSiteAndMessageContacts(glContactQueryParams);
                  }
              }
          }
      });
  } else {
      $("#sites-info").mCustomScrollbar("update");
  }
};

function getGlContactLengthCheck(){
  let contactLength = $('#sites-info .rosterfwdcontent').length;
  if(contactLength == 0) {
      $("#sites-info").find('.contactHeaderDiv').remove();
  }
};
function getSiteAndMessageContacts(params) {

    let contactsData        = contact.getContacts(params);

    let searchParams = new URLSearchParams(params),
        pageNumber   = searchParams.get('page');
    let favIcon,imageDiv,nameDiv,roleDeptDiv,profileDiv,nameDivContacts;

    if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
      for (let keys in contactsData) {

          let values              = contactsData[keys],
              jid                 = values.username + '@' + stropheConnection.Server(),
              nodeJid             = stropheConnection.Strophe().getNodeFromJid(jid),
              statusId            = values.status,
              surName             = values.surName,
              displayName         = "",
              siteId              = values.siteId,
              email               = values.email,
              siteAbbreviation    = utils.getSiteAbbreviation(siteId),
              position            = values.position.trim(),
              department          = values.department.trim(),
              activeTechnician    = values.isActiveTechnician,
              positionDiv         = "",
              deptSite            = "",
              deptSiteDiv         = "",
              isChatUser          = values.isChatUser,
              isUnregisteredUser  = values.isUnregisteredUser,
              imageId             = values.imageToken.trim(),
              name                = values.name,
              givenName           = values.givenName,
              imageElment,
              concatedLetter;
              displayName = getGlContactDisplayName(name,givenName,surName,values)
              concatedLetter = utils.getImageDisplayText(name);
              imageElment  = getGlImageElement(imageId,values,nodeJid,concatedLetter);
              let oncallImageNameClass = getGlContactOncallImgClass(statusId,isUnregisteredUser);
          
              favIcon = getGlContactFavIcon(values)
          
          imageDiv    = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';

          let singleNameAlignClass = getGlContactSingleNameAlignClass(department,siteAbbreviation,position,activeTechnician)

          nameDiv     = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div '+singleNameAlignClass+'"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

          let profileStatusDiv = getGlContactProfileStatusDiv(statusId,isUnregisteredUser,values);

          let onCallTopClass = getGlContactOncallElementTopClass(department,position,profileStatusDiv);

          nameDivContacts = nameDiv + profileStatusDiv + '</div>';

          positionDiv = getGlContactPositionDiv(position,activeTechnician,onCallTopClass)
         
          deptSite = getGlContactDeptSite(department,siteAbbreviation)

          deptSiteDiv = getGlContactDeptSiteDiv(deptSite)

          roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

          let statusClassName = getGlContactStatusClassName(deptSite,positionDiv,profileStatusDiv)

          profileDiv  = '<div data-id="'+isChatUser+'" data-isunregister="'+isUnregisteredUser+'" class=" contactlist site-c rosterfwdcontent row sideBar-body '+statusClassName+'" id="roster-' + nodeJid + '"><span style="display: none" id="nonapp-'+nodeJid+'" >'+email+'</span><div  class="col-sm-3 col-xs-3 sideBar-avatar">';

          
          $("#sites-info").find('.mCSB_container').append(profileDiv + imageDiv + nameDivContacts + roleDeptDiv);

          let imgEl = document.getElementById("globalSiteContacImg-"+nodeJid);
              if(imgEl){
                utils.imgElementErrorCheck(imgEl)
              }
      }
    }

    getGlContactScrollSet(pageNumber)
    
    $('#sites-info').find('.mCSB_container').css('z-index',0)

    getGlContactLengthCheck()

    roster.Rosterclick();
    return true;
}

/** Get Globel Contacts Element Append End **/

/** Get Globel Sites Element Append Start **/

function getGlSiteOncallParams(siteId,search,page){
  let params;
  if(siteId == null || siteId == "" || siteId.length == 0) {
    params = '?search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination;
  } else {
    params = '?siteId=' + siteId + '&search=' + search + '&page=' + page + '&size=' + language.locali().default_size_pagination;
  }

  return params;
};

function getGlSiteOncallEmptyRoleCheck(isEmpty,elementId){
  if (isEmpty == 1) 
    $(elementId).find(".roles-content").remove()
}

function getGlSiteOncallFillcolor(onCallForegroundColor){
  let fillColor = onCallForegroundColor?utils.hexToRgbA(onCallForegroundColor):"#fff";

  return fillColor;
};
function getGlOncallActiveMemberId(values){
  let activeMemberId = (values.activeMemberId) ? values.activeMemberId.trim() : "";

  return activeMemberId;
};

function getGlOncallActiveMemberObj(activeMemberName,activeMemberId,userLoggedIn){
  let activeMemberColorName;
  if(activeMemberName == "") {
    activeMemberColorName = "#999";
  } else if (activeMemberName != "" && activeMemberId === userLoggedIn) {
      activeMemberColorName = "#42AB14";
      activeMemberName = "You";
  } else {
      activeMemberColorName = "#454545";
  }

  let activeMembObj = {
    activeMemberName  : activeMemberName,
    activeMemberColorName : activeMemberColorName
  }

  return activeMembObj;
};

function getGlOncallIconixBadgeIcon(activeMemberName,isChatUser){
  let IconixBadgeIcon = '';
      if(activeMemberName != "") {
          if(isChatUser){
              IconixBadgeIcon = '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">'
          }
      }

      return IconixBadgeIcon;
};
function getsharedDeviceUserChatEl(values){
  let sharedDeviceUserChatEl;
  if(values.isChatUser == 1){

    sharedDeviceUserChatEl = '<li role="presentation"><a class="sharedDeviceUserChat" data-member="'+values.activeMemberId+'" data-chat-user="'+values.isChatUser+'"><img src="./src/images/svg/ikonixicon.svg" alt="" class="SharedDeviceIconImg">Connect Chat</a></li>';
    }else {
      sharedDeviceUserChatEl = ''
    }

  return sharedDeviceUserChatEl;
};

function getsharedDeviceListEl(values,activeMemberId,sharedDeviceUserChatEl,sharedDeviceRoleEl){

  let sharedDeviceListEl;

  if(values.onCallReceiveDeviceId && (activeMemberId != "" && activeMemberId != "null")){

    sharedDeviceListEl = sharedDeviceUserChatEl + sharedDeviceRoleEl;

  }else if((activeMemberId != "" && activeMemberId != "null")) {

      sharedDeviceListEl = sharedDeviceUserChatEl;

  }else if(values.onCallReceiveDeviceId) {

      sharedDeviceListEl =  sharedDeviceRoleEl;

  }else {
      sharedDeviceListEl = "";
  }

  return sharedDeviceListEl;
}

function getsharedDeviceListElObj(oncall_pager_no,values,pagerIconEl,profileVisibility,msg_pager_no,sharedDeviceListEl){
let oncall_pager_el,msg_pager_el;
  if(oncall_pager_no !=""){
    oncall_pager_el = '<li role="presentation"><a class="msg-role-pager" data-name="'+values.onCallName+'" data-pagerNo = "'+oncall_pager_no+'" data-nodeId="'+values.nodeId+'" data-type="Pager" data-toid="'+values.onCallId+'">'+pagerIconEl+ ' Message Role Pager</a></li>'

    sharedDeviceListEl += oncall_pager_el;
  }
  if(msg_pager_no !="" && profileVisibility.pager_number){
      msg_pager_el = '<li role="presentation"><a href="javascript:void(0);" class="msg-pager" data-name="'+values.activeMemberName+'" data-pagerNo = "'+msg_pager_no+'" data-nodeId="'+values.activeMemberDetails.localUserNodeId+'" data-type="Pager" data-toid="'+values.activeMemberId+'">'+pagerIconEl+' Message Pager</a></li>'
      sharedDeviceListEl += msg_pager_el;
  }

  let obj = {
    sharedDeviceListEl   :   sharedDeviceListEl,
  }
  return obj;

}

function getsharedDevClass(pagerEl){
  let SharedDevClass = (pagerEl == "") ? "sharedMsgIconMove" : "";

  return SharedDevClass;
};
function getGlOncallSharedDeviceMsgObj(sharedInputObj){

  let values          = sharedInputObj.values,
      activeMemberId  = sharedInputObj.activeMemberId,
      oncall_pager_no = sharedInputObj.oncall_pager_no,
      msg_pager_no    = sharedInputObj.msg_pager_no,
      profileVisibility = sharedInputObj.profileVisibility,
      userLoggedIn    =   sharedInputObj.userLoggedIn,
      pagerEl         =   sharedInputObj.pagerEl,
      pagerIconEl     = sharedInputObj.pagerIconEl;
      let sharedDeviceListEl,sharedDeviceMsgEl;

      if((values.onCallReceiveDeviceId || (activeMemberId != "" && values.isActive) || (oncall_pager_no != "" || (msg_pager_no != "" && profileVisibility.pager_number)) && activeMemberId !== userLoggedIn )){
        let SharedDevClass = getsharedDevClass(pagerEl);
        let sharedDeviceUserChatEl;

        sharedDeviceUserChatEl = getsharedDeviceUserChatEl(values)
       

        let sharedDeviceRoleEl = '<li role="presentation"><a class="msg-received-device-pager" data-deviceName ="'+values.onCallReceiveDeviceName+'" data-pagerNo = "'+values.onCallReceiveDeviceId+'" data-nodeid="'+values.nodeId+'" data-type="ReceiveDevice" data-toid="'+values.onCallId+'"><img src="./src/images/svg/message.svg" alt="" class="SharedDeviceIconImg">Message '+values.onCallReceiveDeviceName+'</a></li>'

        sharedDeviceListEl = getsharedDeviceListEl(values,activeMemberId,sharedDeviceUserChatEl,sharedDeviceRoleEl);

        let sharedDeviceListElObj = getsharedDeviceListElObj(oncall_pager_no,values,pagerIconEl,profileVisibility,msg_pager_no,sharedDeviceListEl);

        sharedDeviceListEl   =   sharedDeviceListElObj.sharedDeviceListEl;

        if(sharedDeviceListEl != ""){
            sharedDeviceMsgEl = '<div class="dropdown sharedDevMsgDiv  '+SharedDevClass+' " ><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown"><svg class="sharedDeviceMsgIcon" data-onCallReceiveDeviceName = "'+values.onCallReceiveDeviceName+'" data-onCallReceiveDeviceId = "'+values.onCallReceiveDeviceId+'" data-member="'+values.activeMemberId+'" data-chat-user="'+values.isChatUser+'" data-isactive="'+values.isActive+'" data-nodeId="'+values.nodeId+'" data-oncallName="'+values.onCallName+'" data-name="'+values.activeMemberName+'" data-oncall_pager_no="'+oncall_pager_no+'" data-msg_pager_no = "'+msg_pager_no+'" data-activeMemberId="'+values.activeMemberId+'"  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 20 20"><defs><style>.cls-1{fill:#f09343;}</style></defs><title></title><path class="cls-1" d="M5.83,10.66a.69.69,0,0,1-.51-.21.71.71,0,0,1-.21-.5.73.73,0,0,1,.71-.72H9.07a.72.72,0,0,1,0,1.43Zm0-.78Z"/><path class="cls-1" d="M5.28,17.41a1,1,0,0,1-.38-.07,1.05,1.05,0,0,1-.66-1V14.17H4a2,2,0,0,1-2-2V5A2,2,0,0,1,4,3H15.66a2,2,0,0,1,2,2v7.14a2,2,0,0,1-2,2H8.83L6,17.09A1,1,0,0,1,5.28,17.41ZM4,4.44A.58.58,0,0,0,3.4,5v7.14a.58.58,0,0,0,.58.58H5a.71.71,0,0,1,.72.71v1.94L8,13a.7.7,0,0,1,.51-.22h7.14a.58.58,0,0,0,.58-.58V5a.58.58,0,0,0-.58-.58Z"/><path class="cls-1" d="M13.71,8.12H5.93a.72.72,0,1,1,0-1.43h7.78a.75.75,0,0,1,.51.21.71.71,0,0,1-.51,1.22Z"/></svg></a><ul class="dropdown-menu contact-menu sharedDeviceUlEl" role="menu" aria-labelledby="menu1">'+sharedDeviceListEl+'</ul></div>'
        }else {
            sharedDeviceMsgEl = "";
        }
        
     }else {
        sharedDeviceMsgEl = "";
     }

     let sharedDeviceMsgElObj = {
      sharedDeviceMsgEl   :   sharedDeviceMsgEl,
      pagerEl             :   pagerEl
     }

     return sharedDeviceMsgElObj;
};

function getGlOncallSharedDeviceMemberAppend(oncallAppendObj){
  let values                = oncallAppendObj.values,
      isActive              = oncallAppendObj.isActive,
      activeMemberName      = oncallAppendObj.activeMemberName,
      activeMemberColorName = oncallAppendObj.activeMemberColorName,
      sharedDeviceSpanHtml  = oncallAppendObj.sharedDeviceSpanHtml,
      siteName              = oncallAppendObj.siteName,
      contentDiv            = oncallAppendObj.contentDiv,
      onCallUnit            = oncallAppendObj.onCallUnit

      let siteDiv = "";
      let activeMemberDiv = "";
      let countOfOnCallMembers = values.countOfOnCallMembers;

      if(countOfOnCallMembers != 0 && isActive) {

          if(activeMemberName != "" ) {
              activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" title="'+activeMemberName+'" style="color: '+ activeMemberColorName +'">' + activeMemberName + ' </span>'+sharedDeviceSpanHtml+'</div>';
          } else {
              let content = roles.getOncallRoleType(isActive); 
              activeMemberColorName = roles.getOncallActiveMemberColorName(isActive);

              activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" style="color: '+ activeMemberColorName +'">'+content+'</span>'+sharedDeviceSpanHtml+'</div>';
          }
      }else {
          let content = roles.getOncallRoleType(isActive);
          activeMemberColorName = roles.getOncallActiveMemberColorName(isActive);

          activeMemberDiv += '<div class="sideBar-msg active-member-div"><span class="name-status" style="color: '+ activeMemberColorName +'">'+content+'</span>'+sharedDeviceSpanHtml+'</div>';
      }
      
      if(siteName != "") {
        onCallUnit = (onCallUnit != null) ? onCallUnit : '';

        siteDiv = '<div class="sideBar-msg site-div"><span class="name-status site-name" title="'+ siteName + ' ' +onCallUnit+ ' ">' + siteName + ' ' +onCallUnit+  '</span></div>';
      }
      

      let detailsDiv = siteDiv + activeMemberDiv +  '</div></div>';
          $("#roles-sites-info").find('.mCSB_container').append(contentDiv + detailsDiv);
};


function getGlOncallSharedDeviceScroll(page,elementId,elementName){
  let rolesLength;
  if (page == 1 || page == "") {
    $(elementId).mCustomScrollbar("destroy");

      $(elementId).mCustomScrollbar({
          callbacks: {
              onTotalScroll: function () {
                  let siteId = $("#currentSiteId").val();
                  if(siteId == 0) {
                      siteId = "";
                  }
                  let rolesCount = $(''+elementId+' .roles-content').length;
                  rolesLength = rolesCount / language.locali().default_size_pagination;
                  page = rolesLength + 1;
                  if (rolesCount % language.locali().default_size_pagination == 0) {
                    getSiteAndOnCalls(siteId, '', page, '',elementName);
                  }
              }
          }
      });
  } else {
      $(elementId).mCustomScrollbar("update");
  }

  rolesLength = $(''+elementId+' .roles-content').length;
  if(rolesLength == 0) {
    $(elementId).find('.rolesHeaderDiv').remove();
  } 
};
function getSiteAndOnCalls(siteId, search, page, isEmpty,elementName) {
  let  oncallsUrl = window._env_.ApiURL + "/onCalls/";

  let elementId =  "#"+elementName;
  let params;

  $("#currentSiteId").val(siteId);
  params = getGlSiteOncallParams(siteId,search,page)
     
      $.ajax({
          url: oncallsUrl + params,
          type: 'GET',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData: false,
          async: false,
          crossDomain: true,
          headers: {
              'authorization': storage.getuserToken()
          },
          statusCode: {
              401: function (response) {
                  console.log('Security Token Error');
              },
              500: function (response) {
                  console.log('Internal Server Error');
              }
          },
          success: function (response) {
              userToken = storage.getuserToken();
              let values,Icon;

              getGlSiteOncallEmptyRoleCheck(isEmpty,elementId)
              

              for (let keys in response.data) {
                  values = response.data[keys];
                  let onCallImage = values.onCallImage.trim();
                  let onCallForegroundColor = values.onCallForegroundColor.trim();
                  let fillColor = getGlSiteOncallFillcolor(onCallForegroundColor);
                  switch (values.onCallIcon) {
                      case 'Ambulance':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26848" data-name="Group 26848" transform="translate(-9318 -11373)">\n' +
                              '    <g id="Group_26842" data-name="Group 26842">\n' +
                              '      <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9318 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '      <path id="ambulance" d="M49.471,177.472a4.948,4.948,0,1,1,1.449,3.5A4.767,4.767,0,0,1,49.471,177.472Zm14.841-19.789H49.471v-9.894h6.107a1.607,1.607,0,0,1,.85.348l7.537,7.537a2.823,2.823,0,0,1,.348.85ZM14.841,177.472a4.948,4.948,0,0,1,4.947-4.947,4.947,4.947,0,1,1-3.5,8.445A4.766,4.766,0,0,1,14.841,177.472ZM9.894,151.5v-7.421a1.191,1.191,0,0,1,1.237-1.237h8.657v-8.658a1.191,1.191,0,0,1,1.237-1.237h7.421a1.191,1.191,0,0,1,1.237,1.237v8.658H38.34a1.191,1.191,0,0,1,1.237,1.237V151.5a1.191,1.191,0,0,1-1.237,1.237H29.683v8.657a1.191,1.191,0,0,1-1.237,1.237H21.025a1.191,1.191,0,0,1-1.237-1.237v-8.657H11.131A1.191,1.191,0,0,1,9.894,151.5ZM0,130.474V175a2.377,2.377,0,0,0,.734,1.739,2.377,2.377,0,0,0,1.739.734H9.894a9.533,9.533,0,0,0,2.9,7,9.533,9.533,0,0,0,7,2.9,9.533,9.533,0,0,0,7-2.9,9.533,9.533,0,0,0,2.9-7H44.524a9.893,9.893,0,0,0,16.89,7,9.533,9.533,0,0,0,2.9-7H69.26a2.474,2.474,0,0,0,0-4.947V156.446a6.027,6.027,0,0,0-.5-2.242,5.876,5.876,0,0,0-1.237-1.971l-7.653-7.653a5.875,5.875,0,0,0-1.971-1.237,6.026,6.026,0,0,0-2.242-.5H49.471V130.474A2.508,2.508,0,0,0,47,128H2.474a2.377,2.377,0,0,0-1.739.734A2.377,2.377,0,0,0,0,130.474Z" transform="translate(9357 11290)"    fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>'
                          break;
                      case "Group":
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26856" data-name="Group 26856" transform="translate(-9312 -11567)">\n' +
                              '    <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9312 11567)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="group_12_" data-name="group (12)" transform="translate(9343 11612.5)">\n' +
                              '      <circle id="Ellipse_260" data-name="Ellipse 260" cx="7.304" cy="7.304" r="7.304" transform="translate(7.304 11.978)"    fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20434" data-name="Path 20434" d="M24.357,14.461a15.3,15.3,0,0,0-6.1,12.234v.913H2.739A2.759,2.759,0,0,1,0,24.868V23.042A10.037,10.037,0,0,1,10.042,13h9.129A9.977,9.977,0,0,1,24.357,14.461Z" transform="translate(0 17.237)"    fill="'+fillColor+'"/>\n' +
                              '      <circle id="Ellipse_261" data-name="Ellipse 261" cx="7.304" cy="7.304" r="7.304" transform="translate(65.732 11.978)"     fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20435" data-name="Path 20435" d="M41.687,23.042v1.826a2.759,2.759,0,0,1-2.739,2.739H23.429v-.913a15.3,15.3,0,0,0-6.1-12.234A9.977,9.977,0,0,1,22.516,13h9.129A10.037,10.037,0,0,1,41.687,23.042Z" transform="translate(45.956 17.237)"  fill="'+fillColor+'"/>\n' +
                              '      <circle id="Ellipse_262" data-name="Ellipse 262" cx="10.279" cy="10.279" r="10.279" transform="translate(33.065 6.5)" fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20436" data-name="Path 20436" d="M36.627,14H16.542A10.051,10.051,0,0,0,6.5,24.042V29.52a2.74,2.74,0,0,0,2.739,2.739H43.931A2.74,2.74,0,0,0,46.67,29.52V24.042A10.051,10.051,0,0,0,36.627,14Z" transform="translate(17.237 19.888)" fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Role':
                          Icon =  '<svg class="svg-code-roles" xmlns="http://www.w3.org/2000/svg" width="24px" height="51px" viewBox="0 0 12.195 10.085">\n' +
                              '  <g id="Group_27174" data-name="Group 27174" transform="translate(0.15 0.15)">\n' +
                              '    <g id="Id_Card" data-name="Id Card" transform="translate(0 0)">\n' +
                              '      <path id="Path_10935" data-name="Path 10935" d="M11.147,23.379H.747A.75.75,0,0,1,0,22.63V16.147A.747.747,0,0,1,.747,15.4H4.512a.18.18,0,0,1,0,.361H.747a.386.386,0,0,0-.387.387V22.63a.387.387,0,0,0,.387.387h10.4a.387.387,0,0,0,.387-.387V16.147a.386.386,0,0,0-.387-.387H7.564a.18.18,0,0,1,0-.361h3.583a.747.747,0,0,1,.747.747V22.63A.749.749,0,0,1,11.147,23.379Z" transform="translate(0 -13.594)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                              '      <path id="Path_10937" data-name="Path 10937" d="M9.127,26.377a1.084,1.084,0,1,1,1.084-1.084A1.085,1.085,0,0,1,9.127,26.377Zm0-1.808a.723.723,0,1,0,.723.723A.724.724,0,0,0,9.127,24.569Zm1.857,4.163H7.27a.179.179,0,0,1-.18-.18,2.037,2.037,0,1,1,4.074,0A.179.179,0,0,1,10.984,28.732ZM7.46,28.371h3.332a1.676,1.676,0,0,0-3.332,0Zm8.363-3.278H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Z" transform="translate(-5.772 -20.766)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                              '      <g id="Group_14070" data-name="Group 14070" transform="translate(4.33 1.5)">\n' +
                              '        <path id="Path_10938" data-name="Path 10938" d="M26.21,15.451h-2.4a.506.506,0,0,1-.506-.506v-.69a.506.506,0,0,1,.506-.506h2.4a.506.506,0,0,1,.506.506v.69A.5.5,0,0,1,26.21,15.451Zm-2.4-1.338a.145.145,0,0,0-.145.145v.69a.145.145,0,0,0,.145.145h2.4a.145.145,0,0,0,.145-.145v-.69a.145.145,0,0,0-.145-.145Z" transform="translate(-23.3 -13.75)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                              '      </g>\n' +
                              '      <g id="Group_14071" data-name="Group 14071" transform="translate(5.12)">\n' +
                              '        <path id="Path_10939" data-name="Path 10939" d="M29.21,7.542a.179.179,0,0,1-.18-.18V6.6a.559.559,0,1,0-1.119,0v.762a.18.18,0,1,1-.361,0V6.6a.92.92,0,1,1,1.84,0v.762A.18.18,0,0,1,29.21,7.542Z" transform="translate(-27.55 -5.68)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                              '      </g>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Doctor':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26853" data-name="Group 26853" transform="translate(-9312 -11948)">\n' +
                              '    <g id="doctor" transform="translate(9356.984 11988.932)">\n' +
                              '      <path id="Path_12306" data-name="Path 12306" d="M55.695,47.823c-4.232-8.041-8.464-6.771-13.12-7.195A13.047,13.047,0,0,1,43,44.861c6.771,1.693,8.464,9.734,8.464,14.389v4.232H43V59.25H47.23s0-10.58-6.348-10.58-6.348,10.157-6.348,10.58h4.232v4.232H30.3V59.25c0-4.655,1.693-13.12,8.464-14.389a14.707,14.707,0,0,0-.846-5.5,2.858,2.858,0,0,1-1.693-2.539c0-2.539,3.386-1.693,5.925-6.348,0,0,3.809-9.734,2.539-18.2h0a7.779,7.779,0,0,0-.3-2.18,10.056,10.056,0,0,0-.543-1.9v-.15C42.575,3.809,38.343,0,30.3,0S18.029,3.809,16.336,8.464h0a15.761,15.761,0,0,0-.578,2.07c-.109.9-.129.653-.268,2.162h0c-.846,8.464,2.539,18.2,2.539,18.2,2.539,4.232,5.925,3.386,5.925,6.348,0,2.116-2.116,2.963-4.655,3.386-.846.846-1.693,2.539-1.693,5.925v5.079a6.365,6.365,0,0,1,4.232,5.925c0,2.963-2.963,5.925-6.348,5.925a6.509,6.509,0,0,1-6.348-6.348,5.977,5.977,0,0,1,4.232-5.925V46.13a21.442,21.442,0,0,1,.846-5.5c-2.963.423-6.348,1.693-9.311,7.195C2.37,52.479,1.1,67.714,1.1,67.714H59.08c.423,0-.846-15.236-3.386-19.891Z" transform="translate(0)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_12307" data-name="Path 12307" d="M8.232,15.116A2.116,2.116,0,1,1,6.116,13,2.116,2.116,0,0,1,8.232,15.116Z" transform="translate(9.373 42.018)" fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'User':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26854" data-name="Group 26854" transform="translate(-9312 -12119)">\n' +
                              '    <circle id="Ellipse_266" data-name="Ellipse 266" cx="75" cy="75" r="75" transform="translate(9312 12119)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="Group_26845" data-name="Group 26845">\n' +
                              '      <g id="Group_26843" data-name="Group 26843" transform="translate(322.872 437.125)">\n' +
                              '        <g id="Group_26844" data-name="Group 26844" transform="translate(9028 11724.451)">\n' +
                              '          <path id="person_4_" data-name="person (4)" d="M19.073,26.468c-.333.016-.667.04-1.267.1C8.89,27.467,0,35.076,0,44.443v8.988H71.9V44.443c0-9.3-8.908-16.865-18.393-17.909-.122-.016-.526-.047-.93-.066-4.853,5.482-9.347,5.019-16.627,5.019S23.927,31.95,19.073,26.468Z" transform="translate(0 11.085)"  fill="'+fillColor+'"/>\n' +
                              '          <circle id="Ellipse_263" data-name="Ellipse 263" cx="19" cy="19" r="19" transform="translate(17.128 0.423)"  fill="'+fillColor+'"/>\n' +
                              '        </g>\n' +
                              '      </g>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Network':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26849" data-name="Group 26849" transform="translate(-9607 -11373)">\n' +
                              '    <circle id="Ellipse_267" data-name="Ellipse 267" cx="75" cy="75" r="75" transform="translate(9607 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <path id="Subtraction_7" data-name="Subtraction 7" d="M-8660.631-11598.523h-12.716a1.5,1.5,0,0,1-1.529-1.471v-12.213a1.49,1.49,0,0,1,1.431-1.465h12.946a1.5,1.5,0,0,1,1.4,1.465v12.213A1.5,1.5,0,0,1-8660.631-11598.523Zm-29.813,0h-12.715a1.5,1.5,0,0,1-1.526-1.471v-12.213a1.491,1.491,0,0,1,1.436-1.465h12.9a1.494,1.494,0,0,1,1.438,1.465v12.213A1.5,1.5,0,0,1-8690.444-11598.523Zm-29.809,0h-12.717a1.5,1.5,0,0,1-1.525-1.471v-12.213a1.491,1.491,0,0,1,1.435-1.465h12.9a1.493,1.493,0,0,1,1.437,1.465v12.213A1.5,1.5,0,0,1-8720.253-11598.523Zm25.978-17.613h0l-5.048,0v-7.1h-24.763v7.1h-5.048v-9.527a2.48,2.48,0,0,1,2.524-2.426h27.286v-8.592h-7.946a2.015,2.015,0,0,1-2.051-1.969v-16.377a2.015,2.015,0,0,1,2.051-1.971h20.941a2.014,2.014,0,0,1,2.049,1.971v16.377a2.014,2.014,0,0,1-2.049,1.969h-7.946v8.592h27.286a2.477,2.477,0,0,1,2.521,2.426v9.525l-5.047,0v-7.1h-24.761v7.105Z" transform="translate(18379 23076.344)"  fill="'+fillColor+'"/>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Fire':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26857" data-name="Group 26857" transform="translate(-8957 -11849)">\n' +
                              '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(8957 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <path id="Path_20450" data-name="Path 20450" d="M68.919,114.61c-10.275-.177-17.855-5.146-22.613-13.92-5-9.228-4.048-18.452,1.977-27.1,1.28-1.837,2.606-3.935,5.2-3.171,2.793.82,2.189,3.478,2.272,5.615.12,3.186-.106,6.408.29,9.555a7.515,7.515,0,0,0,6.571,6.959c3.591.612,6.868-.025,9.194-3.181a7.847,7.847,0,0,0,.162-9.631c-1.923-2.8-4.564-5.1-6.848-7.659-7.828-8.757-4.5-16.6,1.388-24.524,1.751-2.36,4.134-2.164,4.773.9,1.862,8.931,9.039,13.7,14.691,19.726,14.352,15.3,10.13,37.574-8.383,44.97A24.585,24.585,0,0,1,68.919,114.61Z" transform="translate(8962.914 11844.05)"  fill="'+fillColor+'"/>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Heartbeat':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26850" data-name="Group 26850" transform="translate(-9607 -11557)">\n' +
                              '    <circle id="Ellipse_268" data-name="Ellipse 268" cx="75" cy="75" r="75" transform="translate(9607 11557)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="cardiogram" transform="translate(9647 11600)">\n' +
                              '      <path id="Path_12304" data-name="Path 12304" d="M35.1,30.829C50.4,17.452,69.844,29.2,70.163,45.139A20.689,20.689,0,0,1,66.739,56.8H51.615l-2.69-4.483a2.055,2.055,0,0,0-3.5-.046l-5.676,8.919L31.48,43.726a2.057,2.057,0,0,0-3.667-.095L20.721,56.8H3.468C-9.312,36.827,15.946,14.073,35.1,30.829Z" transform="translate(0 -25.006)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_12305" data-name="Path 12305" d="M65.784,211.935l5.747-10.672,8.126,17.155a2.057,2.057,0,0,0,3.592.223l5.888-9.253,1.578,2.629a2.055,2.055,0,0,0,1.763,1h13.036c-.409.428,1.769-1.746-26.934,26.809a2.056,2.056,0,0,1-2.9,0c-28.261-28.115-26.526-26.382-26.934-26.809H63.974a2.055,2.055,0,0,0,1.81-1.081Z" transform="translate(-42.026 -177.11)"  fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Security':
                          Icon ='<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26851" data-name="Group 26851" transform="translate(-9607 -11759)">\n' +
                              '    <circle id="Ellipse_269" data-name="Ellipse 269" cx="75" cy="75" r="75" transform="translate(9607 11759)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="Group_26846" data-name="Group 26846" transform="translate(333.508 -74.384)">\n' +
                              '      <path id="Subtraction_8" data-name="Subtraction 8" d="M24.859,56.546h0l-.662-.265A39.91,39.91,0,0,1,0,19.556V7.093L24.859,0,49.718,7.093V19.556a39.91,39.91,0,0,1-24.2,36.725l-.662.265ZM24.852,6.895V49.288l.8-.431a33.322,33.322,0,0,0,17.435-29.3V12.1Z" transform="translate(9321 11883)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20439" data-name="Path 20439" d="M256,7.093V19.556a39.924,39.924,0,0,0,24.2,36.725l.663.265V0Z" transform="translate(9065 11883)"  fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Hospital':
                          Icon ='<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26852" data-name="Group 26852" transform="translate(-9607 -11961)">\n' +
                              '    <circle id="Ellipse_270" data-name="Ellipse 270" cx="75" cy="75" r="75" transform="translate(9607 11961)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="Group_26847" data-name="Group 26847" transform="translate(484.601 311.972)">\n' +
                              '      <path id="Subtraction_5" data-name="Subtraction 5" d="M46.3,77H3.7A3.735,3.735,0,0,1,0,73.244V16.9a3.735,3.735,0,0,1,3.7-3.757H16.667V2a2,2,0,0,1,2-2H33.185a2,2,0,0,1,2,2V13.146H46.3A3.735,3.735,0,0,1,50,16.9V73.244A3.735,3.735,0,0,1,46.3,77ZM17.267,50.707a.6.6,0,0,0-.6.6V70.765a.6.6,0,0,0,.6.6H30.881a.6.6,0,0,0,.6-.6V51.307a.6.6,0,0,0-.6-.6ZM28.377,33.8a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm20.37-13.146a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6ZM21.533,6.4a1.173,1.173,0,0,0-1.163,1.18V8.76a1.173,1.173,0,0,0,1.163,1.18h2.676v2.713a1.173,1.173,0,0,0,1.164,1.18h1.163a1.173,1.173,0,0,0,1.164-1.18V9.94h2.675a1.173,1.173,0,0,0,1.164-1.18V7.58A1.173,1.173,0,0,0,30.374,6.4H27.7V3.686a1.173,1.173,0,0,0-1.164-1.179H25.373a1.173,1.173,0,0,0-1.164,1.179V6.4Z" transform="translate(9172.399 11686.028)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Rectangle_7797" data-name="Rectangle 7797" d="M1,0H60a1,1,0,0,1,1,1V4a0,0,0,0,1,0,0H0A0,0,0,0,1,0,4V1A1,1,0,0,1,1,0Z" transform="translate(9167.399 11759.028)"  fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'Operator':
                          Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                              '  <g id="Group_26858" data-name="Group 26858" transform="translate(-9116 -11849)">\n' +
                              '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(9116 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                              '    <g id="call-center_Svg" data-name="call-center Svg" transform="translate(9058.147 11838.974)">\n' +
                              '      <path id="Subtraction_10" data-name="Subtraction 10" d="M62.066,22.4H.071a5.923,5.923,0,0,1-.061-1.28A22.167,22.167,0,0,1,17.948.054,1.8,1.8,0,0,1,18.3.016a1.2,1.2,0,0,1,.76.27A18.868,18.868,0,0,0,30.291,3.87a27.757,27.757,0,0,0,5.093-.316A18.724,18.724,0,0,0,43.075.287,1.25,1.25,0,0,1,43.864,0a1.643,1.643,0,0,1,.322.034,21.907,21.907,0,0,1,13.453,8.4,21.68,21.68,0,0,1,4.484,13.14,4.633,4.633,0,0,1-.058.816Z" transform="translate(101.853 97.488)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20448" data-name="Path 20448" d="M189.076,137.441a13.047,13.047,0,0,1-6.894-.922,17.627,17.627,0,0,1-7.565-6.2,20.3,20.3,0,0,1-3.646-8.613,16.357,16.357,0,0,1,3.185-13.181,16.617,16.617,0,0,1,29.59,7.565,17.791,17.791,0,0,1-1.278,9.724c-.482,1.132-1.111,2.2-1.677,3.29a.66.66,0,0,1-.356.272c-.9.21-1.823.4-2.724.566a.655.655,0,0,1-.4-.084,4.679,4.679,0,0,0-2.871-.9h-3.206a4.355,4.355,0,0,0-2.766.964,4.515,4.515,0,0,0,.147,7.167A5.149,5.149,0,0,0,189.076,137.441Z" transform="translate(-54.418 -40.296)"  fill="'+fillColor+'"/>\n' +
                              '      <path id="Path_20449" data-name="Path 20449" d="M167.212,90.5a17.245,17.245,0,0,1-3.583,2.012,24.651,24.651,0,0,1-6.915,1.739h-.063a1.369,1.369,0,0,0-1.236.587,2.144,2.144,0,0,1-1.823.838H150.3a2.483,2.483,0,0,1-.021-4.967c1.09-.021,2.2,0,3.29,0a2.523,2.523,0,0,1,1.991.943.571.571,0,0,0,.335.168,20.427,20.427,0,0,0,9.409-3.08.354.354,0,0,0,.189-.482A4.252,4.252,0,0,1,165.305,87c-.021-2.745-.021-5.511,0-8.257a4.023,4.023,0,0,1,2.1-3.625.311.311,0,0,0,.189-.356,20.888,20.888,0,0,0-2.6-8.885,21.265,21.265,0,0,0-31.958-6.329,20.587,20.587,0,0,0-3.9,4.086,26.417,26.417,0,0,0-1.572,2.515,20.923,20.923,0,0,0-2.452,8.592.285.285,0,0,0,.168.335,4.037,4.037,0,0,1,2.117,3.688v8.11a4.183,4.183,0,0,1-2.724,4,4.35,4.35,0,0,1-4.82-1.257,3.925,3.925,0,0,1-.943-2.452c-.021-2.892-.042-5.8,0-8.7a3.873,3.873,0,0,1,2.117-3.395.431.431,0,0,0,.251-.4,23.147,23.147,0,0,1,.65-4.485,24.909,24.909,0,0,1,9.263-14.082,24.249,24.249,0,0,1,11.945-4.883,25.157,25.157,0,0,1,27.054,17.079,23.78,23.78,0,0,1,1.216,6.412.341.341,0,0,0,.23.356,4.083,4.083,0,0,1,2.159,3.709c0,2.682-.021,5.365,0,8.047a4.249,4.249,0,0,1-6.413,3.751C167.338,90.586,167.3,90.544,167.212,90.5Z" transform="translate(-13.463)"  fill="'+fillColor+'"/>\n' +
                              '    </g>\n' +
                              '  </g>\n' +
                              '</svg>';
                          break;
                      case 'None':
                          Icon = "";
                          break;
                      default:
                          Icon = "";
                  }

                  let userLoggedIn = stropheConnection.logininfo.userJID;
                  let activeMemberId = getGlOncallActiveMemberId(values);
                  let activeMemberName = values.activeMemberName.trim();
                  let isChatUser = values.isChatUser;
                  let onCallId = values.onCallId.trim();
                  let onCallName = values.onCallName.trim();
                  let siteId = values.siteId;
                  let isActive = values.isActive;
                  let onCallUnit = values.onCallUnit;

                  let activeMemberColorName = "";

                  let activeMemberObj = getGlOncallActiveMemberObj(activeMemberName,activeMemberId,userLoggedIn);
                  activeMemberName = activeMemberObj.activeMemberName;
                  activeMemberColorName = activeMemberObj.activeMemberColorName;

                  let IconixBadgeIcon = getGlOncallIconixBadgeIcon(activeMemberName,isChatUser);

                  let contentDiv = '<div class="roles-content row sideBar-body" id="role-' + onCallId + '" data-member="'+activeMemberId+'" data-chat-user="'+isChatUser+'" data-isunregister="'+values.isUnregisteredUser+'" data-isactive="'+isActive+'"><div  class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><div class="color-code-roles" style="background-color: '+ utils.hexToRgbA(onCallImage) +';">'+Icon+IconixBadgeIcon+'</div></div> </div><div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name contact-name"><div class="contact-list-div"><span class="name-meta oncall-name" title="'+onCallName+'">' + onCallName + '</span></div></div></div>';
                  let siteName = utils.getSiteAbbreviation(siteId);
                  let msg_pager_no = (values.activeMemberDetails != null) ? values.activeMemberDetails.pagerNumber : ""; 
                  let oncall_pager_no = values.onCallPagerNumber; 
                  let pagerIconEl = '<img src="./src/images/pager.svg" class="SharedDeviceIconImg"/>';
                  let pagerEl = "";
                  let profileVisibility = JSON.parse(localStorage.getItem("settings")).profileVisibility;
                 

                  let sharedDeviceMsgEl

                  let sharedInputObj = {
                    values  : values,
                    activeMemberId  : activeMemberId,
                    oncall_pager_no : oncall_pager_no,
                    msg_pager_no    : msg_pager_no,
                    profileVisibility : profileVisibility,
                    userLoggedIn    :   userLoggedIn,
                    pagerEl         :   pagerEl,
                    pagerIconEl     : pagerIconEl,

                  }

                  let sharedDeviceMsgElObj = getGlOncallSharedDeviceMsgObj(sharedInputObj)
                  
                  sharedDeviceMsgEl  =   sharedDeviceMsgElObj.sharedDeviceMsgEl;
                  pagerEl            =   sharedDeviceMsgElObj.pagerEl;
                   
                  let sharedDeviceSpanHtml;
                  sharedDeviceSpanHtml = '<span class="sharedDeviceSpanHtml">'+sharedDeviceMsgEl+pagerEl+'</span>';
              
                  let oncallAppendObj = {
                    values                : values,
                    isActive              : isActive,
                    activeMemberName      : activeMemberName,
                    activeMemberColorName :  activeMemberColorName,
                    sharedDeviceSpanHtml  : sharedDeviceSpanHtml,
                    siteName              :   siteName,
                    contentDiv            : contentDiv,
                    onCallUnit            : onCallUnit 
                  }
                  getGlOncallSharedDeviceMemberAppend(oncallAppendObj);
                  
              }
              getGlOncallSharedDeviceScroll(page,elementId,elementName);
                     
          },
          error: function (jqXHR, exception) {
            utils.errorMsgHandling(jqXHR, exception)
              
          },
          complete: function(){
            $(".sideRoleNavLoader").hide()


          }
      });
      return true;
  };

/** Get Globel Sites Element Append End **/

/** Get Globel Participant Contacts Element Append Start **/

function glGroupContactEmptyCheck(pageNumber,elementId){
  if (pageNumber == 1) 
        $(elementId).find('.participantcontent').remove();
};

function getGlGroupContactImgElement(imageId,values,nodeJid,concatedLetter){
  let userToken           = storage.getuserToken(),
      userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
      blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));

  let image,imageElment;
  if (imageId != null && imageId.trim() != "" && imageId !== 'src/images/avatar/chat/profile_img_one.svg') {
    if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
        
        let last2 = values.username.slice(-2);
        if(last2 == '==')
            imageId = userInfo[values.username.toLowerCase()][2];
        
        image = mediaUrl + imageId + "?mf=" + userToken;
    } else{
        image = mediaUrl + imageId + "?mf=" + userToken;
    }
    indexDb.blobImageConversion(image,'#addParticipantGlobalImgId-'+nodeJid+'',nodeJid,'profileImages');

    imageElment = '<img id="addParticipantGlobalImgId-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + contact.profileImgError() + '" data-text="'+concatedLetter+'">';
  } else {
      imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
  }

  if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
  imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
    userInfo[nodeJid][2] = image;
    userInfo[nodeJid][3] = language.locali().default_status;
    localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
    }
  }

  return imageElment;
};


function getGlGroupContactFavIcon(values){
  let favIcon = '';

  if(values.isChatUser == 1 && values.isUnregisteredUser != 1){
    favIcon = '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">';
  }

  return favIcon;
};

function glGroupPositionDiv(position,activeTechnician){
  let positionDiv = "";
  if(position != "") {
    positionDiv = '<div class="sideBar-msg role-div"><span class="name-status" title="'+position+'">' + position + ' </span>';

    if(activeTechnician)
    positionDiv += '<span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';

    positionDiv += '</div>';

  }else {

    if(activeTechnician)
    positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'" style="display:none;">-</span><span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span></div>';

  }

  return positionDiv;
};

function glGroupDeptSite(department,siteAbbreviation){
  let deptSite ='';
  if (department != "" && siteAbbreviation != "") {
    deptSite = department + ", " + siteAbbreviation;
  } else if (department == "" && siteAbbreviation != "") {
      deptSite = siteAbbreviation;
  } else if (department != "" && siteAbbreviation == "") {
      deptSite = department;
  } 

  return deptSite;
};

function glGroupDeptSiteDiv(deptSite){
  let deptSiteDiv = '<div class="sideBar-msg dept-div">';
  if(deptSite != "")
      deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';

  return deptSiteDiv;
};

function glGroupParticipantDivAppend(elementId){


  let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
  if (groupaddUserInfo != null) {
      for (let key in groupaddUserInfo) {
          $(elementId).find("#participant-" + key).css("display", "none");
      }
  }

  let newGroupLength = $(""+elementId+" .participantcontent:visible").length;
  if(newGroupLength == 0) {
      $(elementId).find('.participantHeaderDiv').remove();
  }

};

function getsiteHeaderDivHeight(siteHeaderDivHeight){
  let value = siteHeaderDivHeight ? siteHeaderDivHeight : 0;

  return value;
};

function getcontactHeaderDivHeight(contactHeaderDivHeight){
  let value = contactHeaderDivHeight ? contactHeaderDivHeight : 0;

  return value;
};

function getcontactsiteElHeight(siteElHeight){
  let value = siteElHeight ? siteElHeight : 0;

  return value;
};

function glGroupParticipantDivHeightSet(divHeightObj){
  
  let elementId   =   divHeightObj.elementId,
      mainDivHeight = divHeightObj.mainDivHeight,
      totalHeight = divHeightObj.totalHeight,
      siteElHeight  = divHeightObj.siteElHeight,
      siteHeaderDivHeight = divHeightObj.siteHeaderDivHeight,
      contactHeaderDivHeight  = divHeightObj.contactHeaderDivHeight,
      inputboxHeight  = divHeightObj.inputboxHeight;

      if(mainDivHeight < totalHeight){
        let fixedHeight =totalHeight - (siteElHeight+siteHeaderDivHeight+contactHeaderDivHeight+inputboxHeight);
        $(elementId).css('height',fixedHeight)
      }
};

function glGroupCustomScrollSet(selectedSitesInfo,siteId,rosterCount,page,search) {
  let selectedSites;

  if(typeof selectedSitesInfo != "undefined" && selectedSitesInfo != null && 
      selectedSitesInfo.contactsSelectedSites.length != 0) 
      selectedSites = selectedSitesInfo.contactsSelectedSites;
  else 
      selectedSites = siteId;
  
  if (rosterCount % language.locali().default_size_pagination == 0) {
      let glGroupCustomQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1'+ '&siteId=' + selectedSites + '&page=' + page + '&search='+search;
      getNewSiteAndGroupContacts(glGroupCustomQueryParams,'participant-sites-info');
  }
};
function ImgElemntInSiteAndGrpContact(imgEl){
  if(imgEl){
    utils.imgElementErrorCheck(imgEl)
  }
}
function getNewSiteAndGroupContacts (newQueryParams,elementName) {

    let elementId =  "#"+elementName;

    let contactsData        = contact.getContacts(newQueryParams);
       

    let nameDivContacts;
    let searchParams = new URLSearchParams(newQueryParams),
        pageNumber   = searchParams.get('page');

        glGroupContactEmptyCheck(pageNumber,elementId)
    

    if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
      for (let keys in contactsData) {

          let values              = contactsData[keys],
              jid                 = values.username + '@' + stropheConnection.Server(),
              nodeJid             = stropheConnection.Strophe().getNodeFromJid(jid),
              statusId            = values.status,
              surName             = values.surName,
              displayName         = "",
              siteId              = values.siteId,
              siteAbbreviation    = utils.getSiteAbbreviation(siteId),
              position            = values.position.trim(),
              department          = values.department.trim(),
              activeTechnician    = values.isActiveTechnician,
              positionDiv         = "",
              deptSite            = "",
              deptSiteDiv         = "",
              isUnregisteredUser  = values.isUnregisteredUser,
              imageId             = values.imageToken.trim(),
              name                = values.name,
              givenName           = values.givenName,
              imageElment ,
              concatedLetter;

              displayName = getGlContactDisplayName(name,givenName,surName,values)
              concatedLetter = utils.getImageDisplayText(name);
              imageElment = getGlGroupContactImgElement(imageId,values,nodeJid,concatedLetter);

              let oncallImageNameClass = getGlContactOncallImgClass(statusId,isUnregisteredUser);

              let favIcon = getGlGroupContactFavIcon(values);
           
          let participantDiv  = '<div class="row sideBar-body participantcontent" id="participant-' + nodeJid + '" data-ischatuser = "'+values.isChatUser+'" data-isunregisteruser = "'+values.isUnregisteredUser+'"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
          let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
          let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

          let profileStatusDiv;
          profileStatusDiv = getGlContactProfileStatusDiv(statusId,isUnregisteredUser,values);
        
          nameDivContacts = nameDiv + profileStatusDiv + '</div>';

          positionDiv = glGroupPositionDiv(position,activeTechnician);

          deptSite = glGroupDeptSite(department,siteAbbreviation);

          deptSiteDiv = glGroupDeptSiteDiv(deptSite);

          let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

          if($(""+elementId+" [id='participant-"+nodeJid+"']").length == 0) {

            $(elementId).find('.mCSB_container').append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);

            let imgEl = document.getElementById("addParticipantGlobalImgId-"+nodeJid);

            ImgElemntInSiteAndGrpContact(imgEl)
          }
      }
    }
    glGroupParticipantDivAppend(elementId)

    if (pageNumber == 1 || pageNumber == "") {
      $(elementId).mCustomScrollbar("destroy");

      /** Golbal Team search Height Calculation  **/
      let siteHeaderDivHeight = $(elementId+' .participantSitesHeaderDiv:visible').innerHeight()
      siteHeaderDivHeight = getsiteHeaderDivHeight(siteHeaderDivHeight); 

      let contactHeaderDivHeight = $(elementId+' .participantHeaderDiv:visible').innerHeight()
      contactHeaderDivHeight = getcontactHeaderDivHeight(contactHeaderDivHeight);

      let siteElHeight = $(elementId+' .sitecontent:visible').innerHeight();
      siteElHeight = getcontactsiteElHeight(siteElHeight);

      let inputboxHeight = $('#participantSitesText').innerHeight()

      let totalHeight = $(elementId).innerHeight();
      let mainDivHeight = $('#participants').height()

      let divHeightObj = {
        elementId   :   elementId,
        mainDivHeight : mainDivHeight,
        totalHeight : totalHeight,
        siteElHeight  : siteElHeight,
        siteHeaderDivHeight : siteHeaderDivHeight,
        contactHeaderDivHeight  : contactHeaderDivHeight,
        inputboxHeight  : inputboxHeight
      }

      glGroupParticipantDivHeightSet(divHeightObj)

      /** Participant Div Calculation and Update**/
      let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId)-23;
      let osType = utils.getDeviceOsType();

      if(osType == "Windows"){
        participantStaticDivHeight = participantStaticDivHeight-4;
      }

      $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
        if($('#bubble-block').children().length > 0){
          $('#participants').css('padding','0px')
          $('#participant-sites-info').css('padding','0px')
        }else {
          $('#participants').css('padding','1px')
          $('#participant-sites-info').css('padding','1px')
        }
        $(elementId).mCustomScrollbar({
            callbacks: {
                onTotalScroll: function () {
                    let selectedSitesInfo = JSON.parse(localStorage.getItem("selectedSitesInfo")),
                        siteId            = '',
                        rosterCount       = $(''+elementId+' .participantcontent').length,
                        rosterLength      = rosterCount / language.locali().default_size_pagination,
                        page              = Math.round(rosterLength) + 1,
                        search            = $("#participantSitesText").val();

                        glGroupCustomScrollSet(selectedSitesInfo,siteId,rosterCount,page,search)
                }
            }
        });
    } else {
      /** Participant Div Calculation and Update**/
      let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId);
      let osType = utils.getDeviceOsType();

      participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight)

      $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
      $("#participant-sites-info").mCustomScrollbar("update");
    }

    return true;

};

/** Get Globel Participant Contacts Element Append End **/

function getNewSiteAndBroadCastContacts(newQueryParams,elementName){
  broadcastContact.getNewSiteAndBroadCastContactsCallBack(newQueryParams,elementName)
}



// Check for session timeout periodically
setInterval(() => {
  const remainingTime = getRemainingSessionTime();
  if (remainingTime <= 0) {
    keycloakApi.handleLogout();
  }
}, 1000); // Check every second


// Function to get the remaining time until session expiration
function getRemainingSessionTime() {
  const sessionStartTime = localStorage.getItem("sessionStartTime");
  const sessionDuration = localStorage.getItem("totalSessionTime");
  
  const currentTime = Date.now();
  const elapsedTime = currentTime - parseInt(sessionStartTime, 10);
  return sessionDuration - elapsedTime;
}