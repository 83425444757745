let attachment = require("./attachment.js");
let clearmessage = require("./clearmessage.js");
let utils = require("./utils.js");
let mediaSendMessage = require("./sendMessage.js");
let groups = require("./groups.js");
let stropheConnection = require("./stropheConnection.js");
let mediaUpload = require("./mediaupload.js");
let conversation = require("./conversation.js");
let mediaUserOncalls = require('./userOncalls.js');
let setting = require('./settings.js');
let language = require('./locale/language.js');
const mediaIndexDb = require("./indexDb.js");
const broadcast = require("./broadcast.js");
let addparticipant = true;
let MediaStream,Mediafile,actionType,content,chatType;
let settings,output;
document.getElementById("media-upload").onchange = function (e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  $("#upload-caption").prop("disabled", false);
  let validImageTypes = ["image/jpeg", "image/png"];
  let sFileName = this.files[0].name;
  let sFileExtension = sFileName.split(".")[sFileName.split(".").length - 1].toLowerCase();
  let iFileSize = this.files[0].size;
  let sFile = this.files[0];
  let fileType = this.files[0].type;
  setting.getSettings();
  settings = JSON.parse(localStorage.getItem("settings"));
  let kilobyte = 1024*1024;
  let fileSize = mediaUploadFileSize(settings);
  let megabyte = kilobyte * parseInt(fileSize);
  if (( ( $.inArray(fileType, validImageTypes) >= 0 ) && iFileSize < megabyte) || (sFileExtension === "mp4" && iFileSize < megabyte)) {
    let reader = new FileReader();
    reader.onload = function (e) {
      let result = e.target.result;
      mediaFileReader(sFileName,sFileExtension,iFileSize,sFile,fileType,validImageTypes,result);
    };
    // read the image file as a 
    reader.readAsDataURL(sFile);
    
  } else {
    mediaFileErrorHandling(fileType,validImageTypes,iFileSize,megabyte,fileSize,sFileExtension);
  }
};

function mediaUploadFileSize(settings){
  let fileSize = typeof settings.fileSizeLimitInMB != 'undefined' ? settings.fileSizeLimitInMB : 1;

  return fileSize;
};
function mediaFileReader(sFileName,sFileExtension,iFileSize,sFile,fileType,validImageTypes,result){
  let message_type;
    $("#media-upload").val("");
    if (sFileExtension === "mp4") {
      message_type = "video";
      videoUpload(sFile, sFileName, message_type, iFileSize, result);
    } else if ( $.inArray(fileType, validImageTypes) >= 0 ) {
      $("#media-upload-popup").css("visibility", "visible");
      $("#media-upload-popup").css("opacity", "1");
      message_type = "image";
      let imageData = result;
      let img = new Image();
      img.src = imageData;
      img.onload = function () {
        let imagetype = message_type + "/" + sFileExtension;
        let canvas = $("<canvas/>").get(0);
        canvas.width = 81;
        canvas.height = 61;
        let context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, 81, 61);
        imageData = canvas.toDataURL(imagetype, 0.1);
        let thumb = imageData.replace(/^data:image\/\w+;base64,/, "");
        imageUpload(sFile, sFileName, message_type, iFileSize, thumb,result);
      };
    }
};
function mediaFileErrorHandling(fileType,validImageTypes,iFileSize,megabyte,fileSize,sFileExtension){
  $("#media-upload").val("");
    if ( $.inArray(fileType, validImageTypes) >= 0 ) {
      if (iFileSize < megabyte) {
        $("#infoContent").text('Sorry, Upload file size is less than '+fileSize+'MB');
      } else {
        $("#infoContent").text('Sorry, this file types are not supported, Please upload the file types JPG, JPEG, PNG and the file size is less than '+fileSize+'MB');
      }
    }
    else if (sFileExtension === "mp4") {
      if (iFileSize < megabyte) {
        $("#infoContent").text('Sorry, Upload file size is less than '+fileSize+'MB');
      } else {
        $("#infoContent").text('Sorry, this file types are not supported, Please upload the file types MP4 file and the file size is less than '+fileSize+'MB');
      }
    } else {
      $("#infoContent").text("Sorry, this file types are not supported, Please upload the file types 'JPG, JPEG, PNG or MP4' file");
    }
    $("#infobox").css("display", "block").delay(4000).fadeOut();
}

function imgUploadCaptionFunc(){
  $("#upload-caption").on("input", function () {
    let  Settings = JSON.parse(localStorage.getItem("settings"));
    let messageCharacterLimit = Settings.messageCharacterLimit;
    let text_length = $("#upload-caption").val().length;
    if (text_length > messageCharacterLimit) {
      let limitValue = $("#upload-caption").val().substring(0, messageCharacterLimit);
      $("#upload-caption").val(limitValue);
    }
  });
}

function imgMediaUploadSendFunc(Oriimage, message_type, iFileSize, thumbnailImage, sFileName){
  $(".media-upload-send").click(function () {
    let CommentText = $('#comment').text();
    $(".loader").css("display", "block");
    $(".media-upload-send").css("display", "none");
    $(".retry").css("display", "none");
    $("#upload-caption").prop("disabled", true);
    $('.attachment').trigger('click');
    let caption = $("#upload-caption").val();
    let options = {};
    options.thumb = thumbnailImage;
    options.name = sFileName;
    options.caption = caption;
    let randomValue = utils.cryptoRandomGenerate();
    let messageid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
   
      attachment.fileUploadToS3(Oriimage, message_type, messageid, "media-upload-popup", iFileSize, options);
      
      $('#comment').text(CommentText);
      let offlineText = JSON.parse(localStorage.getItem("offlineText"));
      if(offlineText == null){
        offlineText = {};
      }
      let toJid = JSON.parse(localStorage.getItem("toJID"));
      let nodeJid = stropheConnection.Strophe().getNodeFromJid(toJid);
      offlineText[nodeJid] = [];
      offlineText[nodeJid].push('chatcontent');
      offlineText[nodeJid].push(CommentText);
      localStorage.setItem("offlineText", JSON.stringify(offlineText));
  });
}

function imgUploadKeypressFunc(){
  $("#upload-caption").keypress(function (e) {
    if (e.keyCode == 13) {
      $("#upload-caption").prop("disabled", true);
      e.preventDefault();
      $(".media-upload-send").trigger("click");
    }
  });
}

function imageUpload(Oriimage, sFileName, message_type, iFileSize, thumbnailImage, Image) {
  let kilobyte = 1024*1024;
  let fileSize = typeof settings.fileSizeLimitInMB != 'undefined'?settings.fileSizeLimitInMB :1
  let megabyte = kilobyte * parseInt(fileSize);
  let Settings = JSON.parse(localStorage.getItem("settings"));
  let messageCharacterLimit = Settings.messageCharacterLimit;

  if (iFileSize <= megabyte && message_type === "image") {
    $(".media-upload-popup").html("<div id = 'upload-media'><img class='media-attach-prev' src='images/pic.jpg' /><a class='image-cropped media-upload-send'><i class='sendIcon'></i></a><div><textarea type='text' maxlength='20000' id='upload-caption' class='attachmentCaptionText' placeholder='Add a caption…' style='margin-right:10px'></textarea><span style='display:none;'><small id='uploadCaptionCharcount'>0</small>/<small id='uploadCaptionCharTotalCount'>"+messageCharacterLimit+"</small></span></div><div class='ack-select'><div class='select-popup'><input id='ack' type='checkbox' name='ackCheckbox' value='1'><label class='checkbox-inline' for='ack'><span>Acknowledgement</span></label></div><div class='select-popup'><input type='checkbox' name='ackCheckbox' id='accrej' value='2'><label class='checkbox-inline' for='accrej'><span>Accept / Reject</span></label></div></div></div>");
    document.getElementById("media-upload-popup").style.display = "block";
    document.getElementById("media-upload-popup").style.visibility = "visible";
    document.getElementById("media-upload-popup").style.opacity = "1";
    document.getElementById("upload-media").style.opacity = "1";
    $("#media-upload-popup").find("img").attr("src", Image);
    $(".media-upload-send").css("display", "block");
    $(".retry").css("display", "block");
    imgUploadCaptionFunc()
    
    imageUploadCheckboxUpdate()
    
    imgMediaUploadSendFunc(Oriimage, message_type, iFileSize, thumbnailImage, sFileName)
    
    imgUploadKeypressFunc()
    
    /** Img Upload Caption Input Box Keyup Function **/
    $("#upload-caption").on("keyup", function () {
      Settings = JSON.parse(localStorage.getItem("settings"));
      let messageCharacterLimit = Settings.messageCharacterLimit;
      
      let text_length = $("#upload-caption").val().length; //Input Box Text Length
      if (text_length <= messageCharacterLimit) {
        $('#uploadCaptionCharcount').text(text_length);
        if(text_length == messageCharacterLimit){
          $('#uploadCaptionCharcount').css('color','red');
          $('#uploadCaptionCharTotalCount').css('color','red');

        }else {
          $('#uploadCaptionCharcount').removeAttr( 'style' );
          $('#uploadCaptionCharTotalCount').removeAttr( 'style' );

        }

        let limitValue = $("#upload-caption").val().substring(0, messageCharacterLimit); //Truncate the String
        $("#upload-caption").val(limitValue);
      }
      else {
        $('#uploadCaptionCharcount').removeAttr( 'style' );
        $('#uploadCaptionCharTotalCount').removeAttr( 'style' );
      }
    });

  } else {
    $("#infoContent").text(language.locali().filesize_large);
    $("#infobox").css("display", "block").delay(4000).fadeOut();
  }
}

function videoUploadSendFunc(thumb, sFileName, videos, OriVideo, message_type,iFileSize){
  $(".media-upload-send").click(function () {
    $(".media-upload-send").off("click");
    $(".loader").css("display", "block");
    $(".media-upload-send").css("display", "none");
    $(".retry").css("display", "none");
    $("#upload-caption").prop("disabled", true);
    $('.attachment').trigger('click');
    let caption = $("#upload-caption").val();
    let randomValue = utils.cryptoRandomGenerate();
    let messageid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
    let options = {};
    options.thumb = thumb;
    options.name = sFileName;
    options.caption = caption;
    let minutes = parseInt(videos.duration / 60, 10);
    let seconds = parseInt(videos.duration % 60);
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    options.duration = minutes + ":" + seconds;
      attachment.fileUploadToS3(OriVideo, message_type, messageid, "media-upload-popup", iFileSize, options);
        
  });
}

function videoUpload(OriVideo, sFileName, message_type, iFileSize, loadVideo, sFileduration) {
  let kilobyte = 1024*1024;
  let fileSize = typeof settings.fileSizeLimitInMB != 'undefined'?settings.fileSizeLimitInMB :1
  let megabyte = kilobyte * parseInt(fileSize);
  if (iFileSize <= megabyte && message_type === "video") {
    $(".media-upload-popup").html("<div id = 'upload-media'><video id='uploadvideo' controls controlsList='nofullscreen nodownload noremote foobar' style='width:100%' src='" + loadVideo + "'></video><a class='image-cropped media-upload-send'><i class='sendIcon'></i></a><div><textarea type='text' id='upload-caption' class='attachmentCaptionText' maxlength='1024' id='upload-caption' placeholder='Add a caption…'></textarea></div><div class='ack-select'><div class='select-popup'><input type='checkbox' name='ackCheckbox' id='ack' value='1'><label class='checkbox-inline' for='ack'><span>Acknowledgement</span></label></div><div class='select-popup'><input type='checkbox' name='ackCheckbox' id='accrej' value='2'><label for='accrej' class='checkbox-inline'><span>Accept / Reject</span></label></div></div></div>");
    document.getElementById("media-upload-popup").style.display = "block";
    document.getElementById("media-upload-popup").style.visibility = "visible";
    document.getElementById("media-upload-popup").style.opacity = "1";
    document.getElementById("upload-media").style.opacity = "1";
    let videos = document.getElementById("uploadvideo");
    videos.onloadeddata = function () {
      let canvas = $("<canvas/>").get(0);
      canvas.width = 96;
      canvas.height = 96;
      let context = canvas.getContext("2d");
      $(".media-upload-send").css("display", "block");
      $(".retry").css("display", "block");
      /** Video Default Width Set**/
      let bodyHeight = $('body').height();
      let videoPopupHeight = $('.media-upload-popup').height();
      if(videoPopupHeight >= bodyHeight) {
        $('#uploadvideo').css('max-width','45%');
      }

      context.drawImage(videos, 0, 0, 96, 96);
      let thumbnailImage = canvas.toDataURL("image/jpeg", 0.2);
      let thumb = thumbnailImage.replace(/^data:image\/\w+;base64,/, "");

      videoUploadSendFunc(thumb, sFileName, videos, OriVideo, message_type,iFileSize);

      $(':checkbox').on('change', function () {
        let th = $(this),
          name = th.attr('name');
        if (th.is(':checked')) {
          $(':checkbox[name="' + name + '"]').not(th).prop('checked', false);
        }
      });

      $("#upload-caption").keypress(function (e) {
        if (e.keyCode == 13) {
          $("#upload-caption").prop("disabled", true);
          e.preventDefault();
          $(".media-upload-send").trigger("click");
        }
      });
    };
  } else {
    $("#infoContent").text(language.locali().filesize_large);
    $("#infobox").css("display", "block").delay(4000).fadeOut();
  }
}

function convertDataURIToBinary(dataURI) {
  let BASE64_MARKER = ";base64,";
  let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  let base64 = dataURI.substring(base64Index);
  let raw = window.atob(base64);
  let array = new Uint8Array(new ArrayBuffer(raw.length));

  for (let i = 0; i < raw.length; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
function fileUploadFileSize(settings){
  let fileSize = typeof settings.fileSizeLimitInMB != 'undefined'?settings.fileSizeLimitInMB :1

  return fileSize;
};
function fileUploadPopupUpdate(sFileExtension,sFileName){
  if (sFileExtension == "pdf" || sFileExtension == "PDF") {
    $(".media-upload-popup").addClass("pdf-viewer");
    $(".media-upload-popup").html("<div id = 'upload-media' style='width:100%'> <canvas id='the-canvas' style='display: block;'></canvas><span class='fileCaption'>" + sFileName + "</span><a class='image-cropped media-upload-send'><i class='sendIcon'></i></a><div class='ack-select'><div class='select-popup'><input type='checkbox' name='ackCheckbox' value='1' id='ack'><label for='ack' class='checkbox-inline'><span>Acknowledgement</span></label></div><div class='select-popup'><input type='checkbox' id='accrej' name='ackCheckbox' value='2'><label class='checkbox-inline' for='accrej'><span>Accept / Reject</span></label></div></div></div>");
  } else {
    $(".loader").css("display", "none");
    let imgsrc;
    if (sFileExtension == "xls" || sFileExtension == "xlsx") {
      imgsrc = "./src/images/xls.webp";
    } else {
      imgsrc = "./src/images/doc-file-format.webp";
    }
    $(".media-upload-popup").html("<div id = 'upload-media' style='width:100%'><img  src='" + imgsrc + "' /><span class='fileCaption'>" + sFileName + "</span><a class='image-cropped media-upload-send'><i class='sendIcon'></i></a><div class='ack-select'><div class='select-popup'><input type='checkbox' name='ackCheckbox' value='1' id='ack'><label for='ack' class='checkbox-inline'><span>Acknowledgement</span></label></div><div class='select-popup'><input id='accrej' type='checkbox' name='ackCheckbox' value='2'><label for='accrej' class='checkbox-inline'><span>Accept / Reject</span></label></div></div></div>");
  }
};
function fileUploadCheckboxUpdate(th,name){
  if (th.is(':checked')) {
    $(':checkbox[name="' + name + '"]').not(th).prop('checked', false);
  }
};

function fileUploadCaptionUpdate(e){
  if (e.keyCode == 13) {
    e.preventDefault();
    $("#upload-caption").prop("disabled", true);
    $(".media-upload-send").trigger("click");
  }
};
document.getElementById("file-upload").onchange = function (e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  setting.getSettings();
  let kilobyte = 1024*1024;
  settings = JSON.parse(localStorage.getItem("settings"));
  let fileSize = fileUploadFileSize(settings);
  let megabyte = parseInt(fileSize) * kilobyte;


  let sFileName = this.files[0].name;
  $("#upload-caption").prop("disabled", false);
  let sFileExtension = sFileName.split(".")[sFileName.split(".").length - 1].toLowerCase();
  let iFileSize = this.files[0].size;
  let filetype = "application/" + sFileExtension;
  let sFile = this.files[0];
  let blob = new Blob([this.files[0]], {
    type: filetype
  });

  $("#file-upload").val("");
  if (sFileExtension === "pdf") {

    if (iFileSize < megabyte) {
      let reader = new FileReader();
      reader.onload = function (e) {
        $("#the-canvas").hide();
        $(".loader").css("display", "block");
        $(".media-upload-send").css("display", "block");
        $(".retry").css("display", "block");
        let pdfAsArray = convertDataURIToBinary(e.target.result);

        let pdfOnLoad = pdfjsLib.getDocument(pdfAsArray);
        pdfOnLoad.promise.then(function(pdf) {
          // load document
          pdf.getPage(1).then(function (page) {
            let scale = 0.3;
            let viewport = page.getViewport({scale});
            let canvas = document.getElementById("the-canvas");
            let context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            let renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
            $(".loader").css("display", "none");
          });
        });

        $(".media-upload-popup").removeClass("pdf-viewer");

        fileUploadPopupUpdate(sFileExtension,sFileName);

        $(':checkbox').on('change', function () {
          let th = $(this),
            name = th.attr('name');
            
          fileUploadCheckboxUpdate(th,name);
         
        });
        document.getElementById("media-upload-popup").style.display = "block";
        document.getElementById("media-upload-popup").style.visibility = "visible";
        document.getElementById("media-upload-popup").style.opacity = "1";
        document.getElementById("upload-media").style.opacity = "1";
        $(".media-upload-send").trigger("click");
        $(".media-upload-send").click(function () {
          let CommentText = $('#comment').text();
          $(".loader").css("display", "block");
          $(".media-upload-send").css("display", "none");
          $(".retry").css("display", "none");
          $("#upload-caption").prop("disabled", true);
          $('.attachment').trigger('click');
          let options = {};
          options.name = sFileName;
          options.caption = sFileExtension;
          let message_type = "file";
          let randomValue = utils.cryptoRandomGenerate();

          let messageid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);

          setTimeout(function () {
            attachment.fileUploadToS3(sFile, message_type, messageid, "media-upload-popup", iFileSize, options);
            $('#comment').text(CommentText);

            let offlineText = getOfflineTxt();

            let toJid = JSON.parse(localStorage.getItem("toJID"));
            let nodeJid = stropheConnection.Strophe().getNodeFromJid(toJid);

            offlineText[nodeJid] = [];
            offlineText[nodeJid].push('chatcontent');
            offlineText[nodeJid].push(CommentText);
            localStorage.setItem("offlineText", JSON.stringify(offlineText));
            $('#attachmentDiv').show();

          }, 0);
        });
        $("#upload-caption").keypress(function (e) {
          fileUploadCaptionUpdate(e);
        });
      };
      reader.readAsDataURL(blob);
    } else {
      $("#infoContent").text('Sorry, Upload file size must be less than  '+fileSize+'MB');
      $("#infobox").css("display", "block").delay(4000).fadeOut();
      $(".loader").css("display", "none");
    }
  } else {
    $(".loader").css("display", "none");
    $("#attachmentDiv").removeClass("hov");
    $("#infoContent").text(language.locali().only_pdf_allowed);
    $("#infobox").css("display", "block").delay(4000).fadeOut();
  }
};

// read the image file as a data URL.
$("#upload-retry").click(function () {
  $("#upload_camera").trigger("click");
  $("#camerapic-upload h3").text(language.locali().cameraAdjust);
});

$("#upload_camera").click(function () {
  $("#upload-img-select").show();
  $(".cam-close").show();
  $("#upload-retry").show();
  let videoDiv = "<video id = 'upload-stream' style='width:100%'></video><p id='error-message'></p><a id ='upload-img-select' class = 'image-cropped'><i> <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'></path><circle cx='12' cy='12' r='3.2' fill='#f07000'></circle><path d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z' fill='#f07000'></path></svg></i></a><canvas> </canvas>";
  $("#change-camera-1").html(videoDiv);
  $("#camerapic-upload").css("visibility", "visible");
  $("#camerapic-upload").css("display", "block");
  $("#camerapic-upload").css("opacity", "1");
  let video = document.querySelector("#upload-stream");
  navigator.mediaDevices.getUserMedia({video: true, audio: false})
    .then(function(stream) {
      video.srcObject = stream;
      video.play();
      MediaStream = stream.getTracks()[0];
      $("#change-camera-1").find("#upload-stream").show();
      $("#change-camera-1").find("#error-message").hide();
      $("#camerapic-upload h3").text(language.locali().cameraAdjust);
      triggerImageSelect2();
    })
    .catch(function(err) {
      $("#change-camera-1").find("#error-message").show();
      displayErrorMessage("Camera permission should be enabled.",true);
    });
  navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || '');
 
});

function displayErrorMessage(error_msg, error) {
  MediaStream = "";
  error = error || "";
  if (error) {
    console.log(error_msg);
  }
  $("#change-camera-1").find("#error-message").html(error_msg);

  $("#camera-stream").hide();
  $("#change-camera-1").find("#error-message").show();
  $("#change-camera-1").find("#upload-stream").hide();
  $("#upload-img-select").hide();
}
function imageUploadMediaStreamCheck(MediaStream){
  if (typeof MediaStream != "undefined" && MediaStream != "") {
    MediaStream.stop();
  }
};
function imageUploadCheckboxUpdate(){
  $(':checkbox').on('change', function () {
    let th = $(this),
      name = th.attr('name');
      if (th.is(':checked')) {
        $(':checkbox[name="' + name + '"]').not(th).prop('checked', false);
      }
  });
};
function getOfflineTxt(){
  let offlineText = JSON.parse(localStorage.getItem("offlineText"));
  if(offlineText == null){
    offlineText = {};
  }
  return offlineText;
};
function imageUploadCropCaptionLimitSet(text_length){
  if (text_length > 30) {
    let limitValue = $("#cam-caption").val().substring(0, 30);
    $("#cam-caption").val(limitValue);
  }
};
function imageUploadCamCaptionLimitSet(){
  let Settings = JSON.parse(localStorage.getItem("settings"));
  let messageCharacterLimit = Settings.messageCharacterLimit;

  let text_length = $("#cam-caption").val().length; //Input Box Text Length
  if (text_length <= messageCharacterLimit) {
    $('#camCaptionCharcount').text(text_length);
    if(text_length == messageCharacterLimit){
      $('#camCaptionCharcount').css('color','red');
      $('#camCaptionCharTotalCount').css('color','red');
    }else {
      $('#camCaptionCharcount').removeAttr( 'style' );
      $('#camCaptionCharTotalCount').removeAttr( 'style' );
    }
    let limitValue = $("#cam-caption").val().substring(0, messageCharacterLimit); //Truncate the String
    $("#cam-caption").val(limitValue);
  }
  else {
    $('#camCaptionCharcount').removeAttr( 'style' );
    $('#camCaptionCharTotalCount').removeAttr( 'style' );
  }
};
function triggerImageSelect2() {
  $("#upload-img-select").click(function () {
    $(".cam-close").show();
    $("#upload-retry").show();
    let thumb;
    let video = document.querySelector("#upload-stream");
    let hidden_canvas = document.querySelector("canvas");
    context = hidden_canvas.getContext("2d");
    let width = video.videoWidth,
      height = video.videoHeight;
    if (width && height) {
      // Setup a canvas with the same dimensions as the video.
      hidden_canvas.width = width;
      hidden_canvas.height = height;
      // Make a copy of the current frame in the video on the canvas.
      context.drawImage(video, 0, 0, width, height);
      output = hidden_canvas.toDataURL("image/png");
      let img = new Image();
      img.src = output;
      img.onload = function () {
        let imagetype = "image/png";
        let canvas = $("<canvas/>").get(0);
        canvas.width = 81;
        canvas.height = 61;
        let context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, 81, 61);
        let imageData = canvas.toDataURL(imagetype, 0.1);
        thumb = imageData.replace(/^data:image\/\w+;base64,/, "");
      };
    }
    imageUploadMediaStreamCheck(MediaStream);
    
    let ImgDiv = "<a id='up-img-crop' class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' viewBox='0 0 24 24'><path fill='#f07000' d='M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z'/></svg></i></a>";
    $("#change-camera-1").html(ImgDiv);
    $("#camerapic-upload h3").text(language.locali().imageAdjust);
    let el = document.getElementById("change-camera-1");
    let vanilla = new Croppie(el, {
      viewport: {
        width: 300,
        height: 300,
        type: "square"
      },
      boundary: {
        width: 300,
        height: 300
      },
      showZoomer: false
    });
    vanilla.bind({
      url: output
    });

    let Settings = JSON.parse(localStorage.getItem("settings"));
    let messageCharacterLimit = Settings.messageCharacterLimit; //Max Char Limit

    $("#change-camera-1").append("<div class = 'cam-up row' ><div><textarea type='text' maxlength="+messageCharacterLimit+" id='cam-caption' class='attachmentCaptionText' placeholder='Add a caption…' style='margin-right:10px'></textarea><span style='display:none;'><small id='camCaptionCharcount'>0</small>/<small id='camCaptionCharTotalCount'>"+messageCharacterLimit+"</small></span></div></div><div class='ack-select'><div class='select-popup'><input id='ack' type='checkbox' name='ackCheckbox' value='1'><label class='checkbox-inline' for='ack'><span>Acknowledgement</span></label></div><div class='select-popup'><input type='checkbox' id='accrej' name='ackCheckbox' value='2'><label for='accrej' class='checkbox-inline'><span>Accept / Reject</span></label></div></div></div>");

    imageUploadCheckboxUpdate()

    /** Camera Caption Input Box Keyup Function **/
    $("#cam-caption").on("keyup", function () {
      imageUploadCamCaptionLimitSet()
      
    });
  
    
    //on button click
    $("#up-img-crop").click(function () {
      let CommentText = $('#comment').text();

      $(".loader").css("display", "block");
      $(".cam-close").hide();
      $("#upload-retry").hide();
      vanilla.result("blob").then(function (blob) {
        let sfile = new File([blob], "camera-pic.webp");
        let message_type = "image";
        $("#cam-caption").on("input", function () {
          let text_length = $("#cam-caption").val().length;
          imageUploadCropCaptionLimitSet(text_length)
        });
        let caption = $("#cam-caption").val().trim();
        let options = {};
        options.name = utils.getId() + utils.getTimestamp() + ".png";
        options.caption = caption;
        options.thumb = thumb;
        let randomValue = utils.cryptoRandomGenerate();

        let messageid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
        attachment.fileUploadToS3(sfile, message_type, messageid, "camerapic-upload", sfile.size, options);

        $('#comment').text(CommentText);
        let offlineText = getOfflineTxt()
        offlineText[nodeJid] = [];
        offlineText[nodeJid].push('chatcontent');
        offlineText[nodeJid].push(CommentText);
        localStorage.setItem("offlineText", JSON.stringify(offlineText));
      });
    });
  });
}

$(".cam-close").click(function () {
  utils.closeProfilePopUp("camerapic-upload");
  if (typeof MediaStream != "undefined" && MediaStream != "") {
    MediaStream.stop();
  }
});

function uploadmedia() {
  Mediafile = true;
  $(".attachment").addClass("hov");
  document.querySelector("input#media-upload").click();
}

function uploadfile() {
  Mediafile = false;
  $(".attachment").addClass("hov");
  document.querySelector("input#file-upload").click();
}
$(".attachment").click(function (e) {
  e.stopPropagation();
  let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
  let nodeJid = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
  let blockName = $(".heading-name-meta").text();
  if (blockUserInfo != null && typeof blockUserInfo[nodeJid] != "undefined") {
    let unblockUserInfo = {};
    unblockUserInfo[0] = [];
    unblockUserInfo[0].push(nodeJid);
    localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));

    let content = "Unblock " + ' "' + blockName.trim() + '"?';
    $("#unblock-contact").find('.popup-detail').html(content);
    $("#unblock-contact").addClass("open");
  } else {
    if ($(this).hasClass("hov")) {
      $(".first-child").off("click");
      $(".second-child").off("click");
      $(".third-child").off("click");
      $(this).removeClass("hov");
      if ($(".prof-opt").hasClass("open")) {
        $(".prof-opt").removeClass("open");
      }
      $(".first-child").css("animation", "bright 1s .3s forwards");
      $(".second-child").css("animation", "bdeg 1s .2s forwards");
      $(".third-child").css("animation", "bbottom 1s .1s forwards");
    } else {
      $(this).addClass("hov");
      if ($(".prof-opt").hasClass("open")) {
        $(".prof-opt").removeClass("open");
      }
      $(".first-child").css("animation", "toright 0s .0s forwards");
      $(".second-child").css("animation", "todeg 0s .0s forwards");
      $(".third-child").css("animation", "tobottom 0s .0s forwards");
      $(".first-child").click(function (e) {
        e.stopImmediatePropagation();
        $(".attachment").removeClass("hov");
        Mediafile = true;
        uploadmedia();
      });
      $(".second-child").click(function () { });

      $(".third-child").click(function (e) {
        e.stopImmediatePropagation();
        $(".attachment").removeClass("hov");
        Mediafile = false;
        uploadfile();
      });
    }
  }
});



$(function () {

  //function used to login in the current session
  $("#login-session").click(function (e){
    localStorage.setItem("AnotherSessionOn", 0);
    location.reload(true);
  });

  $("#media-upload-popup").find(".retry").click(function (e) {
    e.stopPropagation();
    if (Mediafile) {
      uploadmedia();
    } else {
      uploadfile();
    }
  });
  $(".popup-mute-link").click(function () {
    $("#popup-mute").addClass("open");
  });
  $("#popup-mute .mute-bts .save").click(function () {
    $("#popup-mute").removeClass("open");
  });
  $("#popup-mute .mute-bts .cancel").click(function () {
    $("#popup-mute").removeClass("open");
  });
  $(".clear-msg-link").click(function () {
    $("#infobox").css("display", "none");
    $('#recentchat').removeClass('pencil-icon-overlay')
    $('.chat-option').removeClass('menu');

    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    actionType = "";
    
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == "broadcast") {
        content = language.locali().clear_chat_with + ' "' + minEmoji(broadcastProfile[nodeJid][1]) + '"?';
        $("#clear-msg .clearHighPriorityTag").hide()
      } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        content = language.locali().clear_chat_with + ' "' + minEmoji(userInfo[nodeJid][1]) + '"?';
        $("#clear-msg .clearHighPriorityTag").show()
      } else {
        content = language.locali().clear_chat_with + ' "' + minEmoji(groupInfo[nodeJid][1]) + '"?';
        $("#clear-msg .clearHighPriorityTag").show()
      }
      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      $("#clear-msg").addClass("open");
   
  });
  $("#clear-msg .mute-bts .save").click(function () {
    $('.sideRecentListNavLoader').show()
    $('.reply-box').css('display','flex')
    clearmessage.clearChat("clear");
    $("#clear-msg").removeClass("open");
    $(".heading-avatar-img").trigger("click");
    $(".side-profile").css({
      left: "-100%"
    });
    $('#recentchat').show();
    
  });
  $("#clear-msg .mute-bts .cancel").click(function () {
    $("#clear-msg").removeClass("open");
    delete localStorage.clearJID;
  });
  $(".del-chat").click(function () {
    $('#deleteforeveryone').hide();
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      
      $('.exit_and_del').hide();

      content = language.locali().delete_chat_with + ' "' + userInfo[nodeJid][1] + '?';
      $("#exit-group").find(".save").html(language.locali().delete);
    } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined" && groupInfo[nodeJid][0] == "group") {
      content = language.locali().exit + ' "' + groupInfo[nodeJid][1] + language.locali().group + '"?';
      $("#exit-group").find(".save").html(language.locali().exit);
    } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == "broadcast") {
      $('.exit_and_del').hide();
      
      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      content = language.locali().delete_chat_with + ' ' + broadcastProfile[nodeJid][1] + ' ?';
      $("#exit-group").find(".save").html(language.locali().delete);
    }
    $("#exit-group").find(".popupcontent").html(content);
    $("#exit-group").addClass("open");
  });
  $(".exit-group-link").click(function () {
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    $('#recentchat').removeClass('pencil-icon-overlay')
    $('.chat-option').removeClass('menu');

    actionType = "delete";
    $('.exit_and_del').show();
    $('#deleteforeveryone').hide();
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      content = '<div>Delete chat with "' + minEmoji(userInfo[nodeJid][1]) + '"?</div>';
      $("#exit-group").find(".save").html(language.locali().delete);
      $(".exit_and_del").hide();
      $("#exit-group").find(".popupcontent").html(content);
      $("#exit-group").addClass("open");

      $("#exit-group").find(".popupcontent").html(content);
      $("#exit-group").addClass("open");

    } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined" && groupInfo[nodeJid][0] == "group") {
      content = "<div>" + language.locali().exit + ' ' + minEmoji(groupInfo[nodeJid][1]) + ' ' + language.locali().group + "?</div>";
      $("#exit-group").find(".save").html(language.locali().exit);
      
      $("#exit-group").find(".popupcontent").html(content);
      $("#exit-group").addClass("open");
    } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == "broadcast") {
      localStorage.setItem("clearJID", JSON.stringify(nodeJid));
      content = '<div>Delete chat with "' + minEmoji(broadcastProfile[nodeJid][1]) + '"?</div>';
      $("#exit-group").find(".save").html(language.locali().delete);
      $(".exit_and_del").hide();
      
      $("#delete_broadcast").find(".popupcontent div").html(content);
      $("#delete_broadcast").addClass("open");
    }
  });
  /** Exit And Delete Click Event Start **/
  $(".exit_and_del").click(function () {
    let toJID = JSON.parse(localStorage.getItem("toJID"));
    if(toJID == null){
      toJID = $("#exitAndDelTojid").val();
      localStorage.setItem("toJID", JSON.stringify(toJID));
    }

    $("#isExitAndDel").val(1)
    actionType = "deletegroup";// Key using for Exit And Delete Group
    $("#exit-group .mute-bts .save").trigger('click')

    setTimeout(() => {
      actionType = ""
    }, 500);
    
  });
  /** Exit And Delete Click Event End **/

  $(".delete-group-link").click(function () {

    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    $("#isRecentDelClick").val(0)
    $('.exit_and_del').hide();
    $('#deleteforeveryone').hide();
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    actionType = "deletegroup";
    if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined" && groupInfo[nodeJid][0] == "group") {
      content = "<div>" + language.locali().delete + ' ' + minEmoji(groupInfo[nodeJid][1]) + ' ' + language.locali().group + "?</div>";
      $("#exit-group").find(".save").html(language.locali().delete);
    }
    $("#exit-group").find(".popupcontent").html(content);
    $("#exit-group").addClass("open");
  });
  /** Delete Broadcast Start **/
  $(".delete-broadcast-link").click(function () {
    let broadcastInfo = JSON.parse(localStorage.getItem("broadcastProfile"));
    console.log(broadcastInfo)
    $('#deleteforeveryone').hide();
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
    actionType = "deletebroadcast";
    if (broadcastInfo != null && typeof broadcastInfo[nodeJid] != "undefined" && broadcastInfo[nodeJid][0] == "broadcast") {
      content = "<div>" + language.locali().delete + ' ' + minEmoji(broadcastInfo[nodeJid][1]) + ' ' + language.locali().broadcast + "?</div>";
      $("#delete_broadcast").find(".save").html(language.locali().delete);
    }
    $("#delete_broadcast").find(".popupcontent").html(content);
    $("#delete_broadcast").addClass("open");
  });
  /** Delete Broadcast End **/

  function getExitGroupFileMsgIds(value,fileMessageIds,fileMessageIdsComma){
    if (value.type === "image" || value.type === "video" || value.type === "audio" || value.type === "file") {
      fileMessageIds = fileMessageIds + fileMessageIdsComma + "'" + value.message + "'";
      if(value.type === "image"){
        mediaIndexDb.deleteChatImage(value.keyid,'chatImages')
      }
    }
    return fileMessageIds;
  };
  function exitGroupFileDel(fileMessageIds){
    if (fileMessageIds != "") {
      let nodeJid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
      let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 
    "broadcast") {
        chatType = "broadcast";
      } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        chatType = "chat";
      } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
        chatType = "groupchat";
      }
      mediaUpload.fileDelete(fileMessageIds, "");
    }
  };
  function exitGroupCallDelMsg(messageids) {
    if (messageids != "") {
      clearmessage.deleteMessage(messageids);
    } else {
      $("#infoContent").text("Select atleast one message to delete");
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
    $(".conversation").removeClass("small-pane");
  };

  function checkBtnTypeVal(btnEl,actionType){
    if(btnEl == "Delete"){ // Check Button Txt (Delete Or Exit)
      actionType = "deletegroup"
    }
    return actionType;
  }

  function exitGrpSplitwithNodeJid(userInfo,broadcastProfile,tojid,nodeJid,btnEl){
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        
      if($('#conversation:visible').length == 0){
        delete localStorage.toJID;
        clearmessage.clearChat("delete");

      }else {
        clearmessage.clearChat("delete");
        setTimeout(function () {

        if(stropheConnection.Strophe().getNodeFromJid(tojid) == nodeJid){
        delete localStorage.toJID;

          }
      },1000)
      }
    } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
      clearmessage.clearChat("delete");
    } else {

      actionType = checkBtnTypeVal(btnEl,actionType);

      groups.userexit(nodeJid, actionType);
    }
    delete localStorage.clearJID;
    $(".conversation").removeClass("small-pane");
  }

  function exitGrpSplitwithToJid(userInfo,broadcastProfile,tojid){
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
      if ((userInfo != null && typeof userInfo[nodeJid] != "undefined") || (broadcastProfile != null && typeof broadcastProfile
    [nodeJid] != "undefined")) {
        clearmessage.clearChat("delete");
        setTimeout(function () {

          if(stropheConnection.Strophe().getNodeFromJid(tojid) == nodeJid){
          delete localStorage.toJID;

          }
        },1000)
        $(".conversation").removeClass("small-pane");
      } else {
        groups.userexit(nodeJid, actionType);
      }
  }
  
  function exitGroupSplitFunction(btnEl){
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = JSON.parse(localStorage.getItem("clearJID"));
    if (nodeJid != null) {
      exitGrpSplitwithNodeJid(userInfo,broadcastProfile,tojid,nodeJid,btnEl);
      
    } else if (tojid != null) {
      exitGrpSplitwithToJid(userInfo,broadcastProfile,tojid);
      
    }
  };

  $("#exit-group .mute-bts .save").click(function () {
    $('.sideRecentListNavLoader').show()
    let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
    let messageids = "";
    let comma = "";
    let fileMessageIds = "";
    let fileMessageIdsComma = "";
    if (selectedmsgInfo != null) {
      for (let key in selectedmsgInfo) {
        let value = selectedmsgInfo[key];
        let itemvalue = value.keyid;
        messageids = messageids + comma + itemvalue;
        comma = ",";
        fileMessageIds = getExitGroupFileMsgIds(value,fileMessageIds,fileMessageIdsComma)
        fileMessageIdsComma = ",";
      }
      exitGroupFileDel(fileMessageIds)
      exitGroupCallDelMsg(messageids)
      
    } else {
      let isExitAndDel = $("#isExitAndDel").val();
      if(isExitAndDel == 1 || $(this).text() == "Delete") { //Check Exit All or Delete
        actionType = "deletegroup"
      }else {
        actionType = "delete"

      }

      exitGroupSplitFunction($(this))

      if($(this).text() == "Exit" && actionType != "deletegroup"){ // Refresh Recent Chat
        let recent = require('./recent.js');

        setTimeout(function () {
          recent.getRecentChat()
          
        }, 500);
      }
      
      

    }
    $("#exit-group").removeClass("open");

    /** Update Favorite Message Start **/
    let updateStarmessage = stropheConnection
    .StropheJs()
    .$iq({
        type: "set",
        id: utils.getId(),
        to: stropheConnection.Server()
    })
    .c("query", {
        xmlns: "jabber:iq:user_fav",
        status: "update_favourite_message",
    });
    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().send(updateStarmessage);
    }
    /** Update Favorite Message End **/
    
    /** Recent Tab loader Hide **/
    setTimeout(function () {
      $('#norecent').hide();
      $('.sideRecentListNavLoader').hide()
    }, 1000);
   
  });

  $("#delete_broadcast .mute-bts .save").click(function () {
    $('.sideRecentListNavLoader').show()
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
  
    broadcast.deleteBroadcast(nodeJid);

    $("#delete_broadcast").removeClass("open");

  
    setTimeout(function () {

      $('#norecent').hide();
      $('.sideRecentListNavLoader').hide()
    }, 1000);
   
  });

  function deleteForEveryOneGetMsgIds(selectedmsgInfo,messageids,comma,fileMessageIds,fileMessageIdsComma,MessageType){
    for (let key in selectedmsgInfo) {
      let value = selectedmsgInfo[key];
      let itemvalue = value.keyid;
      messageids = messageids + comma + itemvalue;
      comma = ",";
      if (value.type === "image" || value.type === "video" || value.type === "audio" || value.type === "file") {
        fileMessageIds = fileMessageIds + fileMessageIdsComma + "'" + value.message + "'";
        MessageType = "media";
      }
      fileMessageIdsComma = ",";
    }
    let result = {
      fileMessageIds  : fileMessageIds,
      MessageType     : MessageType,
      messageids      : messageids
    }
    return result;
  };
  function delForEveryoneFileDelete(fileMessageIds){
    if (fileMessageIds != "") {

      mediaUpload.fileDelete(fileMessageIds, "");
    }
  };
  function delForEveryoneGetChatType(){
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let chatType;
    if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == "broadcast") {
      chatType = "broadcast";
    } else if (typeof userInfo[nodeJid] != "undefined") {
      chatType = "chat";
    } else if (typeof groupInfo[nodeJid] != "undefined") {
      chatType = "groupchat";
    }

    return chatType;
  };
  function delForEveryoneRecallMsg(messageids,chatType,MessageType){
    if (messageids != "") {

      clearmessage.RecallMessage(messageids, chatType, MessageType);

    } else {
      $("#infoContent").text("Select atleast one message to delete");
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
  };
  function delForEveryoneClearChat(nodeJid,userInfo,broadcastProfile,actionType){
    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      clearmessage.clearChat("delete");
    } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
      clearmessage.clearChat("delete");
    } else {
      groups.userexit(nodeJid, actionType);
    }
  };
  function delForEveryoneSplitFunction(){
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let nodeJid = JSON.parse(localStorage.getItem("clearJID"));
    if (nodeJid != null) {
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
        clearmessage.clearChat("delete");
      } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
        clearmessage.clearChat("delete");
      } else {
        groups.userexit(nodeJid, actionType);
      }
      delete localStorage.clearJID;
    } else if (tojid != null) {
      nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
      delForEveryoneClearChat(nodeJid,userInfo,broadcastProfile,actionType)
      
    }
  };
  $("#deleteforeveryone").click(function () {
    let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
    let messageids = "";
    let comma = "";
    let fileMessageIds = "";
    let fileMessageIdsComma = "";
    let MessageType = "text"
    $("input:checkbox.select-message").prop("checked", false);
    $(".multi-count").html("0 selected");
    if (selectedmsgInfo != null) {
      let msgObj = deleteForEveryOneGetMsgIds(selectedmsgInfo,messageids,comma,fileMessageIds,fileMessageIdsComma,MessageType)
      fileMessageIds = msgObj.fileMessageIds;
      MessageType    = msgObj.MessageType;
      messageids     = msgObj.messageids;
      delForEveryoneFileDelete(fileMessageIds)
     
      let chatType = delForEveryoneGetChatType();
      
      delForEveryoneRecallMsg(messageids,chatType,MessageType)
      
    } else {
      delForEveryoneSplitFunction()
    }
    $("#exit-group").removeClass("open");
    $(".conversation").removeClass("small-pane");
    $('.msg-sent-status').html('');
  });

  $("#exit-group .mute-bts .cancel").click(function () {
    $("#isRecentDelClick").val(0)
    $("#exit-group").removeClass("open");
    delete localStorage.clearJID;
    actionType = "";
  });

  $("#delete_broadcast .mute-bts .cancel").click(function () {
    $("#delete_broadcast").removeClass("open");
  });

  $("#add-participant .add-participant-msg-close").click(function () {
    $("#add-participant").css("display", "none");
    $("#add-participant").removeClass("open");
    $("#partticipantText").val("");
    $(".egrp-filter").find('.active').removeClass('active');
    $(".egrp-filter").find('.site').addClass('active');
    $('.egrp-filter').removeClass("menu");
    $("input:checkbox.select-message3").prop("checked", false).change();
    delete localStorage.selecteduserInfo;
  });

  $("#add-participant .add-participant-msg-send").on("click", function (event) {
    if (addparticipant) {
      addparticipant = false;
            
      let isGroup = $('.add-participant-msg-send').attr('data-isgroup');

      let selecteduserInfo = JSON.parse(localStorage.getItem("selecteduserInfo"));
      let selectedUsers = selecteduserInfo;
      if (selectedUsers == null) {
        $("#infoContent").text(language.locali().one_user);
        $("#infobox").css("display", "block").delay(4000).fadeOut();
        addparticipant = true;
      } else {
        $("#add-participant").css("display", "none");
        $("#add-participant").removeClass("open");
        $("#partticipantText").val("");
        $(".egrp-filter").find('.active').removeClass('active');
        $(".egrp-filter").find('.site').addClass('active');
        $('.egrp-filter').removeClass("menu");
        for (let userkey in selectedUsers) {
          let uservalue = selectedUsers[userkey];
          if(isGroup == 1){

            groups.addMember(uservalue[0] + "@" + stropheConnection.Server(), uservalue[1]);
          }else {
            broadcast.addBroadcast(uservalue[0] + "@" + stropheConnection.Server(), uservalue[1]);

          }
        }
        setTimeout(function () {
          addparticipant = true;
        }, 2000);
      }
    }
  });

  // function for site function checkbox click
  $("#sites-details").off().on('click', '.sitecontent', function () {
    let result = $(this).attr("id").split("-");
    let selectedId = result[1];
    if ($("#sites-details .sitecontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked")) {
      $("#sites-details .sitecontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked", false).change();
    } else {
      $("#sites-details .sitecontent").find("input[type=checkbox][value=" + selectedId + "]").prop("checked", true).change();
    }
  });


  $(".forward-msg-link").click(function () {
    $(this).attr("disabled", false);
    let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
    if (selectedmsgInfo == null) {
      $("#infoContent").text(language.locali().one_msg);
      $("#infobox").css("display", "block").delay(4000).fadeOut();
    } else {
      $("#forward-msg").addClass("open");
    }
  });
  $("#forward-msg .frwrd-msg-close").click(function () {
    $("#forward-msg").removeClass("open");
    $("#forwardText").val("");
    $(".fwd-filter").find('.active').removeClass('active');
    $(".fwd-filter").find('.site').addClass('active');
    $('.fwd-filter').removeClass("menu");
    delete localStorage.selecteduserInfo;
    if ($('.select-msg-blk').css('display') != 'block') {
      delete localStorage.selectedmsgInfo;
    }
  });
  function frwrdMsgSendDesignUpdate(blockUserInfo,tojid,displayname){
    if (tojid != null && typeof blockUserInfo[stropheConnection.Strophe().getNodeFromJid(tojid)] != "undefined") {
      utils.blockUserDesignAdd(displayname)
     
    } else {
     
      $('#comment').attr("data-text", "Start typing...");
      $(".blocked-person").css("display", "none");
      $(".row.reply").css("display", "flex");
      $("#replyemoji").show();
      $("#microphone").show();
      $(".row.reply").css("display", "flex");
      $('.heading-attach').show();
    }
  };
  function frwrdMsgSendGetChatType(touser,broadcastProfile,userInfo,groupInfo){
    if (broadcastProfile != null && typeof broadcastProfile[touser] != "undefined" && broadcastProfile[touser][0] == "broadcast") {
      chatType = "broadcast";
    } else if (typeof userInfo[touser] != "undefined") {
      chatType = "chat";
    } else if (typeof groupInfo[touser] != "undefined") {
      chatType = "groupchat";
      touser = touser + "@mix." + stropheConnection.Server();
    }
    let chatObj = {
      chatType  : chatType,
      touser    : touser
    };

    return chatObj;
  };
  function frwrdMsgSendGetDataObj(){
      let selecteduserInfo = JSON.parse(localStorage.getItem("selecteduserInfo"));
      let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
      let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      let forwardMsgIds = [];
      let data = [];
      let messageUser = [];
      let forwardMsgUserIds = {};
      let i = 0;
      let touser;
      for (let key in selectedmsgInfo) {
        let value = selectedmsgInfo[key];
        if (value.type === "image" || value.type === "video" || value.type === "audio" || value.type === "file") {
          forwardMsgIds.push(value.message);
        }

        for (let userkey in selecteduserInfo) {
          let randomValue = utils.cryptoRandomGenerate();

          let messageid = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
          let uservalue = selecteduserInfo[userkey];
          if (value.type === "image" || value.type === "video" || value.type === "audio" || value.type === "file") {
            touser = uservalue[0];
            let chatObj = frwrdMsgSendGetChatType(touser,broadcastProfile,userInfo,groupInfo);

            chatType = chatObj.chatType;
            touser   = chatObj.touser;
            
            forwardMsgUserIds[touser] = chatType;
            let obj = {
              fileToken: value.message,
              messageId: messageid,
              toUser: touser,
              type: chatType
            };
            data.push(obj);
          }

          messageUser[i] = messageid;
          i++;
        }


      }

      let dataObj = {
        data    : data,
        touser  : touser,
        messageUser : messageUser,
        forwardMsgIds: forwardMsgIds
      }

      return dataObj;
  };
  function frwrdMsgSendGetFwdResponseId(forwardMsgIds,filesData){
    let forwardResponseIds = "";
    if (forwardMsgIds.length > 0) {
      forwardResponseIds = mediaUpload.fileForwardMultiple(filesData);
    }

    return forwardResponseIds;
  };
  function fwdMsgGetToUser(touser,uservalue){
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    if (broadcastProfile != null && typeof broadcastProfile[touser] != "undefined" && broadcastProfile[touser][0] == "broadcast") {
      touser = uservalue[0];
    } else if (userInfo != null && typeof userInfo[touser] != "undefined") {
      touser = uservalue[0];
    } else if (groupInfo != null && typeof groupInfo[touser] != "undefined") {
      touser = uservalue[0] + "@mix." + stropheConnection.Server();
    }
    return touser;
  };
  function frwrdMsgSendMessage(selectedmsgInfo,selecteduserInfo,messageUserMapping,forwardResponseIds,touser){
    let i = 0;
    let msg = "";
    
      for (let key in selectedmsgInfo) {
        let value = selectedmsgInfo[key];
        let itemvalue = value.keyid;
        let itemtype = value.type;

        for (let userkey in selecteduserInfo) {
          let uservalue = selecteduserInfo[userkey];
          let messageId;
          if (messageUserMapping[0][i] != undefined && typeof messageUserMapping[0][i] != "undefined") {
            messageId = messageUserMapping[0][i];
            if (forwardResponseIds != "") {

              touser = fwdMsgGetToUser(touser,uservalue)
              
              msg = forwardResponseIds[messageId];
            } else {
             let randomValue = utils.cryptoRandomGenerate();

              messageId = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
            }
          } else {
            let randomValue = utils.cryptoRandomGenerate();

            messageId = randomValue.toString(36).replace(/[^a-z]+/g, "").substring(0, 9);
          }
          i++;
          mediaSendMessage.sendforwardmsg(itemvalue, itemtype, uservalue[0], msg, messageId, uservalue[1], uservalue[2]);
        }

      }
  };
  $("#forward-msg .frwrd-msg-send").click(function () {
    let selecteduserInfo = JSON.parse(localStorage.getItem("selecteduserInfo"));
    let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
   
    if (selectedmsgInfo == null) {
      $("#infoContent").text(language.locali().one_msg);
      $("#infobox").css("display", "block").delay(4000).fadeOut();
    } else if (selecteduserInfo == null) {
      $("#infoContent").text(language.locali().one_user_group);
      $("#infobox").css("display", "block").delay(4000).fadeOut();
    } else {
      $("#forward-msg").removeClass("open");
      $(".select-msg-blk").hide();
      $(".del-msg-wrap").hide();
      let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
      let tojid = JSON.parse(localStorage.getItem("toJID"));
      let displayname = selecteduserInfo[0][1];
      frwrdMsgSendDesignUpdate(blockUserInfo,tojid,displayname)
      $(this).hide();

      let forwardMsgIds = [];
      let data = [];
      let messageUserMapping = [];
      let messageUser = [];
      let touser;
      let dataObj = frwrdMsgSendGetDataObj()

      data    = dataObj.data;
      touser  = dataObj.touser;
      messageUser = dataObj.messageUser;
      forwardMsgIds = dataObj.forwardMsgIds;

      messageUserMapping.push(messageUser);
      let filesData = {
        medias: data
      };

      let forwardResponseIds = frwrdMsgSendGetFwdResponseId(forwardMsgIds,filesData)
     
      frwrdMsgSendMessage(selectedmsgInfo,selecteduserInfo,messageUserMapping,forwardResponseIds,touser)

      
      $(this).show();
      delete localStorage.selectedmsgInfo;
      delete localStorage.selecteduserInfo;
      $("input:checkbox.select-message3").prop("checked", false).change();
      $("input:checkbox.select-message").prop("checked", false).change();
      $("#forward-msg").removeClass("open");
      $(".select-msg-blk").hide();
      $(".del-msg-wrap").hide();
      $("#forwardText").val("");
      $(".fwd-filter").find('.active').removeClass('active');
      $(".fwd-filter").find('.site').addClass('active');
      $('.fwd-filter').removeClass("menu");
      $(".multi-count").html("0 selected");
      $("#forwardText").val("");
      $("#forwardText").trigger("keyup");
    }

  });
  $("#exit-group .mute-bts *").click(function () {
    $("#exit-group").removeClass("open");
  });
  $(".delete-chat-link").click(function () {
    $("#delete-chat").addClass("open");
  });
  $("#delete-chat .mute-bts *").click(function () {
    $("#delete-chat").removeClass("open");
  });
  $(".read_list .accordian").click(function () {
    $(".read_list .delivered-list").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });
  $(".deliver_list .accordian").click(function () {
    $(".deliver_list .delivered-list").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });
  $("#response-accordian").click(function () {
    $(".response_list #response-append-data").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });
  $("#acknowledge-accordian").click(function () {
    $(".acknowledge_list #acknowledge-append-data").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });
  $("#accept-accordian").click(function () {
    $(".accept_list #accept-append-data").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });

  $("#reject-accordian").click(function () {
    $(".reject_list #reject-append-data").toggleClass("listview");
    if (!$(this).hasClass('plus')) {
      $(this).attr('src', './src/images/sub_ic.svg');
      $(this).addClass('plus');
    } else {
      $(this).attr('src', './src/images/add_ic.svg');
      $(this).removeClass('plus');
    }
  });
  $(".acknowledge-icon").click(function () {
    let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
    let blockName = $(".heading-name-meta").text();
    if (blockUserInfo != null && typeof blockUserInfo[nodeJid] != "undefined") {
      let unblockUserInfo = {};
      unblockUserInfo[0] = [];
      unblockUserInfo[0].push(nodeJid);
      localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));

      content = "Unblock " + ' "' + blockName.trim() + '"?';
      $("#unblock-contact").find('.popup-detail').html(content);
      $("#unblock-contact").addClass("open");
    } else {
      $(".ack-popup").toggleClass("view");
    }
  });
  $("#conversation, .side").click(function () {
    $(".ack-popup").removeClass("view");
  });
  $(".ack-popup").click(function () {
    $(this).removeClass("view");
  });

  $(".trash-msg").click(function () {
  
      let selectedmsgInfo = JSON.parse(localStorage.getItem("selectedmsgInfo"));
      if (selectedmsgInfo == null) {
        $("#infoContent").text(language.locali().one_msg);
        $("#infobox").css("display", "block").delay(4000).fadeOut();
      } else {
        $('#deleteforeveryone').hide();
        content = '<div id = "tested">Are you sure want to Delete?</div> ';
        $("#exit-group").find('.save').html(language.locali().delete);
        $("#exit-group").find('.popup-mute').html(content);
        $("#exit-group").addClass("open");
      }
   

  });

  // action on clicking priority message pinned on conversation
  $("body").on("click", ".priority-message-conversation", function () {
      let priorityMessages = JSON.parse(localStorage.getItem("priorityMessages"));
      conversation.priorityMessageClick(priorityMessages[0]);
  });
  
  // action on clicking oncall popup close button 
  $("#oncall-details .oncall-details-msg-close").click(function () {
    $("#oncall-details").css("display", "none");
    $("#oncall-details").removeClass("open");
  });

  //action on clicking oncall button in own profile page
  $("body").on('click', '.profile-oncall', function(e) {
      e.stopPropagation();
      $("#onCallHeadingText").hide();
      $(".popup.oncall-details .popup-title").css('padding-bottom','10px')
      $(".oncall-details-msg-close").css('margin','5px 0')

      $("#oncall-details").css("display", "block");
      $("#oncall-details").addClass("open");
      mediaUserOncalls.getOncallNames(stropheConnection.logininfo.userJID, 0, 1, 1, $(this).attr("data-name"));
  });

  //action on clicking oncall button in recent chat
  $("body").on('click', '.oncall-recent', function(e) {
      e.stopPropagation();
      $("#onCallHeadingText").show();
      $(".popup.oncall-details .popup-title").css('padding-bottom','0px')
      $(".oncall-details-msg-close").css('margin','18px 0')

      $("#oncall-details").css("display", "block");
      $("#oncall-details").addClass("open");
      mediaUserOncalls.getOncallNames($(this).closest(".recentcontent").attr("id"), 0, 1, 1, $(this).closest(".recentcontent").find(".name-meta").text());
  });

  
});
