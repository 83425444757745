let db;
let dbVersion = 1;
let dbReady = false;
let dbName = 'IkonixConnectDb'

module.exports = {
    /** Index DB Initialization**/
    indexDbInitialization: async function () {

        let request = indexedDB.open(dbName, dbVersion);

        request.onerror = function(e) {
            console.error('Unable to open database.');
        }

        request.onsuccess = function(e) {
            db = e.target.result;
        }

        request.onupgradeneeded = function(e) {
            let db = e.target.result;
            db.createObjectStore('profileImages', {key:'key', autoIncrement: false});
            db.createObjectStore('chatImages', {key:'key', autoIncrement: false});
            db.createObjectStore('chatAudios', {key:'key', autoIncrement: false});

            dbReady = true;

        }
    },
    /** Convert Image Url to BlobImage and Storing**/
    blobImageConversion: function(url,element,key,tabelName,type) {
        fetch(url)
        .then(function (response) {
            return response.blob();
        })
        .then(function (blob) {
           let blobUrl ;
           blobUrl = URL.createObjectURL(blob);
           if(tabelName == 'profileImages'){
                if(type == 'className')
                    $(element).attr('src',blobUrl);
                else
                    $(element).first().attr('src',blobUrl);

           }else if(tabelName == 'chatImages') {
                $(element).first().attr('src',blobUrl)
                $(element).css('background-image', 'url(' + blobUrl + ')');
           }else {
                $(element).first().attr('src',blobUrl)
           }
        
            if(key && tabelName && db){
                let trans;
                try {
                    trans = db.transaction([tabelName], 'readwrite');
                    let addReq = trans.objectStore(tabelName).add(blobUrl,key);

                    addReq.onerror = function(e) {
                        console.log(e.target.error);
                    }
        
                    trans.oncomplete = function(e) {
                        console.log('Image stored successfully!');
                    }
                } catch (error) {
                    console.log("Error!");
                }
            }
        });
  
     
    },
     /** Convert Video Url to Blob**/
     videoBlobConversion: function(url,element) {
    
            fetch(url)
            .then(function (response) {
                return response.blob();
            })
            .then(function (blob) {
               let blobUrl ;
               blobUrl = URL.createObjectURL(blob);
               $(element).first().attr('src',blobUrl)

            });
         
    },
    deleteChatImage: function(key,tabelName){
        // Open a database (or create if it doesn't exist)
        let request = indexedDB.open('IkonixConnectDb', 1);

        // Handle success
        request.onsuccess = function(event) {
            let db = event.target.result;
            // Perform operations on the database
            try {
                request = db
                .transaction([tabelName], "readwrite")
                .objectStore(tabelName)
                .delete(key);
                request.onsuccess = (event) => {
                    console.log('Deleted Successfully!!!')
                };
            } catch (error) {
                
            }
            
        };

        // Handle errors
        request.onerror = function(event) {
            console.error('Database error:', event.target.errorCode);
        };
        
        
    }
}