let CryptLib = require("cryptlib");
let stropheConnection = require("./stropheConnection.js");
let utils = require("./utils.js");
const settings = require("./settings.js");

let self = (module.exports = {
  setSession: function (username) {
    let logsession = sessionStorage.getItem("Info");
    let key = CryptLib.getHashSha256(username, 32);
    let logininfo = stropheConnection.logininfo;
    logininfo.userJID = username;
    let loginUserInfo = {};
    loginUserInfo[0] = [];
    loginUserInfo[0].push(username);
    localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
    let body = CryptLib.decrypt(logsession, key, username);
    let authinfo = JSON.parse(body);

    return new Promise(resolve => {
      module.exports.getSession(username, authinfo[1]);
      resolve();
    });
  },
  getSession: function (Username, Secure) {
    let setSessionUrl = window._env_.ApiURL + "/login";
    let formData = {
      password: Secure,
      username: Username,
      type: "WEB"
    };

    $.ajax({
      url: setSessionUrl,
      type: "POST",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(formData),
      async: false,
      crossDomain: true,
      statusCode: {
        204: function (response) {
          console.log("Username does not exist");
        },
        401: function (response) {
          console.log("Username or password is wrong");
        },
        500: function (response) {
          console.log("Internal Server Error");
        }
      },
      success: function (response) {
        if (typeof response.status != "undefined" && response.status == 400) {
          console.log("400 error");
        } else if (typeof response.status != "undefined" && response.status == 500) {
          console.log("500 error");
        }
        else {
          localStorage.setItem("userToken", JSON.stringify(response.data.token));
        }
      },
      error: function (jqXHR, exception) {
        utils.errorMsgHandling(jqXHR, exception)

      }
    });
  }
});
