let pagerStorage = require('./storage.js');
const utils = require('./utils.js');
let pagerUtils = require('./utils.js');


module.exports = {

    getPager: function () {
        let pagerUrl = window._env_.ApiURL + "/pager/profiles";
        $.ajax({
            url: pagerUrl ,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': pagerStorage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                localStorage.setItem("pager", JSON.stringify(response.data.PagerProfiles))
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return true;
    },
    postPagerMassage: function () {
        let postPagerUrl = window._env_.ApiURL + "/pager/message";
        let nodeId = $('#pagerNodeId').attr('data-nodeid');
        let pagerDeviceType = $('#pagerDeviceType').attr("data-type");
        let pagerToId = $('#pagerToId').attr("data-toid");

        let formData
            formData = {message: $('.pager_massage').val(), to:pagerToId, nodeId:nodeId, deviceType:pagerDeviceType, };

        $.ajax({
            url: postPagerUrl,
            type: 'POST',
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(formData),
            async: true,
            crossDomain: true,
            headers: {
                'authorization': pagerStorage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    pagerStorage.setSession(username);
                    module.exports.postPagerMassage();

                },
                500: function (response) {
                    console.log('Internal Server Error');
                }, 
                400: function (response) {
                    console.log('Bad Request');
                }
            },
            success: function (response) {
                $('.pager_cancel').click();
                if(response.status == 200){
                    $("#infobox").css({"display":"block", "background-color":"#0e740f"});
                    $("#infoContent").text('Pager message sent successfully');
                    $('#infobox').css("display", "block").delay(4000).fadeOut();
                }else {
                    $("#infoContent").text(response.message);
                    $('#infobox').css("display", "block").delay(4000).fadeOut();
                }

            },
            error: function (jqXHR, exception) {

            }
        });
        return true;
    },

}


