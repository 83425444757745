let indexDb = require('./indexDb.js');
let contact = require('./contact.js');
let utils = require('./utils.js');
let stropheConnection = require("./stropheConnection.js");
let storage = require('./storage.js');
let language = require('./locale/language.js');

let broadcastContacts = {
  /** Broadcast Global Search Contact Append Start **/
    glBrGroupContactEmptyCheck: function(pageNumber,elementId){
        if (pageNumber == 1) 
            $(elementId).find('.participantcontent').remove();
    },
    getBrGlContactDisplayName: function(name,givenName,surName,values){
        let displayName = "";
        if(name == null){
        if((givenName != null && givenName != '') && (surName != null && surName != '')){
            displayName = givenName + " " + surName;
    
        }else if((givenName != null && givenName != '')){
            displayName = givenName;
    
        }else if((surName != null && surName != '')){
            displayName = surName;
    
        }else {
            displayName = values.ikonixUsername;
        }
        }else {
            displayName = name;
        }
    
        return displayName;
    },
  
  getBrGlGroupContactImgElement: function(imageId,values,nodeJid,concatedLetter){
    let userToken           = storage.getuserToken(),
        userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
        blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));
  
    let image,imageElment;
    if (imageId != null && imageId.trim() != "" && imageId !== 'src/images/avatar/chat/profile_img_one.svg') {
        let mediaUrl = window._env_.ApiURL + "/media/";
   
      if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
          
          let last2 = values.username.slice(-2);
          if(last2 == '==')
              imageId = userInfo[values.username.toLowerCase()][2];
          
          image = mediaUrl + imageId + "?mf=" + userToken;
      } else{
          image = mediaUrl + imageId + "?mf=" + userToken;
      }
      indexDb.blobImageConversion(image,'#addParticipantBrGlobalImgId-'+nodeJid+'',nodeJid,'profileImages');
  
      imageElment = '<img id="addParticipantBrGlobalImgId-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + contact.profileImgError() + '" data-text="'+concatedLetter+'">';
    } else {
        imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
    }
  
    if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
    imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
      if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
      userInfo[nodeJid][2] = image;
      userInfo[nodeJid][3] = language.locali().default_status;
      localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
      }
    }
    return imageElment;
  },
  
  glBrGroupPositionDiv: function(position,activeTechnician){
    let brPositionDiv = "";
    if(position != "") {
      brPositionDiv = '<div class="sideBar-msg role-div"><span class="name-status" title="'+position+'">' + position + ' </span>';
  
      if(activeTechnician)
      brPositionDiv += '<span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
  
      brPositionDiv += '</div>';
  
    }else {
  
      if(activeTechnician)
      brPositionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'" style="display:none;">-</span><span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span></div>';
  
    }
  
    return brPositionDiv;
  },
  
  glBrGroupDeptSite: function(department,siteAbbreviation){
    let brDeptSite ='';
    if (department != "" && siteAbbreviation != "") {
      brDeptSite = department + ", " + siteAbbreviation;
    } else if (department == "" && siteAbbreviation != "") {
        brDeptSite = siteAbbreviation;
    } else if (department != "" && siteAbbreviation == "") {
        brDeptSite = department;
    } 
  
    return brDeptSite;
  },
  
  glBrGroupDeptSiteDiv: function(deptSite){
    let brDeptSiteDiv = '<div class="sideBar-msg dept-div">';
    if(deptSite != "")
        brDeptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';
  
    return brDeptSiteDiv;
  },
  
  glBrGroupParticipantDivAppend: function(elementId){
    let newGroupLength = $(""+elementId+" .participantcontent").length;
  
    if(newGroupLength == 0) {
        $(elementId).find('.participantHeaderDiv').remove();
    }
  
    let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));
    if (broadcastAddUserInfo != null) {
        for (let key in broadcastAddUserInfo) {
            $(elementId).find("#participant-" + key).css("display", "none");
        }
    }
  },
  
  getBrsiteHeaderDivHeight: function(siteHeaderDivHeight){
    let brDivValue = siteHeaderDivHeight ? siteHeaderDivHeight : 0;
  
    return brDivValue;
  },
  
  getBrcontactHeaderDivHeight: function(contactHeaderDivHeight){
    let brContactHeaderValue = contactHeaderDivHeight ? contactHeaderDivHeight : 0;
  
    return brContactHeaderValue;
  },
  
  getBrcontactsiteElHeight: function(siteElHeight){
    let brContactSiteVal = siteElHeight ? siteElHeight : 0;
  
    return brContactSiteVal;
  },
  
  glBrGroupParticipantDivHeightSet: function(divHeightObj){
    
    let brGrpElementId   =   divHeightObj.elementId,
        mainDivHeight = divHeightObj.mainDivHeight,
        totalHeight = divHeightObj.totalHeight,
        siteElHeight  = divHeightObj.siteElHeight,
        siteHeaderDivHeight = divHeightObj.siteHeaderDivHeight,
        contactHeaderDivHeight  = divHeightObj.contactHeaderDivHeight,
        inputboxHeight  = divHeightObj.inputboxHeight;
  
        if(mainDivHeight < totalHeight){
          let fixedHeight =totalHeight - (siteElHeight+siteHeaderDivHeight+contactHeaderDivHeight+inputboxHeight);
          $(brGrpElementId).css('height',fixedHeight)
        }
  },
  
  glBrGroupCustomScrollSet: function(selectedSitesInfo,siteId,rosterCount,page,search) {
    let selectedSites;
  
    if(typeof selectedSitesInfo != "undefined" && selectedSitesInfo != null && 
        selectedSitesInfo.contactsSelectedSites.length != 0) 
        selectedSites = selectedSitesInfo.contactsSelectedSites;
    else 
        selectedSites = siteId;
    
    if (rosterCount % language.locali().default_size_pagination == 0) {
        let glGroupCustomQueryParams = '?size=' + language.locali().default_size_pagination + '&isChatUser=1'+ '&siteId=' + selectedSites + '&page=' + page + '&search='+search;
        module.exports.getNewSiteAndBroadCastContactsCallBack(glGroupCustomQueryParams,'broadcast-participant-sites-info');
    }
  },
  getbrGlContactOncallImgClass: function(statusId,isUnregisteredUser){
    let oncallImageNameClass = '';
        if(!(utils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall';
  
    return oncallImageNameClass;
  },
  getBrGlGroupContactFavIcon: function(values){
    let favIcon = '';
  
    if(values.isChatUser == 1 && values.isUnregisteredUser != 1){
      favIcon = '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">';
    }
  
    return favIcon;
  },
  getBrGlContactProfileStatusDiv: function(statusId,isUnregisteredUser,values){
    let profileStatusDiv;
    if(utils.getStatusName(statusId) != ""&& isUnregisteredUser == 0) 
        profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + utils.getStatusName(statusId) + '" style="color:' + utils.getStatusImage(statusId) + '">'+ utils.getStatusName(statusId) + '</span></div>' : '';
    else 
        profileStatusDiv = "";
  
    return profileStatusDiv;
  },
  getNewSiteAndBroadCastContactsCallBack: function(newQueryParams,elementName) {
  
      let elementId =  "#"+elementName;
  
      let contactsData        = contact.getContacts(newQueryParams);
         
  
      let nameDivContacts;
      let searchParams = new URLSearchParams(newQueryParams),
          pageNumber   = searchParams.get('page');
  
          module.exports.glBrGroupContactEmptyCheck(pageNumber,elementId)
      
  
      if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
        for (let keys in contactsData) {
  
            let values              = contactsData[keys],
                jid                 = values.username + '@' + stropheConnection.Server(),
                nodeJid             = stropheConnection.Strophe().getNodeFromJid(jid),
                statusId            = values.status,
                surName             = values.surName,
                displayName         = "",
                siteId              = values.siteId,
                siteAbbreviation    = utils.getSiteAbbreviation(siteId),
                position            = values.position.trim(),
                department          = values.department.trim(),
                activeTechnician    = values.isActiveTechnician,
                positionDiv         = "",
                deptSite            = "",
                deptSiteDiv         = "",
                isUnregisteredUser  = values.isUnregisteredUser,
                imageId             = values.imageToken.trim(),
                name                = values.name,
                givenName           = values.givenName,
                imageElment ,
                concatedLetter;
  
                displayName = module.exports.getBrGlContactDisplayName(name,givenName,surName,values)
                concatedLetter = utils.getImageDisplayText(name);
                imageElment = module.exports.getBrGlGroupContactImgElement(imageId,values,nodeJid,concatedLetter);
  
                let oncallImageNameClass = module.exports.getbrGlContactOncallImgClass(statusId,isUnregisteredUser);
  
                let favIcon = module.exports.getBrGlGroupContactFavIcon(values);
             
            let participantDiv  = '<div class="row sideBar-body broadCastParticipantcontent" id="participant-' + nodeJid + '" data-ischatuser = "'+values.isChatUser+'" data-isunregisteruser = "'+values.isUnregisteredUser+'"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
            let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
            let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';
  
            let profileStatusDiv;
            profileStatusDiv = module.exports.getBrGlContactProfileStatusDiv(statusId,isUnregisteredUser,values);
          
            nameDivContacts = nameDiv + profileStatusDiv + '</div>';
  
            positionDiv = module.exports.glBrGroupPositionDiv(position,activeTechnician);
  
            deptSite = module.exports.glBrGroupDeptSite(department,siteAbbreviation);
  
            deptSiteDiv = module.exports.glBrGroupDeptSiteDiv(deptSite);
  
            let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';
  
            if($(""+elementId+" [id='participant-"+nodeJid+"']").length == 0) {
  
              $(elementId).find('.mCSB_container').append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);
  
              let imgEl = document.getElementById("addParticipantGlobalImgId-"+nodeJid);
                    if(imgEl){
                      utils.imgElementErrorCheck(imgEl)
                    }
                   
  
            }
        }
      }
      module.exports.glBrGroupParticipantDivAppend(elementId)
  
      if (pageNumber == 1 || pageNumber == "") {
        $(elementId).mCustomScrollbar("destroy");
  
        /** Golbal Team search Height Calculation  **/
        let siteHeaderDivHeight = $(elementId+' .broadCastParticipantSitesHeaderDiv:visible').innerHeight()
        siteHeaderDivHeight = module.exports.getBrsiteHeaderDivHeight(siteHeaderDivHeight); 
  
        let contactHeaderDivHeight = $(elementId+' .broadCastParticipantHeaderDiv:visible').innerHeight()
        contactHeaderDivHeight = module.exports.getBrcontactHeaderDivHeight(contactHeaderDivHeight);
  
        let siteElHeight = $(elementId+' .sitecontent:visible').innerHeight();
        siteElHeight = module.exports.getBrcontactsiteElHeight(siteElHeight);
  
        let inputboxHeight = $('#broadcastParticipantSitesText').innerHeight()
  
        let totalHeight = $(elementId).innerHeight();
        let mainDivHeight = $('#broadcast-participants').height()
  
        let divHeightObj = {
          elementId   :   elementId,
          mainDivHeight : mainDivHeight,
          totalHeight : totalHeight,
          siteElHeight  : siteElHeight,
          siteHeaderDivHeight : siteHeaderDivHeight,
          contactHeaderDivHeight  : contactHeaderDivHeight,
          inputboxHeight  : inputboxHeight
        }
  
        module.exports.glBrGroupParticipantDivHeightSet(divHeightObj)
  
        /** Participant Div Calculation and Update**/
        let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId);
        let osType = utils.getDeviceOsType();

        participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight)

        $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
  
          $(elementId).mCustomScrollbar({
              callbacks: {
                  onTotalScroll: function () {
                    console.log('Broadcast Scroll!!!!')

                      let selectedSitesInfo = JSON.parse(localStorage.getItem("selectedSitesInfo")),
                          siteId            = '',
                          rosterCount       = $(''+elementId+' .broadCastParticipantcontent').length,
                          rosterLength      = rosterCount / language.locali().default_size_pagination,
                          page              = Math.round(rosterLength) + 1,
                          search            = $("#broadcastParticipantSitesText").val();
  
                          module.exports.glBrGroupCustomScrollSet(selectedSitesInfo,siteId,rosterCount,page,search)
                  }
              }
          });
      } else {
        /** Participant Div Calculation and Update**/
        let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId);
        let osType = utils.getDeviceOsType();

        participantStaticDivHeight = utils.StaticDivHeightCalculation(osType,participantStaticDivHeight)

        $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
        $(elementId).mCustomScrollbar("update");
      }
  
      return true;
  
  }
  
  /** Broadcast Global Search Contact Append End **/
}

module.exports = broadcastContacts;

