let stropheConnection = require("./stropheConnection.js");
let utils = require("./utils.js");
let grpConversation = require("./conversation.js");
let storage = require("./storage.js");
const contact = require("./contact.js");
const indexDb = require("./indexDb.js");
let language = require('./locale/language.js');
let mediaUrl = window._env_.ApiURL + "/media/";
let colorCode = "";

module.exports = {

  getGroups: function () {
    let groupiq = stropheConnection.StropheJs().$iq({
      type: "get",
      to: "any@mix." + stropheConnection.Server(),
      id: utils.randomString(16, "a#")
    }).c("query", {
      xmlns: "jabber:iq:mixgroupchat",
      status: "get_group_id"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(groupiq, module.exports.groupiqCallback);
    }
    // Indicate that this handler should be called repeatedly
  },
  getGroup: function (groupId) {
    let groupiq = stropheConnection.StropheJs().$iq({
      type: "get",
      to: groupId + "@mix." + stropheConnection.Server(),
      id: utils.randomString(16, "a#")
    }).c("query", {
      xmlns: "jabber:iq:mixgroupchat",
      status: "get_group_profile",
      group_id: groupId
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(groupiq, module.exports.groupiqCallback);
    }
    // Indicate that this handler should be called repeatedly
  },
  groupiqCallbackProfileGet: function(){
    let groupProfile = JSON.parse(localStorage.getItem("groupProfile"));
    if (groupProfile == null) {
      groupProfile = {};
    }
    return groupProfile;
  },
  groupIqCallBackImageEmptyCheck: function(image){
    if (image == "" || image == "empty") {
      image = "src/images/avatar/group/group_four.svg";
    }
    return image;
  },
  groupiqCallbackProfileUpdate: function(groupProfile,nodeJid,groupIQVal,Createddate){
    let nickName,image,specialGroup,notificationGroup,siteId,icon,foregroundColor,locationId;

    if (groupIQVal.find("vCard").length) {
      nickName = groupIQVal.find("vCard").find("nickName").text();
       groupProfile[nodeJid].push(nickName);
       image = groupIQVal.find("vCard").find("image").text().trim();
       image = module.exports.groupIqCallBackImageEmptyCheck(image);
       
       groupProfile[nodeJid].push(image);
       groupProfile[nodeJid].push(Createddate);
       specialGroup = groupIQVal.find("vCard").find("specialGroup").text();
       notificationGroup = groupIQVal.find("vCard").find("notification").text();
       siteId = groupIQVal.find("vCard").find("locationId").text();
       icon = groupIQVal.find("vCard").find("icon").text();
       foregroundColor = groupIQVal.find("vCard").find("foregroundColor").text();
       locationId = groupIQVal.find("vCard").find("locationId").text();
       if (specialGroup)
         groupProfile[nodeJid].push(specialGroup);
       if (notificationGroup)
         groupProfile[nodeJid].push(notificationGroup);
       if (siteId)
         groupProfile[nodeJid].push(siteId);
       if(icon)
         groupProfile[nodeJid].push(icon);
       if(foregroundColor)
         groupProfile[nodeJid].push(foregroundColor);
       if(locationId)
         groupProfile[nodeJid].push(locationId);
     }

     let groupObj = {
      groupProfile : groupProfile,
      image : image,
      icon  : icon,
      foregroundColor : foregroundColor,
      locationId  : locationId
     }

     return groupObj;
  },
  groupiqCallbackProfileImage(image){
    let profileImage;
    let mediaUrl = window._env_.ApiURL + "/media/";
    let userToken = storage.getuserToken();

    if (utils.getHexRegexPattern().test(image)) {
      profileImage = image;
    } else if (image !== "src/images/avatar/group/group_four.svg" && image.trim() !== "") {
      profileImage = mediaUrl + image + "?mf=" + userToken;
    } else if (image.trim() == "") {
      profileImage = "src/images/avatar/group/group_four.svg";
    } else {
      profileImage = image;
    }
    return profileImage;
  },
  groupIqCallbackProfessionAppend: function(locationId,nodeJid){
    if(utils.getSiteAbbreviation(locationId)){
      $("[id='"+nodeJid+"']").find(".profession").show()
      $("[id='"+nodeJid+"']").find(".profession").html(utils.getSiteAbbreviation(locationId));
    }else {
      $("[id='"+nodeJid+"']").find(".profession").html(utils.getSiteAbbreviation(locationId));
      $("[id='"+nodeJid+"']").find(".profession").hide()
    }
  },
  getGroupIqOncallIcon: function(icon){
    icon = (typeof(icon) != 'undefined') ? icon:"";

    return icon;
  },
  getGroupIqForegroundColor: function(foregroundColor){
    let fillColor = (typeof(foregroundColor) != 'undefined') ? foregroundColor:"#fff";

    return fillColor;
  },
  groupiqCallbackIconUpdate(nodeJid,nickName,locationId,image,iconObj,profileImage){
    let icon = iconObj.icon;
    let foregroundColor = iconObj.foregroundColor;

    if ($("[id='"+nodeJid+"']").length) {
      $("[id='"+nodeJid+"']").find(".name-meta").prop("title", minEmoji(nickName));
      $("[id='"+nodeJid+"']").find(".name-meta").html(minEmoji(nickName));
      
      module.exports.groupIqCallbackProfessionAppend(locationId,nodeJid);
      
      $("[id='forwardcontact-"+nodeJid+"']").find(".name-meta").html(minEmoji(nickName));
      let Icon ='';
      if (utils.getHexRegexPattern().test(image) || image.startsWith("#")) {
        let onCallImage = image;
        let onCallIcon = module.exports.getGroupIqOncallIcon(icon);
        let fillColor = module.exports.getGroupIqForegroundColor(foregroundColor);
        switch (onCallIcon) {
          case 'Ambulance':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26848" data-name="Group 26848" transform="translate(-9318 -11373)">\n' +
                '    <g id="Group_26842" data-name="Group 26842">\n' +
                '      <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9318 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '      <path id="ambulance" d="M49.471,177.472a4.948,4.948,0,1,1,1.449,3.5A4.767,4.767,0,0,1,49.471,177.472Zm14.841-19.789H49.471v-9.894h6.107a1.607,1.607,0,0,1,.85.348l7.537,7.537a2.823,2.823,0,0,1,.348.85ZM14.841,177.472a4.948,4.948,0,0,1,4.947-4.947,4.947,4.947,0,1,1-3.5,8.445A4.766,4.766,0,0,1,14.841,177.472ZM9.894,151.5v-7.421a1.191,1.191,0,0,1,1.237-1.237h8.657v-8.658a1.191,1.191,0,0,1,1.237-1.237h7.421a1.191,1.191,0,0,1,1.237,1.237v8.658H38.34a1.191,1.191,0,0,1,1.237,1.237V151.5a1.191,1.191,0,0,1-1.237,1.237H29.683v8.657a1.191,1.191,0,0,1-1.237,1.237H21.025a1.191,1.191,0,0,1-1.237-1.237v-8.657H11.131A1.191,1.191,0,0,1,9.894,151.5ZM0,130.474V175a2.377,2.377,0,0,0,.734,1.739,2.377,2.377,0,0,0,1.739.734H9.894a9.533,9.533,0,0,0,2.9,7,9.533,9.533,0,0,0,7,2.9,9.533,9.533,0,0,0,7-2.9,9.533,9.533,0,0,0,2.9-7H44.524a9.893,9.893,0,0,0,16.89,7,9.533,9.533,0,0,0,2.9-7H69.26a2.474,2.474,0,0,0,0-4.947V156.446a6.027,6.027,0,0,0-.5-2.242,5.876,5.876,0,0,0-1.237-1.971l-7.653-7.653a5.875,5.875,0,0,0-1.971-1.237,6.026,6.026,0,0,0-2.242-.5H49.471V130.474A2.508,2.508,0,0,0,47,128H2.474a2.377,2.377,0,0,0-1.739.734A2.377,2.377,0,0,0,0,130.474Z" transform="translate(9357 11290)"    fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>'
            break;
          case "Group":
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26856" data-name="Group 26856" transform="translate(-9312 -11567)">\n' +
                '    <circle id="Ellipse_254" data-name="Ellipse 254" cx="75" cy="75" r="75" transform="translate(9312 11567)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="group_12_" data-name="group (12)" transform="translate(9343 11612.5)">\n' +
                '      <circle id="Ellipse_260" data-name="Ellipse 260" cx="7.304" cy="7.304" r="7.304" transform="translate(7.304 11.978)"    fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20434" data-name="Path 20434" d="M24.357,14.461a15.3,15.3,0,0,0-6.1,12.234v.913H2.739A2.759,2.759,0,0,1,0,24.868V23.042A10.037,10.037,0,0,1,10.042,13h9.129A9.977,9.977,0,0,1,24.357,14.461Z" transform="translate(0 17.237)"    fill="'+fillColor+'"/>\n' +
                '      <circle id="Ellipse_261" data-name="Ellipse 261" cx="7.304" cy="7.304" r="7.304" transform="translate(65.732 11.978)"     fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20435" data-name="Path 20435" d="M41.687,23.042v1.826a2.759,2.759,0,0,1-2.739,2.739H23.429v-.913a15.3,15.3,0,0,0-6.1-12.234A9.977,9.977,0,0,1,22.516,13h9.129A10.037,10.037,0,0,1,41.687,23.042Z" transform="translate(45.956 17.237)"  fill="'+fillColor+'"/>\n' +
                '      <circle id="Ellipse_262" data-name="Ellipse 262" cx="10.279" cy="10.279" r="10.279" transform="translate(33.065 6.5)" fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20436" data-name="Path 20436" d="M36.627,14H16.542A10.051,10.051,0,0,0,6.5,24.042V29.52a2.74,2.74,0,0,0,2.739,2.739H43.931A2.74,2.74,0,0,0,46.67,29.52V24.042A10.051,10.051,0,0,0,36.627,14Z" transform="translate(17.237 19.888)" fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Role':
            Icon =  '<svg class="svg-code-roles" xmlns="http://www.w3.org/2000/svg" width="24px" height="51px" viewBox="0 0 12.195 10.085">\n' +
                '  <g id="Group_27174" data-name="Group 27174" transform="translate(0.15 0.15)">\n' +
                '    <g id="Id_Card" data-name="Id Card" transform="translate(0 0)">\n' +
                '      <path id="Path_10935" data-name="Path 10935" d="M11.147,23.379H.747A.75.75,0,0,1,0,22.63V16.147A.747.747,0,0,1,.747,15.4H4.512a.18.18,0,0,1,0,.361H.747a.386.386,0,0,0-.387.387V22.63a.387.387,0,0,0,.387.387h10.4a.387.387,0,0,0,.387-.387V16.147a.386.386,0,0,0-.387-.387H7.564a.18.18,0,0,1,0-.361h3.583a.747.747,0,0,1,.747.747V22.63A.749.749,0,0,1,11.147,23.379Z" transform="translate(0 -13.594)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                '      <path id="Path_10937" data-name="Path 10937" d="M9.127,26.377a1.084,1.084,0,1,1,1.084-1.084A1.085,1.085,0,0,1,9.127,26.377Zm0-1.808a.723.723,0,1,0,.723.723A.724.724,0,0,0,9.127,24.569Zm1.857,4.163H7.27a.179.179,0,0,1-.18-.18,2.037,2.037,0,1,1,4.074,0A.179.179,0,0,1,10.984,28.732ZM7.46,28.371h3.332a1.676,1.676,0,0,0-3.332,0Zm8.363-3.278H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,1,1,0-.361h4.011a.18.18,0,1,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Zm0,1.1H11.813a.18.18,0,0,1,0-.361h4.011a.18.18,0,0,1,0,.361Z" transform="translate(-5.772 -20.766)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                '      <g id="Group_14070" data-name="Group 14070" transform="translate(4.33 1.5)">\n' +
                '        <path id="Path_10938" data-name="Path 10938" d="M26.21,15.451h-2.4a.506.506,0,0,1-.506-.506v-.69a.506.506,0,0,1,.506-.506h2.4a.506.506,0,0,1,.506.506v.69A.5.5,0,0,1,26.21,15.451Zm-2.4-1.338a.145.145,0,0,0-.145.145v.69a.145.145,0,0,0,.145.145h2.4a.145.145,0,0,0,.145-.145v-.69a.145.145,0,0,0-.145-.145Z" transform="translate(-23.3 -13.75)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                '      </g>\n' +
                '      <g id="Group_14071" data-name="Group 14071" transform="translate(5.12)">\n' +
                '        <path id="Path_10939" data-name="Path 10939" d="M29.21,7.542a.179.179,0,0,1-.18-.18V6.6a.559.559,0,1,0-1.119,0v.762a.18.18,0,1,1-.361,0V6.6a.92.92,0,1,1,1.84,0v.762A.18.18,0,0,1,29.21,7.542Z" transform="translate(-27.55 -5.68)" fill="'+fillColor+'" stroke="'+fillColor+'" stroke-width="0.3"/>\n' +
                '      </g>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Doctor':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
            '  <g id="Group_26853" data-name="Group 26853" transform="translate(-9312 -11948)">\n' +
            '    <g id="doctor" transform="translate(9356.984 11988.932)">\n' +
            '      <path id="Path_12306" data-name="Path 12306" d="M55.695,47.823c-4.232-8.041-8.464-6.771-13.12-7.195A13.047,13.047,0,0,1,43,44.861c6.771,1.693,8.464,9.734,8.464,14.389v4.232H43V59.25H47.23s0-10.58-6.348-10.58-6.348,10.157-6.348,10.58h4.232v4.232H30.3V59.25c0-4.655,1.693-13.12,8.464-14.389a14.707,14.707,0,0,0-.846-5.5,2.858,2.858,0,0,1-1.693-2.539c0-2.539,3.386-1.693,5.925-6.348,0,0,3.809-9.734,2.539-18.2h0a7.779,7.779,0,0,0-.3-2.18,10.056,10.056,0,0,0-.543-1.9v-.15C42.575,3.809,38.343,0,30.3,0S18.029,3.809,16.336,8.464h0a15.761,15.761,0,0,0-.578,2.07c-.109.9-.129.653-.268,2.162h0c-.846,8.464,2.539,18.2,2.539,18.2,2.539,4.232,5.925,3.386,5.925,6.348,0,2.116-2.116,2.963-4.655,3.386-.846.846-1.693,2.539-1.693,5.925v5.079a6.365,6.365,0,0,1,4.232,5.925c0,2.963-2.963,5.925-6.348,5.925a6.509,6.509,0,0,1-6.348-6.348,5.977,5.977,0,0,1,4.232-5.925V46.13a21.442,21.442,0,0,1,.846-5.5c-2.963.423-6.348,1.693-9.311,7.195C2.37,52.479,1.1,67.714,1.1,67.714H59.08c.423,0-.846-15.236-3.386-19.891Z" transform="translate(0)"  fill="'+fillColor+'"/>\n' +
            '      <path id="Path_12307" data-name="Path 12307" d="M8.232,15.116A2.116,2.116,0,1,1,6.116,13,2.116,2.116,0,0,1,8.232,15.116Z" transform="translate(9.373 42.018)" fill="'+fillColor+'"/>\n' +
            '    </g>\n' +
            '  </g>\n' +
            '</svg>';
              break;
          case 'User':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26854" data-name="Group 26854" transform="translate(-9312 -12119)">\n' +
                '    <circle id="Ellipse_266" data-name="Ellipse 266" cx="75" cy="75" r="75" transform="translate(9312 12119)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="Group_26845" data-name="Group 26845">\n' +
                '      <g id="Group_26843" data-name="Group 26843" transform="translate(322.872 437.125)">\n' +
                '        <g id="Group_26844" data-name="Group 26844" transform="translate(9028 11724.451)">\n' +
                '          <path id="person_4_" data-name="person (4)" d="M19.073,26.468c-.333.016-.667.04-1.267.1C8.89,27.467,0,35.076,0,44.443v8.988H71.9V44.443c0-9.3-8.908-16.865-18.393-17.909-.122-.016-.526-.047-.93-.066-4.853,5.482-9.347,5.019-16.627,5.019S23.927,31.95,19.073,26.468Z" transform="translate(0 11.085)"  fill="'+fillColor+'"/>\n' +
                '          <circle id="Ellipse_263" data-name="Ellipse 263" cx="19" cy="19" r="19" transform="translate(17.128 0.423)"  fill="'+fillColor+'"/>\n' +
                '        </g>\n' +
                '      </g>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Network':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26849" data-name="Group 26849" transform="translate(-9607 -11373)">\n' +
                '    <circle id="Ellipse_267" data-name="Ellipse 267" cx="75" cy="75" r="75" transform="translate(9607 11373)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <path id="Subtraction_7" data-name="Subtraction 7" d="M-8660.631-11598.523h-12.716a1.5,1.5,0,0,1-1.529-1.471v-12.213a1.49,1.49,0,0,1,1.431-1.465h12.946a1.5,1.5,0,0,1,1.4,1.465v12.213A1.5,1.5,0,0,1-8660.631-11598.523Zm-29.813,0h-12.715a1.5,1.5,0,0,1-1.526-1.471v-12.213a1.491,1.491,0,0,1,1.436-1.465h12.9a1.494,1.494,0,0,1,1.438,1.465v12.213A1.5,1.5,0,0,1-8690.444-11598.523Zm-29.809,0h-12.717a1.5,1.5,0,0,1-1.525-1.471v-12.213a1.491,1.491,0,0,1,1.435-1.465h12.9a1.493,1.493,0,0,1,1.437,1.465v12.213A1.5,1.5,0,0,1-8720.253-11598.523Zm25.978-17.613h0l-5.048,0v-7.1h-24.763v7.1h-5.048v-9.527a2.48,2.48,0,0,1,2.524-2.426h27.286v-8.592h-7.946a2.015,2.015,0,0,1-2.051-1.969v-16.377a2.015,2.015,0,0,1,2.051-1.971h20.941a2.014,2.014,0,0,1,2.049,1.971v16.377a2.014,2.014,0,0,1-2.049,1.969h-7.946v8.592h27.286a2.477,2.477,0,0,1,2.521,2.426v9.525l-5.047,0v-7.1h-24.761v7.105Z" transform="translate(18379 23076.344)"  fill="'+fillColor+'"/>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Fire':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26857" data-name="Group 26857" transform="translate(-8957 -11849)">\n' +
                '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(8957 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <path id="Path_20450" data-name="Path 20450" d="M68.919,114.61c-10.275-.177-17.855-5.146-22.613-13.92-5-9.228-4.048-18.452,1.977-27.1,1.28-1.837,2.606-3.935,5.2-3.171,2.793.82,2.189,3.478,2.272,5.615.12,3.186-.106,6.408.29,9.555a7.515,7.515,0,0,0,6.571,6.959c3.591.612,6.868-.025,9.194-3.181a7.847,7.847,0,0,0,.162-9.631c-1.923-2.8-4.564-5.1-6.848-7.659-7.828-8.757-4.5-16.6,1.388-24.524,1.751-2.36,4.134-2.164,4.773.9,1.862,8.931,9.039,13.7,14.691,19.726,14.352,15.3,10.13,37.574-8.383,44.97A24.585,24.585,0,0,1,68.919,114.61Z" transform="translate(8962.914 11844.05)"  fill="'+fillColor+'"/>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Heartbeat':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26850" data-name="Group 26850" transform="translate(-9607 -11557)">\n' +
                '    <circle id="Ellipse_268" data-name="Ellipse 268" cx="75" cy="75" r="75" transform="translate(9607 11557)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="cardiogram" transform="translate(9647 11600)">\n' +
                '      <path id="Path_12304" data-name="Path 12304" d="M35.1,30.829C50.4,17.452,69.844,29.2,70.163,45.139A20.689,20.689,0,0,1,66.739,56.8H51.615l-2.69-4.483a2.055,2.055,0,0,0-3.5-.046l-5.676,8.919L31.48,43.726a2.057,2.057,0,0,0-3.667-.095L20.721,56.8H3.468C-9.312,36.827,15.946,14.073,35.1,30.829Z" transform="translate(0 -25.006)"  fill="'+fillColor+'"/>\n' +
                '      <path id="Path_12305" data-name="Path 12305" d="M65.784,211.935l5.747-10.672,8.126,17.155a2.057,2.057,0,0,0,3.592.223l5.888-9.253,1.578,2.629a2.055,2.055,0,0,0,1.763,1h13.036c-.409.428,1.769-1.746-26.934,26.809a2.056,2.056,0,0,1-2.9,0c-28.261-28.115-26.526-26.382-26.934-26.809H63.974a2.055,2.055,0,0,0,1.81-1.081Z" transform="translate(-42.026 -177.11)"  fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Security':
            Icon ='<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26851" data-name="Group 26851" transform="translate(-9607 -11759)">\n' +
                '    <circle id="Ellipse_269" data-name="Ellipse 269" cx="75" cy="75" r="75" transform="translate(9607 11759)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="Group_26846" data-name="Group 26846" transform="translate(333.508 -74.384)">\n' +
                '      <path id="Subtraction_8" data-name="Subtraction 8" d="M24.859,56.546h0l-.662-.265A39.91,39.91,0,0,1,0,19.556V7.093L24.859,0,49.718,7.093V19.556a39.91,39.91,0,0,1-24.2,36.725l-.662.265ZM24.852,6.895V49.288l.8-.431a33.322,33.322,0,0,0,17.435-29.3V12.1Z" transform="translate(9321 11883)"  fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20439" data-name="Path 20439" d="M256,7.093V19.556a39.924,39.924,0,0,0,24.2,36.725l.663.265V0Z" transform="translate(9065 11883)"  fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Hospital':
            Icon ='<svg id="roles_icons_svg" xmlns ="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26852" data-name="Group 26852" transform="translate(-9607 -11961)">\n' +
                '    <circle id="Ellipse_270" data-name="Ellipse 270" cx="75" cy="75" r="75" transform="translate(9607 11961)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="Group_26847" data-name="Group 26847" transform="translate(484.601 311.972)">\n' +
                '      <path id="Subtraction_5" data-name="Subtraction 5" d="M46.3,77H3.7A3.735,3.735,0,0,1,0,73.244V16.9a3.735,3.735,0,0,1,3.7-3.757H16.667V2a2,2,0,0,1,2-2H33.185a2,2,0,0,1,2,2V13.146H46.3A3.735,3.735,0,0,1,50,16.9V73.244A3.735,3.735,0,0,1,46.3,77ZM17.267,50.707a.6.6,0,0,0-.6.6V70.765a.6.6,0,0,0,.6.6H30.881a.6.6,0,0,0,.6-.6V51.307a.6.6,0,0,0-.6-.6ZM28.377,33.8a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6V42.6a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm20.37-13.146a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H40.14a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6Zm-20.37,0a.6.6,0,0,0-.6.6v8.19a.6.6,0,0,0,.6.6H19.77a.6.6,0,0,0,.6-.6v-8.19a.6.6,0,0,0-.6-.6ZM21.533,6.4a1.173,1.173,0,0,0-1.163,1.18V8.76a1.173,1.173,0,0,0,1.163,1.18h2.676v2.713a1.173,1.173,0,0,0,1.164,1.18h1.163a1.173,1.173,0,0,0,1.164-1.18V9.94h2.675a1.173,1.173,0,0,0,1.164-1.18V7.58A1.173,1.173,0,0,0,30.374,6.4H27.7V3.686a1.173,1.173,0,0,0-1.164-1.179H25.373a1.173,1.173,0,0,0-1.164,1.179V6.4Z" transform="translate(9172.399 11686.028)"  fill="'+fillColor+'"/>\n' +
                '      <path id="Rectangle_7797" data-name="Rectangle 7797" d="M1,0H60a1,1,0,0,1,1,1V4a0,0,0,0,1,0,0H0A0,0,0,0,1,0,4V1A1,1,0,0,1,1,0Z" transform="translate(9167.399 11759.028)"  fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'Operator':
            Icon = '<svg id="roles_icons_svg" xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">\n' +
                '  <g id="Group_26858" data-name="Group 26858" transform="translate(-9116 -11849)">\n' +
                '    <circle id="Ellipse_264" data-name="Ellipse 264" cx="75" cy="75" r="75" transform="translate(9116 11849)" fill="'+ utils.hexToRgbA(onCallImage) +'"/>\n' +
                '    <g id="call-center_Svg" data-name="call-center Svg" transform="translate(9058.147 11838.974)">\n' +
                '      <path id="Subtraction_10" data-name="Subtraction 10" d="M62.066,22.4H.071a5.923,5.923,0,0,1-.061-1.28A22.167,22.167,0,0,1,17.948.054,1.8,1.8,0,0,1,18.3.016a1.2,1.2,0,0,1,.76.27A18.868,18.868,0,0,0,30.291,3.87a27.757,27.757,0,0,0,5.093-.316A18.724,18.724,0,0,0,43.075.287,1.25,1.25,0,0,1,43.864,0a1.643,1.643,0,0,1,.322.034,21.907,21.907,0,0,1,13.453,8.4,21.68,21.68,0,0,1,4.484,13.14,4.633,4.633,0,0,1-.058.816Z" transform="translate(101.853 97.488)"  fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20448" data-name="Path 20448" d="M189.076,137.441a13.047,13.047,0,0,1-6.894-.922,17.627,17.627,0,0,1-7.565-6.2,20.3,20.3,0,0,1-3.646-8.613,16.357,16.357,0,0,1,3.185-13.181,16.617,16.617,0,0,1,29.59,7.565,17.791,17.791,0,0,1-1.278,9.724c-.482,1.132-1.111,2.2-1.677,3.29a.66.66,0,0,1-.356.272c-.9.21-1.823.4-2.724.566a.655.655,0,0,1-.4-.084,4.679,4.679,0,0,0-2.871-.9h-3.206a4.355,4.355,0,0,0-2.766.964,4.515,4.515,0,0,0,.147,7.167A5.149,5.149,0,0,0,189.076,137.441Z" transform="translate(-54.418 -40.296)"  fill="'+fillColor+'"/>\n' +
                '      <path id="Path_20449" data-name="Path 20449" d="M167.212,90.5a17.245,17.245,0,0,1-3.583,2.012,24.651,24.651,0,0,1-6.915,1.739h-.063a1.369,1.369,0,0,0-1.236.587,2.144,2.144,0,0,1-1.823.838H150.3a2.483,2.483,0,0,1-.021-4.967c1.09-.021,2.2,0,3.29,0a2.523,2.523,0,0,1,1.991.943.571.571,0,0,0,.335.168,20.427,20.427,0,0,0,9.409-3.08.354.354,0,0,0,.189-.482A4.252,4.252,0,0,1,165.305,87c-.021-2.745-.021-5.511,0-8.257a4.023,4.023,0,0,1,2.1-3.625.311.311,0,0,0,.189-.356,20.888,20.888,0,0,0-2.6-8.885,21.265,21.265,0,0,0-31.958-6.329,20.587,20.587,0,0,0-3.9,4.086,26.417,26.417,0,0,0-1.572,2.515,20.923,20.923,0,0,0-2.452,8.592.285.285,0,0,0,.168.335,4.037,4.037,0,0,1,2.117,3.688v8.11a4.183,4.183,0,0,1-2.724,4,4.35,4.35,0,0,1-4.82-1.257,3.925,3.925,0,0,1-.943-2.452c-.021-2.892-.042-5.8,0-8.7a3.873,3.873,0,0,1,2.117-3.395.431.431,0,0,0,.251-.4,23.147,23.147,0,0,1,.65-4.485,24.909,24.909,0,0,1,9.263-14.082,24.249,24.249,0,0,1,11.945-4.883,25.157,25.157,0,0,1,27.054,17.079,23.78,23.78,0,0,1,1.216,6.412.341.341,0,0,0,.23.356,4.083,4.083,0,0,1,2.159,3.709c0,2.682-.021,5.365,0,8.047a4.249,4.249,0,0,1-6.413,3.751C167.338,90.586,167.3,90.544,167.212,90.5Z" transform="translate(-13.463)"  fill="'+fillColor+'"/>\n' +
                '    </g>\n' +
                '  </g>\n' +
                '</svg>';
            break;
          case 'None':
            Icon = "";
            break;
          default:
            Icon = "";
        }

        let imageRGB = utils.hexToRgbA(profileImage);
        $("[id='"+nodeJid+"']").find(".other-prof-pic").remove();
        let imgDiv = '<div class="color-code-recent" id="color-' + nodeJid + '">'+Icon+'</div>';
        $("[id='"+nodeJid+"']").find(".avatar-icon").html(imgDiv);
        $("[id='color-"+nodeJid+"']").css("background-color", imageRGB);
        $("[id='forwardcontact-"+nodeJid+"']").find(".forwardprofileicon").remove();
        let fwdImgDiv = '<div class="color-code-fwd" id="color-fwd-' + nodeJid + '"></div>';
        $("[id='forwardcontact-"+nodeJid+"']").find(".avatar-icon").html(fwdImgDiv);
        $("[id='color-fwd-"+nodeJid+"']").css("background-color", imageRGB);
        $(".color-code").html(Icon);
      } else {
        indexDb.blobImageConversion(profileImage,'#recentContacImg-'+nodeJid+'',nodeJid,'profileImages');
        $("[id='"+nodeJid+"']").find(".other-prof-pic").attr("src", profileImage);
        $("[id='forwardcontact-"+nodeJid+"']").find(".forwardprofileicon").attr("src", profileImage);
      }
    }
  },
  groupiqCallbackDataAppend(nodeJid,nickName,profileImage){
    if ($("[id='stared"+nodeJid+"']").length) {
      $("[id='stared"+nodeJid+"']").find(".userpersonal-name").html(minEmoji(nickName));
      $("[id='stared"+nodeJid+"']").find(".other-prof-pic").attr("src", profileImage);
    }
    let toJID = utils.toJID();
    if (toJID != "" && nodeJid == stropheConnection.Strophe().getNodeFromJid(toJID)) {

      $("#message").find(".heading-name-meta").html(minEmoji(nickName));
      $("#right-side-prof").attr("src", '');
      $('#message .heading-avatar-icon div').first().attr('src','')
      $("#userprofile").find(".profile-inner").find("img").attr("src", profileImage);
      $(".groupname").html(minEmoji(nickName) + '<i class="groupNameEdit fa fa-pencil"></i>');
    }
              
    if ($("[id='forwardcontact"+nodeJid+"']").length) {
      $("[id='forwardcontact"+nodeJid+"']").find(".name-meta").html(minEmoji(nickName));
      $("[id='forwardcontact"+nodeJid+"']").find(".forwardprofileicon").attr("src", profileImage);
    }
  },
  groupIqGetTitle: function(count){
    let title;
    if(count == 0){
      title = "Ikonix Connect"
    }else {
      title = "(" + count + ") Ikonix Connect"
    }
    return title;
  },
  groupiqCallbacTitleUpdate(nodeJid){
    if ($("[id='"+nodeJid+"']").length) {
      if ($("[id='"+nodeJid+"']").find(".notify").text() != 0) {
        if (document.title != "Ikonix Connect") {
          let title;
          if (document.title.split("(").pop().split(")")[0] != 0) {
            let count = document.title.split("(").pop().split(")")[0] - 1;
            count = parseInt(count);
            title = module.exports.groupIqGetTitle(count)
          }
          document.title = title;
        }
      }
      $("[id='"+nodeJid+"']").remove();
      $("[id='forwardcontact"+nodeJid+"']").remove();
    }
  },
  groupiqCallback: function (groupsiq) {
    try {
      if ($(groupsiq).find("group").length) {
        let groupProfile = module.exports.groupiqCallbackProfileGet()

        $(groupsiq).find("groupdetails").each(function (index) {
          let groupid = $(this).attr("groupid");
          let Createddate = $(this).attr("createddate");
          let nickName = $(this).find("nickName").text();

          let nodeJid = stropheConnection.Strophe().getNodeFromJid(groupid);
          groupProfile[nodeJid] = [];
          groupProfile[nodeJid].push("group");
          let image,icon,foregroundColor,locationId;

          let groupIQVal = $(this);
          let groupObj = module.exports.groupiqCallbackProfileUpdate(groupProfile,nodeJid,groupIQVal,Createddate)

          groupProfile     = groupObj.groupProfile;
          image            = groupObj.image;
          icon             = groupObj.icon;
          foregroundColor  = groupObj.foregroundColor;
          locationId       = groupObj.locationId;

          localStorage.setItem("groupProfile", JSON.stringify(groupProfile));

          let profileImage = module.exports.groupiqCallbackProfileImage(image);

          let iconObj = {
            icon: icon,
            foregroundColor: foregroundColor
          }
          module.exports.groupiqCallbackIconUpdate(nodeJid,nickName,locationId,image,iconObj,profileImage);

          module.exports.groupiqCallbackDataAppend(nodeJid,nickName,profileImage);

          module.exports.getgroupdetails(nodeJid + "@mix." + stropheConnection.Server());
        });

        utils.forwardmsg();
      } else {

        let groupFrom = groupsiq.getAttribute("from");
        let nodeJid = stropheConnection.Strophe().getNodeFromJid(groupFrom);
        module.exports.groupiqCallbacTitleUpdate(nodeJid);
      }
    } catch (err) {
      console.log("groupiqCallback", err);
      console.log("groupiqCallback iq", groupsiq);
    }
    return true;
  },
  getgroupdetails: function (touser) {

    let groupdetailiq = stropheConnection.StropheJs().$iq({
      type: "get",
      to: touser,
      id: utils.randomString(16, "aA#")
    }).c("query", {
      xmlns: "jabber:iq:mixgroupchat",
      status: "get_participant"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(groupdetailiq, module.exports.groupdetailiqCallback);
    }
  },
  groupdetIqMemberDetail: function(){
    let groupMemberDetail = JSON.parse(localStorage.getItem("groupDetails"));
    if (groupMemberDetail == null) {
      groupMemberDetail = {};
    }

    return groupMemberDetail;
  },
  groupdetIqParticipantAppend(nodeJid,groupparticipant){
    if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
      $("#groupparticipant").html(groupparticipant);
    }
  },
  groupdetailiqCallback: function (groupiqdetail) {
    try {
   
    let userlist = "";
    let usersplit = "";
    let userIsAdmin = "";
    let loggedUserPresent = 0;
    let OldContact = "";
    let status,nickNameJID;
    let toJID = JSON.parse(localStorage.getItem("toJID"));
    let jid = groupiqdetail.getAttribute("from");
    let nodeJid = stropheConnection.Strophe().getNodeFromJid(jid);
    let groupProfile = JSON.parse(localStorage.getItem("groupProfile"));
    let groupInfo  = JSON.parse(localStorage.getItem("groupProfile"));
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let groupMemberDetail = module.exports.groupdetIqMemberDetail()
    let userToken = storage.getuserToken();

    groupMemberDetail[nodeJid] = {};

    if ($(groupiqdetail).find("participants").length) {
      let groupparticipant = '<div class="row compose-sideBar"><small></small><div class="row sideBar-body add-participant-link"><div class="add-people-icon">';
      groupparticipant = groupparticipant + '<i class="add-people"></i></div><div class="col-sm-9 col-xs-9 sideBar-main"><div class="row user-name">';
      groupparticipant = groupparticipant + ' <div class="col-sm-12 col-xs-12 name-meta add-name "><a class="">' + language.locali().add_participants + '</a></div></div></div></div></div><div id="groupmemberslist-'+nodeJid+'"></div>';
      
      module.exports.groupdetIqParticipantAppend(nodeJid,groupparticipant)
      
      let usertext = 0;

      $(groupiqdetail).find("participants").find("participant").each(function () {
        let mediaUrl = window._env_.ApiURL + "/media/";
        let nickName = $(this).find("jid").text();
        let GroupUser = stropheConnection.Strophe().getNodeFromJid(nickName);
        let UserType = $(this).find("affiliation").text();
        let itemId = $(this).find("itemid").text();
        let affiliation = $(this).find("affiliation").text();

        let displayName = $(this).find("nickName").text();
        let userName = $(this).find("nickName").text();
        let image = $(this).find("image").text();
        status = $(this).find("status").text();
        let unregisteredUser = $(this).find("unregistered_user").text();
        let name = $(this).find("nickName").text();
        

        let userJID = stropheConnection.logininfo.userJID;
        if (toJID != null) {
          nickNameJID = stropheConnection.Strophe().getNodeFromJid(nickName);
          let imageExtArray = ['jpg','jpeg','png','webp'];
          let profileExtention = image.split('.').pop();

          $("[id='forwardcontact-"+nickNameJID+"']").css('display', 'none');
          if (nickNameJID != userJID && nickNameJID != storage.adminName()) {
            userlist = userlist + usersplit + utils.toTitleCase(displayName);
            usersplit = ", ";
            if (unregisteredUser != 1) {
              if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
                image = mediaUrl + image + "?mf=" + userToken;
              } else if (image.trim() == '') {
                image = "src/images/avatar/chat/profile_img_one.svg";
              }
            } else {
              image = "src/images/avatar/chat/profile_img_one.svg"; 
            }
            
            let admintext,dropdowntext,participantDiv,imageDiv,nameDiv,statusDiv;
            if (affiliation == 'o') {
              admintext = '<span class="grp-admin"><span class = "grp-admin-user">' + language.locali().group_admin + '</span></span>';
              dropdowntext = '<div class="dropdown contact-opt groupadmin"><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown"><i class="option-arrow"></i></a><ul class="dropdown-menu contact-menu" role="menu" aria-labelledby="menu1">';
              dropdowntext = dropdowntext + '</ul></div>';
            } else {

              admintext = '<span class="grp-admin"></span>';
              dropdowntext = '<div class="dropdown contact-opt groupmember"><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown"><i class="option-arrow"></i></a><ul class="dropdown-menu contact-menu" role="menu" aria-labelledby="menu1">';
              dropdowntext = dropdowntext + '</ul></div>';
            }

            let concatedLetter,
            imageElment;

            if(name != "") {
               
                concatedLetter = utils.getImageDisplayText(name);
            }
            let imageFavIconClass = '';

            if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
              if(imageExtArray.includes(profileExtention)){
                indexDb.blobImageConversion(image,'#groupParticipantList-'+nickNameJID+'',nickNameJID,'profileImages');
                imageElment ='<img id="groupParticipantList-'+nickNameJID+'" src="' + image + '" onerror="' + contact.profileImgError() + '" />';
                imageFavIconClass = 'avatar-icon-height';

              }else {
                imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
              }
            }  else {
                imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
            }

            let groupUserNameClass,groupUserStatusClass;
            if (affiliation == 'o') {
              groupUserNameClass='group_user_name_high';
              groupUserStatusClass = 'group_user_status_high';
            }else {
              groupUserNameClass='group_user_name_low';
              groupUserStatusClass = 'group_user_status_low';
            }


            participantDiv = '<div class="row sideBar-body groupmembers"  id="member-' + nickNameJID + '"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
            imageDiv = '<div class="avatar-icon groupparticiroupmemberspantcontent '+imageFavIconClass+'">'+imageElment+'<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg"></div> </div>';
            
            nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main"><div class="row user-name"><div class="col-sm-12 col-xs-12 sideBar-name"><span class="name-meta contactname groupinfo-name '+groupUserNameClass+'">' + minEmoji(displayName) + '</span>' + admintext ;

            statusDiv = '<div class="sideBar-msg"><span class="name-status  '+groupUserStatusClass+'" style="color:'+utils.getStatusImage(status)+'">' + minEmoji(utils.getStatusName(status)) + ' </span>' + dropdowntext + '</div></div></div></div></div>';
            OldContact += participantDiv + imageDiv + nameDiv + statusDiv;

          } else if (nickNameJID == userJID) {
            loggedUserPresent = 1;
            if (affiliation == 'o') {
              usertext = '<span class="grp-admin"><span class = "grp-admin-user">' + language.locali().group_admin + '</span></span>';
              userIsAdmin = 1;
            } else if (affiliation == 'n') {
              usertext = 1;
              userIsAdmin = 0;
            }
          }
          if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $("#groupmemberslist-"+nodeJid+"").html(OldContact);
          }
          if (usertext != 0) {
            $('#comment').prop('disabled', false);
            $('.heading-attach').show();
            $('.reply-recording').show();
            $('.emojiPickerIconWrap').show();
            $("#message .heading-dot").show();

            displayName = 'You';
            if (usertext != 1) {
              $(".groupadmin ul").append('<li role="presentation"><a  class="remove-grp-cnt-link" >' + language.locali().remove + '</a></li>');
              $(".groupmember ul").append('<li role="presentation"><a  class="remove-grp-cnt-link" >' + language.locali().remove + '</a></li><li role="presentation"><a   href="javascript:void(0);" class="make-grp-admin-link">' + language.locali().make_group_admin + '</a></li>');
            } else {
              usertext = "<span></span>";
              $(".groupadmin").html('');
              $(".groupmember").html('');
              $(".add-participant-link").hide();
            }
            $('.heading-online').css('display', 'block');

            let ProfileImage = myInfo[userJID][2];

            if (utils.getHexRegexPattern().test(ProfileImage)) {
              ProfileImage = myInfo[userJID][2];
            } else if (ProfileImage !== 'src/images/avatar/chat/profile_img_one.svg' && ProfileImage.trim() !== '') {
              ProfileImage = mediaUrl + ProfileImage + "?mf=" + userToken;
              indexDb.blobImageConversion(ProfileImage,'#groupParticipantListAdmin-'+nickNameJID+'',nickNameJID,'profileImages');

            } else if (ProfileImage == '') {
              ProfileImage = "src/images/avatar/chat/profile_img_one.svg";
            } else {
              ProfileImage = myInfo[userJID][2];
            }
            status = myInfo[userJID][3];
            let participantDiv,imageDiv,nameDiv,statusDiv;

            let concatedLetter;
            let myInfoName = myInfo[userJID][1];
            if(myInfoName != "") {
               
                concatedLetter = utils.getImageDisplayText(myInfoName);
            }

            if(ProfileImage == "src/images/avatar/chat/profile_img_one.svg"){
              imageDiv = '<div class="avatar-icon groupparticiroupmemberspantcontent"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg><img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg"></div></div>';

            }else {
              imageDiv = '<div class="avatar-icon groupparticiroupmemberspantcontent avatar-icon-height"><img id="groupParticipantListAdmin-'+nickNameJID+'" src="' + ProfileImage + '"><img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg"></div> </div>';

            }

            participantDiv = '<div class="row sideBar-body groupmembers"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
            nameDiv = '<div class="col-sm-9 col-xs-9 sideBar-main"><div class="row user-name"><div class="col-sm-12 col-xs-12 sideBar-name"><span class="name-meta">' + minEmoji(displayName) + '</span>' + usertext;
            statusDiv = '<div class="sideBar-msg"><span class="name-status" style="color:'+utils.getStatusImage(status)+'">' + minEmoji(utils.getStatusName(status)) + ' </span></div></div></div></div></div>';
            if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
                $("#groupmemberslist-"+nodeJid+"").append(participantDiv + imageDiv + nameDiv + statusDiv);
            }
          } else {
            $('#comment').prop('disabled', true);
            if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
              $('.exit-chat').css('display', 'none');
              $('.exit-group-link').hide();
             }
            $('.reply-recording').hide();
            $('.emojiPickerIconWrap').hide();
            $('.heading-attach').hide();

            $(".groupadmin").html('');
            $(".groupmember").html('');
            $('.contact-exit').find('a').html('');
            $('.heading-online').css('display', 'block');
          }
        }
        if (GroupUser != null && GroupUser != storage.adminName()) {
          groupMemberDetail[nodeJid][itemId] = {};
          groupMemberDetail[nodeJid][itemId].user = GroupUser;
          groupMemberDetail[nodeJid][itemId].type = UserType;
          groupMemberDetail[nodeJid][itemId].displayName = userName;
          groupMemberDetail[nodeJid][itemId].image = image;
          groupMemberDetail[nodeJid][itemId].status = status;
          localStorage.setItem("groupDetails", JSON.stringify(groupMemberDetail));
        }
      });
      if (loggedUserPresent == 1) {
        $(".recentchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
        $("[id='"+nodeJid+"']").find(".exitclass").find(".exit-group-delete").html("Delete Team");
      }

      if (userIsAdmin == 0) {
        $(".groupadmin").html('');
        $(".groupmember").html('');
        $('.contact-exit').find('a').html('');
      }

      let groupMembersCount = _.size(groupMemberDetail[nodeJid]);
      let non_chatuser_participants = $(groupiqdetail).find("non_chatuser_participants").find("participant").length;
      if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
      
        $('#groupparticipant').find('small').html(language.locali().participants + " <i>" + parseInt(groupMembersCount + non_chatuser_participants) + "</i>");
      }

      let memberExist = true;
      let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
      let userJID = stropheConnection.logininfo.userJID;
      let groupmember;
      if (groupDetail != null && typeof groupDetail[nodeJid] != "undefined") {
        groupmember = groupDetail[nodeJid];
        for (let keys in groupmember) {
          let values = groupmember[keys];
          let nickNameJID = values.user;
          if (nickNameJID == userJID) {
            memberExist = false;
          }
        }
        if (memberExist) {
          $(".recentchatcontact").find("[id='forwardcontact"+nodeJid+"']").remove();
          $("[id='"+nodeJid+"']").find(".exitclass").remove();
        }
      }

      $(groupiqdetail).find("non_chatuser_participants").find("participant").each(function () {

        let mediaUrl = window._env_.ApiURL + "/media/";
        if($(this).find('user_id').length != 0){

         let nickName = $(this).find("jid").text();
         let GroupUser = stropheConnection.Strophe().getNodeFromJid(nickName);
         let UserType = $(this).find("affiliation").text();
         let itemId = $(this).find("itemid").text();
         let userName = $(this).find("nickName").text();
         let status = $(this).find("status").text();
         let affiliation = $(this).find("affiliation").text();

        
          let user_id = $(this).find("user_id").text();
          let displayName = $(this).find("given_name").html();
          let preferredDevice = $(this).find("preferred_device").html();
          let name = $(this).find("given_name").html();
          
          if(userlist != "")
            userlist = userlist + ", " + utils.toTitleCase(displayName);
          else
            userlist = utils.toTitleCase(displayName);

          let image = $(this).find("image").text();
          if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
            image = mediaUrl + image + "?mf=" + userToken;
          } else if (image.trim() == '') {
            image = "src/images/avatar/chat/profile_img_one.svg";
          }

          let concatedLetter,
              imageElment;

              if(name != "") {
                  concatedLetter = utils.getImageDisplayText(name);
              }
              let imageFavIconClass = '';

              if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
                  indexDb.blobImageConversion(image,'#groupParticipantListImg-'+nickNameJID+'',nickNameJID,'profileImages');

                  imageElment ='<img id="groupParticipantListImg-'+nickNameJID+'" src="' + image + '" onerror="' + contact.profileImgError() + '" />';
                  imageFavIconClass = 'avatar-icon-height';
              }  else {
                  imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
              }
            let groupUserNameClass,groupUserStatusClass;
            if (affiliation == 'o') {
              groupUserNameClass='group_user_name_high';
              groupUserStatusClass = 'group_user_status_high';
            }else {
              groupUserNameClass='group_user_name_low';
              groupUserStatusClass = 'group_user_status_low';
            }

          let participantDivs = '<div class="row sideBar-body groupmembers"  id="member-' + user_id + '"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
          let imageDivs = '<div class="avatar-icon groupparticiroupmemberspantcontent '+imageFavIconClass+'">'+imageElment+'</div> </div>';
          let nameDivs = '<div class="col-sm-9 col-xs-9 sideBar-main"><div class="row user-name"><div class="col-sm-12 col-xs-12 sideBar-name"><span class="name-meta contactname groupinfo-name '+groupUserNameClass+'">' + minEmoji(displayName) + '</span>';
          let PreferredDeviceDivs = '<div class="sideBar-msg"><span class="name-status '+groupUserStatusClass+'">Contact user via ' + preferredDevice + ' </span></div>';
          let OldContacts = participantDivs + imageDivs + nameDivs + PreferredDeviceDivs + '</div></div></div></div></div>';
          if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $("#groupmemberslist-"+nodeJid+"").append(OldContacts);
          }
          if(typeof(groupInfo[nodeJid][4]) != 'undefined') {

            if (GroupUser != null && GroupUser != storage.adminName()) {
              groupMemberDetail[nodeJid][itemId] = {};
              groupMemberDetail[nodeJid][itemId].user = GroupUser;
              groupMemberDetail[nodeJid][itemId].type = UserType;
              groupMemberDetail[nodeJid][itemId].displayName = userName;
              groupMemberDetail[nodeJid][itemId].image = image;
              groupMemberDetail[nodeJid][itemId].status = status;
              localStorage.setItem("groupDetails", JSON.stringify(groupMemberDetail));
            }
          }
        }
        
      })
    } else {
      if ($("[id='forwardcontact"+nodeJid+"']").length) {
        $("[id='forwardcontact"+nodeJid+"']").remove();
      }
      if ($("[id='"+nodeJid+"']").length) {
        $("[id='"+nodeJid+"']").find(".exitclass").find(".exit-group-delete").html("Delete Team");
      }
      localStorage.setItem("groupDetails", JSON.stringify(groupMemberDetail));
      $("#comment").prop("disabled", true);
    }
    if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
      let image = groupProfile[nodeJid][2];
      if (utils.getHexRegexPattern().test(image)) {
        image = groupProfile[nodeJid][2];
      } else if (image !== 'src/images/avatar/group/group_four.svg' && image.trim() !== '') {
        image = mediaUrl + image + "?mf=" + userToken;
      } else if (image.trim() == '') {
        image = "src/images/avatar/group/group_four.svg";
      }

      if (utils.getHexRegexPattern().test(image)) {
        $("img.right-side-prof").css("display", "none");
        $(".color-code").css("display", "block");
        colorCode = utils.hexToRgbA(image);
        $('.color-code').css('background-color', colorCode);
      } else {
        $("img.right-side-prof").css("display", "block");
        $(".color-code").css("display", "none");
        $(".user_profile-image").attr("src", image);
      }
      /** Check Current User Exist Or Not**/
      let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
      let grpUsers = [];
      let userJID = stropheConnection.logininfo.userJID;
       grpUsers = grpConversation.updateuserprofGetGrpUser(grpUsers,groupDetail,nodeJid)
      let grpMem = grpConversation.updateuserprofGetGrpMem(grpUsers,userJID)
      if(grpMem){
        $('.heading-online').html(userlist + ", You");
        $('.row.reply').css('display', 'flex');
        $(".blocked-person").css("display", "none");
      }else {
        $('.heading-online').html(userlist);
      }
      $('.invitation_div').hide();

      let rightSideHeaderId = $("#rightSideHeaderId").val();
      if(rightSideHeaderId == nodeJid){

        grpConversation.updateuserprofile(nodeJid);
      }
    }
  } catch (error) {
      console.log("Group detail iq Callback Error: ", error)
  }
    return true;
  },
  addMember: function (userid, msgToName) {
    let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let addGroupMember = stropheConnection.StropheJs().$iq({ type: "set", id: utils.getId(), to: utils.toJID() }).c("query", {
      status: "add_participants",
      xmlns: "jabber:iq:mixgroupchat"
    }).c("subscriptions", {}).c("subscribe", { node: "urn:xmpp:mix:nodes:messages" }).up().c("subscribe", { node: "urn:xmpp:mix:nodes:presence" }).up().c("subscribe", { node: "urn:xmpp:mix:nodes:participants" }).up().c("subscribe", { node: "urn:xmpp:mix:nodes:subject" }).up().c("subscribe", { node: "urn:xmpp:mix:nodes:config" }).up().up().c("participants", {}).c("participant", {
      message_from: myInfo[myJid][1],
      message_to: msgToName
    }).t(userid).up();
    $("input:checkbox.select-message3").prop("checked", false).change();
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().send(addGroupMember);
    }
  },
  removemember: function (userid, username) {
    let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let groupmember = groupDetail[stropheConnection.Strophe().getNodeFromJid(tojid)];
    for (let key in groupmember) {
      let values = groupmember[key];
      let itemvalue = values.user;
      let itemtype = values.type;
      let statusIQ;
      if (itemvalue == stropheConnection.Strophe().getNodeFromJid(userid)) {
        if (itemtype == "a" || itemtype == "n") {
          statusIQ = "admin_remove_participant";
        } else {
          statusIQ = "admin_remove_admin";
        }
        let removeMember = stropheConnection.StropheJs().$iq({ type: "set", id: utils.getId(), to: tojid }).c("query", {
          xmlns: "jabber:iq:mixgroupchat",
          status: statusIQ
        }).c("participant", {
          message_from: myInfo[myJid][1],
          message_to: username
        }).t(userid).up();
        delete localStorage.adminUserInfo;
        if (stropheConnection.Connection().connected) {
          stropheConnection.Connection().sendIQ(removeMember, module.exports.getremoveMemberCallBack);
        }
      }
    }
  },
  getremoveMemberCallBack: function (removememberiq) {
    return true;
  },
 
  userexit: function (userid, checkType) {
    let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let groupid;
    if (tojid != null) {
      groupid = stropheConnection.Strophe().getNodeFromJid(tojid);
    }
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    if (groupInfo != null && typeof groupInfo[userid] != "undefined" && groupInfo[userid][0] == "group") {
      tojid = userid + "@mix." + stropheConnection.Server();
      groupid = userid;
      userid = stropheConnection.logininfo.userJID;
    }

    if (checkType == "deletegroup") {
      let isMemberExit;
      isMemberExit = module.exports.checkLogedUserExistInGrop(groupid);
      let isExitAndDel = $("#isExitAndDel").val()

      if(isMemberExit && isExitAndDel != 1){
        $("#isExitAndDel").val(0)
        $("#infoContent").text(language.locali().current_member_alert);
        $('#infobox').css("display", "block").delay(6000).fadeOut();
      }else {
        $("#isExitAndDel").val(0)
        
        module.exports.deleteUserGroupCallBack(tojid);
      }
    } else {
      $("#isExitAndDel").val(0);

      module.exports.exitUserGroupCallBack(tojid,myJid,groupid,userid);
    }
  },
  /** Check Logged User Available in Group **/
  checkLogedUserExistInGrop(groupId){
    let memberExist = false;
    let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
    let userJID = stropheConnection.logininfo.userJID;
    let groupmember;
    if (groupDetail != null && typeof groupDetail[groupId] != "undefined") {
      groupmember = groupDetail[groupId];
      for (let keys in groupmember) {
        let values = groupmember[keys];
        let nickNameJID = values.user;
        if (nickNameJID == userJID) {
          memberExist = true;
        }
      }
      return memberExist;
    }
  },
  deleteUserGroupCallBack: function(tojid){
    let userExit = stropheConnection.StropheJs().$iq({ type: "set", id: utils.getId(), to: tojid }).c("query", {
      xmlns: "jabber:iq:mixgroupchat",
      status: "user_delete_group"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(userExit, module.exports.getuserExitCallBack);
      $(".side-frd-profile").css({
        "right": "-100%"
      });
    }
  },
  exitUserGroupCallBack: function(tojid,myJid,groupid,userid){
    let nodeJid,groupmember,statusIQ,userExit;
    let itemtype = "";
    let exituser = userid + "@" + stropheConnection.Server();
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));

    if (userid == stropheConnection.logininfo.userJID) {
      nodeJid = stropheConnection.Strophe().getNodeFromJid(tojid);
      $(".recentchatcontact").find("[id='forwardcontact-"+nodeJid+"']").remove();
    }
    if (groupDetail != null && typeof groupDetail[groupid] != "undefined") {
      groupmember = groupDetail[groupid];
      for (let key in groupmember) {
        let value = groupmember[key];
        let itemvalue = value.user;
        if (itemvalue == userid) {
          itemtype = value.type;
        }
      }
      if (itemtype == "n") {
        statusIQ = "user_exit_group";
      } else {
        statusIQ = "admin_exit_group";
      }
      userExit = stropheConnection.StropheJs().$iq({ type: "set", id: utils.getId(), to: tojid }).c("query", {
        xmlns: "jabber:iq:mixgroupchat",
        status: statusIQ
      }).c("participant", {
        message_from: myInfo[myJid][1],
        message_to: myInfo[myJid][1]
      }).t(exituser).up();
      if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().sendIQ(userExit, module.exports.getuserExitCallBack);
      }
    } else {
      module.exports.getgroupdetails(tojid);
      setTimeout(function () {
        module.exports.userexit(userid, "");
      }, 2000);
    }
  },
  getuserExitCallBack: function (removememberiq) {
    try {
      let jid = removememberiq.getAttribute("from");
      module.exports.getgroupdetails(jid);
    } catch (err) {
      console.log("getuserExitCallBack", err);
      console.log("getuserExitCallBack iq", removememberiq);
    }
    return true;
  },
  makeadmin: function (userid, username) {
    let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let tojid = JSON.parse(localStorage.getItem("toJID"));
    let makeAdmin = stropheConnection.StropheJs().$iq({ type: "set", id: utils.getId(), to: tojid }).c("query", {
      xmlns: "jabber:iq:mixgroupchat",
      status: "make_admin"
    }).c("participant", {
      message_from: myInfo[myJid][1],
      message_to: username  
    }).t(userid).up();
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(makeAdmin, module.exports.getmakeAdminCallBack);
    }
  },
  getmakeAdminCallBack: function (makeadminiq) {
    return true;
  },
  profileImgError: function() {
    $('img').on("error", function () {
      this.onerror = "";
      this.src = "./src/images/avatar/chat/profile_img_one.svg";
    });
  }
};
